import React, { useState } from "react";

const EditModal = ({ registeredOrganizationEntryIndexClicked, registeredOrganizationList, setRegisteredOrganizationList, setEditModalStatus }) => {
	const [editedRegisteredOrganization, setEditedRegisteredOrganization] = useState(
		registeredOrganizationList[registeredOrganizationEntryIndexClicked]
	);
	const handleEdit = () => {
		let updatedRegisteredOrganizationList = [...registeredOrganizationList];
		updatedRegisteredOrganizationList[registeredOrganizationEntryIndexClicked] = editedRegisteredOrganization;
		setRegisteredOrganizationList(updatedRegisteredOrganizationList);
	};

	return (
		<>
			<input type="checkbox" id="edit_registeredOrganization_modal" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box !rounded-none h-fit overflow-visible bg-white">
					<h3 className="font-bold text-lg text-black">Edit Organization Name</h3>
					<textarea className="mt-2 w-full" value={editedRegisteredOrganization} onChange={(e) => setEditedRegisteredOrganization(e.target.value)} />

					<div className="modal-action">
						<label
							className="btn btn-success !rounded-none"
							htmlFor="edit_registeredOrganization_modal"
							onClick={() => {
								handleEdit();
								setEditModalStatus(false);
							}}
						>
							Confirm
						</label>
						<label
							htmlFor="edit_registeredOrganization_modal"
							className="btn btn-active btn-neutral !rounded-none"
							onClick={() => setEditModalStatus(false)}
						>
							Close
						</label>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditModal;
