import { useState, useEffect } from 'react';
import { db } from "../firebase/firebase.config";
import { doc, onSnapshot } from 'firebase/firestore';

const useFirestoreDocument = (collection, documentId) => {
    const [aDocument, setADocument] = useState(null);
    const [docLoading, setDocLoading] = useState(true);

    useEffect(() => {

        const unsubscribe = onSnapshot(doc(db, collection, documentId), (doc) => {
            // console.log("Current data: ", doc.data());
            const data = doc.data();
            if (data) {
                setADocument(data);
            } else {
                setADocument(null);
            }
            setDocLoading(false);
        }, (error) => {
            console.error('Error fetching document:', error);
            setDocLoading(false);
        });

        return () => {
            unsubscribe(); 
        };
    }, [collection, documentId]);

    return [aDocument, docLoading];
};

export default useFirestoreDocument;