import React, { useContext, useEffect, useState } from "react";
import AlMonkLogo from "../../components/AlMonkLogo";
import EntryList from "./EntryList";
import EntryDetails from "./EntryDetails";
import { DataContext } from "../../context/DataProvider/DataProvider";
import { AuthContext } from "../../context/AuthProvider/AuthProvider";
import useMasterAdmin from "../../hooks/useMasterAdmin";
import { collection, doc, getDocs, onSnapshot, query, where, or, and } from "firebase/firestore";
import { db } from "../../firebase/firebase.config";
import Loading from "../../components/Loading/Loading";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { eventTodaysDate } from "../../Utility/FixTodaysDate";
import toast from "react-hot-toast";
import EventFilter from "../Events/EventFilter";


function getDateInYYYYMMDD(date) {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");

	return `${year}${month}${day}`;
}

const formatDate = (date) => {
	const formatter = new Intl.DateTimeFormat("default", { month: "short" });
	const month = formatter.format(date);
	return `${date.getDate().toString().padStart(2, "0")}-${month}-${date.getFullYear()}`;
};

const EntryDashboardMain = () => {
	const { clientsData, clientIds, userClientAndLocation } = useContext(DataContext);
	const { user} = useContext(AuthContext);
	const [isMasterAdmin] = useMasterAdmin(user?.email);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedEntryVehicle, setSelectedEntryVehicle] = useState({});
	const [totalPages, setTotalPages] = useState(0);
	const [queryFromCancellingFilterBox, setQueryFromCancellingFilterBox] = useState(false);
	const [filteredEntryEventData, setFilteredEntryEventData] = useState({
		filterSatus: false,
		filteredData: [],
		stage:false
	});
	const [selectedFilterOptions, setSelectedFilterOptions] = useState({
		selectedClient: "",
		selectedLocations: [],
		selectedZones: [],
		selectedDetections: [],
		selectedDirections: [],
	});
	const [filterValues, setFilterValues] = useState({
		filterClient: "",
		filterLocation: "",
		filterZoneName: "",
		filterDetectionType: "",
		filterDirectionType: "",
		filterDate: new Date(),
		values: false,
	});

	const resetHandler = async () => {
		setSelectedFilterOptions({
			selectedClient: "",
			selectedLocations: [],
			selectedZones: [],
			selectedDetections: [],
			selectedDirections: [],
		});
		if (queryFromCancellingFilterBox) setQueryFromCancellingFilterBox(false);
		setFilterValues((prev) => ({ ...prev, values: false }));
		setFilteredEntryEventData((prev) => ({ ...prev, filterSatus: false }));

		const clientID = selectedFilterOptions.selectedClient;
		const todaysDate = eventTodaysDate();
		const collectionRef = collection(db, `${clientID}-detection-events-${todaysDate}`);
		const queryConstraints = [];
		if (!isMasterAdmin) {
			const locationIDs = userClientAndLocation?.userLocation;
			queryConstraints.push(where("LocationID", "in", locationIDs));
		}
		const q = query(collectionRef, ...queryConstraints);
		getEntryEventsData(q);

		toast.success("Filters reset to default");
	};

	// filter data accoring to location, vehicle and date
	const handleFilter = async (event) => {

		event.preventDefault();
		const client = selectedFilterOptions.selectedClient;
		const locations = selectedFilterOptions.selectedLocations.map((location) => location.value);
		const detections = new Set(selectedFilterOptions.selectedDetections.map((zone) => zone.value))
		const dateValue = event.target.date.value;
		var date = dateValue.replace(/-/g, "");

		setFilteredEntryEventData({
			filterSatus: true,
			filteredData: [],
			stage:false
		})

		const queryConstraints = [];
		if (client) queryConstraints.push(where("ClientID", "==", client));

		if (locations.length) {
			const constraints = [];
			locations.forEach((location) => constraints.push(where("LocationID", "==", location)));
			queryConstraints.push(or(...constraints));
		} else {
			toast.error("Locations Not Selected");
			return;
		}
		const collectionRef = collection(db, `${client}-entries-${date}`);
		const q = query(collectionRef, and(...queryConstraints));
		const querySnapshot = await getDocs(q);

		let list = [];
		querySnapshot.forEach((doc) => {
			list.push({ id: doc.id, ...doc.data() });
		});

		if (detections.size) list = list?.filter((item) => detections.has(item?.Detection));
		list.sort((a, b) => {
			if (a?.Timestamp < b?.Timestamp) return 1;
			if (b?.Timestamp < a?.Timestamp) return -1;
			return 0;
		});

		setFilterValues({
			filterClient: clientsData?.find((item) => item?.ClientID === client).Client,
			filterLocations: selectedFilterOptions.selectedLocations,
			filterZoneNames: selectedFilterOptions.selectedZones,
			filterDetectionTypes: selectedFilterOptions.selectedDetections,
			filterDirectionTypes: selectedFilterOptions.selectedDirections,
			filterDate: formatDate(new Date(dateValue)),
			values: true,
		});		
		setFilteredEntryEventData({
			filterSatus: true,
			filteredData: list,
			stage:true
		});
		if (queryFromCancellingFilterBox) setQueryFromCancellingFilterBox(false);
		setTotalPages(Math.ceil(list?.length / 20));
		setIsLoading(false);
	};

	const queryAndSet = async () => {

		if(filteredEntryEventData?.filterSatus==true){
			return
		}

		if(!selectedFilterOptions?.selectedClient){
			return
		}

		const client = selectedFilterOptions.selectedClient;
		const date = getDateInYYYYMMDD(new Date(filterValues.filterDate));

		const queryConstraints = [];
		queryConstraints.push(where("ClientID", "==", client));

		const collectionRef = collection(db, `${clientIds?.[0]}-entries-${date}`);
		const q = query(collectionRef, and(...queryConstraints));
		const querySnapshot = await getDocs(q);
		
		let list = [];
		querySnapshot.forEach((doc) => {
			list.push({ id: doc.id, ...doc.data() });
		});

		let filteredList = [...list];
		filteredList.sort((a, b) => {
			if (a?.Timestamp < b?.Timestamp) return 1;
			if (b?.Timestamp < a?.Timestamp) return -1;
			return 0;
		});

		setFilteredEntryEventData({
			filterSatus: true,
			filteredData: filteredList,
			stage:true
		});
		setTotalPages(Math.ceil(filteredList?.length / 20));
	};

	//helper function to fetch eventsdata
	function getEntryEventsData(q) {
		const unsubscribe = onSnapshot(
			q,
			(querySnapshot) => {
				let list = [];
				querySnapshot.docs.forEach((doc) => {
					list.push({ id: doc.id, ...doc.data() });
				});
				// console.log(list?.length);

				list.sort((a, b) => {
					if (a?.Timestamp < b?.Timestamp) return 1;
					if (b?.Timestamp < a?.Timestamp) return -1;
					return 0;
				});

				setIsLoading(false);
				setTotalPages(Math.ceil(list?.length / 20));
			},
			(error) => {
				console.log(error);
			}
		);
		return () => {
			unsubscribe();
		};
	}

	// initial select the first element from the data list
	useEffect(() => {
		console.log(filteredEntryEventData?.filteredData?.length, 'filteredEntryEventData', selectedEntryVehicle)
		if (filteredEntryEventData?.filteredData?.length && Object.keys(selectedEntryVehicle)?.length>=0) {
			setSelectedEntryVehicle(filteredEntryEventData?.filteredData?.[0]);
		}
		if (filteredEntryEventData?.stage && filteredEntryEventData?.filteredData?.length<=0) {
			setSelectedEntryVehicle(null);
		}
	}, [selectedEntryVehicle, filteredEntryEventData]);

	// get selected Vehicle
	const handleSelectedEntryVehicle = (selectedEntryVehicle) => {
		setSelectedEntryVehicle(selectedEntryVehicle);
	};

	useEffect(() => {
			queryAndSet();
	}, [selectedFilterOptions, filteredEntryEventData]);
	

	return (
		<div className="bg-white">
			<>
				{/* header div */}
				<div className="flex bg-white items-center justify-between py-2 sticky top-0 z-[2]">
					<div className="pl-3 text-sm text-black">
						{filterValues?.values && (
							<div className="flex items-center">
								<p className="flex items-center font-semibold">
									Client <MdOutlineKeyboardArrowRight className="pt-1 text-[19px]" />
									<span className="font-normal">{filterValues?.filterClient}</span>
								</p>
								<p className="flex items-center font-semibold ml-2">
									Location <MdOutlineKeyboardArrowRight className="pt-1 text-[19px] ml-2" />
									<span className="font-normal">{filterValues?.filterLocation}</span>
								</p>
								<p className="flex items-center font-semibold ml-2">
									Zone
									<MdOutlineKeyboardArrowRight className="pt-1 text-[19px]" />
									<span className="font-normal">{filterValues?.filterZoneName}</span>
								</p>
								<p className="flex items-center font-semibold ml-2">
									Detections
									<MdOutlineKeyboardArrowRight className="pt-1 text-[19px]" />
									<span className="font-normal">{filterValues?.filterDetectionType}</span>
								</p>
								<p className="flex items-center font-semibold ml-2">
									Action
									<MdOutlineKeyboardArrowRight className="pt-1 text-[19px]" />
									<span className="font-normal">{filterValues?.filterDirectionType}</span>
								</p>
								<p className="flex items-center font-semibold ml-2">
									Date
									<MdOutlineKeyboardArrowRight className="pt-1 text-[19px]" />
									<span className="font-normal">{filterValues?.filterDate}</span>
								</p>
							</div>
						)}
					</div>

					<div className="flex items-center justify-end">
						<input type="text" placeholder="Search" className="input input-bordered rounded mr-3 bg-white" />
						<EventFilter
							handleFilter={handleFilter}
							setIsLoading={setIsLoading}
							isLoading={isLoading}
							selectedFilterOptions={selectedFilterOptions}
							setSelectedFilterOptions={setSelectedFilterOptions}
							resetHandler={resetHandler}
						></EventFilter>
						<div className="ml-5">
							<AlMonkLogo />
						</div>
					</div>
				</div>


				{
				filteredEntryEventData?.stage ? (
					filteredEntryEventData?.filteredData?.length ? (
						<>
							{/* events data section */}
							<div className="flex lg:flex-row md:flex-row flex-col ">
								<EntryList
									entryEventData={filteredEntryEventData?.filteredData}
									selectedEntryVehicle={selectedEntryVehicle}
									handleSelectedEntryVehicle={handleSelectedEntryVehicle}
									totalPages={totalPages}
									setIsLoading={setIsLoading}
									isLoading={isLoading}
								></EntryList>
								<EntryDetails isLoading={isLoading} selectedEntryVehicle={selectedEntryVehicle} setIsLoading={setIsLoading}></EntryDetails>
							</div>
						</>
					) : (
						<div className="flex justify-center items-center mx-auto text-black">
							<h2 className="text-xl mt-5 font-semibold">No data found</h2>
						</div>
					)
				) : (
					<Loading></Loading>
				)
				}
			
			</>
		</div>
	);
};

export default EntryDashboardMain;