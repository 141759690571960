import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const SecurityEventsChart = ({ securityEvents }) => {
  // console.log(dailyTrafficReport);
  // const securityEventsReport = [
  //     {
  //         date: '2023-12-16',
  //         Update: 4000,
  //         Approved: 2400,
  //         Pending: 2400,
  //         Rejected: 100,
  //     },
  //     {
  //         date: '2023-12-10',
  //         Update: 3000,
  //         Approved: 1398,
  //         Pending: 2210,
  //         Rejected: 100,
  //     },
  //     {
  //         date: '2023-12-09',
  //         Update: 2000,
  //         Approved: 9800,
  //         Pending: 2290,
  //         Rejected: 100,
  //     },
  //     {
  //         date: '2023-12-15',
  //         Update: 2780,
  //         Approved: 3908,
  //         Pending: 2000,
  //         Rejected: 1000,
  //     },
  //     {
  //         date: '2023-12-11',
  //         Update: 1890,
  //         Approved: 4800,
  //         Pending: 2181,
  //         Rejected: 400,
  //     },
  //     {
  //         date: '2023-12-12',
  //         Update: 2390,
  //         Approved: 3800,
  //         Pending: 2500,
  //         Rejected: 200,
  //     },
  //     {
  //         date: '2023-12-13',
  //         Update: 3490,
  //         Approved: 4300,
  //         Pending: 2100,
  //         Rejected: 100,
  //     },
  // ];

  return (
    <div className="mt-7 mb-10 flex justify-center">
      <ResponsiveContainer width="70%" height={400}>
        <BarChart
          width={500}
          height={300}
          data={securityEvents}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Date" />
          <YAxis />
          <Tooltip />
          <Legend />

          <Bar dataKey="Update" stackId="a" fill="#f06656"></Bar>
          <Bar dataKey="Pending" stackId="a" fill="#f7bc57"></Bar>
          <Bar dataKey="Approved" stackId="a" fill="#82ca9d"></Bar>
          <Bar dataKey="Rejected" stackId="a" fill="#000"></Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SecurityEventsChart;
