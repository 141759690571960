import { collection, onSnapshot, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../../../firebase/firebase.config";
import Loading from "../../../../../components/Loading/Loading";
import { CSVLink } from "react-csv";

const formateDate = (d) => {
	let day = d.getDate().toString().padStart(2, "0");
	let month = (d.getMonth() + 1).toString().padStart(2, "0");
	let year = d.getFullYear();
	return `${year}-${month}-${day}`;
};

const combineFormattedDate = (d) => d.split("-").join("");

const StatusReport = ({ selectedClient, selectedLocation }) => {
	const [statusReport, setStatusReport] = useState([]);

	const [date, setDate] = useState(formateDate(new Date()));

	const [loading, setLoading] = useState(true);

	const fetchSatusReport = async () => {
		const q = query(
			collection(db, `${selectedClient.id}-detection-events-${combineFormattedDate(date)}`),
			where("Location-ID", "==", selectedLocation.id)
		);

		const unsubscribe = onSnapshot(q, async (querySnapshot) => {
			if (querySnapshot.empty) {
				setLoading(false);
				setStatusReport([]);
				return;
			}

			const countByStatus = {
				Approved: { Entry: 0, Exit: 0 },
				Closed: { Entry: 0, Exit: 0 },
				Pending: { Entry: 0, Exit: 0 },
			};
			querySnapshot.docs.forEach((doc) => {
				const data = doc.data();
				const direction = data?.Direction;
				const status = data?.Status;
				// const priority = data?.Priority;

				if (status && status.toLowerCase() !== "critical") {
					if (direction === "Entry") countByStatus[status]["Entry"] += 1;
					else if (direction === "Exit") countByStatus[status]["Exit"] += 1;
				}
			});

			const report = Object.keys(countByStatus).map((status) => ({
				Status: status,
				Entry: countByStatus[status]["Entry"],
				Exit: countByStatus[status]["Exit"],
			}));
			setStatusReport(report);
			setLoading(false);
		});

		return () => {
			unsubscribe();
		};
	};

	useEffect(() => {
		if (selectedClient?.id && selectedLocation?.id && date) {
			setLoading(true);
			fetchSatusReport();
		}
	}, [selectedClient?.id, selectedLocation?.id, date]);

	return (
		<div className="ml-6">
			<h1 className="text-sky-900 text-2xl font-bold mr-3">Status Report</h1>
			<div className="flex flex-col gap-3 my-2">
				<div className="flex flex-row justify-between items-center">
					{/* Date */}
					<div className="flex flex-row gap-8">
						<h1 className="text-black text-lg">Date:</h1>
						<input
							className="bg-gray-100 rounded text-black w-32"
							id="datepicker"
							type="date"
							placeholder="Pick Date"
							value={date}
							onChange={(e) => setDate(e.target.value)}
							max={formateDate(new Date())}
						/>
					</div>
					{!loading && statusReport.length && (
						<CSVLink
							data={statusReport}
							filename={`${new Date(date).toLocaleDateString("en-us", {
								year: "numeric",
								month: "short",
								day: "numeric",
							})} Status Report.csv`}
							className="mr-14 bg-green-500 hover:bg-green-900 hover:border-green-300 px-2 py-1 active:border-2 active:border-green-900 w-36 text-center text-white font-semibold h-[2.5rem]   border-2 border-green-800"
						>
							Download
						</CSVLink>
					)}
				</div>
				{loading ? (
					<Loading />
				) : statusReport.length ? (
					<div className="flex flex-col gap-7 mr-14 overflow-x-auto overflow-y-auto max-h-[70vh]">
						<table className="my-5 text-center self-center w-1/2 border-t-[3px] border-t-black border-b-2 border-b-black">
							<thead className="sticky top-0 bg-gray-100">
								<tr className="border-b text-black">
									<th className="p-3  max-w-[200] xl:text-base text-sm">Status</th>
									<th className="p-3  max-w-[200] xl:text-base text-sm">Entry</th>
									<th className="p-3  max-w-[200] xl:text-base text-sm">Exit</th>
								</tr>
							</thead>
							<tbody className="bg-sky-100">
								{statusReport.map((ele) => (
									<tr key={ele.Status} className="border-b text-black">
										<td className="p-3 max-w-[80] xl:text-base text-sm">{ele.Status}</td>
										<td className="p-3 max-w-[80] xl:text-base text-sm">{ele.Entry}</td>
										<td className="p-3 max-w-[80] xl:text-base text-sm">{ele.Exit}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				) : (
					<div className="flex flex-row justify-around my-6">
						<div className="alert alert-info px-20 w-fit">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
								></path>
							</svg>
							<span>No Data for {date} is available yet !</span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default StatusReport;
