import React, { useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthProvider/AuthProvider';
import logo from '../../images/logo.png';
import { DataContext } from '../../context/DataProvider/DataProvider';

const Login = () => {
    const { user, logIn, setAuthLoading, resetPassword, verifyEmail, logOut } = useContext(AuthContext);
    const { setUserClientAndLocation } = useContext(DataContext);
    const [error, setError] = useState({
        status: false,
        message: ''
    });
    const [userEmail, setUserEmail] = useState('');

    // For redirection
    const location = useLocation();
    const navigate = useNavigate();
    const from = location.state?.from?.pathname || '/dashboard';

    const handleSubmit = event => {
        event.preventDefault();
        const form = event.target;
        const email = form.email.value;
        const password = form.password.value;
        // console.log(email, password);

        if (!email && !password) {
            setError({
                status: true,
                message: 'Invalid username and password'
            });
        } else if (!email && password) {
            // console.log('Invalid email');
            setError({
                status: true,
                message: 'Invalid email'
            });
        } else if (!password && email) {
            // console.log('Invalid Password');
            setError({
                status: true,
                message: 'Invalid Password'
            });
        } else if (email && password) {
            setError({
                status: false,
                message: ''
            });
            logIn(email, password)
                .then(result => {
                    const user = result.user;
                    //console.log(user);
                    form.reset();
                    if (!user.emailVerified) {
                        handleVerifyEmail();
                        toast.error('Email is not verified, check your email');
                        //reset the user client and location info
                        setUserClientAndLocation({
                            userClientID: "",
                            userClientName: "",
                            userLocation: "",
                        })

                        logOut()
                            .then(() => { })
                            .catch(error => console.error(error))
                    }
                    else {
                        // console.log(user);
                        if (user?.uid) {

                            toast.success('Login successfull');
                            navigate('/dashboard');

                            //store last login
                            try {

                                const userInfo = {
                                    userId: user?.uid
                                };
                                fetch('http://gk.secquraise.com/api/updateLoginTime', {
                                    method: 'POST',
                                    headers: {
                                        'content-type': 'application/json'
                                    },
                                    body: JSON.stringify(userInfo)
                                })
                                    .then(res => res.json())
                                    .then(data => {
                                        // console.log('saved user details:', data);                                   
                                    })
                            }
                            catch (err) {
                                console.error(err);
                            };
                        }
                    }

                })
                .catch(err => {
                    console.error(err.message);
                    form.reset();
                    setError({
                        status: true,
                        message: 'Wrong username or password'
                    });
                    toast.error('Wrong username or password');
                })
                .finally(() => {
                    setAuthLoading(false);
                })
        }

    }

    //Send verification email
    const handleVerifyEmail = () => {
        verifyEmail()
            .then(() => {
            });
    }

    //Get the email
    const handleEmailBlur = event => {
        const email = event.target.value;
        if (email) {
            // console.log("email");
            setUserEmail(email);
        }
    }

    //Set new password(forgot password)
    const handleResetPassword = () => {
        if (!userEmail) {
            toast.error('Please enter your email address.');
            return;
        }
        resetPassword(userEmail)
            .then(() => {
                toast.success('Password reset email sent. Please check your email.');
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorMessage);
            });
    }

    return (
        <div className='h-[90vh]'>

            <div className='w-[200px] bg-[#ff847c] flex justify-evenly items-center fixed right-0 top-0'>
                <a href="https://www.aimlmonks.com/" target="_blank" rel="noreferrer">
                    <img src={logo} alt="logo" />
                </a>
                <p className='text-white text-xl'>AI/ML Monks</p>
            </div>

            {/* ----login form---- */}
            <form onSubmit={handleSubmit} className='max-w-[400px] p-4 border mt-[10%] mx-auto'>
                <h1 className='font-bold text-3xl text-center mb-10'>Login</h1>
                <div className='grid grid-cols-1 gap-4'>

                    <div className="form-control">
                        <input onBlur={handleEmailBlur} type="email" name='email' placeholder="Email" className="input w-full bg-white border-[#D9D9D9] rounded-none" />
                    </div>
                    <div className="form-control mt-4">
                        <input type="password" name='password' placeholder="Password" className="input w-full bg-white border-[#D9D9D9] rounded-none" />
                    </div>

                </div>

                <input className='btn w-[70%] mt-8 mx-auto block bg-[#375075] text-white hover:bg-[#A6A6A6] border-0 rounded-none' type="submit" value="Login" />
                {
                    error?.status && <p className='text-red-500 mt-3'>{error?.message}</p>
                }
                <p onClick={handleResetPassword} className='text-blue-500 text-center mx-auto mt-3 hover:cursor-pointer'>Forgotten password?</p>
            </form>
        </div>
    );
};

export default Login;