import React from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { isSubstringInArray } from "../../Utility/isSubstringInArray";
import { useContext } from "react";
import { DataContext } from "../../context/DataProvider/DataProvider";
import { AuthContext } from "../../context/AuthProvider/AuthProvider";
import useMasterAdmin from "../../hooks/useMasterAdmin";
import { useEffect } from "react";
import { useState } from "react";
import { BsPinFill } from "react-icons/bs";

// formats date to 1-Oct-2023 format
const formatDate = (date) => {
	const formatter = new Intl.DateTimeFormat("default", { month: "short" });
	const month = formatter.format(date);
	return `${date.getDate().toString().padStart(2, "0")}-${month}-${date.getFullYear()}`;
};

const LocationZoneDetectionDirectionRender = ({ clientName }) => {
	return (
		<div className="flex items-center">
			<p className="flex items-center font-semibold">
				Client <MdOutlineKeyboardArrowRight className="pt-1 text-[19px]" />
				<span className="font-normal">{clientName}</span>
			</p>
			<p className="flex items-center font-semibold ml-2">
				Location <MdOutlineKeyboardArrowRight className="pt-1 text-[19px] ml-2" />
				<span className="font-normal">All</span>
			</p>
			<p className="flex items-center font-semibold ml-2">
				Zone
				<MdOutlineKeyboardArrowRight className="pt-1 text-[19px]" />
				<span className="font-normal">All</span>
			</p>
			<p className="flex items-center font-semibold ml-2">
				Detections
				<MdOutlineKeyboardArrowRight className="pt-1 text-[19px]" />
				<span className="font-normal">All</span>
			</p>
			<p className="flex items-center font-semibold ml-2">
				Direction
				<MdOutlineKeyboardArrowRight className="pt-1 text-[19px]" />
				<span className="font-normal">All</span>
			</p>
			<p className="flex items-center font-semibold ml-2">
				Date
				<MdOutlineKeyboardArrowRight className="pt-1 text-[19px]" />
				<span className="font-normal">{formatDate(new Date())}</span>
			</p>
		</div>
	);
};

const FilterHeaderDisplay = ({
	filterValues,
	setFilterValues,
	setSelectedFilterOptions,
	queryFromCancellingFilterBox,
	setQueryFromCancellingFilterBox,
}) => {
	const zoneIdList = filterValues.filterZoneNames.map((zone) => zone.value);

	const { clientsData, userClientAndLocation } = useContext(DataContext);
	const { user, authLoading } = useContext(AuthContext);
	const [isMasterAdmin, masterAdminLoading] = useMasterAdmin(user?.email);

	const [clientName, setClientName] = useState("");

	useEffect(() => {
		if (!masterAdminLoading && isMasterAdmin && clientsData.length) setClientName(clientsData[0].Client);
		else if (!masterAdminLoading && !isMasterAdmin && clientsData.length && userClientAndLocation?.userClientID) {
			const client = clientsData?.find((item) => item?.ClientID === userClientAndLocation.userClientID).Client;
			setClientName(client);
		}
	}, [isMasterAdmin, masterAdminLoading, clientsData, userClientAndLocation]);

	const handleClick = (e) => {
		const type = e.currentTarget.name;
		const id = e.currentTarget.id;

		if (!queryFromCancellingFilterBox) setQueryFromCancellingFilterBox(true);

		if (type === "location") {
			const updatedLocationFilter = filterValues.filterLocations.filter((ele) => ele.value !== id);
			setFilterValues((prev) => ({ ...prev, filterLocations: updatedLocationFilter }));
			setSelectedFilterOptions((prev) => ({ ...prev, selectedLocations: updatedLocationFilter }));
		} else if (type === "zone") {
			const updatedZoneFilter = filterValues.filterZoneNames.filter((ele) => ele.value !== id);
			setFilterValues((prev) => ({ ...prev, filterZoneNames: updatedZoneFilter }));
			setSelectedFilterOptions((prev) => ({ ...prev, selectedZones: updatedZoneFilter }));
		} else if (type === "detection") {
			const updatedDetectionFilter = filterValues.filterDetectionTypes.filter((ele) => ele.value !== id);
			setFilterValues((prev) => ({ ...prev, filterDetectionTypes: updatedDetectionFilter }));
			setSelectedFilterOptions((prev) => ({ ...prev, selectedDetections: updatedDetectionFilter }));
		} else if (type === "direction") {
			const updatedDirectionFilter = filterValues.filterDirectionTypes.filter((ele) => ele.value !== id);
			setFilterValues((prev) => ({ ...prev, filterDirectionTypes: updatedDirectionFilter }));
			setSelectedFilterOptions((prev) => ({ ...prev, selectedDirections: updatedDirectionFilter }));
		}
	};

	if (!filterValues.values) return <LocationZoneDetectionDirectionRender clientName={clientName} />;

	return (
		<div className="flex flex-row flex-1 flex-wrap gap-2">
			<div className="bg-slate-200 w-fit h-fit rounded group flex flex-row items-center text-black text-center">
				<p className="p-1">{filterValues.filterClient}</p>
				<span className="pt-[2px] px-1 appearance-none group-hover:bg-blue-100 rounded active:bg-red-500">
					<BsPinFill className="group-hover:text-blue-500" />
				</span>
			</div>
			{filterValues.filterLocations.map((location) => {
				if (!isSubstringInArray(location.value, zoneIdList)) {
					return (
						<div key={location.value} className="flex flex-row gap-1 w-fit h-fit bg-slate-200 rounded text-black text-center">
							<p className="p-1">{location.label}</p>
							<button
								id={location.value}
								className="pt-[2px] px-1 appearance-none group hover:bg-red-100 rounded active:bg-red-500"
								name="location"
								onClick={handleClick}
							>
								<RxCross2 className="group-hover:text-red-500 group-active:text-white" />
							</button>
						</div>
					);
				}
			})}
			{filterValues.filterZoneNames.map((zone) => (
				<div key={zone.value} className=" flex flex-row gap-1 w-fit h-fit bg-slate-200 rounded text-black text-center">
					<p className="p-1">{zone.label}</p>
					<button
						id={zone.value}
						className="pt-[2px] px-1 appearance-none group hover:bg-red-100 rounded active:bg-red-500"
						name="zone"
						onClick={handleClick}
					>
						<RxCross2 className="group-hover:text-red-500 group-active:text-white" />
					</button>
				</div>
			))}
			{filterValues.filterDetectionTypes.map((detection) => (
				<div key={detection.value} className=" flex flex-row gap-1 w-fit h-fit bg-slate-200 rounded text-black text-center">
					<p className="p-1">{detection.label}</p>
					<button
						id={detection.value}
						className="pt-[2px] px-1 appearance-none group hover:bg-red-100 rounded active:bg-red-500"
						name="detection"
						onClick={handleClick}
					>
						<RxCross2 className="group-hover:text-red-500 group-active:text-white" />
					</button>
				</div>
			))}
			{filterValues.filterDirectionTypes.map((direction) => (
				<div key={direction.value} className=" flex flex-row gap-1 w-fit h-fit bg-slate-200 rounded text-black text-center">
					<p className="p-1 pt-[2px]">{direction.label}</p>
					<button
						id={direction.value}
						className="pt-[2px] px-1 appearance-none group hover:bg-red-100 rounded active:bg-red-500"
						name="direction"
						onClick={handleClick}
					>
						<RxCross2 className="group-hover:text-red-500 group-active:text-white" />
					</button>
				</div>
			))}
			<div className="bg-slate-200 w-fit h-fit rounded group flex flex-row items-center text-black text-center">
				<p className="p-1">{filterValues.filterDate}</p>
				<span className="pt-[2px] px-1 appearance-none group-hover:bg-blue-100 rounded active:bg-red-500">
					<BsPinFill className="group-hover:text-blue-500" />
				</span>
			</div>
		</div>
	);
};

export default FilterHeaderDisplay;
