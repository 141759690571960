import React from 'react';
import BlueBtn from '../../../../components/Buttons/BlueBtn';
import { toast } from 'react-hot-toast';
import { db } from '../../../../firebase/firebase.config';
import { collection, doc, getDocs, limit, orderBy, query, setDoc } from 'firebase/firestore';
import { newCameraIdGen } from '../../../../Utility/IdGenerator';

const AddDeviceCamera = ({ setCloseModal, deviceId, useCase }) => {

    const handleAddCamera = e => {
        e.preventDefault();
        const form = e.target;
        const cameraName = form.cameraName.value;
        const cameraIp = form.cameraIp.value;
        const usecase = form.usecase.value;

        // console.log(cameraName, cameraIp);

        if ((cameraName.length < 1) || (cameraIp.length < 1)) {
            return;
        }
        else {
            addCameraDataToDB(cameraName, cameraIp, usecase);
            form.reset();
        }
    }

    const addCameraDataToDB = async (cameraName, cameraIp, usecase) => {
        // Add a new document in collection "clients-data-collection"
        try {
            const collectionRef = collection(db, `device-data-collection/${deviceId}/Cameras`);
            const q = query(collectionRef, orderBy("CreatedAt", "desc"), limit(1));
            const querySnapshot = await getDocs(q);
            let lastCamera = '';
            querySnapshot.forEach((doc) => {
                // console.log(doc.id, " => ", doc.data());
                lastCamera = doc.data();
            });
            //console.log(lastCamera);
            if (!lastCamera) {
                const docID = 'CAM0001';
                const res = await setDoc(doc(db, `device-data-collection/${deviceId}/Cameras`, docID), {
                    Camera_ID: docID,
                    CameraName: cameraName,
                    CameraIP: cameraIp,
                    UseCase: usecase,
                    CreatedAt: Date.now()
                });
                //console.log('camera id: ', res.id);
                setCloseModal(false);
                toast.success('Camera added successfully');

            }
            else {

                // console.log('res', newClientLocationIdGen(lastCamera.Camera_ID));
                const docID = newCameraIdGen(lastCamera?.Camera_ID);

                const res = await setDoc(doc(db, `device-data-collection/${deviceId}/Cameras`, docID), {
                    Camera_ID: docID,
                    CameraName: cameraName,
                    CameraIP: cameraIp,
                    UseCase: usecase,
                    CreatedAt: Date.now()
                });
                //console.log('Camera id: ', res.id);
                setCloseModal(false);
                toast.success('Camera added successfully');
            }


        } catch (err) {
            console.error('error', err);
        }
    }

    return (
        <div>
            <input type="checkbox" id="addClientLocation-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative rounded-none">
                    <label
                        htmlFor="addClientLocation-modal"
                        className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]">
                        ✕
                    </label>
                    <h3
                        className="text-3xl text-center font-bold mb-7">
                        Add Camera
                    </h3>

                    <form onSubmit={handleAddCamera}>

                        {/* Camera Name */}
                        <div className="form-control flex mt-3">
                            <input
                                type="text"
                                name='cameraName'
                                placeholder="Camera Name"
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* Camera IP */}
                        <div className="form-control flex mt-3">
                            <input
                                type="text"
                                name='cameraIp'
                                placeholder="Camera IP"
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                           {/* use case */}
                           <div className="form-control mt-3">
                            <select
                                name='usecase'
                                className="select w-full bg-white border-white border-b-[#D9D9D9] rounded-none" required >
                                {
                                    useCase?.length > 0 ? <>
                                        {
                                            useCase?.map((u, i) => <option
                                                value={u}
                                                key={i}
                                            >{u}</option>)
                                        }
                                    </>
                                        : <option disabled>No usecase available</option>
                                }

                            </select>
                        </div>

                        <div className="form-control mt-3">
                            <BlueBtn>Confirm</BlueBtn>
                        </div>
                    </form>


                </div>
            </div>
        </div>
    );
};

export default AddDeviceCamera;