import React from "react";
import Select from "react-select";

// custom styling for react-select
const selectStyles = {
	container: (provided, state) => ({
		...provided,
		width: "410px",
		minHeight: "55px",
		// maxHeight: `${55 + (state.selectProps.value ? state.selectProps.value.length * 40 : 0)}px`,
		borderWidth: 1,
		borderStyle: "solid",
		borderColor: "#2E3192",
		borderRadius: "8px",
	}),
	control: (provided, state) => ({
		...provided,
		backgroundColor: "#F2F5F7",
		minHeight: "55px",
		// maxHeight: `${55 + (state.selectProps.value ? state.selectProps.value.length * 40 : 0)}px`,
		borderRadius: "8px",
	}),
	menu: (provided) => ({
		...provided,
		backgroundColor: "#F2F5F7",
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: "block",
		backgroundColor: "#2E3192",
	}),
};

const DetectionEventTypes = ({
	detectionOptions,
	selectedDetectionOptions,
	setSelectedDetectionOptions,
	eventTypesOptions,
	selectedEventTypesOptions,
	setSelectedEventTypesOptions,
}) => {
	const handleEventChange = (selectedOptions) => {
		setSelectedEventTypesOptions(selectedOptions);
	};
	const handleDetectionChange = (selectedOptions) => {
		setSelectedDetectionOptions(selectedOptions);
	};
	return (
		<div className="flex flex-row justify-between">
			<div className="flex flex-row gap-[3.52rem] items-center">
				<h1 className="text-black text-2xl font-normal">Events </h1>
				{/* {This div is basically to prevent Event select from expanding when detection multi select expands} */}
				<div className="h-fit">
					<Select options={eventTypesOptions} value={selectedEventTypesOptions} onChange={handleEventChange} styles={selectStyles} isMulti />
				</div>
			</div>
			<div className="flex flex-row gap-[3.52rem] items-center">
				<h1 className="text-black text-2xl font-normal">Detections </h1>
				<Select options={detectionOptions} value={selectedDetectionOptions} onChange={handleDetectionChange} styles={selectStyles} isMulti />
			</div>
		</div>
	);
};

export default DetectionEventTypes;
