import ImageGalleryDisplay from "../../../components/ImageGalleryDisplay";

const CreateEntryImage = ({ files, setFiles, setPhotoUploadModal }) => {
	return (
		<div className="bg-white rounded-lg h-[921px] p-4 overflow-y-scroll">
			<div className="flex flex-row justify-between items-center mb-5">
				<h1 className="font-semibold text-base">Uploaded Images</h1>
				<label className="btn btn-primary" htmlFor="addImage-modal" onClick={() => setPhotoUploadModal(true)}>
					Upload Image
				</label>
			</div>
			{files.length > 0 ? (
				<ImageGalleryDisplay files={files} setFiles={setFiles} />
			) : (
				<div className="bg-[#F2F5F7] h-72 border border-[#2E3192] p-1 flex flex-col rounded-md">
					<h2 className="text-2xl text-black my-auto mx-auto font-semibold">Upload an image</h2>
				</div>
			)}
		</div>
	);
};

export default CreateEntryImage;
