import { collection, deleteDoc, doc, getDocs, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import Loading from '../../../components/Loading/Loading';
import { db } from '../../../firebase/firebase.config';
import '../Dashboard.css';
import AddUserModal from './AddUserModal';
import EditUserModal from './EditUserModal';
import { FiTrash2 } from "react-icons/fi";
import { MdModeEditOutline } from "react-icons/md";
import AlMonkLogo from '../../../components/AlMonkLogo';
import { useContext } from 'react';
import { DataContext } from '../../../context/DataProvider/DataProvider';
import { AuthContext } from '../../../context/AuthProvider/AuthProvider';
import useMasterAdmin from '../../../hooks/useMasterAdmin';
import TableLocationRow from './TableLocationRow';

const ManageUser = () => {
    const { clientsData, clientIds, clientNames } = useContext(DataContext);
    const { user } = useContext(AuthContext);
    const [isMasterAdmin] = useMasterAdmin(user?.email);
    const [closeModal, setCloseModal] = useState(false);
    const [userData, setUserData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [aUser, setAUser] = useState(null);
    const [phoneValue, setPhoneValue] = useState(null);


    // console.log("isAdmin:",userType);

    // fetch all Userdata from firebase
    useEffect(() => {
        const collectionRef = collection(db, "userDataCollection");
        const queryConstraints = [];
        queryConstraints.push(orderBy("Role"));
        queryConstraints.push(where("Role", "!=", 'Master Admin'));

        if (isMasterAdmin) {
            const q = query(collectionRef, ...queryConstraints);
            getUserData(q);
        }
        else if (!isMasterAdmin && clientIds?.length > 0) {
            const clientId = clientIds[0];
            queryConstraints.push(where("ClientID", "==", clientId));
            const q = query(collectionRef, ...queryConstraints);
            // console.log(queryConstraints);
            getUserData(q);
        }
        // query(collection(db, "userDataCollection"), where("Role", "!=", 'Master Admin'), orderBy("Role"))
    }, [isMasterAdmin, clientIds, user?.uid]);

    //helper function to fetch userData
    function getUserData(q) {
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let list = [];
            querySnapshot.docs.forEach((doc) => {
                list.push({ id: doc.id, ...doc.data() });
            });
            // console.log(list?.length);
            setUserData(list);
            setIsLoading(false);
        },
            (error) => {
                console.log(error);
            }
        );
        return () => {
            unsubscribe();
        };
    }

    //delete User
    const handleUserDelete = async (user) => {
        //console.log(user);
        await deleteDoc(doc(db, "userDataCollection", user?.id));
        userDelete(user.UserId);
        setUserData(userData.filter((item) => item.id !== user?.id));
        toast.success(`User deleted successfully`);
    }

    //send user delete request(delete a user from firebase auth) 
    const userDelete = uid => {
        fetch(`http://gk.secquraise.com/api/deleteUser/${uid}`, {
            method: 'DELETE'
        })
            .then(res => res.json())
            .then(data => {
                //console.log(data);
                if (data.deletedCount > 0) {
                    toast.success(`user deleted successfully`)
                }
            })
    }

    //modal functions
    const closeDeleteModal = () => {
        setAUser(null);
    }

    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <div className='bg-white'>
            <div className='fixed right-0 top-0 flex items-center justify-end z-[2]'>
                <AlMonkLogo />
            </div>
            <h1 className='font-bold text-5xl text-center mt-10 text-black'>User Management</h1>
            <div className='w-full pr-10 flex items-center justify-end'>
                <label htmlFor="addUser-modal" onClick={() => setCloseModal(true)} className='btn w-[150px] mt-4 bg-[#375075] text-white hover:bg-[#A6A6A6] border-0 rounded-none'>Add User</label>
            </div>

            {/* Table */}
            <div className="overflow-x-auto mx-2 custom-table-border mt-5 overflow-y-auto max-h-[75vh]">
                <table className="w-full text-center text-black">
                    <thead className='sticky top-0 border-b'>
                        <tr className='border-b'>
                            <th className='p-3 bg-white max-w-[80px]'>Sl No</th>
                            <th className='p-3 bg-white max-w-[200px]'>Client Name</th>
                            <th className='p-3 bg-white max-w-[200px]'>Name</th>
                            <th className='p-3 bg-white max-w-[200px]'>Email Id</th>
                            <th className='p-3 bg-white max-w-[200px]'>Phone</th>
                            <th className='p-3 bg-white max-w-[100px]'>Role</th>
                            <th className='p-3 bg-white max-w-[200px]'>Locations</th>
                            <th className='p-3 bg-white max-w-[100px]'>Status</th>
                            <th className='p-3 bg-white max-w-[200px]'>Last Login</th>
                            <th className='p-3 bg-white max-w-[50px]'></th>
                            <th className='p-3 bg-white max-w-[50px]'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            userData?.map((userD, i) => <tr key={i} className='border-b'>
                                <td className='p-3 max-w-[80]'>{i + 1}</td>
                                <td className='p-3 max-w-[200]'>{userD?.Client}</td>
                                <td className='p-3 max-w-[200]'>{userD?.Name}</td>
                                <td className='p-3 max-w-[200]'>{userD?.Email}</td>
                                <td className='p-3 max-w-[200]'>{userD?.Phone}</td>
                                <td className='p-3 max-w-[100]'>{userD?.Role}</td>
                                <td className='p-3 max-w-[200]'>
                                    {userD?.Location?.map((loc, index) => (
                                        <React.Fragment key={index}>
                                            {index > 0 && ' '}
                                            <TableLocationRow
                                                ClientID={userD?.ClientID}
                                                locationID={loc}
                                                setIsLoading={setIsLoading} />
                                        </React.Fragment>
                                    ))}
                                </td>
                                <td className='p-3 max-w-[100]'>Active</td>
                                <td className='p-3 max-w-[200]'>{userD?.LastSeen}</td>
                                <td className='p-3 max-w-[50]'><label
                                    onClick={() => {
                                        setAUser(userD);
                                        setPhoneValue(userD.Phone);
                                    }}
                                    htmlFor="updateUser-modal" ><MdModeEditOutline className='text-2xl' />
                                </label></td>
                                <td className='p-3 max-w-[50]'><label onClick={() => setAUser(userD)} htmlFor="confirmation-modal" ><FiTrash2 className='text-2xl' /> </label> </td>
                            </tr>)
                        }

                    </tbody>
                </table>
            </div>

            {
                aUser && <ConfirmModal
                    title={`Delete this user?`}
                    message={`If you delete ${aUser.Name}. It cannot be restore`}
                    successButtonName="Delete"
                    successAction={handleUserDelete}
                    modalData={aUser}
                    closeDeleteModal={closeDeleteModal}
                >
                </ConfirmModal>
            }

            {
                aUser && <EditUserModal
                    aUser={aUser}
                    setAUser={setAUser}
                    phoneValue={phoneValue}
                    setPhoneValue={setPhoneValue}
                    clientsData={clientsData}
                    clientNames={clientNames}
                    setIsLoading={setIsLoading}
                ></EditUserModal>
            }

            {
                closeModal &&
                <AddUserModal
                    setCloseModal={setCloseModal}
                    clientsData={clientsData}
                    clientNames={clientNames}
                    setIsLoading={setIsLoading}
                ></AddUserModal>
            }


        </div>
    );
};

export default ManageUser;