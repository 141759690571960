import React from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import { FaEdit } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";

// formates date as 12-Aug-2023
const formatDate = (date) => {
	const formatter = new Intl.DateTimeFormat("default", { month: "short" });
	const month = formatter.format(date);
	return `${date.getDate().toString().padStart(2, "0")}-${month}-${date.getFullYear()}`;
};
const NonRepetitiveDates = ({ exclusiveDates, setDatesConfig }) => {
	const [date, setDate] = useState(null); // to control datepicker state
	const [dateClicked, setDateClicked] = useState(null); // date that is clicked on to be edited or deleted
	const curDate = new Date().setHours(0, 0, 0, 0);

	// adding dates
	const handleAdd = async () => {
		let formattedDate = formatDate(date);
		if (exclusiveDates.includes(formattedDate)) {
			toast.error(`${formattedDate} is already added`);
			return;
		}

		let dates = [...exclusiveDates];
		dates.push(formattedDate);
		dates.sort((a, b) => new Date(b) - new Date(a));

		// setSelectedDates(dates);
		setDatesConfig((prev) => ({ ...prev, ExclusiveDates: dates }));

		toast.success(`${formattedDate} added`);
	};

	const handleEdit = async () => {
		let formattedEditDate = formatDate(date);

		if (!exclusiveDates.includes(formattedEditDate)) {
			let dates = exclusiveDates;
			let index = dates.findIndex((ele) => ele === dateClicked);
			dates[index] = formattedEditDate;
			dates.sort((a, b) => new Date(b) - new Date(a));

			// setSelectedDates(dates);
			setDatesConfig((prev) => ({ ...prev, ExclusiveDates: dates }));
			setDateClicked(formattedEditDate);
		} else {
			toast.error(`${formattedEditDate} is already present , please select another date`);
		}
	};

	const handleDelete = async () => {
		// setSelectedDates((prev) => prev.filter((ele) => ele !== dateClicked));
		setDatesConfig((prev) => ({ ...prev, ExclusiveDates: prev.ExclusiveDates.filter((ele) => ele !== dateClicked) }));
		toast.success(`${dateClicked} is succesfully deleted`);
	};

	return (
		<div className="mt-16 mb-3">
			<div className="mb-11 flex flex-row justify-between">
				<h1 className="text-black font-semibold lg:text-[32px] md:text-base inline-block mr-10">Exclusive Dates</h1>

				{/* The button to open modal */}
				<label htmlFor="date_modal" className="btn bg-[#2E3192] font-bold text-base text-white ml-36 !md:text-sm">
					Add Date
				</label>
			</div>

			{exclusiveDates.length ? (
				<div className="overflow-x-auto mx-2 mt-5 mb-8 max-h-[70vh] overflow-y-auto flex flex-row justify-around">
					<table className="w-1/2 text-center text-base font-medium border-separate border-spacing-x-0 border-spacing-y-2">
						<thead className="sticky top-0 border">
							<tr className="text-black">
								<th className="p-3 max-w-[200]">Dates</th>
								<th className="p-3 max-w-[50]">Edit</th>
								<th className="p-3 max-w-[50]">Delete</th>
							</tr>
						</thead>
						<tbody>
							{exclusiveDates.map((date, i) => {
								let dateArr = date.split("-");
								let numDate = dateArr[0];
								let month = dateArr[1];
								let year = dateArr[2];

								const d = new Date(date).setHours(0, 0, 0, 0);
								const bgColor = d < curDate ? `bg-slate-200` : d === curDate ? `bg-cyan-200` : `bg-[#FAFAFA]`;
								return (
									<tr className={`h-[65px]`} key={date}>
										<td className={`text-black border-black border-l border-t border-b rounded-tl-[20px] rounded-bl-[20px] ${bgColor}`}>
											{date}
											{/* {numDate}-{month}-{year} */}
										</td>
										<td className={`border-black border-t border-b ${bgColor}`}>
											<label
												htmlFor="Adddate_modal"
												id={`${numDate}-${month}-${year}`}
												onClick={(e) => {
													setDate(new Date(e.currentTarget.id));
													setDateClicked(e.currentTarget.id);
												}}
											>
												<div className="flex flex-row justify-around text-[#0D6EFD]">{d >= curDate && "EDIT"}</div>
											</label>
										</td>
										<td className={`border-black border-r border-t border-b rounded-tr-[20px] rounded-br-[20px] ${bgColor}`}>
											<label
												htmlFor="delete_modal"
												id={`${numDate}-${month}-${year}`}
												onClick={(e) => {
													setDate(new Date(e.currentTarget.id));
													setDateClicked(e.currentTarget.id);
												}}
											>
												<div className="flex flex-row justify-around text-[#DC3545]">{d >= curDate && "DELETE"}</div>
											</label>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			) : (
				<p className="text-black text-center text-base mb-3">No dates added yet</p>
			)}

			{/* Add Modal */}
			<input type="checkbox" id="date_modal" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box h-fit overflow-visible bg-white">
					<h3 className="font-bold text-lg text-black">Pick New Date Entry</h3>
					<DatePicker
						showIcon
						placeholderText="Click here to pick date"
						selected={date}
						onChange={(date) => setDate(date)}
						minDate={new Date()}
						className="bg-slate-100 rounded-sm my-4 text-slate-600"
					/>

					<div className="modal-action">
						<button className="btn btn-accent !rounded-none" onClick={handleAdd}>
							Add
						</button>
						<label htmlFor="date_modal" className="btn btn-active btn-neutral !rounded-none">
							Close
						</label>
					</div>
				</div>
			</div>

			{/* Edit Modal */}
			<input type="checkbox" id="Adddate_modal" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box h-fit overflow-visible bg-white">
					<h3 className="font-bold text-lg text-black">Edit Date</h3>
					<DatePicker
						showIcon
						placeholderText="Click here to pick date"
						selected={date}
						onChange={(date) => setDate(date)}
						minDate={new Date()}
						className="bg-slate-100 rounded-sm my-4 text-slate-600"
					/>

					<div className="modal-action">
						<label className="btn btn-success !rounded-none" htmlFor="Adddate_modal" onClick={handleEdit}>
							Confirm
						</label>
						<label htmlFor="Adddate_modal" className="btn btn-active btn-neutral !rounded-none">
							Close
						</label>
					</div>
				</div>
			</div>

			{/* Delete Modal */}
			<input type="checkbox" id="delete_modal" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box h-fit overflow-visible bg-white">
					<h3 className="font-bold text-lg text-black">Delete Date ?</h3>
					<p className="text-black">Are you sure you want to delete {dateClicked} ?</p>

					<div className="modal-action">
						<label className="btn btn-success !rounded-none" htmlFor="delete_modal" onClick={handleDelete}>
							Confirm
						</label>
						<label htmlFor="delete_modal" className="btn btn-active btn-neutral !rounded-none">
							Close
						</label>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NonRepetitiveDates;
