import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase/firebase.config';
import BlueBtn from '../../../../components/Buttons/BlueBtn';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input'
import { DataContext } from '../../../../context/DataProvider/DataProvider';
import Select from "react-select";

const EditClientModal = ({ aClient, setAClient, phoneValue, setPhoneValue }) => {
    const { useCases } = useContext(DataContext);
    const { Client, Address, PinCode, Email, Phone, Status, UseCase, id } = aClient;
    let [email, setEmail] = useState('');//email format validation
    const [errorMessage, setErrorMessage] = useState('');
    const [usecaseOptions, setUsecaseOptions] = useState([]);
    const [selectedUsecaseOptions, setSelectedUsecaseOptions] = useState([]);

    //console.log(Name, Email, Phone, Role, Location, id);

    // console.log(useCases);

    const handleUpdateClient = (e) => {
        e.preventDefault();
        const form = e.target;
        const client = form.client.value;
        const address = form.address.value;
        const pinCode = form.pinCode.value;
        const phone = form.phone.value;
        const status = form.status.value;
        // const usecase = form.usecase.value;

        // console.log(client, address, pinCode, email, phoneValue, status, usecase);

        let usecase;
        if (selectedUsecaseOptions?.length > 0) {
            // console.log(selectedLocationsOptions);
            const useValues = selectedUsecaseOptions.map(option => option.value);
            usecase = [...useValues];
        }

        console.log(client, address, pinCode, email, phoneValue, status, usecase);

        // return if user enter an invalid email
        if (!validate(email)) {
            setErrorMessage('Please enter valid email');
            return;
        }
        if ((client.length < 1) || (phone.length <= 6)) {
            return;
        }
        else {
            //console.log('all value is valid');
            UpdateClientData(client, address, pinCode, email, phoneValue, status, usecase);
            form.reset();
            setSelectedUsecaseOptions([]);
            setPhoneValue(null);
        }

        setErrorMessage('');
    }

    const UpdateClientData = async (client, address, pinCode, email, phoneValue, status, usecase) => {
        // Update document in collection "clients-data-collection"
        try {
            const docRef = doc(db, "clients-data-collection", id);
            const res = await updateDoc(docRef, {
                Client: client,
                Address: address,
                PinCode: pinCode,
                Email: email,
                Phone: phoneValue,
                Status: status,
                UseCase: usecase
            });
            setAClient(null);
            toast.success('Client data updated');
        } catch (err) {
            console.error(err);
        }
    }


    // Email pattern validation
    const validate = (email) => {
        if (email.trim().match(/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
            return false;
        } else if (email.trim() === '') {
            return false;
        }

        return true;
    }

    //initially select the client email
    useEffect(() => {
        if (Email) {
            setEmail(Email);
        }
    }, [Email, id]);

    useEffect(() => {

        if (useCases?.useCaseName) {

            // const filtered = [
            //     ...useCases?.useCaseName?.filter(option => !selectedUsecaseOptions.includes(option)),
            //     ...selectedUsecaseOptions.filter(option => !useCases?.useCaseName?.includes(option))
            // ];

            let allOptions = useCases?.useCaseName?.map((use) => ({ value: use, label: use }));
            setUsecaseOptions(allOptions);

            if (UseCase) {
                let userLocations = UseCase.map((use) => ({ value: use, label: use }));
                setSelectedUsecaseOptions(userLocations);
            }

        }

    }, [id, useCases?.useCaseName, UseCase]);

    const handleLocationsSelect = (selectedOptions) => {
        setSelectedUsecaseOptions(selectedOptions);
    };

    const handleCloseModal = () => {
        setSelectedUsecaseOptions([]);
    }

    // custom styling for react-select
    const selectStyles = {
        container: (provided) => ({
            ...provided,
            minWidth: 300,
            maxWidth: 646,
        }),
    };


    return (
        <div>
            <input type="checkbox" id="updateClient-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative rounded-none">
                    <label
                        htmlFor="updateClient-modal"
                        onClick={handleCloseModal}
                        className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]">
                        ✕
                    </label>
                    <h3
                        className="text-3xl text-center font-bold mb-7">
                        Update Client
                    </h3>

                    <form onSubmit={handleUpdateClient}>

                        {/* Client */}
                        <div className="form-control flex mt-3">
                            <input
                                type="text"
                                name='client'
                                placeholder="Client"
                                defaultValue={Client}
                                key={Client}
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* Address */}
                        <div className="form-control flex mt-3">
                            <input
                                type="text"
                                name='address'
                                placeholder="Address"
                                defaultValue={Address}
                                key={Address}
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* Pin Code */}
                        <div className="form-control mt-3">
                            <input
                                type="text"
                                name='pinCode'
                                placeholder="Pin Code"
                                defaultValue={PinCode}
                                key={PinCode}
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* Email */}
                        <div className="form-control flex mt-3">
                            <input
                                type="text"
                                name='email'
                                placeholder="Email"
                                defaultValue={Email}
                                key={Email}
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                onChange={e => setEmail(e.target.value)}
                                required />
                            {errorMessage && <span className='text-red-600 mt-1 pl-3'>{errorMessage}</span>}
                        </div>

                        {/* Phone */}
                        <div className="form-control flex mt-3">
                            <PhoneInput
                                name='phone'
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="IN"
                                placeholder="Enter phone number"
                                className="input bg-white active:border-white active:border-b-[#D9D9D9] focus:border-b-[#D9D9D9] focus:border-white border-white border-b-[#D9D9D9] rounded-none"
                                value={phoneValue}
                                onChange={setPhoneValue}
                                required />
                        </div>

                        {/* Status */}
                        <div className="form-control mt-3">
                            <select
                                name='status'
                                defaultValue={Status}
                                key={Status}
                                className="select w-full bg-white border-white border-b-[#D9D9D9] rounded-none" required >
                                <option>Active</option>
                                <option>Inactive</option>
                            </select>
                        </div>

                        {/* use case */}
                        <div className="form-control mt-3">
                            {/* <select
                                name='usecase'
                                defaultValue={UseCase}
                                key={UseCase}
                                className="select w-full bg-white border-white border-b-[#D9D9D9] rounded-none" required >
                                {
                                    useCases?.useCaseName?.length > 0 ? <>
                                        {
                                            useCases?.useCaseName?.map((useCase, i) => <option
                                                value={useCase}
                                                key={i}
                                            >{useCase}</option>)
                                        }
                                    </>
                                        : <option disabled>No usecase available</option>
                                }
                            </select> */}

                            <Select
                                className="text-black"
                                options={usecaseOptions}
                                placeholder={"Select Usecase"}
                                value={selectedUsecaseOptions}
                                onChange={handleLocationsSelect}
                                styles={selectStyles}
                                isMulti
                            />
                        </div>

                        <div className="form-control mt-3">
                            <BlueBtn>Update</BlueBtn>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    );
};

export default EditClientModal;