import React, { useEffect, useState } from 'react';
import Loading from '../../../../components/Loading/Loading';
import { toast } from 'react-hot-toast';
import { collection, deleteDoc, doc, onSnapshot, query } from 'firebase/firestore';
import { db } from '../../../../firebase/firebase.config';
import { useLocation, useNavigate } from 'react-router-dom';
import AlMonkLogo from '../../../../components/AlMonkLogo';
import LocationZoneTableRow from './LocationZoneTableRow';
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';
import AddLocationZone from './AddLocationZone';
import EditLocationZone from './EditLocationZone';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import useFirestoreDocument from '../../../../hooks/useFirestoreDocument';

const LocationZone = () => {
    const location = useLocation();
    const clientId = location.state?.clientId;
    const Location_ID = location.state?.Location_ID;
    const navigate = useNavigate();

    // console.log('Device management:', clientId, Location_ID);
    const [closeModal, setCloseModal] = useState(false);
    const [aZone, setAZone] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [locationZoneData, setLocationZoneData] = useState([]);
    const [useCase, setUseCase] = useState([]);
    const [clientDoc, clientLoading] = useFirestoreDocument('clients-data-collection', clientId);
    const [breadcrumbsValues, setBreadcrumbsValues] = useState({
        clientId: '',
        locationId: '',
        values: false,
    });

    //set the client & location value for breadcrumbs
    useEffect(() => {
        setBreadcrumbsValues({
            clientId: clientId,
            locationId: Location_ID,
            values: true,
        });
    }, [clientId, Location_ID]);

    // set client Usecase 
    useEffect(() => {
        if (clientDoc && !clientLoading) {
            setUseCase([clientDoc?.UseCase]);
        }
    }, [clientId, clientDoc, clientLoading]);

    // fetch all locationData from firebase
    useEffect(() => {

        if (clientId && Location_ID) {
            const unsub = onSnapshot(
                // query(collection(db, `clients-data-collection/${clientID}/Location`), orderBy('CreatedAt', 'desc'))

                query(collection(db, `clients-data-collection/${clientId}/Location/${Location_ID}/Zones`)),
                (snapShot) => {
                    let list = [];
                    snapShot.docs.forEach((doc) => {
                        list.push({ id: doc.id, ...doc.data() });
                    });
                    // console.log('list',list);
                    setLocationZoneData(list);
                    setIsLoading(false);
                },
                (error) => {
                    console.log(error);
                }
            );

            return () => {
                unsub();
            };
        }

    }, [clientId, Location_ID]);

    //delete zone
    const handleLocationZoneDelete = async (zone) => {
        //console.log(zone);
        await deleteDoc(doc(db, `clients-data-collection/${clientId}/Location/${Location_ID}/Zones`, zone?.id));
        setLocationZoneData(locationZoneData.filter((item) => item.id !== zone?.id));
        toast.success(`Zone deleted successfully`);
    }

    //modal functions
    const closeDeleteModal = () => {
        setAZone(null);
    }

    //navigate to Location
    const handleNavigateToLocation = () => {
        // console.log('clientzone:',clientId);
        navigate(`/dashboard/masteradminclient/${clientId}`, {
            state: { clientId }
        });
    };

    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <div>

            {/* header div */}
            <div className='flex items-center bg-white justify-between pb-2 sticky top-0 z-[2]'>
                <div className='ml-6 mt-1'>
                    {/* breadcrumbs */}
                    <div className='pl-1 mt-3 text-sm text-black'>
                        {
                            breadcrumbsValues?.values && <div className='flex items-center'>
                                <p className='flex items-center font-semibold'>Client <MdOutlineKeyboardArrowRight className='pt-1 text-[19px]' /><span className='font-normal'>{breadcrumbsValues?.clientId}</span></p>
                                <p className='flex items-center font-semibold ml-1'>Location <MdOutlineKeyboardArrowRight className='pt-1 text-[19px]' /><span className='font-normal'>{breadcrumbsValues?.locationId}</span></p>
                            </div>
                        }
                    </div>
                    <div className="flex items-center mt-3">
                        <button
                            onClick={handleNavigateToLocation}
                            className='text-3xl mr-2 text-[#375075]'
                        >
                            <IoIosArrowDropleftCircle className='text-[#375075]' />
                        </button>

                        <h1 className='font-bold text-3xl text-start'>Zone Management</h1>
                    </div>
                </div>

                <div className='flex items-center justify-end'>

                    <div className='ml-5'>
                        <AlMonkLogo />
                    </div>
                </div>
            </div>

            <div className='w-full pr-10 flex items-center justify-end'>
                <label htmlFor="addZone-modal" onClick={() => setCloseModal(true)} className='btn w-[150px] mt-4 bg-[#375075] text-white hover:bg-[#A6A6A6] border-0 rounded-none'>Add Zone</label>
            </div>

            {/* Table */}
            {
                locationZoneData?.length > 0 ? <div className="overflow-x-auto mx-auto custom-table-border mt-5 max-h-[87vh] overflow-y-auto max-w-[70%]">
                    <table className="w-full text-center">
                        <thead className='sticky top-0 border-b'>
                            <tr className='border-b'>
                                <th className='p-3 bg-white max-w-[80px]'>Sl No</th>
                                <th className='p-3 bg-white max-w-[100px]'>Zone Id</th>
                                <th className='p-3 bg-white max-w-[200px]'>Zone Name</th>
                                <th className='p-3 bg-white max-w-[200px]'>Usecase</th>
                                <th className='p-3 bg-white max-w-[200px]'>Devices</th>
                                <th className='p-3 bg-white max-w-[50px]'></th>
                                <th className='p-3 bg-white max-w-[50px]'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                locationZoneData?.map((zoneD, i) => <LocationZoneTableRow
                                    key={zoneD?.id}
                                    i={i}
                                    zoneD={zoneD}
                                    setAZone={setAZone}
                                    clientId={clientId}
                                    Location_ID={Location_ID}
                                ></LocationZoneTableRow>)
                            }

                        </tbody>
                    </table>
                </div>
                    : <div className="flex justify-center items-center mx-auto text-black">
                        <h2 className="text-xl mt-5 font-semibold">No zone added yet</h2>
                    </div>
            }


            {
                aZone && <ConfirmModal
                    title={`Delete this Zone?`}
                    message={`If you delete ${aZone?.ZoneName}. It cannot be restore`}
                    successButtonName="Delete"
                    successAction={handleLocationZoneDelete}
                    modalData={aZone}
                    closeDeleteModal={closeDeleteModal}
                >
                </ConfirmModal>
            }

            {
                aZone && <EditLocationZone
                    aZone={aZone}
                    setAZone={setAZone}
                    clientId={clientId}
                    Location_ID={Location_ID}
                    useCase={useCase}
                ></EditLocationZone>
            }

            {
                closeModal &&
                <AddLocationZone
                    setCloseModal={setCloseModal}
                    clientId={clientId}
                    Location_ID={Location_ID}
                    useCase={useCase}
                ></AddLocationZone>
            }

        </div>
    );
};

export default LocationZone;