import { arrayRemove, arrayUnion, collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { db } from "../../../../../firebase/firebase.config";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { toast } from "react-hot-toast";
import Select from "react-select";

const Modal = ({ alertName, alertProfile, alertIndex, alertsConfig, setAlertsConfig, users, setModalsActive }) => {
	const [recipients, setRecipients] = useState([]); // set recipients for selected location and client

	const [enabled, setEnabled] = useState(false);

	const [emailOptions, setEmailOptions] = useState();
	const [inputEmailField, setInputEmailField] = useState(false);
	const [enteredEmail, setEnteredEmail] = useState("");

	// custom styling for react-select
	const selectStyles = {
		menuList: (styles) => {
			return {
				...styles,
				maxHeight: 136,
			};
		},
		container: (provided) => ({
			...provided,
			width: 300,
		}),
	};

	useEffect(() => {
		setRecipients(alertsConfig[alertIndex].Recipients);
		setEnabled(alertsConfig[alertIndex].Status === "Enabled" ? true : false);
	}, []);

	//whenever users list changes so reset the options list for react-select
	useEffect(() => {
		let obj = [];
		users.forEach((ele) => {
			obj = [...obj, { value: ele, label: ele }];
		});
		setEmailOptions(obj);
	}, [users]);

	//setting selected input from react select
	const handleEmailInputChange = (selectedOption) => {
		setEnteredEmail(selectedOption.value);
	};

	const addRecipient = async (email) => {
		if (!email) {
			toast.error("Email Field Empty");
			return;
		}
		if (recipients.includes(email)) {
			toast.error("Email Already Present");
			return;
		}
		setRecipients((prev) => {
			return [...prev, email];
		});
	};

	const deleteRecipient = async (email) => {
		setRecipients((prev) => prev.filter((ele) => ele !== email));
	};

	const handleCheck = async (e) => {
		let status = e.target.checked;
		setEnabled(status);
	};

	const handleConfirm = async () => {
		let tempAlertConfig = alertsConfig;
		tempAlertConfig[alertIndex].Recipients = recipients;
		tempAlertConfig[alertIndex].Status = enabled ? "Enabled" : "Disabled";
		setAlertsConfig(tempAlertConfig);

		const docRef = doc(db, "AlertTypesCollection", alertsConfig[alertIndex].id);
		await updateDoc(docRef, {
			Status: enabled ? "Enabled" : "Disabled",
			Recipients: recipients,
		});

		// add profiles to recipients emails in userDataCollection
		recipients.forEach(async (email) => {
			// querying for each email
			const colRef = collection(db, "userDataCollection");
			const q = query(colRef, where("Email", "==", email));
			const querySnapshot = await getDocs(q);

			//since Email is unique so their will be only one document
			let userID;
			if (querySnapshot.empty) return; // no matching emails found
			querySnapshot.forEach((doc) => {
				// doc.data() is never undefined for query doc snapshots
				userID = doc.id;
			});

			// updating AlertProfile for queryied email
			// if checkbox is enabled then add that alertprofile from the user's AlertProfile else remove
			const userDocRef = doc(db, "userDataCollection", userID);
			if (enabled) {
				await updateDoc(userDocRef, {
					AlertProfile: arrayUnion(alertProfile),
				});
			} else {
				await updateDoc(userDocRef, {
					AlertProfile: arrayRemove(alertProfile),
				});
			}
		});

		toast.success("Changes saved !");
		setModalsActive((prev) => prev.filter((ele) => ele !== alertProfile));
	};

	return (
		<>
			<input type="checkbox" id={`${alertProfile}modal`} className="modal-toggle" />
			<div className="modal">
				<div className="modal-box rounded-none bg-white overflow-y-scroll">
					<label
						htmlFor={`${alertProfile}modal`}
						className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]"
						onClick={() => setModalsActive((prev) => prev.filter((ele) => ele !== alertProfile))}
					>
						✕
					</label>
					<h3 className="font-bold text-3xl text-black text-center">{alertName}</h3>

					<div className="flex flex-row justify-between items-center text-black mt-3">
						<p className="py-4 text-black text-lg">Add recipients</p>
						<button onClick={() => setInputEmailField(true)}>
							<FiPlus className="font-extrabold text-4xl disabled:bg-white" />
						</button>
					</div>

					{inputEmailField && (
						<div className="text-black flex flex-row justify-between my-4">
							<Select options={emailOptions} placeholder={"Enter New Email ID here"} onChange={handleEmailInputChange} styles={selectStyles} />
							<div className="flex flex-row justify-between gap-2">
								<button
									className="w-14 bg-green-500 hover:bg-green-900 hover:border-green-300 text-white font-semibold flex items-center justify-center border-2 border-green-800"
									onClick={() => addRecipient(enteredEmail)}
								>
									Add
								</button>
								<button
									className="w-14 bg-blue-500 hover:bg-blue-900 hover:border-blue-300 text-white font-semibold flex items-center justify-center border-2 border-blue-800"
									onClick={() => setInputEmailField(false)}
								>
									Close
								</button>
							</div>
						</div>
					)}

					<table className="w-full text-black text-lg border border-gray-900 mb-6">
						<tbody className="text-black">
							{recipients.map((email) => (
								<tr key={email}>
									<td className="border border-gray-300 pl-3">{email}</td>
									<td className="border border-gray-300 align-middle text-center">
										<button onClick={(e) => deleteRecipient(e.currentTarget.id)} id={email}>
											<FiTrash2 />
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>

					{/* enable alert checkbox */}
					<div className="flex flex-row items-center">
						<input className="!bg-white h-4 w-4" type="checkbox" id="alert" checked={enabled} onChange={handleCheck} />
						<label className="ml-3 text-black text-lg text-center" htmlFor="alert">
							Enable Alert
						</label>
					</div>

					<div className="modal-action !justify-center">
						<div className="tooltip tooltip-info" data-tip="Click to save changes">
							<label
								// htmlFor={`${alertprofile}modal`}
								className="bg-green-500 active:bg-green-900 hover:border-green-300 hover:bg-green-600 text-white font-semibold h-[2.5rem] w-24 flex items-center justify-center border-2 border-green-800"
								onClick={handleConfirm}
							>
								Confirm
							</label>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Modal;
