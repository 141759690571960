import React, { useEffect, useState } from 'react';
import AddDeviceModal from './AddDeviceModal';
import { FiTrash2 } from "react-icons/fi";
import { MdModeEditOutline, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { collection, deleteDoc, doc, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../../../firebase/firebase.config';
import { toast } from 'react-hot-toast';
import EditDeviceModal from './EditDeviceModal';
import Loading from '../../../../components/Loading/Loading';
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';
import AlMonkLogo from '../../../../components/AlMonkLogo';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import { HiOutlineArrowCircleRight } from 'react-icons/hi';
import useFirestoreDocument from '../../../../hooks/useFirestoreDocument';

const DeviceManagement = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const clientId = location.state?.clientId;
    const Location_ID = location.state?.Location_ID;
    const Zone_ID = location.state?.Zone_ID;
    const ZoneName = location.state?.ZoneName;

    const [closeModal, setCloseModal] = useState(false);
    const [deviceData, setDeviceData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [aDevice, setADevice] = useState(null);
    const [useCase, setUseCase] = useState([]);
    const [clientDoc, clientLoading] = useFirestoreDocument('clients-data-collection', clientId);
    const [breadcrumbsValues, setBreadcrumbsValues] = useState({
        clientId: '',
        locationId: '',
        zoneId: '',
        values: false,
    });

    // set client Usecase 
    useEffect(() => {
        if (clientDoc && !clientLoading) {
            setUseCase([clientDoc?.UseCase]);
        }
    }, [clientId, clientDoc, clientLoading]);

    //set the client, location & zone value for breadcrumbs
    useEffect(() => {
        setBreadcrumbsValues({
            clientId: clientId,
            locationId: Location_ID,
            zoneId: Zone_ID,
            values: true,
        });
    }, [clientId, Location_ID, Zone_ID]);


    // fetch all deviceData from firebase
    useEffect(() => {

        if (clientId && Location_ID && Zone_ID) {
            const unsub = onSnapshot(
                // orderBy("CreatedAt", "desc")
                query(collection(db, "device-data-collection"),
                    where("ClientID", "==", clientId),
                    where("Location_ID", "==", Location_ID),
                    where("ZoneID", "==", Zone_ID)),
                (snapShot) => {
                    let list = [];
                    snapShot.docs.forEach((doc) => {
                        list.push({ id: doc.id, ...doc.data() });
                    });
                    //console.log(list);
                    setDeviceData(list);
                    setIsLoading(false);
                },
                (error) => {
                    console.log(error);
                }
            );

            return () => {
                unsub();
            };
        }

    }, [clientId, Location_ID, Zone_ID]);
    //console.log("data:", deviceData);

    //delete Device
    const handleDeviceDelete = async (device) => {
        //console.log(device);
        await deleteDoc(doc(db, "device-data-collection", device?.id));
        setDeviceData(deviceData?.filter((item) => item?.id !== device?.id));
        toast.success(`Device deleted successfully`);
    }

    //navigate to zone
    const handleNavigateToZone = () => {
        navigate(`/dashboard/masteradminclient/${clientId}/${Location_ID}`, {
            state: { clientId, Location_ID }
        });
    };

    //navigate to camera
    const handleNavigateToCamera = (deviceId) => {
        navigate(`/dashboard/masteradmindevice/${deviceId}`, {
            state: { clientId, Location_ID, Zone_ID, deviceId, ZoneName }
        });
    };

    //modal functions
    const closeDeleteModal = () => {
        setADevice(null);
    }

    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <>
            {/* header part */}
            <div className='flex items-center bg-white justify-between pb-2 sticky top-0 z-[2]'>
                <div className='ml-6 mt-1'>
                    {/* breadcrumbs */}
                    <div className='pl-1 mt-3 text-sm text-black'>
                        {
                            breadcrumbsValues?.values && <div className='flex items-center'>
                                <p className='flex items-center font-semibold'>Client <MdOutlineKeyboardArrowRight className='pt-1 text-[19px]' /><span className='font-normal'>{breadcrumbsValues?.clientId}</span></p>
                                <p className='flex items-center font-semibold ml-1'>Location <MdOutlineKeyboardArrowRight className='pt-1 text-[19px]' /><span className='font-normal'>{breadcrumbsValues?.locationId}</span></p>
                                <p className='flex items-center font-semibold ml-1'>Zone <MdOutlineKeyboardArrowRight className='pt-1 text-[19px]' /><span className='font-normal'>{breadcrumbsValues?.zoneId}</span></p>
                            </div>
                        }
                    </div>
                    <div className="flex items-center mt-3">
                        <button
                            onClick={handleNavigateToZone}
                            className='text-3xl mr-2 text-[#375075]'
                        >
                            <IoIosArrowDropleftCircle className='text-[#375075]' />
                        </button>

                        <h1 className='font-bold text-3xl text-start'>Device Management</h1>
                    </div>
                </div>

                <div className='flex items-center justify-end'>

                    <div className='ml-5'>
                        <AlMonkLogo />
                    </div>
                </div>
            </div>

            <div className='w-full pr-10 flex items-center justify-end'>
                <label htmlFor="addDevice-modal" onClick={() => setCloseModal(true)} className='btn w-[150px] mt-4 bg-[#375075] text-white hover:bg-[#A6A6A6] border-0 rounded-none'>Add Device</label>
            </div>

            {/* Table */}
            {
                deviceData?.length > 0 ?
                    <div className="overflow-x-auto mx-2 custom-table-border mt-5 max-h-[90vh] overflow-y-auto">
                        <table className="w-full text-center">
                            <thead className='sticky top-0 border-b'>
                                <tr className='border-b'>
                                    <th className='p-3 bg-white max-w-[80px]'>Sl No</th>
                                    <th className='p-3 bg-white max-w-[100px]'>DeviceId</th>
                                    <th className='p-3 bg-white max-w-[100px]'>PinCode</th>
                                    <th className='p-3 bg-white max-w-[100px]'>DeviceIP</th>
                                    <th className='p-3 bg-white max-w-[100px]'>Status</th>
                                    <th className='p-3 bg-white max-w-[100px]'>Usecase</th>
                                    <th className='p-3 bg-white max-w-[100px]'>Camera</th>
                                    <th className='p-3 bg-white max-w-[100px]'></th>
                                    <th className='p-3 bg-white max-w-[100px]'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    deviceData?.map((deviceD, i) => <tr key={i} className='border-b'>
                                        <td className='max-w-[80px]'>{i + 1}</td>
                                        <td className='max-w-[100px]'>{deviceD?.DeviceID}</td>
                                        <td className='max-w-[100px]'>{deviceD?.PinCode}</td>
                                        <td className='max-w-[100px]'>{deviceD?.DeviceIp}</td>
                                        <td className='max-w-[100px]'>{deviceD?.Status}</td>
                                        <td className='max-w-[100px]'>{deviceD?.UseCase}</td>

                                        <td className='p-3 break-words max-w-[200px]'>
                                            <button
                                                onClick={() => handleNavigateToCamera(deviceD?.id)}
                                                className='mx-auto p-1 font-bold text-[#375075] flex items-center border border-white hover:border-[#375075]'
                                            >
                                                Cameras
                                                <HiOutlineArrowCircleRight className='ml-1 text-lg' />
                                            </button>
                                        </td>

                                        <td className='max-w-[100px]'><label onClick={() => setADevice(deviceD)} htmlFor="updateDevice-modal" ><MdModeEditOutline className='text-2xl' /> </label> </td>
                                        <td className='max-w-[100px]'><label onClick={() => setADevice(deviceD)} htmlFor="confirmation-modal" ><FiTrash2 className='text-2xl' /> </label> </td>
                                    </tr>)
                                }

                            </tbody>
                        </table>
                    </div>
                    : <div className="flex justify-center items-center mx-auto text-black">
                        <h2 className="text-xl mt-5 font-semibold">No Device added yet</h2>
                    </div>
            }

            {
                aDevice && <ConfirmModal
                    title={`Delete this device?`}
                    message={`If you delete ${aDevice?.DeviceID}. It cannot be restore`}
                    successButtonName="Delete"
                    successAction={handleDeviceDelete}
                    modalData={aDevice}
                    closeDeleteModal={closeDeleteModal}
                >
                </ConfirmModal>
            }

            {
                aDevice && <EditDeviceModal
                    aDevice={aDevice}
                    setADevice={setADevice}
                    clientId={clientId}
                    useCase={useCase}
                ></EditDeviceModal>
            }

            {
                closeModal &&
                <AddDeviceModal
                    setCloseModal={setCloseModal}
                    clientId={clientId}
                    Location_ID={Location_ID}
                    Zone_ID={Zone_ID}
                    ZoneName={ZoneName}
                    clientDoc={clientDoc}
                    useCase={useCase}
                ></AddDeviceModal>
            }

        </>
    );
};

export default DeviceManagement;