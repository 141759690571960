import React, { useEffect, useState } from "react";
import Loading from "../../../../../components/Loading/Loading";
import Select from "react-select";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import app, { db } from "../../../../../firebase/firebase.config";
import { formatDateString } from "../../../../../Utility/fixDateFormat";

const formateDateYYYYMMDD = (dateString) => {
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);
  return `${year}-${month}-${day}`;
};

const dateRangeGenerator = function* (start, end, step = 1) {
  let d = start;
  while (d <= end) {
    yield combineDate(d);
    d.setDate(d.getDate() + step);
  }
};

function combineDate(date) {
  const d = new Date(date);
  return d.getFullYear().toString() + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0");
}

// custom styling for react-select
const selectStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: 300,
    maxWidth: 646,
  }),
};

const SecurityValidation = ({ selectedClient, selectedLocation }) => {
  const [securityValidation, setSecurityValidation] = useState([]);

  const [dateRange, setDateRange] = useState([new Date().getTime() - 7 * 24 * 60 * 60 * 1000, new Date()]);
  const [startDate, endDate] = dateRange;

  const [detectionsOptions, setDetectionsOptions] = useState([]);
  const [selectedDetectionsOptions, setSelectedDetectionsOptions] = useState([]);
  const [filterapply, isapply] = useState(true)

  const [loading, setLoading] = useState(true);

  async function fetchSecurityValidationData() {
    let initArr = [];
    let entryArr = [];

    const start = new Date(startDate);
    const startingDateString = combineDate(start);

    const end = new Date(endDate);
    const endingDateString = combineDate(end);

    const dateRange = [...dateRangeGenerator(start, end)];

    let finalDocs = [];

    for (let d of dateRange) {
      let q = query(
        collection(db, `${selectedClient.id}-entries-${d}`),
        where("LocationID", "==", selectedLocation.id),
        where(
          "Detection",
          "in",
          selectedDetectionsOptions?.map((g) => g?.value)
        )
      );
      const querySnapshot = await getDocs(q);
      querySnapshot?.docs?.map((h) => finalDocs?.push({ date: d, action: h?.data()?.Action, type: "Record" }));
    }

    for (let d of dateRange) {
      let q = query(
        collection(db, `${selectedClient.id}-detection-events-${d}`),
        where("Location-ID", "==", selectedLocation.id),
        where(
          "Class_label",
          "in",
          selectedDetectionsOptions?.map((g) => g?.value)
        )
      );
      const querySnapshot = await getDocs(q);
      querySnapshot?.docs?.map((h) => finalDocs?.push({ date: d, action: h?.data()?.Direction, type: "Event" }));
    }

    const groupedCounts = finalDocs?.reduce((acc, item) => {
      // Initialize the date object if it doesn't exist
      if (!acc?.[item?.date]) {
        acc[item.date] = {
          entryEvent: 0,
          exitEvent: 0,
          entryRecord: 0,
          exitRecord: 0,
          deltaEvent:0,
          deltaRecord:0,
          Date:formateDateYYYYMMDD(item?.date),

        };
      }

      // Count based on action and type
      if (item?.type === "Event") {
        if (item?.action === "Entry") {
          acc[item.date].entryEvent++;
        } else if (item?.action === "Exit") {
          acc[item.date].exitEvent++;
        }
      } else if (item?.type === "Record") {
        if (item?.action === "Entry") {
          acc[item.date].entryRecord++;
        } else if (item.action === "Exit") {
          acc[item.date].exitRecord++;
        }
      }
      return acc; 
    }, {});

    for (const date in groupedCounts) {
      groupedCounts[date]['deltaEntry'] = groupedCounts[date].entryEvent - groupedCounts[date].entryRecord;
      groupedCounts[date]['deltaExit'] = groupedCounts[date].exitEvent - groupedCounts[date].exitRecord;
    }

    setSecurityValidation(Object.values(groupedCounts||{})?.sort((a, b) => b?.Date?.localeCompare(a?.Date)))
    
    // querySnapshot.forEach((doc) => {
    //   const data = doc.data();
    //   const dateString = data?.DateStamp;
    //   const date = formateDateYYYYMMDD(dateString);

    //   console.log(start, end, 'starting ending')
    //   let entryExitCntObj = {
    //     Date: date,
    //     SecurityInit_Entry: 0,
    //     SecurityInit_Exit: 0,
    //     EntrybySecurity: 0,
    //     ExitbySecurity: 0,
    //     EntryDelta: 0,
    //     ExitDelta: 0,
    //   };

    //   const initEntryKey = "SecurityInit_Entry";
    //   if (Object.hasOwn(data, initEntryKey))
    //     selectedDetectionsOptions?.forEach((detection) => {
    //       entryExitCntObj["SecurityInit_Entry"] += data[initEntryKey]?.[
    //         detection.value
    //       ]
    //         ? data[initEntryKey][detection.value]
    //         : 0;
    //     });

    //   const initExitKey = "SecurityInit_Exit";
    //   if (Object.hasOwn(data, initExitKey))
    //     selectedDetectionsOptions?.forEach((detection) => {
    //       entryExitCntObj["SecurityInit_Exit"] += data[initExitKey]?.[
    //         detection.value
    //       ]
    //         ? data[initExitKey][detection.value]
    //         : 0;
    //     });

    //   // entryExitCntObj.EntryDelta = Math.abs(entryExitCntObj.SecurityInit_Entry - entryExitCntObj.EntrybySecurity);
    //   // entryExitCntObj.ExitDelta = Math.abs(entryExitCntObj.SecurityInit_Exit - entryExitCntObj.ExitbySecurity);

    //   initArr.push(entryExitCntObj);
    //   // console.log("SecurityValidation data:", doc);
    // });

    // const entryq = query(
    //   collection(db, "SecurityEntryReport"),
    //   where("DateStamp", ">=", startingDateString),
    //   where("DateStamp", "<=", endingDateString),
    //   where("ClientID", "==", selectedClient.id),
    //   where("LocationID", "==", selectedLocation.id)
    // );
    // console.log(entryq, 'the entry query in set')
    // const queryEntrySnapshot = await getDocs(entryq);
    // queryEntrySnapshot.forEach((doc) => {
    //   // list.push({ id: doc.id, ...doc.data() });
    //   const data = doc.data();
    //   const dateString = data?.DateStamp;
    //   const date = formateDateYYYYMMDD(dateString);
    //   // datesInDb.add(dateString);
    //   let entryExitCntObj = {
    //     Date: date,
    //     EntrybySecurity: 0,
    //     ExitbySecurity: 0,
    //   };

    //   const securityEntryKey = "EntrybySecurity";
    //   if (Object.hasOwn(data, securityEntryKey))
    //     selectedDetectionsOptions?.forEach((detection) => {
    //       entryExitCntObj["EntrybySecurity"] += data[securityEntryKey]?.[
    //         detection.value
    //       ]
    //         ? data[securityEntryKey][detection.value]
    //         : 0;
    //     });

    //   const securityExitKey = "ExitbySecurity";
    //   if (Object.hasOwn(data, securityExitKey))
    //     selectedDetectionsOptions?.forEach((detection) => {
    //       entryExitCntObj["ExitbySecurity"] += data[securityExitKey]?.[
    //         detection.value
    //       ]
    //         ? data[securityExitKey][detection.value]
    //         : 0;
    //     });

    //   entryArr.push(entryExitCntObj);
    //   // console.log("SecurityValidation data:", doc);
    // });

    // console.log("initArr:", initArr);
    // console.log("entryArr:", entryArr);

    //   const uniqueDates = new Set([
    //     ...entryArr.map((item) => item.Date),
    //     ...initArr.map((item) => item.Date),
    //   ]);

    //   // Merge arrays for all unique dates
    //   const listArr = Array.from(uniqueDates).map((date) => {
    //     const entryItem = entryArr.find((item) => item.Date === date) || {
    //       EntrybySecurity: 0,
    //       ExitbySecurity: 0,
    //     };
    //     const initItem = initArr.find((item) => item.Date === date) || {
    //       SecurityInit_Entry: 0,
    //       SecurityInit_Exit: 0,
    //     };

    //     const entryDelta = Math.abs(
    //       initItem.SecurityInit_Entry - entryItem.EntrybySecurity
    //     );
    //     const exitDelta = Math.abs(
    //       initItem.SecurityInit_Exit - entryItem.ExitbySecurity
    //     );

    //     return {
    //       Date: date,
    //       SecurityInit_Entry: initItem?.SecurityInit_Entry,
    //       SecurityInit_Exit: initItem?.SecurityInit_Exit,
    //       EntrybySecurity: entryItem?.EntrybySecurity,
    //       ExitbySecurity: entryItem?.ExitbySecurity,
    //       EntryDelta: entryDelta ? entryDelta : 0,
    //       ExitDelta: exitDelta ? exitDelta : 0,
    //     };
    //   });

    //   // console.log("listArr:", listArr);

    //   // adding dates that were not in db
    //   dateRange.forEach((ele) => {
    //     if (!uniqueDates.has(formateDateYYYYMMDD(ele))) {
    //       listArr.push({
    //         Date: formateDateYYYYMMDD(ele),
    //         SecurityInit_Entry: 0,
    //         SecurityInit_Exit: 0,
    //         EntrybySecurity: 0,
    //         ExitbySecurity: 0,
    //         EntryDelta: 0,
    //         ExitDelta: 0,
    //       });
    //     }
    //   });

    //   listArr.sort((a, b) => {
    //     const dateA = new Date(a?.["Date"] || "2024-01-01");
    //     const dateB = new Date(b?.["Date"] || "2024-01-01");
    //     return dateA - dateB;
    // });

    //   setSecurityValidation(listArr);
    setLoading(false);
    isapply(false)
  }

  async function fetchDetections() {
    const docRef = doc(db, `clients-data-collection/${selectedClient.id}/Location/${selectedLocation.id}/Detections`, "detection-list");
    const docSnap = await getDoc(docRef);
    let detectionList = docSnap.data()?.Vehicles;
    detectionList = detectionList.filter((detection) => detection !== "Numberplate").sort();
    let options = detectionList.map((detection) => ({
      value: detection,
      label: detection,
    }));
    setDetectionsOptions(options);
    setSelectedDetectionsOptions(options);
  }

  useEffect(() => {
    if (selectedClient.id && selectedLocation.id) {
      fetchDetections();
    }
  }, [selectedClient.id, selectedLocation.id]);

  useEffect(() => {
    // if(!filterapply){
    //   return
    // }

    if (selectedClient.id && selectedLocation.id && selectedDetectionsOptions.length && startDate && endDate) {
      setLoading(true);
      fetchSecurityValidationData();
    }
  }, [selectedClient, selectedLocation, selectedDetectionsOptions, startDate ,endDate]);

  const handleDetectionsSelect = (selectedOptions) => {
    setSelectedDetectionsOptions(selectedOptions);
  };


  return (
    <div className="mx-6">
      <h1 className="text-black text-2xl font-semibold mr-3">Events and Records</h1>
      <div className="flex flex-col gap-3 my-5">
        <div className="flex flex-row gap-2 xl:justify-between items-center">
          {/* Detection and Date Range */}
          <div className="flex gap-4 justify-between items-center">
            {/* Detections */}
            <div className="flex flex-row gap-4 items-center">
              <h1 className="text-black text-base xl:text-lg">Detection:</h1>
              <Select className="text-black" options={detectionsOptions} placeholder={"Select Detections"} value={selectedDetectionsOptions} onChange={handleDetectionsSelect} styles={selectStyles} isMulti />
            </div>
            {/* Date Range */}
            <div className="flex flex-row gap-1 items-center">
              <h1 className="text-black text-base xl:text-lg">Date range:</h1>
              <DatePicker
                className="bg-gray-100 text-black w-fit px-5"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                isClearable={true}
                showIcon
                placeholderText="Select Date range"
                renderInput
              />
            </div>
            {/* <button onClick={()=>isapply(true)} className="bg-yellow-400 text-black px-3 py-1 text-[14px] font-medium hover:bg-yellow-500 active:bg-yellow-600">Apply</button> */}
          </div>
          {/* Toggle and  Download*/}
          <div className="flex flex-row gap-3">
            {/* {!loading && securityValidation.length && (
							<div className="flex flex-row mr-3 gap-3 items-center bg-slate-200 p-1 rounded">
								<span className="text-black">Data</span>
								<input type="checkbox" className="toggle toggle-accent" onChange={(e) => setCharts(e.target.checked)} checked={charts} />
								<span className="text-black">Chart</span>
							</div>
						)} */}

            {!loading && securityValidation.length && selectedDetectionsOptions.length && (
              <CSVLink data={securityValidation} filename={"Events and Records Report.csv"} className="mr-6 bg-green-500 hover:bg-green-900 hover:border-green-300 px-2 py-1 active:border-2 active:border-green-900 w-36 text-center text-white font-semibold h-[2.5rem]   border-2 border-green-800">
                Download
              </CSVLink>
            )}
          </div>
        </div>

        {selectedDetectionsOptions.length === 0 ? (
          <div className="flex flex-row justify-around my-6">
            <div className="alert alert-info px-20 w-fit">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
              <span>Select Detections !</span>
            </div>
          </div>
        ) : (
          // : charts ? (
          // 	<>{loading ? <Loading /> : <DailyEntryExitByDay securityValidation={securityValidation}></DailyEntryExitByDay>}</>
          // )
          <>
            {startDate && endDate ? (
              loading ? (
                <Loading />
              ) : (
                <div className="flex flex-col gap-7 mr-6 overflow-x-auto overflow-y-auto max-h-[70vh]">
                  <table className="text-center self-center w-full border-t-[3px] border-t-black border-b-2 border-b-black">
                    <thead className="sticky top-0 bg-gray-100">
                      <tr className="border-b text-black">
                        <th className="p-3 max-w-[200] xl:text-base text-sm">Date</th>
                        <th className="p-3 max-w-[200] xl:text-base text-sm">Entry Events</th>
                        <th className="p-3 max-w-[200] xl:text-base text-sm">Entry Records</th>
                        <th className="p-3 max-w-[200] xl:text-base text-sm">Entry Delta</th>
                        <th className="p-3 max-w-[200] xl:text-base text-sm">Exit Events</th>
                        <th className="p-3 max-w-[200] xl:text-base text-sm">Exit Records</th>
                        <th className="p-3 max-w-[200] xl:text-base text-sm">Exit Delta</th>
                      </tr>
                    </thead>
                    <tbody className="bg-sky-100">
                      {securityValidation?.map((ele, i) => (
                        <tr key={ele["Date"]} className="border-b text-black">
                          <td className="p-3 max-w-[80] xl:text-base text-sm">{ele["Date"]}</td>
                          <td className="p-3 max-w-[80] xl:text-base text-sm">{ele["entryEvent"]}</td>
                          <td className="p-3 max-w-[80] xl:text-base text-sm">{ele["entryRecord"]}</td>
                          <td className="p-3 max-w-[80] xl:text-base text-sm">{ele["deltaEntry"]}</td>
                          <td className="p-3 max-w-[80] xl:text-base text-sm">{ele["exitEvent"]}</td>
                          <td className="p-3 max-w-[80] xl:text-base text-sm">{ele["exitRecord"]}</td>
                          <td className="p-3 max-w-[80] xl:text-base text-sm">{ele["deltaExit"]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            ) : (
              <div className="flex flex-row justify-around my-6">
                <div className="alert alert-info px-20 w-fit">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  <span>
                    Select {!startDate && "Starting date and"} {!endDate && "Ending date"}
                  </span>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SecurityValidation;
