import { doc, updateDoc } from 'firebase/firestore';
import React, { useContext } from 'react';
import { db } from '../../firebase/firebase.config';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { AuthContext } from '../../context/AuthProvider/AuthProvider';
import { getCurrentDate, getCurrentTime } from '../../Utility/FixTodaysDate';

const EntryApprovalModal = ({ setIsApprove, selectedVehicle }) => {
    const { Location, id, Timestamp, Zone, Action, ClientID, Detection, EventId } = selectedVehicle || {};
    const { user } = useContext(AuthContext);
    // console.log(selectedVehicle);
    const [isApproved, setIsApproved] = useState(false);
    const [isRejected, setIsRejected] = useState(false);

    const date = Timestamp.slice(0, 8);

    async function handleSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const remarks = form.remarks.value;

        if (remarks?.length > 0 && isApproved) {
            const eventStatus = 'Approved';
            // console.log("Approved:", eventStatus);

            updateEntryData(eventStatus, remarks);
            if (EventId?.length > 0) {
                updateEventData(eventStatus, remarks);
            }
            setIsApproved(false);

        } else if (remarks?.length > 0 && isRejected) {
            const eventStatus = 'Rejected';
            // console.log("Rejected:", eventStatus);

            updateEntryData(eventStatus, remarks);
            if (EventId?.length > 0) {
                updateEventData(eventStatus, remarks);
            }
            setIsRejected(false);

        } else {
            toast.error("Please provide a remarks");
            return;
        }
        form.reset();
    }

    async function updateEntryData(eventStatus, remarks) {

        if (eventStatus && remarks) {
            try {
                // console.log("updateEntryData:", eventStatus, remarks, `${ClientID}-entries-${date}`, id);

                const docRef = doc(db, `${ClientID}-entries-${date}`, id);
                const res = await updateDoc(docRef, {
                    Status: eventStatus,
                    ApprovalRemarks: remarks,
                    ApproverUserID: user?.uid,
                    ApproverUserName: user?.displayName,
                    ApproveDate: getCurrentDate(),
                    ApproveTime: getCurrentTime(),
                });
                // toast.success('entries data updated');
                setIsApprove(false);
            } catch (err) {
                console.error(err);
            }
        }
    }


    async function updateEventData(eventStatus, remarks) {

        if (eventStatus && remarks) {
            try {
                for (const eventId of EventId) {

                    // console.log("updateEventData:", eventStatus, remarks, `${ClientID}-priority-${date}`, eventId);

                    const docRef = doc(db, `${ClientID}-priority-${date}`, eventId);
                    const res = await updateDoc(docRef, {
                        Status: eventStatus,
                        ApprovalRemarks: remarks,
                        ApproverUserID: user?.uid,
                        ApproverUserName: user?.displayName,
                        ApproveDate: getCurrentDate(),
                        ApproveTime: getCurrentTime(),
                    });

                    toast.success('priority data updated');
                    setIsApprove(false);
                }
            } catch (err) {
                console.error(err);
            }
        }
    }



    return (
        <>
            <input type="checkbox" id="entryApprove-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative rounded-none bg-white">
                    <label
                        htmlFor="entryApprove-modal"
                        className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]">
                        ✕
                    </label>
                    <h3
                        className="text-3xl text-black text-center font-bold mb-7">
                        Approve Event
                    </h3>
                    <p className='text-black text-lg'>Location: {Location}</p>
                    <p className='text-black text-lg'>Gate: {Zone}</p>
                    <p className='text-black text-lg'>Direction: {Action}</p>
                    <p className='text-black text-lg'>Detection: {Detection}</p>

                    <form onSubmit={handleSubmit}>
                        <textarea
                            className='mx-auto w-full bg-white px-2.5 py-4 border-2 border-gray-300 rounded mt-5 block'
                            name="remarks" required></textarea>
                        <div className='flex justify-center items-center px-5'>
                            <input type="submit" onClick={() => setIsApproved(true)} className='btn w-[170px] bg-green-500 text-white hover:bg-[#A6A6A6] border-0 rounded-none mt-5' value="Approve" />
                            <input type="submit" onClick={() => setIsRejected(true)} className='btn w-[170px] bg-red-500 text-white hover:bg-[#A6A6A6] border-0 rounded-none mt-5 ml-5' value="Reject" />
                        </div>

                    </form>

                </div>
            </div>
        </>
    );
};

export default EntryApprovalModal;