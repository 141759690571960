import { collection, doc, getDocs, limit, orderBy, query, setDoc } from 'firebase/firestore';
import React from 'react';
import { toast } from 'react-hot-toast';
import BlueBtn from '../../../../components/Buttons/BlueBtn';
import { db } from '../../../../firebase/firebase.config';
import { deviceIdGen } from '../../../../Utility/DeviceIdGenerator';
import useFirestoreDocument from '../../../../hooks/useFirestoreDocument';
import Loading from '../../../../components/Loading/Loading';

const AddDeviceModal = ({
    setCloseModal,
    clientId,
    Location_ID,
    Zone_ID,
    ZoneName,
    clientDoc,
    useCase
}) => {
    const [locationDoc, locationLoading] = useFirestoreDocument(`clients-data-collection/${clientId}/Location`, Location_ID);

    const handleAddDevice = e => {
        e.preventDefault();
        const form = e.target;
        const pinCode = form.pinCode.value;
        const deviceIp = form.deviceIp.value;
        const status = form.status.value;
        const usecase = form.usecase.value;
        // const zone = form.zone.value;

        //console.log(deviceID, client, location, pinCode, cameraName, cameraIp, deviceIp, usecase);
        addDeviceDataToDB(pinCode, deviceIp, status, usecase);
        form.reset();
    }

    const addDeviceDataToDB = async (pinCode, deviceIp, status, usecase) => {
        // Add a new document in collection "deviceDataCollection"
        try {
            const collectionRef = collection(db, 'device-data-collection');
            const q = query(collectionRef, orderBy("CreatedAt", "desc"), limit(1));
            const querySnapshot = await getDocs(q);
            let lastDevice = '';
            querySnapshot.forEach((doc) => {
                // console.log(doc.id, " => ", doc.data());
                lastDevice = doc.data();
            });
            //console.log(lastDevice);
            const clientName = clientDoc?.Client;
            const locationName = locationDoc?.Name;
            if (!lastDevice) {
                const docID = 'EEM0001';
                const res = await setDoc(doc(db, "device-data-collection", docID), {
                    DeviceID: docID,
                    Client: clientName,
                    Location: locationName,
                    ClientID: clientId,
                    Location_ID: Location_ID,
                    PinCode: pinCode,
                    DeviceIp: deviceIp,
                    ZoneID: Zone_ID,
                    Zone: ZoneName,
                    Status: status,
                    UseCase: usecase,
                    CreatedAt: Date.now()
                });
                //console.log('device-id: ', res.id);
                setCloseModal(false);
                toast.success('Device added successfully');

            }
            else {

                // console.log('res', deviceIdGen(lastDevice.DeviceID));

                const docID = deviceIdGen(lastDevice?.DeviceID);

                const res = await setDoc(doc(db, "device-data-collection", docID), {
                    DeviceID: docID,
                    Client: clientName,
                    Location: locationName,
                    ClientID: clientId,
                    Location_ID: Location_ID,
                    PinCode: pinCode,
                    DeviceIp: deviceIp,
                    ZoneID: Zone_ID,
                    Zone: ZoneName,
                    Status: status,
                    UseCase: usecase,
                    CreatedAt: Date.now()
                });
                //console.log('device-id: ', res.id);
                setCloseModal(false);
                toast.success('Device added successfully');
            }

        }
        catch (err) {
            console.error('error', err);
        }
    }

    // if (clientLoading || locationLoading) {
    //     return <Loading></Loading>
    // }

    return (
        <div>
            <input type="checkbox" id="addDevice-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative rounded-none">
                    <label
                        htmlFor="addDevice-modal"
                        className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]">
                        ✕
                    </label>
                    <h3
                        className="text-3xl text-center font-bold mb-7">
                        Add Device
                    </h3>

                    <form onSubmit={handleAddDevice}>

                        {/* Pin Code */}
                        <div className="form-control mt-3">
                            <input
                                type="text"
                                name='pinCode'
                                placeholder="Pin Code"
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* Device IP */}
                        <div className="form-control flex mt-3">
                            <input
                                type="text"
                                name='deviceIp'
                                placeholder="Device IP"
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* status */}
                        <div className="form-control mt-3">
                            <select
                                name='status'
                                className="select w-full bg-white border-white border-b-[#D9D9D9] rounded-none" required >
                                <option>Up</option>
                                <option>Down</option>
                            </select>
                        </div>

                        {/* use case */}
                        <div className="form-control mt-3">
                            <select
                                name='usecase'
                                className="select w-full bg-white border-white border-b-[#D9D9D9] rounded-none" required >
                                <option>{useCase}</option>
                            </select>
                        </div>

                        <div className="form-control mt-3">
                            <BlueBtn>Confirm</BlueBtn>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    );
};

export default AddDeviceModal;