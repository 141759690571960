import { collection, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { FiTrash2 } from "react-icons/fi";
import { MdModeEditOutline } from "react-icons/md";
import { db } from '../../../../firebase/firebase.config';
import { useNavigate } from 'react-router-dom';
import { HiOutlineArrowCircleRight } from 'react-icons/hi';

const ClientTableRow = ({ i, clientD, setAClient, setPhoneValue }) => {
    const { ClientID, Client, Address, Email, Phone, Status, UseCase, id } = clientD;
    const [deviceCount, setDeviceCount] = useState(0);
    const navigate = useNavigate();

    // console.log(clientD);

    // fetch all deviceData count from firebase
    useEffect(() => {
        // LISTEN (REALTIME)
        const unsub = onSnapshot(
            query(collection(db, "device-data-collection"), where("ClientID", "==", ClientID)),
            (snapShot) => {
                let list = [];
                snapShot.docs.forEach((doc) => {
                    list.push({ id: doc.id, ...doc.data() });
                });
                // console.log('list count:',list);
                setDeviceCount(list?.length);
            },
            (error) => {
                console.log(error);
            }
        );

        return () => {
            unsub();
        };

    }, [ClientID]);
    //console.log("data:", deviceData);

    //navigate to Location
    const handleNavigate = (clientId) => {
        navigate(`/dashboard/masteradminclient/${clientId}`, {
            state: { clientId }
        });
    };

    return (
        <tr key={i} className='border-b'>
            <td className='p-3 break-words max-w-[80px]'>{i + 1}</td>
            <td className='p-3 break-words max-w-[100px]'>{ClientID}</td>
            <td className='p-3 break-words min-w-[200px]'>{Client}</td>
            <td className='p-3 break-words max-w-[200px]'>{Address}</td>
            <td className='p-3 break-words max-w-[200px]'>{Email}</td>
            <td className='p-3 break-words max-w-[200px]'>{Phone}</td>
            <td className='p-3 break-words max-w-[100px]'>{Status}</td>
            <td className='p-3 break-words max-w-[150px]'>{deviceCount}</td>
            <td className='p-3 break-words max-w-[200px]'>{UseCase.join(', ')}</td>

            <td className='p-3 break-words max-w-[200px]'>
                <button
                    onClick={() => handleNavigate(id)}
                    className='mx-auto p-1 font-bold text-[#375075] flex items-center border border-white hover:border-[#375075]'
                >
                    Locations
                    <HiOutlineArrowCircleRight className='ml-1 text-lg' />
                </button>
            </td>

            <td className='p-3 max-w-[50px]'><label onClick={() => {
                setAClient(clientD);
                setPhoneValue(clientD.Phone);
            }}
                htmlFor="updateClient-modal" ><MdModeEditOutline className='text-2xl' />
            </label></td>
            <td className='p-3 max-w-[50px]'>
                <label
                    onClick={() => setAClient(clientD)
                    }
                    htmlFor="confirmation-modal"
                >
                    <FiTrash2 className='text-2xl' />
                </label>
            </td>
        </tr>
    );
};

export default ClientTableRow;