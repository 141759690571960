export function subtractDate(d1, d2) {

    let formattedDiff = '';
    if (d1 && d2) {
        const date1 = new Date(d1);
        const date2 = new Date(d2);
        let diffTime = date1.getTime() - date2.getTime();
        let diffSeconds = Math.floor(diffTime / 1000);
        let diffMinutes = Math.floor(diffSeconds / 60);
        let diffHours = Math.floor(diffMinutes / 60);
        let diffDays = Math.floor(diffHours / 24);
        let diffMonths = Math.floor(diffDays / 30);
        let diffYears = Math.floor(diffMonths / 12);

        if (diffYears > 0) {
            formattedDiff += `${diffYears} year${diffYears > 1 ? 's' : ''}, `;
            formattedDiff += `${diffMonths % 12} month${diffMonths % 12 > 1 ? 's' : ''}`;
        } else if (diffMonths > 0) {
            formattedDiff += `${diffMonths} month${diffMonths > 1 ? 's' : ''}, `;
            formattedDiff += `${diffDays % 30} day${diffDays % 30 > 1 ? 's' : ''}`;
        } else if (diffDays > 0) {
            formattedDiff += `${diffDays} day${diffDays > 1 ? 's' : ''}, `;
            formattedDiff += `${diffHours % 24} hour${diffHours % 24 > 1 ? 's' : ''}`;
        } else if (diffHours > 0) {
            formattedDiff += `${diffHours} hour${diffHours > 1 ? 's' : ''}, `;
            formattedDiff += `${diffMinutes % 60} minute${diffMinutes % 60 > 1 ? 's' : ''}`;
        } else if (diffMinutes > 0) {
            formattedDiff += `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''}, `;
            formattedDiff += `${diffSeconds % 60} second${diffSeconds % 60 > 1 ? 's' : ''}`;
        } else {
            formattedDiff += `${diffSeconds} second${diffSeconds > 1 ? 's' : ''}`;
        }

    }
    
    //console.log(formattedDiff);
    return [formattedDiff];
}