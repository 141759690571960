import { doc, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase/firebase.config';

const TableLocationRow = ({ ClientID, locationID, setIsLoading }) => {
    const [locName, setLocName] = useState('');
    // console.log(ClientID, locationID);

    // fetch those locations according to client
    useEffect(() => {
        if (ClientID && locationID) {
            try {
                const docRef = doc(db, `clients-data-collection/${ClientID}/Location`, locationID);
                const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        const data = { id: docSnapshot.id, ...docSnapshot.data() };
                        // console.log('Loc:',data?.Name);
                        setLocName(data?.Name);
                    } else {
                        console.log("");
                    }
                    setIsLoading(false);
                });
                return () => unsubscribe();
            } catch (err) {
                console.log(err);
            }
        }

    }, [ClientID, locationID]);


    return (
        <>
            <p>
                {
                    locName ? locName : 'null'
                }
            </p>
        </>
    );
};

export default TableLocationRow;