import React, { useState } from "react";
import { useContext } from "react";
import Loading from "../../components/Loading/Loading";
import { fixDateFormate } from "../../Utility/fixDateFormat";
import EventResolvedModal from "./EventResolvedModal";
import { useEffect } from "react";
import { collection, doc, onSnapshot, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../../firebase/firebase.config";
import { AuthContext } from "../../context/AuthProvider/AuthProvider";
import useMasterAdmin from "../../hooks/useMasterAdmin";
import { useNavigate } from "react-router-dom";
import useGetUserType from "../../hooks/useGetUserType";
import EventApproveModal from "./EventApproveModal";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import EntryDetailsDateAndTime from "./EntryDetailsDateAndTime";
import CustomModal from "../../components/customModal";
import toast from "react-hot-toast";

const EventDetails = ({ isLoading, selectedVehicle, setIsLoading }) => {
  const { user } = useContext(AuthContext);
  const { Location, id, Direction, Class_label, Timestamp, Gate, Date, Exception } = selectedVehicle || {};
  const [closeModal, setCloseModal] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [urlExist, setUrlExist] = useState(false);
  const [priorityData, setPriorityData] = useState(null);
  const [isMasterAdmin] = useMasterAdmin(user?.email);
  const [cameraName, setCameraName] = useState(null);
  const [approverList, setApproverList] = useState([]);
  const [isEntry, setIsEntry] = useState(false);
  const [entryPhotos, setEntryPhotos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0); //entry image carousel
  const [priorityAlertInfo, setPriorityAlertInfo] = useState(null); //priority alert
  const [entryDetailsData, setEntryDetailsData] = useState([]);
  const cameraID = selectedVehicle?.["Camera-ID"];
  const deviceID = selectedVehicle?.["Device-ID"];
  const clientID = selectedVehicle?.["Client-ID"];
  const vdoURL = selectedVehicle?.["Video-URL"];
  const locationID = selectedVehicle?.["Location-ID"];
  const [exceptionresolve, setexceptionresolve] = useState(false);
  const { userType, userTypeLoading } = useGetUserType(user?.uid);

  // console.log(id);

  //receive formated date from fixDateFormat() funtion
  const [formatedDate, formatedTime] = fixDateFormate(Timestamp);
  const navigate = useNavigate();

  const hanlderesolve = async (e) => {
    e.preventDefault();
    let eresolve_remarks = e.target?.[0]?.value;
    try {
      toast.loading("please wait");
      // await collection(db, `${clientID}-detection-events-${Date}`).doc(id).update({ Exception: "Handled", eresolve_remarks:eresolve_remarks });
      console.log('deubbbb', `${clientID}-detection-events-${Date}`, id)
      await updateDoc(doc(db, `${clientID}-detection-events-${Date}`, id), { Exception: "Handled", eresolve_remarks:eresolve_remarks })
      toast.dismiss();
      toast.success("resolved");
      setexceptionresolve(false)
    } catch (error) {
      console.log(error)
      toast.dismiss();
      toast.error("something went wrong");
    }
  };

  //fetch All the Alerts Info from priority collection
  useEffect(() => {
    if (id && clientID && Date) {
      const unsub = onSnapshot(
        doc(collection(db, `${clientID}-priority-${Date}`), id),
        (snapShot) => {
          if (snapShot.exists()) {
            const data = snapShot.data();
            // console.log(data);
            setPriorityAlertInfo(data);
          } else {
            console.log("Document not found.");
            setPriorityAlertInfo(null);
          }
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
        }
      );
      return () => {
        unsub();
      };
    }
  }, [id, clientID, Date]);

  //fetch Camera name from device-data-collection
  useEffect(() => {
    if (cameraID && deviceID) {
      // console.log(deviceID, cameraID);
      const unsub = onSnapshot(
        doc(collection(db, `device-data-collection/${deviceID}/Cameras`), cameraID),
        (snapShot) => {
          if (snapShot.exists()) {
            const data = snapShot.data();
            // console.log(data);
            const camera = data?.CameraName;
            setCameraName(camera);
          } else {
            console.log("Document not found.");
            setCameraName(null);
          }
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
        }
      );
      return () => {
        unsub();
      };
    }
  }, [id, deviceID, cameraID]);

  //fetch priority events data
  useEffect(() => {
    setPriorityData(null);
    if (priorityAlertInfo?.Priority) {
      const docRef = doc(db, "ResolvedIssuesCollection", id);

      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          const list = docSnap.data();
          // console.log(list);
          const resolvedArr = list?.resolvedIssues;
          const lastData = resolvedArr[resolvedArr?.length - 1];
          setPriorityData(lastData);
        } else {
          // console.log("No such document!");
          setPriorityData(null);
        }
      });

      return () => unsubscribe();
    }
  }, [id, priorityAlertInfo?.Priority]);

  useEffect(() => {
    if (clientID && locationID) {
      // console.log(clientID, locationID);

      const docRef = doc(db, "AlertTypesCollection", `${clientID}-${locationID}-SAAA`);

      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          const approver = docSnap.data().Recipients;
          setApproverList(approver);
          // console.log(approver);
        } else {
          console.log("No such document!");
          setApproverList([]);
        }
      });

      return () => unsubscribe();
    }
  }, [id, clientID, locationID]);

  //fetch all entry images of this selected event
  useEffect(() => {
    // console.log(clientID, Date, id);
    if (clientID && Date && id) {
      const unsub = onSnapshot(
        query(collection(db, `${clientID}-entries-${Date}`), where("EventId", "array-contains", id)),
        (snapShot) => {
          let list = [];
          snapShot.docs.forEach((doc) => {
            list.push({ id: doc.id, ...doc.data() });
          });
          // console.log('list count:', list);
          setEntryDetailsData([...list]);
          const resultArray = list.reduce((acc, obj) => acc.concat(obj?.["Photo-URL"]), []);
          // console.log('resultArray:', resultArray);
          setEntryPhotos([...resultArray]);
        },
        (error) => {
          console.log(error);
        }
      );

      return () => {
        unsub();
      };
    }
  }, [id, clientID, Date]);

  //check video url
  const fileName = vdoURL?.substring(vdoURL?.lastIndexOf("/") + 1);
  // console.log(fileName);

  // useEffect(() => {
  fetch(`http://gk.secquraise.com/api/checkVideoURL?url=${fileName}&date=${Date}`)
    .then((response) => response.json())
    .then((data) => {
      if (data.exists) {
        // console.log('Video URL exists');
        setUrlExist(true);
        // setIsLoading(false);
      } else {
        // console.log('Video URL does not exist');
        setUrlExist(false);
      }
    })
    .catch((error) => {
      console.log("Error checking video URL:", error);
    });
  // }, [fileName]);

  const contents = (
    <>
      {urlExist ? (
        <>
          <video className="w-[90%] object-cover" height={470} key={id} controls>
            <source src={vdoURL} type="video/mp4" />
          </video>
        </>
      ) : (
        <>
          <div className="w-[90%] h-[470px] bg-black flex justify-center items-center mx-auto">
            <h2 className="text-2xl text-white mt-5 font-semibold">Video upload pending</h2>
          </div>
        </>
      )}
    </>
  );

  //navigate to event details page
  const handleNavigate = () => {
    const eventDoc = { ...selectedVehicle };
    navigate(`/dashboard/eventDetails`, {
      state: { eventDoc },
    });
  };

  //-------------------entry image carousel----------------
  const handleImageToggle = () => {
    setIsEntry(!isEntry);
  };

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? entryPhotos.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === entryPhotos.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  const imageContents = (
    <>
      {entryPhotos?.length > 0 ? (
        <>
          <div className="w-[90%] h-[470px] m-auto relative group">
            <div style={{ backgroundImage: `url(${entryPhotos[currentIndex]})` }} className="w-full h-full bg-center bg-cover duration-500"></div>
            {/* Left Arrow */}
            <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactLeft onClick={prevSlide} size={30} />
            </div>
            {/* Right Arrow */}
            <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactRight onClick={nextSlide} size={30} />
            </div>
            <div className="flex top-4 justify-center py-2">
              {entryPhotos?.map((slide, slideIndex) => (
                <div key={slideIndex} onClick={() => goToSlide(slideIndex)} className="text-2xl cursor-pointer"></div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="w-[90%] h-[470px] bg-black flex justify-center items-center mx-auto">
            <h2 className="text-2xl text-white mt-5 font-semibold">Image not available</h2>
          </div>
        </>
      )}
    </>
  );

  if (isLoading) {
    return <Loading></Loading>;
  }

  return (
    <div className="lg:w-[63%] md:lg:w-[55%] pt-3">
      {/* vehicle Image or video */}
      {/* < div className="flex justify-center h-[470px]">
				{contents}
			</div> */}

      <div className="flex justify-center h-[470px]">{isEntry ? <>{imageContents}</> : <>{contents}</>}</div>

      {/* vehicle info */}
      <div className="flex flex-col px-12 my-4 text-black">
        <div className="flex justify-between items-start">
          <div className="w-[50%]">
            <p className="text-[16px] font-semibold text-start">{Location}</p>
            <p className="text-start mt-5">{Gate}</p>
            <p className="text-start">{Class_label && cameraName ? `${Class_label} ${Direction} detected at ${cameraName}` : `No detection found`}</p>
            {priorityAlertInfo?.Priority === "Priority" && (
              <>
                <p className="text-start font-bold mt-4">Incident:</p>
                <p>{selectedVehicle?.Incident}</p>
              </>
            )}

            {Exception === "Exception" && (
              <button onClick={() => setexceptionresolve(true)} className="bg-green-500 p-2 px-4 rounded-xl text-white font-semibold hover:bg-green-600">
                Resolve
              </button>
            )}


            {Exception==='Handled' && 
            <div className="mt-10">
            <h6>Exception Remakrs</h6>
            <textarea disabled={true} name="eresolve_remarks" value={selectedVehicle?.eresolve_remarks} rows={2} cols="auto" className="text-md w-full text-gray-800 p-3 bg-white border-2 border-slate-200 rounded-lg" name="" id=""></textarea>
            </div>
            }

            {priorityAlertInfo?.Priority === "Priority" && priorityData && (
              <>
                <p className="text-start font-bold mt-4">Resolution History:</p>
                <p>
                  User: <span className="ml-2">{priorityData?.userName}</span>{" "}
                </p>
                <p>
                  Remark: <span className="ml-2">{priorityData?.promptInput}</span>
                </p>
                <p>
                  Status: <span className="ml-2">{priorityData?.eventStatus}</span>
                </p>
                <p>
                  Time: <span className="ml-2">{priorityData?.resolvedTime}</span>
                </p>
              </>
            )}

            {entryDetailsData?.length > 0 && (
              <>
                <p className="text-start font-bold mt-4">Details added:</p>
                {entryDetailsData?.map((entryD) => (
                  <>
                    <p>
                      User: <span className="ml-2">{entryD?.Entryby ? entryD?.Entryby : "null"}</span>{" "}
                    </p>
                    <p>
                      Remark: <span className="ml-2">{entryD?.Remarks ? entryD?.Remarks : "null"}</span>
                    </p>
                    <p>
                      Purpose: <span className="ml-2">{entryD?.Purpose ? entryD?.Purpose : "null"}</span>
                    </p>
                    <p>
                      Sender: <span className="ml-2">{entryD?.Sender ? entryD?.Sender : "null"}</span>
                    </p>
                    <EntryDetailsDateAndTime EntryTimestamp={entryD?.Timestamp}></EntryDetailsDateAndTime>
                    <p className="mb-4">
                      VehicleNo: <span className="ml-2">{entryD?.VehicleNo ? entryD?.VehicleNo : "null"}</span>
                    </p>
                  </>
                ))}
              </>
            )}

            {(priorityAlertInfo?.Status !== "Approved" || priorityAlertInfo?.Status !== "Rejected") && priorityAlertInfo?.ApproverUserName && priorityAlertInfo?.Remarks && (
              <>
                <p className="text-start font-bold mt-4">Approval History:</p>
                <p>
                  User: <span className="ml-2">{priorityAlertInfo?.ApproverUserName ? priorityAlertInfo?.ApproverUserName : "null"}</span>{" "}
                </p>
                <p>
                  Remark: <span className="ml-2">{priorityAlertInfo?.ApprovalRemarks ? priorityAlertInfo?.ApprovalRemarks : "null"}</span>
                </p>
                <p>
                  Status: <span className="ml-2">{priorityAlertInfo?.Status ? priorityAlertInfo?.Status : "null"}</span>
                </p>
                <p>
                  Approve Date: <span className="ml-2">{priorityAlertInfo?.ApproveDate ? priorityAlertInfo?.ApproveDate : "null"}</span>
                </p>
                <p>
                  Approve Time: <span className="ml-2">{priorityAlertInfo?.ApproveTime ? priorityAlertInfo?.ApproveTime : "null"}</span>
                </p>
              </>
            )}
          </div>

          <div className="flex flex-col font-medium text-[16px] text-end w-[30%]">
            <p className="ml-2">
              {formatedDate} {formatedTime}
            </p>

            {priorityAlertInfo?.EntryID && (
              <label onClick={handleImageToggle} className={"btn w-[145px] self-end bg-[#375075] text-white hover:bg-[#A6A6A6] border-0 rounded-none mt-5"}>
                {isEntry ? "Videos" : "Images"}
              </label>
            )}

            {priorityAlertInfo?.Priority === "Priority" && userType?.Role !== "Security Guard" && (
              <label
                htmlFor="eventResolved-modal"
                onClick={() => setCloseModal(true)}
                className={priorityAlertInfo?.Status === "Pending" ? "btn w-[175px] self-end bg-red-600 text-white hover:bg-[#A6A6A6] border-0 rounded-none mt-5" : "btn w-[175px] self-end bg-[#375075] text-white hover:bg-[#A6A6A6] border-0 rounded-none mt-5"}
                disabled={isMasterAdmin || priorityAlertInfo?.Status === "Approved" || priorityAlertInfo?.Status === "Closed"}
              >
                {priorityAlertInfo?.Status === "Pending" ? "Resolve" : priorityAlertInfo?.Status === "Approved" ? "Authorized" : priorityAlertInfo?.Status === "Closed" ? "Closed" : "Resolve"}
              </label>
            )}

            {priorityAlertInfo?.Priority === "Critical" && (priorityAlertInfo?.Status === "Pending" || priorityAlertInfo?.Status === "Update") && userType?.Role === "Security Guard" && (
              <>
                <button onClick={handleNavigate} className="btn w-[145px] self-end bg-green-700 text-white hover:bg-[#A6A6A6] border-0 rounded-none mt-5" disabled={priorityAlertInfo?.Status === "Approved" || priorityAlertInfo?.Status === "Rejected"}>
                  Add Details
                </button>
              </>
            )}

            {priorityAlertInfo?.Priority === "Critical" && approverList.includes(user?.email) && userType?.Role !== "Security Guard" && (
              <>
                <label
                  htmlFor="eventApprove-modal"
                  onClick={() => setIsApprove(true)}
                  className={priorityAlertInfo?.Status === "Pending" ? "btn w-[145px] self-end bg-red-600 text-white hover:bg-[#A6A6A6] border-0 rounded-none mt-5" : "btn w-[145px] self-end bg-[#375075] text-white hover:bg-[#A6A6A6] border-0 rounded-none mt-5"}
                  disabled={isMasterAdmin || priorityAlertInfo?.Status === "Approved" || priorityAlertInfo?.Status === "Rejected"}
                >
                  {priorityAlertInfo?.Status === "Pending" || priorityAlertInfo?.Status === "Update" ? "Approve" : priorityAlertInfo?.Status === "Approved" ? "Approved" : "Rejected"}
                </label>
              </>
            )}
          </div>
        </div>
      </div>

      {closeModal && <EventResolvedModal setCloseModal={setCloseModal} selectedVehicle={selectedVehicle} gate={Gate}></EventResolvedModal>}

      {isApprove && <EventApproveModal setIsApprove={setIsApprove} selectedVehicle={selectedVehicle} gate={Gate} priorityAlertInfo={priorityAlertInfo}></EventApproveModal>}

      {exceptionresolve && (
        <CustomModal setIsOpen={setexceptionresolve}>
          <form onSubmit={hanlderesolve}>
            <header>
              <h4 className="text-black font-bold text-lg">Resolve Exception</h4>
            </header>
            <section className="my-4">
              <div className="body flex flex-col">
                <label className="text-lg text-black" htmlFor="">
                  Remarks
                </label>
                <textarea name="eresolve_remarks" value={selectedVehicle?.eresolve_remarks} cols={20} rows={10} className="text-md text-gray-800 p-3 bg-white border-2 border-slate-200 rounded-lg" name="" id=""></textarea>
              </div>
            </section>
            <footer className="flex justify-between items-center">
              {Exception !== "Handled" && (
                <button type="submit" className="bg-green-500 text-sm rounded-lg p-2 px-3 text-gray-700">
                  Resolve
                </button>
              )}
              <button onClick={() => setexceptionresolve(false)} className="bg-blue-500 text-sm rounded-lg p-2 px-3 text-white">
                Close
              </button>
            </footer>
          </form>
        </CustomModal>
      )}
    </div>
  );
};

export default EventDetails;
