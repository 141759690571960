import React from "react";
import AddModal from "./Modals/AddModal";
import EditModal from "./Modals/EditModal";
import { useState } from "react";
import DeleteModal from "./Modals/DeleteModal";

const PurposeList = ({ purposeList, setPurposeList }) => {
	const [purposeEntryIndexClicked, setPurposeEntryIndexClicked] = useState(null);
	const [addModalStatus, setAddModalStatus] = useState(false);
	const [editModalStatus, setEditModalStatus] = useState(false);
	const [deleteModalStatus, setDeleteModalStatus] = useState(false);

	return (
		<div className="mt-16 mb-3">
			<div className="mb-11 flex flex-row justify-between">
				<h1 className="text-black font-semibold lg:text-[32px] md:text-base inline-block mr-10">Purpose List</h1>

				{/* The button to open modal */}
				<label
					htmlFor="add_purpose_modal"
					className="btn bg-[#2E3192] font-bold text-base text-white ml-36 !md:text-sm"
					onClick={() => setAddModalStatus(true)}
				>
					Add Purpose
				</label>
			</div>
			{purposeList.length ? (
				<div className="overflow-x-auto mx-2 mt-5 mb-8 max-h-[70vh] overflow-y-auto flex flex-row justify-around">
					<table className="w-3/4 text-center text-base font-medium border-separate border-spacing-x-0 border-spacing-y-2">
						<thead className="sticky top-0">
							<tr className="text-black">
								<th className="p-3 w-[200] lg:text-base text-sm">Purpose</th>
								<th className="p-3 w-48">Edit</th>
								<th className="p-3 w-48">Delete</th>
							</tr>
						</thead>
						<tbody>
							{purposeList.map((purpose, i) => (
								<tr key={`${purpose[0]}_${i}`} className="h-[65px]">
									<td className="text-black border-black border-l border-t border-b rounded-tl-[20px] rounded-bl-[20px] bg-[#FAFAFA]">{purpose}</td>
									<td className="border-black border-t border-b bg-[#FAFAFA]">
										<label
											htmlFor="edit_purpose_modal"
											id={i}
											onClick={(e) => {
												setPurposeEntryIndexClicked(e.currentTarget.id);
												setEditModalStatus(true);
											}}
										>
											<div className="flex flex-row justify-around text-[#0D6EFD]">EDIT</div>
										</label>
									</td>
									<td className="border-black border-r border-t border-b rounded-tr-[20px] rounded-br-[20px] bg-[#FAFAFA]">
										<label
											htmlFor="delete_purpose_modal"
											id={i}
											onClick={(e) => {
												setPurposeEntryIndexClicked(e.currentTarget.id);
												setDeleteModalStatus(true);
											}}
										>
											<div className="flex flex-row justify-around text-[#DC3545]">DELETE</div>
										</label>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : (
				<p className="text-black text-center text-base mb-3">No Purpose added yet</p>
			)}

			{addModalStatus && <AddModal setPurposeList={setPurposeList} setAddModalStatus={setAddModalStatus} />}
			{editModalStatus && (
				<EditModal
					purposeEntryIndexClicked={purposeEntryIndexClicked}
					purposeList={purposeList}
					setPurposeList={setPurposeList}
					setEditModalStatus={setEditModalStatus}
				/>
			)}
			{deleteModalStatus && (
				<DeleteModal
					purposeEntryIndexClicked={purposeEntryIndexClicked}
					purposeList={purposeList}
					setPurposeList={setPurposeList}
					setDeleteModalStatus={setDeleteModalStatus}
				/>
			)}
		</div>
	);
};

export default PurposeList;
