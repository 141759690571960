//This function will return todays time like "2023/03/09 9:18:23 pm" this format
export function fixDate() {
	const date = new Date();
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const day = date.getDate().toString().padStart(2, "0");
	const hours = date.getHours() % 12;
	const minutes = date.getMinutes().toString().padStart(2, "0");
	const seconds = date.getSeconds().toString().padStart(2, "0");
	const ampm = date.getHours() >= 12 ? "pm" : "am";
	const todaysD = `${year}/${month}/${day} ${hours}:${minutes}:${seconds} ${ampm}`;
	//console.log(todaysD);
	return [todaysD];
}

export function dateTimeInReadableFormat(date) {
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const day = date.getDate().toString().padStart(2, "0");
	const hours = date.getHours();
	const minutes = date.getMinutes().toString().padStart(2, "0");
	const seconds = date.getSeconds().toString().padStart(2, "0");
	const todaysD = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
	return todaysD;
}

// returns timestamp in "yymmdd-hhmmss" format from new Date() component
export function getTimeStampFromDateParam(date) {
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const day = date.getDate().toString().padStart(2, "0");
	const hours = date.getHours().toString().padStart(2, "0");
	const minutes = date.getMinutes().toString().padStart(2, "0");
	const seconds = date.getSeconds().toString().padStart(2, "0");

	return `${year}${month}${day}-${hours}${minutes}${seconds}`;
}

//This function will return todays Date only like this '20230622'
export function eventTodaysDate() {
	const d = new Date();
	const todaysDate = d.getFullYear() + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0");
	return todaysDate;
}

//This function will return todays Date only like this '2023-06-22'
export function getCurrentDate() {
	const d = new Date();
	const year = d.getFullYear();
	const month = (d.getMonth() + 1).toString().padStart(2, "0");
	const day = d.getDate().toString().padStart(2, "0");
	const formattedDate = `${year}-${month}-${day}`;

	return formattedDate;
}


//This function will return todays time only like this '18:15:11'
export function getCurrentTime() {
	const now = new Date();
	const hours = String(now.getHours()).padStart(2, "0");
	const minutes = String(now.getMinutes()).padStart(2, "0");
	const seconds = String(now.getSeconds()).padStart(2, "0");

	const currentTime = `${hours}:${minutes}:${seconds}`;
	// console.log(currentTime); // Output: "18:15:11"

	return currentTime;
}

//This function will return todays Date like this '20230622-181511'
export function FromDateParam() {
	const todaysDate = eventTodaysDate();
	const now = new Date();
	const hours = String(now.getHours()).padStart(2, "0");
	const minutes = String(now.getMinutes()).padStart(2, "0");
	const seconds = String(now.getSeconds()).padStart(2, "0");

	const timeStampFormatedDate = `${todaysDate}-${hours}${minutes}${seconds}`;
	const timeFormated = `${hours}${minutes}${seconds}`;

	return { timeStampFormatedDate, timeFormated };
}
