import React from 'react';
import BlueBtn from '../../../../components/Buttons/BlueBtn';
import { toast } from 'react-hot-toast';
import { db } from '../../../../firebase/firebase.config';
import { collection, doc, getDocs, limit, orderBy, query, setDoc } from 'firebase/firestore';
import { newZoneIdGen } from '../../../../Utility/IdGenerator';

const AddLocationZone = ({ setCloseModal, clientId, Location_ID, useCase }) => {

    const handleAddLocationZone = e => {
        e.preventDefault();
        const form = e.target;
        const zoneName = form.zoneName.value;
        const usecase = form.usecase.value;
        // console.log(zoneName, usecase);

        if ((zoneName.length < 1)) {
            return;
        }
        else {
            addZoneDataToDB(zoneName, usecase);
            form.reset();
        }
    }

    const addZoneDataToDB = async (zoneName, usecase) => {
        // Add a new document in collection
        try {
            const collectionRef = collection(db, `clients-data-collection/${clientId}/Location/${Location_ID}/Zones`);
            const q = query(collectionRef, orderBy("CreatedAt", "desc"), limit(1));
            const querySnapshot = await getDocs(q);
            let lastZone = '';
            querySnapshot.forEach((doc) => {
                // console.log(doc.id, " => ", doc.data());
                lastZone = doc.data();
            });
            //console.log(lastZone);
            if (!lastZone) {
                const docID = 'ZONE0001';
                const res = await setDoc(doc(db, `clients-data-collection/${clientId}/Location/${Location_ID}/Zones`, docID), {
                    Zone_ID: docID,
                    ZoneName: zoneName,
                    UseCase: usecase,
                    CreatedAt: Date.now(),
                    Client_ID:clientId,
                    Location_ID:Location_ID,

                });
                //console.log('zone id: ', res.id);
                setCloseModal(false);
                toast.success('Zone added successfully');

            }
            else {
                // console.log('res', newClientLocationIdGen(lastZone.Zone_ID));
                const docID = newZoneIdGen(lastZone?.Zone_ID);
                const res = await setDoc(doc(db, `clients-data-collection/${clientId}/Location/${Location_ID}/Zones`, docID), {
                    Zone_ID: docID,
                    ZoneName: zoneName,
                    UseCase: usecase,
                    CreatedAt: Date.now(),
                    Client_ID:clientId,
                    Location_ID:Location_ID,
                });
                //console.log('zone id: ', res.id);
                setCloseModal(false);
                toast.success('Zone added successfully');
            }


        } catch (err) {
            console.error('error', err);
        }
    }


    return (
        <div>
            <input type="checkbox" id="addZone-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative rounded-none">
                    <label
                        htmlFor="addZone-modal"
                        className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]">
                        ✕
                    </label>
                    <h3
                        className="text-3xl text-center font-bold mb-7">
                        Add Zone
                    </h3>

                    <form onSubmit={handleAddLocationZone}>

                        {/* Zone Name */}
                        <div className="form-control flex mt-3">
                            <input
                                type="text"
                                name='zoneName'
                                placeholder="Zone Name"
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* use case */}
                        <div className="form-control mt-3">
                            <select
                                name='usecase'
                                className="select w-full bg-white border-white border-b-[#D9D9D9] rounded-none" required >
                                {
                                    useCase?.length > 0 ? <>
                                        {
                                            useCase?.map((u, i) => <option
                                                value={u}
                                                key={i}
                                            >{u}</option>)
                                        }
                                    </>
                                        : <option disabled>No usecase available</option>
                                }

                            </select>
                        </div>

                        <div className="form-control mt-5">
                            <BlueBtn>Confirm</BlueBtn>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    );
};

export default AddLocationZone;