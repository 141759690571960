import { deleteDoc, doc } from "firebase/firestore";
import React from "react";
import { db } from "../../../../../../../firebase/firebase.config";
import { toast } from "react-hot-toast";
import BlueBtn from "../../../../../../../components/Buttons/BlueBtn";

const DeleteEntryModal = ({ alertProfile, setDeleteEntryModal, setWhitelistVehicleData, dataToDelete }) => {
	const deleteEntryFromDB = async (id) => {
		await deleteDoc(doc(db, `UnauthorizedEntryCollection/${alertProfile}/Whitelist`, id));

		setWhitelistVehicleData((prev) => [...prev.filter((vehicle) => vehicle.id !== id)]);
		toast.success("Entry Deleted");
		setDeleteEntryModal(false);
	};

	const handleUpdateEntry = (e) => {
		e.preventDefault();

		deleteEntryFromDB(dataToDelete.id)
			.then(console.log("Deleted"))
			.catch((err) => console.log("error", err));
	};

	return (
		<div>
			<input type="checkbox" id="deleteEntry-modal" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box relative rounded-none bg-white w-[30rem]">
					<label
						onClick={() => setDeleteEntryModal(false)}
						htmlFor="deleteEntry-modal"
						className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]"
					>
						✕
					</label>
					<h3 className="text-2xl text-center font-bold mb-7 text-black">Delete Vehicle</h3>

					<form className="ml-14" onSubmit={handleUpdateEntry}>
						<div className="form-control gap-3 text-black w-80">
							<div className="flex flex-row justify-between">
								<label htmlFor="vehicleType">Detection</label>
								<input
									id="vehicleType"
									name="vehicleType"
									value={dataToDelete.VehicleType}
									className="bg-gray-100 w-48 h-[1.5rem] p-2"
									type="text"
									readOnly
								/>
							</div>
							<div className="flex flex-row justify-between">
								<label htmlFor="vehicleNumber">Vehicle Number</label>
								<input
									id="vehicleNumber"
									name="vehicleNumber"
									value={dataToDelete.VehicleNumber}
									className="bg-gray-100 w-48 h-[1.5rem] p-2"
									type="text"
									readOnly
								/>
							</div>
							<div className="form-control mt-5">
								<BlueBtn>Confirm !</BlueBtn>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default DeleteEntryModal;
