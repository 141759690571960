import { collection, doc, onSnapshot, query, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import BlueBtn from '../../../components/Buttons/BlueBtn';
import { db } from '../../../firebase/firebase.config';
import 'react-phone-number-input/style.css';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthProvider/AuthProvider';
import { uniqueArr } from '../../../Utility/RemoveDuplicate';
import useGetUserType from '../../../hooks/useGetUserType';
import { DataContext } from '../../../context/DataProvider/DataProvider';
import MultiSelectDropdown from './MultiSelectDropdown';
import Select from "react-select";

const EditUserModal = ({
    aUser,
    setAUser,
    phoneValue,
    setPhoneValue,
    clientsData,
    clientNames,
    setIsLoading
}) => {
    const { Client, Name, Email, Phone, Role, Location, id } = aUser;
    const { user } = useContext(AuthContext);
    const { userClientAndLocation, dataLoading } = useContext(DataContext);
    let [email, setEmail] = useState(Email);//email format validation
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedClient, setSelectedClient] = useState('');
    const [locationData, setLocationData] = useState([]);
    const [locationNames, setLocationNames] = useState([]);
    // const [iLocationValues, setiLocationValues] = useState([]);
    const { userType, userTypeLoading } = useGetUserType(user?.uid);
    const currentUserRole = userType?.Role;
    const [locationsOptions, setLocationsOptions] = useState([]);
    const [selectedLocationsOptions, setSelectedLocationsOptions] = useState([]);

    // console.log(Name, Email, Phone, Role, Location, id);

    // initially select the client
    useEffect(() => {
        setSelectedClient(Client);
    }, [id, Client]);

    // initially select the existing client
    // useEffect(() => {
    //     if (iLocationValues?.length < 1) {
    //         const filteredLoc = locationData?.filter(item => Location?.includes(item?.Location_ID));
    //         const names = filteredLoc?.map(item => item?.Name);
    //         console.log(filteredLoc, names, Name, Location);
    //         console.log(locationData);
    //         setiLocationValues(names);
    //     }
    // }, [id, locationData, Location, iLocationValues]);



    //fetch those locations according to selected client
    useEffect(() => {
        // console.log('inside:', selectedClient);
        if (selectedClient) {
            const clientInfo = clientsData?.find(c => c?.Client.toLowerCase() === selectedClient.toLowerCase());
            const clientId = clientInfo?.ClientID;

            const unsub = onSnapshot(
                query(collection(db, `clients-data-collection/${clientId}/Location`)),
                (snapShot) => {
                    let list = [];
                    snapShot.docs.forEach((doc) => {
                        list.push({ id: doc.id, ...doc.data() });
                    });
                    // console.log('location list:', list);
                    setLocationData([...list]);
                    if (currentUserRole && !userTypeLoading && !dataLoading) {
                        if (currentUserRole === 'Local Admin' || currentUserRole === 'Regional Admin') {
                            const filteredList = list?.filter(item => userClientAndLocation?.userLocation.includes(item?.Location_ID));
                            const names = filteredList.map(item => item.Name);
                            setLocationNames(uniqueArr(names));
                        }
                    } else {
                        const locationNameList = list?.map((l) => l?.Name);
                        setLocationNames(uniqueArr(locationNameList));
                    }
                    setIsLoading(false);
                },
                (error) => {
                    console.log(error);
                }
            );

            return () => {
                unsub();
            };
        }
    }, [clientsData, userTypeLoading, dataLoading, selectedClient]);

    const handleUpdateUser = (e) => {
        e.preventDefault();
        const form = e.target;
        const name = form.name.value;
        const phone = form.phone.value;
        // const phone = phoneNo && formatPhoneNumberIntl(phoneNo);
        const role = form.role.value;
        //get location id
        let location;
        if (selectedLocationsOptions?.length > 0) {
            // console.log(selectedLocationsOptions);
            const locValues = selectedLocationsOptions.map(option => option.value);
            const filteredLoc = locationData?.filter(item => locValues?.includes(item?.Name));
            const ids = filteredLoc?.map(item => item?.Location_ID);
            location = [...ids];
        }
        if (selectedLocationsOptions?.length === 0) {
            location = Location;
        }
        // console.log(name, email, phone, role, location);

        // return if user enter an invalid email
        if (!validate(email)) {
            setErrorMessage('Please enter valid email');
            return;
        }
        if ((name.length < 1) || (phone.length <= 6) || (selectedLocationsOptions?.length < 1)) {
            return;
        }
        else {
            //console.log('valid values');
            UpdateUserData(name, email, phone, role, location);
            form.reset();
            setPhoneValue(null);
            setSelectedClient('');
            selectedLocationsOptions([]);
        }

        setErrorMessage('');
    }

    const UpdateUserData = async (name, email, phone, role, location) => {
        // Update document in collection "userDataCollection"
        try {
            const docRef = doc(db, "userDataCollection", id);
            const res = await updateDoc(docRef, {
                Name: name,
                // Email: email,
                Phone: phone,
                Role: role,
                Location: location
            });
            setAUser(null);
            toast.success('User data updated');
        } catch (err) {
            console.error(err);
        }
    }

    // Email pattern validation
    const validate = (email) => {
        if (email.trim().match(/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
            return false;
        } else if (email.trim() === '') {
            return false;
        }

        return true;
    }

    useEffect(() => {
        if (locationNames) {
            let allOptions = locationNames.map((location) => ({ value: location, label: location }));
            setLocationsOptions(allOptions);
            const filteredLoc = locationData?.filter(item => Location?.includes(item?.Location_ID));
            const names = filteredLoc?.map(item => item?.Name);
            let userLocations = names.map((location) => ({ value: location, label: location }));
            setSelectedLocationsOptions(userLocations);
        }

    }, [id, locationNames]);

    const handleCloseModal = () => {
        setSelectedClient('');
        setSelectedLocationsOptions([]);
    }

    // const handleClientSelect = (e) => {
    //     const dropDownClient = e.target?.value;
    //     setSelectedClient(dropDownClient);
    // };

    const handleLocationsSelect = (selectedOptions) => {
        setSelectedLocationsOptions(selectedOptions);
    };

    // custom styling for react-select
    const selectStyles = {
        container: (provided) => ({
            ...provided,
            minWidth: 300,
            maxWidth: 646,
        }),
    };

    return (
        <div className=''>
            <input type="checkbox" id="updateUser-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative rounded-none bg-white text-black">
                    <label
                        htmlFor="updateUser-modal"
                        onClick={handleCloseModal}
                        className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]"
                    >
                        ✕
                    </label>
                    <h3 className="text-3xl text-center font-bold mb-9">Update Userdata</h3>

                    <form onSubmit={handleUpdateUser}>

                        {
                            <div className="form-control flex mt-4">
                                <select
                                    name='client'
                                    defaultValue={Client}
                                    key={Client}
                                    // onChange={handleClientSelect}
                                    className="select bg-white border-white border-b-[#D9D9D9] rounded-none"
                                    required >
                                    <option disabled>{Client}</option>
                                </select>
                            </div>
                        }

                        {/* Name */}
                        <div className="form-control flex mt-4">
                            <input
                                type="text"
                                name='name'
                                placeholder="Name"
                                defaultValue={Name}
                                key={Name}
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>


                        {/* Email */}
                        <div className="form-control mt-4">
                            <input
                                type="text"
                                name='email'
                                placeholder="Email Id"
                                value={Email}
                                key={Email}
                                className='input bg-white border-white border-b-[#D9D9D9] rounded-none'
                                onChange={e => setEmail(e.target.value)}
                                readOnly />
                            {errorMessage && <span className='text-red-600 mt-1 pl-3'>{errorMessage}</span>}
                        </div>

                        {/* Phone */}
                        <div className="form-control flex mt-4">
                            <PhoneInput
                                name='phone'
                                // defaultValue={Phone}
                                // key={Phone}
                                // placeholder={Phone}
                                international
                                countryCallingCodeEditable={false}
                                className="input bg-white active:border-white active:border-b-[#D9D9D9] focus:border-b-[#D9D9D9] focus:border-white border-white border-b-[#D9D9D9] rounded-none"
                                value={phoneValue}
                                onChange={setPhoneValue}
                                required />
                        </div>

                        {/* User role */}
                        <div className="form-control mt-4">
                            <select
                                name='role'
                                defaultValue={Role}
                                key={Role}
                                className="select bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required >
                                <option>Central Admin</option>
                                <option>Central User</option>
                                <option>Central Ops User</option>
                                <option>Local Admin</option>
                                <option>Local User</option>
                                <option>Local Ops User</option>
                                <option>Regional Admin</option>
                                <option>Regional User</option>
                                <option>Security Guard</option>
                            </select>
                        </div>

                        {/* Location */}
                        <div className="form-control mt-4">
                            {/* <MultiSelectDropdown
                                optionList={locationNames}
                                ivalues={iLocationValues}
                                setivalues={setiLocationValues}
                                key={locationNames}
                            ></MultiSelectDropdown> */}

                            <Select
                                className="text-black"
                                options={locationsOptions}
                                placeholder={"Select Location"}
                                value={selectedLocationsOptions}
                                onChange={handleLocationsSelect}
                                styles={selectStyles}
                                isMulti
                            />

                        </div>

                        <div className="form-control mt-4">
                            <BlueBtn>Update</BlueBtn>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    );
};

export default EditUserModal;