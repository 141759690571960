import React from "react";

const TimeDisplayDesktop = ({ time }) => {
	const hours = time.hours.toString().padStart(2, "0");
	const minutes = time.minutes.toString().padStart(2, "0");
	const seconds = time.seconds.toString().padStart(2, "0");

	return (
		<div className="w-fit h-fit p-1 text-base">
			{hours} : {minutes} : {seconds}
		</div>
	);
};

export default TimeDisplayDesktop;
