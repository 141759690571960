//This function will convert "20230328-150602" this into "2023/03/09 9:18:23 pm" this
export function fixDeviceDate(deviceDate) {
    let formattedDeviceDate;
    if (deviceDate){
        const year = deviceDate.slice(0, 4);
        const month = deviceDate.slice(4, 6);
        const day = deviceDate.slice(6, 8);
        const hours = deviceDate.slice(9, 11);
        const minutes = deviceDate.slice(11, 13);
        const seconds = deviceDate.slice(13, 15);
        const ampm = (hours >= 12) ? 'pm' : 'am';
        formattedDeviceDate = `${year}/${month}/${day} ${hours % 12}:${minutes}:${seconds} ${ampm}`;
    }
   
    //console.log(formattedDeviceDate);
    return formattedDeviceDate;
}