import React, { useEffect, useState } from 'react';
import { collection, deleteDoc, doc, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../../../../firebase/firebase.config';
import { toast } from 'react-hot-toast';
import Loading from '../../../../components/Loading/Loading';
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';
import EditClientModal from './EditClientModal';
import AddClientModal from './AddClientModal';
import AlMonkLogo from '../../../../components/AlMonkLogo';
import ClientTableRow from './ClientTableRow';

const MasterClient = () => {
    const [closeModal, setCloseModal] = useState(false);
    const [clientData, setClientData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [aClient, setAClient] = useState(null);
    const [phoneValue, setPhoneValue] = useState(null);


    // fetch all clientData from firebase
    useEffect(() => {

        // LISTEN (REALTIME)
        const unsub = onSnapshot(
            query(collection(db, "clients-data-collection"), orderBy("CreatedAt", "desc")),
            (snapShot) => {
                let list = [];
                snapShot.docs.forEach((doc) => {
                    list.push({ id: doc.id, ...doc.data() });
                });
                //console.log(list);
                setClientData(list);
                setIsLoading(false);
            },
            (error) => {
                console.log(error);
            }
        );

        return () => {
            unsub();
        };

    }, []);
    //console.log("data:", clientData);

    //delete Client
    const handleClientDelete = async (client) => {
        //console.log(client);
        await deleteDoc(doc(db, "clients-data-collection", client?.id));
        setClientData(clientData.filter((item) => item.id !== client?.id));
        toast.success(`Client deleted successfully`);
    }

    //modal functions
    const closeDeleteModal = () => {
        setAClient(null);
    }

    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <div>

            {/* header div */}
            <div className='flex items-center bg-white justify-between py-2 sticky top-0 z-[2]'>
                <h1 className='font-bold ml-6 text-3xl text-start'>Client Management</h1>
                <div className='flex items-center justify-end'>
                    <input
                        type="text"
                        placeholder="Search"
                        className="input input-bordered rounded mr-3" />
                    <div className='ml-5'>
                        <AlMonkLogo />
                    </div>
                </div>
            </div>

            <div className='w-full pr-10 flex items-center justify-end'>

                <label htmlFor="addClient-modal" onClick={() => setCloseModal(true)} className='btn w-[150px] mt-4 bg-[#375075] text-white hover:bg-[#A6A6A6] border-0 rounded-none'>Add Client</label>
            </div>

            {/* Table */}
            <div className="overflow-x-auto mx-2 custom-table-border mt-5 max-h-[87vh] overflow-y-auto">
                <table className="w-full text-center">
                    <thead className='sticky top-0 border-b'>
                        <tr className='border-b'>
                            <th className='p-3 bg-white max-w-[80px]'>Sl No</th>
                            <th className='p-3 bg-white max-w-[100px]'>Client Id</th>
                            <th className='p-3 bg-white max-w-[200px]'>Client Name</th>
                            <th className='p-3 bg-white max-w-[200px]'>Address</th>
                            <th className='p-3 bg-white max-w-[200px]'>Email</th>
                            <th className='p-3 bg-white max-w-[200px]'>Phone</th>
                            <th className='p-3 bg-white max-w-[100px]'>Status</th>
                            <th className='p-3 bg-white max-w-[150px]'>Device Count</th>
                            <th className='p-3 bg-white max-w-[200px]'>Usecase</th>
                            <th className='p-3 bg-white max-w-[100px]'>Location</th>
                            <th className='p-3 bg-white max-w-[50px]'></th>
                            <th className='p-3 bg-white max-w-[50px]'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            clientData?.map((clientD, i) => <ClientTableRow
                                key={clientD?.id}
                                i={i}
                                clientD={clientD}
                                setAClient={setAClient}
                                // deviceCount={deviceCount}
                                setPhoneValue={setPhoneValue}
                            ></ClientTableRow>)
                        }

                    </tbody>
                </table>
            </div>

            {
                aClient && <ConfirmModal
                    title={`Delete this Client?`}
                    message={`If you delete ${aClient?.ClientID}. It cannot be restore`}
                    successButtonName="Delete"
                    successAction={handleClientDelete}
                    modalData={aClient}
                    closeDeleteModal={closeDeleteModal}
                >
                </ConfirmModal>
            }

            {
                aClient && <EditClientModal
                    aClient={aClient}
                    setAClient={setAClient}
                    phoneValue={phoneValue}
                    setPhoneValue={setPhoneValue}
                ></EditClientModal>
            }

            {
                closeModal &&
                <AddClientModal
                    setCloseModal={setCloseModal}
                ></AddClientModal>
            }


        </div>
    );
};

export default MasterClient;