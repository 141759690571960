import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import { AuthContext } from '../../context/AuthProvider/AuthProvider';
import useMasterAdmin from '../../hooks/useMasterAdmin';

const MasterAdminRoute = ({ children }) => {
    const { user, authLoading } = useContext(AuthContext);
    const [isMasterAdmin, masterAdminLoading] = useMasterAdmin(user?.email);
    const location = useLocation();

    if (authLoading || masterAdminLoading) {
        return <Loading></Loading>
    }

    //check user
    if (user && isMasterAdmin) {
        return children;
    }

    return <Navigate to="/login" state={{ from: location }} replace></Navigate>;
};

export default MasterAdminRoute;