import React, { useContext, useState } from 'react';
import { DataContext } from '../../../../context/DataProvider/DataProvider';
import Select from "react-select";
import { useEffect } from 'react';
import { db } from '../../../../firebase/firebase.config';
import { doc, onSnapshot, setDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';

const DetectionModal = ({ aLocation, setALocation, clientId, setIsLoading }) => {
    const { id } = aLocation;
    const { dataLoading, vehicleTypes } = useContext(DataContext);
    const [detections, setDetections] = useState([]);
    const [selectedDetectionOptions, setSelectedDetectionOptions] = useState([]);

    //set the detection options
    useEffect(() => {
        if (!dataLoading) {
            const vehicles = vehicleTypes?.Vehicles;
            setDetections([...vehicles?.map((ele) => ({ value: ele, label: ele }))]);
        }

    }, [dataLoading, vehicleTypes?.Vehicles]);

    //fetch Detections according to client & Location
    useEffect(() => {
        if (clientId && id) {

            const collectionRef = doc(db, `clients-data-collection/${clientId}/Location/${id}/Detections`, "detection-list");
            const unsub = onSnapshot(collectionRef, (detectionSnapshot) => {
                if (detectionSnapshot.exists()) {
                    const vehicles = detectionSnapshot.data()?.Vehicles;
                    // console.log(vehicles)
                    setSelectedDetectionOptions([...vehicles?.map((ele) => ({ value: ele, label: ele }))]);
                } else {
                    console.log('Detection not found.');
                }
                setIsLoading(false);
            }, (error) => {
                console.log('Error getting detection:', error);
            });

            return () => {
                unsub();
            };
        }
    }, [clientId, id]);

    const addDetectionToDB = async () => {
        const collectionRef = doc(db, `clients-data-collection/${clientId}/Location/${id}/Detections`, "detection-list");

        const selectedVehicles = selectedDetectionOptions.map((opt) => opt.value);
        await setDoc(collectionRef, {
            Vehicles: selectedVehicles,
            Client_ID:clientId,
            Location_ID:id
        });
        setALocation(null);
        toast.success("Selected Detections Added");
    };

    // custom styling for react-select
    const selectStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%',
        }),
    };

    const handleVehicleChange = (selectedOptions) => {
        // console.log(selectedOptions);
        setSelectedDetectionOptions([...selectedOptions]);
    };

    const handleAddDetections = () => {
        addDetectionToDB();
    };

    const handleCloseModal = () => {
        setALocation(null);
    }

    return (
        <>
            <input type="checkbox" id="addDetection-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative rounded-none">
                    <label
                        htmlFor="addDetection-modal"
                        onClick={handleCloseModal}
                        className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]">
                        ✕
                    </label>
                    <h3
                        className="text-3xl text-center font-bold mb-7">
                        Detections
                    </h3>

                    <div className="flex flex-col min-h-[300px] justify-between items-center">
                        <div className="w-full mt-6 mb-6 ">
                            {
                                detections?.length > 0 && <Select
                                    className="text-black bg-white border-white border-b-[#D9D9D9] rounded-none"
                                    options={detections}
                                    placeholder={"Select detection options"}
                                    value={selectedDetectionOptions}
                                    onChange={handleVehicleChange}
                                    styles={selectStyles}
                                    isMulti
                                />
                            }
                        </div>

                        <button
                            className="py-2 px-9 mx-auto block bg-[#375075] text-white hover:bg-[#A6A6A6] border-0 rounded-none"
                            onClick={handleAddDetections}
                        >
                            Add
                        </button>
                    </div>

                </div>
            </div>
        </>
    );
};

export default DetectionModal;