import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { DataContext } from "../../../../../../context/DataProvider/DataProvider";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../../../../firebase/firebase.config";
import AddEntryModal from "./Modals/AddEntryModal";
import EditEntryModal from "./Modals/EditEntryModal";
import DeleteEntryModal from "./Modals/DeleteEntryModal";
import Loading from "../../../../../../components/Loading/Loading";

const removeDashFromDateString = (date) => date.split("-").join("");

const formateDate = (d) => {
	let day = d.getDate().toString().padStart(2, "0");
	let month = (d.getMonth() + 1).toString().padStart(2, "0");
	let year = d.getFullYear();
	return `${year}-${month}-${day}`;
};

function isEmpty(obj) {
	for (const prop in obj) {
		if (Object.hasOwn(obj, prop)) return false;
	}

	return true;
}

// function that returns color style of the cell
function highlightCell(authorisedCount, actualCount) {
	if (authorisedCount && actualCount) {
		if (actualCount > authorisedCount) {
			return "!bg-red-500 p-3 max-w-[100]";
		} else if (actualCount === authorisedCount) {
			return "!bg-red-100 p-3 max-w-[100]";
		} else {
			return "!bg-yellow-500 p-3 max-w-[100]";
		}
	} else {
		return "bg-[#FAFAFA] !text-red-900 font-bold p-3 max-w-[100]";
	}
}

const AuthorizedCountMain = ({ selectedClient, selectedLocation }) => {
	const { devices } = useContext(DataContext);

	const [zones, setZones] = useState([]);
	const [selectedZone, setSelectedZone] = useState({ Zone: "", ZoneID: "", ClientID: "", Location_ID: "" });

	const [alertProfile, setAlertProfile] = useState("");
	const [alertProfileID, setAlertProfileID] = useState("");

	// const [isAlertProfileAdded, setIsAlertProfileAdded] = useState(false); // to set wether alert profile is sent to recipients profile in userDataCollection

	const [date, setDate] = useState(formateDate(new Date()));

	// const [actualCount, setActualCount] = useState({});
	const [actualCountEntryCount, setActualCountEntryCount] = useState({});
	const [actualCountExitCount, setActualCountExitCount] = useState({});

	const [authorizedEntryCount, setAuthorizedEntryCount] = useState({});
	const [authorizedExitCount, setAuthorizedExitCount] = useState({});

	const [isLoading, setIsLoading] = useState(true);

	// data to edit and delete
	const [dataToEdit, setDataToEdit] = useState(null);
	const [dataToDelete, setDataToDelete] = useState(null);

	//modal operations
	const [addEntryModal, setAddEntryModal] = useState(false);
	const [deleteEntryModal, setDeleteEntryModal] = useState(false);
	const [editEntryModal, setEditEntryModal] = useState(false);

	// setting unique zones for select menu and default  setting the first option
	useEffect(() => {
		let zoneDataArray = devices.filter((ele) => ele.Client === selectedClient && ele.Location === selectedLocation);
		let uniqueZoneDataArray = [...new Map(zoneDataArray.map((item) => [item["Zone"], item])).values()];
		setZones(uniqueZoneDataArray);
		setSelectedZone(uniqueZoneDataArray[0]);
	}, [selectedClient, selectedLocation, devices]);

	// setting devices of that zone
	useEffect(() => {
		const fetchAuthCountZoneData = async (filteredList) => {
			setIsLoading(true);

			const alertProfileStringID = `${selectedZone.ClientID}-${selectedZone.Location_ID}-ACE-${selectedZone.ZoneID}-${removeDashFromDateString(
				date
			)}`;
			setAlertProfileID(alertProfileStringID);
			const alertProfile = `${selectedZone.ClientID}-${selectedZone.Location_ID}-ACE`;
			setAlertProfile(alertProfile);

			// Entry
			const entryDocRef = doc(db, `AuthorizedCountCollection/${alertProfileStringID}/Threshold`, "EntryCount");
			const entryDocSnap = await getDoc(entryDocRef);

			let entryData = { ...entryDocSnap.data() };
			let entryCount = {};
			for (const [key, value] of Object.entries(entryData)) {
				if (key !== "DocID") entryCount[key] = value;
			}

			// Exit
			const exitDocRef = doc(db, `AuthorizedCountCollection/${alertProfileStringID}/Threshold`, "ExitCount");
			const exitDocSnap = await getDoc(exitDocRef);

			let exitData = { ...exitDocSnap.data() };
			let exitCount = {};

			for (const [key, value] of Object.entries(exitData)) {
				if (key !== "DocID") exitCount[key] = value;
			}

			setAuthorizedEntryCount(entryCount);
			setAuthorizedExitCount(exitCount);

			// actual

			const selectedClientID = selectedZone.ClientID;
			const actualCountDocRef = doc(db, "actualCountCollection", `${selectedClientID}-${removeDashFromDateString(date)}`);
			const actualCountDocSnap = await getDoc(actualCountDocRef);
			let actualCountData = { ...actualCountDocSnap.data() };

			let actualEntryCount = {};
			let actualExitCount = {};

			//Entry
			if (actualCountData?.[`${selectedZone.Location_ID}_${selectedZone.ZoneID}_Entry`]) {
				for (const [vehicle, value] of Object.entries(actualCountData[`${selectedZone.Location_ID}_${selectedZone.ZoneID}_Entry`])) {
					actualEntryCount[vehicle] = value;
				}
			}

			//Exit
			if (actualCountData?.[`${selectedZone.Location_ID}_${selectedZone.ZoneID}_Exit`]) {
				for (const [vehicle, value] of Object.entries(actualCountData[`${selectedZone.Location_ID}_${selectedZone.ZoneID}_Exit`])) {
					actualExitCount[vehicle] = value;
				}
			}

			setActualCountEntryCount(actualEntryCount);
			setActualCountExitCount(actualExitCount);

			setIsLoading(false);
		};

		if (selectedZone?.Zone && date) {
			const filteredList = [
				...devices.filter((ele) => ele.Client === selectedClient && ele.Location === selectedLocation && ele.Zone === selectedZone.Zone),
			];

			fetchAuthCountZoneData(filteredList);
		}
	}, [selectedClient, selectedLocation, selectedZone, date]);

	if (isLoading) return <Loading />;

	if (!isLoading && !zones.length)
		return (
			<div className="flex flex-row justify-around">
				<div className="alert alert-error w-1/3">
					<svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
						/>
					</svg>
					<span>Authorized Count Alert Configuration is not available for this Location</span>
				</div>
			</div>
		);

	return (
		<div className="mx-6">
			{/* Title */}
			<h1 className="text-sky-900 text-[32px] font-semibold">Authorised Count</h1>

			<div className="flex flex-row justify-between my-5 mr-16">
				<div className="flex flex-row gap-6 items-center">
					<h1 className="text-black text-2xl font-normal">Zone</h1>
					<select
						className="bg-[#F2F5F7] w-[350px] h-[55px] border border-[#2E3192] rounded-[8px] px-3 py-4 text-black text-base font-normal"
						value={selectedZone?.Zone}
						onChange={(e) => setSelectedZone(e.target.value)}
					>
						<option defaultValue disabled>
							Select Zone
						</option>
						{zones.map((zone) => (
							<option key={zone.ZoneID} value={zone.Zone}>
								{zone.Zone}
							</option>
						))}
					</select>
					<label className="text-black text-2xl font-normal" htmlFor="datepicker">
						Date
					</label>
					<input
						className="bg-[#F2F5F7] w-[350px] h-[55px] border border-[#2E3192] rounded-[8px] px-3 py-4 text-black text-base font-normal"
						id="datepicker"
						type="date"
						placeholder="Pick Date"
						value={date}
						onChange={(e) => setDate(e.target.value)}
						// max={formateDate(new Date())}
					/>
				</div>
				<label
					htmlFor="addEntry-modal"
					onClick={() => setAddEntryModal(true)}
					className="btn bg-[#2E3192] font-bold text-base text-white !md:text-sm"
				>
					Add Entry
				</label>
			</div>

			{/* <div className="w-full pr-10 flex items-center justify-end">
				
			</div> */}

			{!isEmpty(authorizedEntryCount) ? (
				<div className="overflow-x-auto mx-2 mt-5 mb-8 max-h-[70vh] overflow-y-auto flex flex-row justify-around">
					<table className="w-full text-center text-base font-medium border-separate border-spacing-x-0 border-spacing-y-2">
						<thead className="sticky top-0">
							<tr className="text-black">
								<th className="p-3  max-w-[200] lg:text-base text-sm">Detections</th>
								<th className="p-3  max-w-[200] lg:text-base text-sm">Authorized Entry</th>
								<th className="p-3  max-w-[200] lg:text-base text-sm">Actual Entry</th>
								<th className="p-3  max-w-[200] lg:text-base text-sm">Authorized Exit</th>
								<th className="p-3  max-w-[200] lg:text-base text-sm">Actual Exit</th>
								<th className="p-3 max-w-[50]"></th>
								<th className="p-3 max-w-[50]"></th>
							</tr>
						</thead>
						<tbody>
							{Object.entries(authorizedEntryCount).map((ele) => (
								<tr key={ele[0]} className="border-b text-black">
									<td className="p-3 max-w-[80]  text-black border-black border-l border-t border-b rounded-tl-[20px] rounded-bl-[20px] bg-[#FAFAFA]">
										{ele[0]}
									</td>
									<td className="p-3 max-w-[80] border-black border-t border-b bg-[#FAFAFA]">{ele[1]}</td>
									<td className={`${highlightCell(ele[1], actualCountEntryCount[ele[0]])} border-black border-t border-b bg-[#FAFAFA]`}>
										{actualCountEntryCount[ele[0]] ? actualCountEntryCount[ele[0]] : "Nil"}
									</td>
									<td className="p-3 max-w-[80] border-black border-t border-b bg-[#FAFAFA]">{authorizedExitCount[ele[0]]}</td>
									<td className={`${highlightCell(authorizedExitCount[ele[0]], actualCountExitCount[ele[0]])}border-black border-t border-b`}>
										{actualCountExitCount[ele[0]] ? actualCountExitCount[ele[0]] : "Nil"}
									</td>
									<td className="p-3 max-w-[80]  border-black border-t border-b">
										<label
											onClick={() => {
												setDataToEdit(ele);
												setEditEntryModal(true);
											}}
											htmlFor="updateEntry-modal"
										>
											<div className="flex flex-row justify-around text-[#0D6EFD]">EDIT</div>
										</label>
									</td>
									<td className="p-3 max-w-[80] border-black border-r border-t border-b rounded-tr-[20px] rounded-br-[20px]">
										<label
											onClick={() => {
												setDataToDelete(ele);
												setDeleteEntryModal(true);
											}}
											htmlFor="deleteEntry-modal"
										>
											<div className="flex flex-row justify-around text-[#DC3545]">DELETE</div>
										</label>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : (
				<div className="flex justify-center items-center mx-auto">
					<h2 className="text-xl mt-5 font-semibold">No data added yet</h2>
				</div>
			)}

			{addEntryModal && (
				<AddEntryModal
					alertProfile={alertProfile}
					alertProfileID={alertProfileID}
					authorizedEntryCount={authorizedEntryCount}
					selectedClient={selectedClient}
					selectedLocation={selectedLocation}
					selectedZone={selectedZone}
					date={date}
					setAuthorizedEntryCount={setAuthorizedEntryCount}
					setAuthorizedExitCount={setAuthorizedExitCount}
					setAddEntryModal={setAddEntryModal}
				/>
			)}

			{editEntryModal && (
				<EditEntryModal
					alertProfileID={alertProfileID}
					dataToEdit={dataToEdit}
					setAuthorizedEntryCount={setAuthorizedEntryCount}
					authorizedExitCount={authorizedExitCount}
					setAuthorizedExitCount={setAuthorizedExitCount}
					setEditEntryModal={setEditEntryModal}
				/>
			)}

			{deleteEntryModal && (
				<DeleteEntryModal
					alertProfileID={alertProfileID}
					dataToDelete={dataToDelete}
					setAuthorizedEntryCount={setAuthorizedEntryCount}
					authorizedExitCount={authorizedExitCount}
					setAuthorizedExitCount={setAuthorizedExitCount}
					setDeleteEntryModal={setDeleteEntryModal}
				/>
			)}
		</div>
	);
};

export default AuthorizedCountMain;
