import React, { useEffect, useState } from 'react';
import { collection, doc, getDocs, limit, orderBy, query, setDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import BlueBtn from '../../../../components/Buttons/BlueBtn';
import { db } from '../../../../firebase/firebase.config';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input'
import { newClientIdGen } from '../../../../Utility/IdGenerator';
import { useContext } from 'react';
import { DataContext } from '../../../../context/DataProvider/DataProvider';
import Select from "react-select";

const AddClientModal = ({ setCloseModal }) => {
    const { useCases } = useContext(DataContext);
    const [phoneValue, setPhoneValue] = useState(null);//phone number value
    let [email, setEmail] = useState('');//email format validation
    const [errorMessage, setErrorMessage] = useState('');
    const [usecaseOptions, setUsecaseOptions] = useState([]);
    const [selectedUsecaseOptions, setSelectedUsecaseOptions] = useState([]);

    // console.log(useCases);

    const handleAddClient = e => {
        e.preventDefault();
        const form = e.target;
        const client = form.client.value;
        const address = form.address.value;
        const pinCode = form.pinCode.value;
        const status = form.status.value;
        // const usecase = form.usecase.value;

        // console.log(client, address, pinCode, email, phoneValue, status, selectedUsecaseOptions);

        let usecase;
        if (selectedUsecaseOptions?.length > 0) {
            // console.log(selectedLocationsOptions);
            const locValues = selectedUsecaseOptions.map(option => option.value);
            usecase = [...locValues];
        }

        // console.log(client, address, pinCode, email, phoneValue, status, usecase);

        // return if user enter an invalid email
        if (!validate(email)) {
            setErrorMessage('Please enter valid email');
            return;
        }
        if ((client.length < 1) || (address.length < 1) || (pinCode.length < 1) || (phoneValue.length <= 6)) {
            return;
        }
        else {
            addClientDataToDB(client, address, pinCode, email, phoneValue, status, usecase);
            form.reset();
            setSelectedUsecaseOptions([]);
            setPhoneValue(null);
        }

        setErrorMessage('');
    }

    const addClientDataToDB = async (client, address, pinCode, email, phoneValue, status, usecase) => {
        // Add a new document in collection "clients-data-collection"
        try {
            const collectionRef = collection(db, 'clients-data-collection');
            const q = query(collectionRef, orderBy("CreatedAt", "desc"), limit(1));
            const querySnapshot = await getDocs(q);
            let lastClient = '';
            querySnapshot.forEach((doc) => {
                // console.log(doc.id, " => ", doc.data());
                lastClient = doc.data();
            });
            //console.log(lastClient);
            if (!lastClient) {
                const docID = 'CD0001';
                const res = await setDoc(doc(db, "clients-data-collection", docID), {
                    ClientID: docID,
                    Client: client,
                    Address: address,
                    PinCode: pinCode,
                    Email: email,
                    Phone: phoneValue,
                    Status: status,
                    UseCase: usecase,
                    CreatedAt: Date.now()
                });
                //console.log('Client-id: ', res.id);
                setCloseModal(false);
                toast.success('Client added successfully');

            }
            else {

                // console.log('res', newClientIdGen(lastClient.ClientID));
                const docID = newClientIdGen(lastClient?.ClientID);

                const res = await setDoc(doc(db, "clients-data-collection", docID), {
                    ClientID: docID,
                    Client: client,
                    Address: address,
                    PinCode: pinCode,
                    Email: email,
                    Phone: phoneValue,
                    Status: status,
                    UseCase: usecase,
                    CreatedAt: Date.now()
                });
                //console.log('Client-id: ', res.id);
                setCloseModal(false);
                toast.success('Client added successfully');
            }


        } catch (err) {
            console.error('error', err);
        }
    }

    // Email pattern validation
    const validate = (email) => {
        if (email.trim().match(/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
            return false;
        } else if (email.trim() === '') {
            return false;
        }

        return true;
    }

    useEffect(() => {
        if (useCases?.useCaseName) {
            let allOptions = useCases?.useCaseName?.map((location) => ({ value: location, label: location }));
            setUsecaseOptions(allOptions);
        }
    }, [useCases?.useCaseName]);

    const handleLocationsSelect = (selectedOptions) => {
        setSelectedUsecaseOptions(selectedOptions);
    };

    const handleCloseModal = () => {
        setSelectedUsecaseOptions([]);
    }

    // custom styling for react-select
    const selectStyles = {
        container: (provided) => ({
            ...provided,
            minWidth: 300,
            maxWidth: 646,
        }),
    };

    return (
        <div>
            <input type="checkbox" id="addClient-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative rounded-none">
                    <label
                        htmlFor="addClient-modal"
                        onClick={handleCloseModal}
                        className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]">
                        ✕
                    </label>
                    <h3
                        className="text-3xl text-center font-bold mb-7">
                        Add Client
                    </h3>

                    <form onSubmit={handleAddClient}>

                        {/* Client */}
                        <div className="form-control flex mt-3">
                            <input
                                type="text"
                                name='client'
                                placeholder="Client"
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* Address */}
                        <div className="form-control flex mt-3">
                            <input
                                type="text"
                                name='address'
                                placeholder="Address"
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* Pin Code */}
                        <div className="form-control mt-3">
                            <input
                                type="text"
                                name='pinCode'
                                placeholder="Pin Code"
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* Email */}
                        <div className="form-control flex mt-3">
                            <input
                                type="text"
                                name='email'
                                placeholder="Email"
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                onChange={e => setEmail(e.target.value)}
                                required />
                            {errorMessage && <span className='text-red-600 mt-1 pl-3'>{errorMessage}</span>}
                        </div>

                        {/* Phone */}
                        <div className="form-control flex mt-3">
                            <PhoneInput
                                name='phone'
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="IN"
                                placeholder="Enter phone number"
                                className="input bg-white active:border-white active:border-b-[#D9D9D9] focus:border-b-[#D9D9D9] focus:border-white border-white border-b-[#D9D9D9] rounded-none"
                                value={phoneValue}
                                onChange={setPhoneValue}
                                required />
                        </div>

                        {/* Status */}
                        <div className="form-control mt-3">
                            <select
                                name='status'
                                className="select w-full bg-white border-white border-b-[#D9D9D9] rounded-none" required >
                                <option>Active</option>
                                <option>Inactive</option>
                            </select>
                        </div>

                        {/* use case */}
                        <div className="form-control mt-3">
                            {/* <select
                                name='usecase'
                                className="select w-full bg-white border-white border-b-[#D9D9D9] rounded-none" required >
                                {
                                    useCases?.useCaseName?.length > 0 ? <>
                                        {
                                            useCases?.useCaseName?.map((useCase, i) => <option
                                                value={useCase}
                                                key={i}
                                            >{useCase}</option>)
                                        }
                                    </>
                                        : <option disabled>No usecase available</option>
                                }

                            </select> */}

                            <Select
                                className="text-black"
                                options={usecaseOptions}
                                placeholder={"Select Usecase"}
                                value={selectedUsecaseOptions}
                                onChange={handleLocationsSelect}
                                styles={selectStyles}
                                isMulti
                            />

                        </div>

                        <div className="form-control mt-3">
                            <BlueBtn>Confirm</BlueBtn>
                        </div>
                    </form>


                </div>
            </div >
        </div >
    );
};

export default AddClientModal;