import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { db } from '../../firebase/firebase.config';
import { AuthContext } from '../../context/AuthProvider/AuthProvider';
import { getCurrentTime } from '../../Utility/FixTodaysDate';


const EventResolvedModal = ({ setCloseModal, selectedVehicle, gate }) => {
    const { Location, id, Date, Incident, Direction, Class_label, } = selectedVehicle || {};
    const { user } = useContext(AuthContext);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isAction, setIsAction] = useState(false);
    const clientId = selectedVehicle?.["Client-ID"];
    const locationId = selectedVehicle?.["Location-ID"];
    const zoneId = selectedVehicle?.["Zone-ID"];

    // console.log(id);

    const userID = user?.uid;
    const currentTime = getCurrentTime();
    // console.log(currentTime);

    async function handlePriorityEvent(e) {
        e.preventDefault();
        const form = e.target;
        const eventComment = form.eventComment.value;
        if (eventComment?.length > 0 && isAuthorized) {
            const eventStatus = 'Approved';

            updateResolveData(id, userID, eventComment, eventStatus);
            updateEventData(id, eventStatus);

            //update the authorized count value
            updateAuthorisedEntryExitCount(Date, clientId, locationId, zoneId, Class_label, Direction, Incident);
            setIsAuthorized(false);

        } else if (eventComment?.length > 0 && isAction) {
            const eventStatus = 'Closed';
            // console.log("taking action:", datePart, timePart, id, eventStatus, eventComment, userID, Location, gate, Class_label);
            updateResolveData(id, userID, eventComment, eventStatus);
            updateEventData(id, eventStatus);
            setIsAction(false);

        } else {
            toast.error("Please write a comment");
            return;
        }
        form.reset();
    }

    async function updateResolveData(eventID, userID, promptInput, eventStatus) {
        const userName = user?.displayName;
        const userEmail = user?.email;
        if (eventID && userID && eventStatus && userName && userEmail) {
            try {
                const resolveData = {
                    userID,
                    userName: userName,
                    userEmail: userEmail,
                    promptInput,
                    eventStatus,
                    resolvedTime: currentTime,
                }
                const docRef = doc(db, "ResolvedIssuesCollection", eventID);
                const res = await updateDoc(docRef, {
                    resolvedIssues: arrayUnion(resolveData)
                });
                toast.success('data updated');
                setCloseModal(false);
            } catch (err) {
                console.error(err);
            }
        }
    }

    async function updateEventData(eventID, eventStatus) {
        try {
            
            const docRef = doc(db, `${clientId}-priority-${Date}`, eventID);
            const res = await updateDoc(docRef, {
                Status: eventStatus,
            });
            // toast.success('event data updated');
            setCloseModal(false);
        } catch (err) {
            console.error(err);
        }
    }

    //update authorised entry exit count if the incident is "Authorized count exceed"
    async function updateAuthorisedEntryExitCount(date, clientID, locationID, zoneID, detection, direction, incident) {
        const updateValue = {
            date,
            clientID,
            locationID,
            zoneID,
            detection,
            direction,
            incident
        }
        // console.log(updateValue);
        try {
            fetch('http://gk.secquraise.com/api/updateAuthorisedCounts', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(updateValue)
            })
                .then(res => res.json())
                .then(data => {
                    console.log('response:', data);
                    // toast.success('Data successfully updated');
                })
        }
        catch (err) {
            console.error(err);
            toast.error('Failed to update');
        };
    }

    return (
        <>
            <input type="checkbox" id="eventResolved-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative rounded-none bg-white">
                    <label
                        htmlFor="eventResolved-modal"
                        className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]">
                        ✕
                    </label>
                    <h3
                        className="text-3xl text-black text-center font-bold mb-7">
                        Resolve Incident
                    </h3>
                    <p className='text-black text-lg'>Location: {Location}</p>
                    <p className='text-black text-lg'>Gate: {gate}</p>
                    <p className='text-black text-lg'>Incident: {Incident}</p>
                    <p className='text-black text-lg'>Detection: {Class_label}</p>
                    <form onSubmit={handlePriorityEvent}>
                        <textarea
                            className='mx-auto w-full bg-white px-2.5 py-4 border-2 border-gray-300 rounded mt-5 block'
                            name="eventComment" required></textarea>
                        <div className='flex justify-center items-center px-5'>
                            <input type="submit" onClick={() => setIsAuthorized(true)} className='btn w-[170px] bg-green-500 text-white hover:bg-[#A6A6A6] border-0 rounded-none mt-5' value="Authorize" />
                            <input type="submit" onClick={() => setIsAction(true)} className='btn w-[170px] bg-indigo-600 text-white hover:bg-[#A6A6A6] border-0 rounded-none mt-5 ml-5' value="Close" />
                        </div>

                    </form>


                </div>
            </div>
        </>
    );
};

export default EventResolvedModal;