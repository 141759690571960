import React, { useEffect, useState } from 'react';
import AlMonkLogo from '../../../../components/AlMonkLogo';
import Loading from '../../../../components/Loading/Loading';
import { Link, useLocation, useNavigate, } from 'react-router-dom';
import { collection, deleteDoc, doc, onSnapshot, query } from 'firebase/firestore';
import { db } from '../../../../firebase/firebase.config';
import ClientLocationTableRow from './ClientLocationTableRow';
import { toast } from 'react-hot-toast';
import { IoIosArrowDropleftCircle } from "react-icons/io";
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';
import AddClientLocation from './AddClientLocation';
import EditClientLocation from './EditClientLocation';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import useFirestoreDocument from '../../../../hooks/useFirestoreDocument';
import DetectionModal from './DetectionModal';

const ClientLocation = () => {
    const location = useLocation();
    const clientId = location.state?.clientId;
    const [closeModal, setCloseModal] = useState(false);
    const [aLocation, setALocation] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [clientLocations, setClientLocations] = useState([]);
    const [useCase, setUseCase] = useState([]);
    const [clientDoc, clientLoading] = useFirestoreDocument('clients-data-collection', clientId);
    const [breadcrumbsValues, setBreadcrumbsValues] = useState({
        clientId: '',
        values: false,
    });

    // console.log('client info:', clientId);

    //set the client value for breadcrumbs
    useEffect(() => {
        setBreadcrumbsValues({
            clientId: clientId,
            values: true,
        });
    }, [clientId]);

    // fetch all locationData from firebase
    useEffect(() => {

        if (clientId) {
            const unsub = onSnapshot(
                // query(collection(db, `clients-data-collection/${clientID}/Location`), orderBy('CreatedAt', 'desc'))

                query(collection(db, `clients-data-collection/${clientId}/Location`)),
                (snapShot) => {
                    let list = [];
                    snapShot.docs.forEach((doc) => {
                        list.push({ id: doc.id, ...doc.data() });
                    });
                    // console.log('list',list);
                    setClientLocations(list);
                    setIsLoading(false);
                },
                (error) => {
                    console.log(error);
                }
            );

            return () => {
                unsub();
            };
        }

    }, [clientId]);

    // set client Usecase 
    useEffect(() => {
        if (clientDoc && !clientLoading) {
            setUseCase([clientDoc?.UseCase]);
        }
    }, [clientId, clientDoc, clientLoading]);

    //delete location
    const handleClientLocationDelete = async (location) => {
        //console.log(location);
        await deleteDoc(doc(db, `clients-data-collection/${clientId}/Location`, location?.id));
        setClientLocations(clientLocations.filter((item) => item.id !== location?.id));
        toast.success(`Location deleted successfully`);
    }

    //modal functions
    const closeDeleteModal = () => {
        setALocation(null);
    }

    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <>

            {/* header div */}
            <div className='flex items-center bg-white justify-between py-2 sticky top-0 z-[2]'>
                <div className='ml-6 mt-1'>
                    {/* breadcrumbs */}
                    <div className='pl-1 text-sm text-black'>
                        {
                            breadcrumbsValues?.values && <div className='flex items-center'>
                                <p className='flex items-center font-semibold'>Client <MdOutlineKeyboardArrowRight className='pt-1 text-[19px]' /><span className='font-normal'>{breadcrumbsValues?.clientId}</span></p>
                            </div>
                        }
                    </div>
                    <div className="flex items-center mt-3">
                        <Link to={`/dashboard/masteradminclient`}>
                            <IoIosArrowDropleftCircle className='text-3xl mr-2 text-[#375075]'></IoIosArrowDropleftCircle>
                        </Link>
                        <h1 className='font-bold text-3xl text-start'>Location Management</h1>
                    </div>
                </div>

                <div className='flex items-center justify-end'>

                    <div className='ml-5'>
                        <AlMonkLogo />
                    </div>
                </div>
            </div>

            <div className='w-full pr-10 flex items-center justify-end'>

                <label htmlFor="addClientLocation-modal" onClick={() => setCloseModal(true)} className='btn w-[150px] mt-4 bg-[#375075] text-white hover:bg-[#A6A6A6] border-0 rounded-none'>Add Location</label>
            </div>

            {/* Table */}
            {
                clientLocations?.length > 0 ? <div className="overflow-x-auto mx-2 custom-table-border mt-5 max-h-[87vh] overflow-y-auto">
                    <table className="w-full text-center">
                        <thead className='sticky top-0 border-b'>
                            <tr className='border-b'>
                                <th className='p-3 bg-white max-w-[80px]'>Sl No</th>
                                <th className='p-3 bg-white max-w-[100px]'>Location Id</th>
                                <th className='p-3 bg-white max-w-[200px]'>Location Name</th>
                                <th className='p-3 bg-white max-w-[200px]'>Address</th>
                                <th className='p-3 bg-white max-w-[200px]'>Pincode</th>
                                <th className='p-3 bg-white max-w-[200px]'>Usecase</th>
                                <th className='p-3 bg-white max-w-[200px]'>Detection</th>
                                <th className='p-3 bg-white max-w-[200px]'>Zones</th>
                                <th className='p-3 bg-white max-w-[50px]'></th>
                                <th className='p-3 bg-white max-w-[50px]'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                clientLocations?.map((locationD, i) => <ClientLocationTableRow
                                    key={locationD?.id}
                                    i={i}
                                    locationD={locationD}
                                    setALocation={setALocation}
                                    clientId={clientId}                                   
                                ></ClientLocationTableRow>)
                            }

                        </tbody>
                    </table>
                </div>
                    : <div className="flex justify-center items-center mx-auto text-black">
                        <h2 className="text-xl mt-5 font-semibold">No Location added yet</h2>
                    </div>
            }


            {
                aLocation && <ConfirmModal
                    title={`Delete this Location?`}
                    message={`If you delete ${aLocation?.Name}. It cannot be restore`}
                    successButtonName="Delete"
                    successAction={handleClientLocationDelete}
                    modalData={aLocation}
                    closeDeleteModal={closeDeleteModal}
                >
                </ConfirmModal>
            }

            {
                aLocation && <EditClientLocation
                    aLocation={aLocation}
                    setALocation={setALocation}
                    clientId={clientId}
                    useCase={useCase}
                ></EditClientLocation>
            }

            {
                closeModal &&
                <AddClientLocation
                    setCloseModal={setCloseModal}
                    clientId={clientId}
                    useCase={useCase}
                ></AddClientLocation>
            }

            {
                aLocation && <DetectionModal
                    aLocation={aLocation}
                    setALocation={setALocation}
                    clientId={clientId}
                    setIsLoading={setIsLoading}
                ></DetectionModal>
            }
        </>
    );
};

export default ClientLocation;