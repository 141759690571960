import React from 'react';
import { fixDateFormate } from '../../Utility/fixDateFormat';

const EntryDetailsDateAndTime = ({ EntryTimestamp }) => {
    const [formatedDate, formatedTime] = fixDateFormate(EntryTimestamp);
    return (
        <>
            <p>
                Date: <span className="ml-2">{formatedDate ? formatedDate : "null"}</span>
            </p>
            <p>
                Time: <span className="ml-2">{formatedTime ? formatedTime : "null"}</span>
            </p>
        </>
    );
};

export default EntryDetailsDateAndTime;