import FileUpload from "./FileUpload";

const AddEntryImageModal = ({ setPhotoUploadModal, setCameraModal, setImageURLForPreview, setImagePreviewModal }) => {
	const handleCloseModal = () => {
		setPhotoUploadModal(false);
	};

	return (
		<>
			<input type="checkbox" id="addImage-modal" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box relative bg-white text-black w-[400px]">
					<label htmlFor="addImage-modal" onClick={handleCloseModal} className="btn btn-sm btn-outline btn-error btn-circle absolute right-2 top-2">
						✕
					</label>
					<h3 className="text-3xl text-center font-medium mb-9">Upload Image</h3>
					<FileUpload setCameraModal={setCameraModal} setImageURLForPreview={setImageURLForPreview} setImagePreviewModal={setImagePreviewModal} />
				</div>
			</div>
		</>
	);
};

export default AddEntryImageModal;
