import React from "react";

const DeleteModal = ({
	setDeleteModalStatus,
	registeredOrganizationEntryIndexClicked,
	registeredOrganizationList,
	setRegisteredOrganizationList,
}) => {
	const handleDelete = () => {
		const list = [...registeredOrganizationList];
		const updatedRegisteredOrganizationList = list.filter((ele) => ele !== registeredOrganizationList[registeredOrganizationEntryIndexClicked]);
		setRegisteredOrganizationList(updatedRegisteredOrganizationList);
	};
	return (
		<>
			<input type="checkbox" id="delete_registeredOrganization_modal" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box h-fit !rounded-none overflow-visible bg-white">
					<h3 className="font-bold text-lg text-black">Delete Entry ?</h3>
					<p className="text-black">Are you sure you want to delete this Organization ?</p>
					<textarea className="mt-2 w-full" defaultValue={registeredOrganizationList[registeredOrganizationEntryIndexClicked]} readOnly />
					<div className="modal-action">
						<label
							className="btn btn-success !rounded-none"
							htmlFor="delete_modal"
							onClick={() => {
								handleDelete();
								setDeleteModalStatus(false);
							}}
						>
							Confirm
						</label>
						<label
							htmlFor="delete_registeredOrganization_modal"
							className="btn btn-active btn-neutral !rounded-none"
							onClick={() => setDeleteModalStatus(false)}
						>
							Close
						</label>
					</div>
				</div>
			</div>
		</>
	);
};

export default DeleteModal;
