import React, { useEffect } from 'react';
import Loading from '../../../../../components/Loading/Loading';
import { uniqueArr } from '../../../../../Utility/RemoveDuplicate';
import Clients from './Clients';
import Locations from './Locations';


const LeftTable = ({ data, clients, isLoading, clientSelect, setClientSelect, locations, setLocations, locationSelect, setLocationSelect, devices, setDevices, isActive }) => {
    //console.log(clients);


    //Initially select the the 1st client
    useEffect(() => {
        if (clients?.length > 0) {
            setClientSelect(clients[0]);
        }
        if (!clients?.length) {
            setClientSelect(null);
        }
    }, [clients, setClientSelect]);

    //Initially select the the 1st location
    useEffect(() => {

        if (locations?.length > 0) {
            setLocationSelect(locations[0]);
        }
        if (!locations?.length) {
            setLocationSelect(null);
        }
    }, [locations, setLocationSelect]);


    // fetch locations according to the selected client
    useEffect(() => {
        if (data.length) {
            const list = data
                .filter((d) => d?.["Client-ID"] === clientSelect)
                .map((d) => d.Location);
            //console.log(uniqueArr(list));
            setLocations(uniqueArr(list));
        }
    }, [clientSelect]);


    //fetch devices according to the selected client
    useEffect(() => {
        if (data.length) {
            const list = data
                .filter((d) => (d?.["Client-ID"] === clientSelect))
                .map((d) => d?.['Device-ID']);
            //console.log(uniqueArr(list));
            setDevices(uniqueArr(list));
        }
    }, [clientSelect]);


    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <div className="w-full overflow-x-auto mx-2 custom-grid custom-table-border mt-5 max-h-[87vh] overflow-y-auto">

            {/* Clients */}
            <div className=''>
                <div className="w-full p-3 border-b">
                    <p className="font-semibold text-[16px] text-center">Clients</p>
                </div>
                <div>
                    <ul className="w-full flex flex-col items-start overflow-y-auto">

                        {clients?.map((client, i) => <Clients key={i}
                            client={client}
                            clientSelect={clientSelect}
                            setClientSelect={setClientSelect}
                            isActive={isActive}
                        ></Clients>
                        )}

                    </ul>
                </div>
            </div>

        </div>
    );
};

export default LeftTable;