import { useState } from "react";
import { fixDateFormate } from "../Utility/fixDateFormat";
import { useEffect } from "react";

const TagEventModalDisplayCard = ({ event, selectedEventsID, setSelectedEventsID }) => {
	const { id, Class_label, Direction, Timestamp, Date } = event || {};
	const [urlExist, setUrlExist] = useState(false);
	const checked = selectedEventsID.includes(id);

	const vdoURL = event?.["Video-URL"];
	const [formatedDate, formatedTime] = fixDateFormate(Timestamp);
	const fileName = vdoURL?.substring(vdoURL?.lastIndexOf("/") + 1);
	useEffect(() => {
		fetch(`http://gk.secquraise.com/api/checkVideoURL?url=${fileName}&date=${Date}`)
			.then((response) => response.json())
			.then((data) => {
				if (data.exists) setUrlExist(true);
			})
			.catch((error) => {
				console.log("Error checking video URL:", error);
			});
	}, [fileName]);

	const contents = (
		<>
			{urlExist ? (
				<div className="h-full w-full">
					<video className="object-cover h-full w-full" key={id} controls>
						<source src={vdoURL} type="video/mp4" />
					</video>
				</div>
			) : (
				<>
					<div className="w-[360px] h-[204px] flex flex-row items-center justify-around">
						<h2 className="text-2xl text-slate-800 font-semibold">Video upload pending</h2>
					</div>
				</>
			)}
		</>
	);

	const handleCheck = (e) => {
		const status = e.target.checked;
		if (status) setSelectedEventsID((prev) => [...prev, id]);
		else setSelectedEventsID((prev) => prev.filter((eventID) => eventID !== id));
	};

	return (
		<div className="bg-[#F2F5F7] h-72 border border-[#2E3192] flex flex-col rounded-md">
			<div className="flex flex-row justify-between items-center font-bold uppercase p-1">
				<div className="flex flex-row items-start gap-1">
					<p>{Class_label}</p>
					<p>{Direction}</p>
				</div>
				<div className="flex flex-row items-end gap-1">
					<p>{formatedDate}</p>
					<p>{formatedTime}</p>
				</div>
			</div>
			<div className="mt-2">{contents}</div>
			<div className="relative h-full w-full rounded-b-md flex items-center">
				<input
					type="checkbox"
					checked={checked}
					onChange={handleCheck}
					className="appearance-none w-full h-full rounded-b-md bg-success checked:bg-error cursor-pointer hover:shadow-lg active:ring-2"
					id={id}
				/>
				<label
					htmlFor={id}
					className="peer-checked:text-white hover:cursor-pointer absolute z-[1] right-1/2 text-white text-xl uppercase transition-all duration-500 transform ease-in-out font-bold translate-x-1/2"
				>
					{checked ? "Remove" : "Add"}
				</label>
			</div>
		</div>
	);
};

export default TagEventModalDisplayCard;
