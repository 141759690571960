import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react"
import { db } from "../firebase/firebase.config";

const useMasterAdmin = (email) => {
    const [isMasterAdmin, setIsMasterAdmin] = useState(false);
    const [masterAdminLoading, setMasterAdminLoading] = useState(true);

    useEffect(() => {
        if (email) {
            const userData = collection(db, "masterAdminCollection");
            const q = query(userData, where("Email", "==", email));

            const fetchQueryData = async () => {
                let listData = [];
                try {
                    const queryDatas = await getDocs(q);
                    queryDatas.forEach((doc) => {
                        listData.push({ id: doc.id, ...doc.data() });
                    });
                    //console.log('list:',listData[0].Role);
                    const masterAdminResult = listData[0]?.Role === 'Master Admin';
                    setIsMasterAdmin(masterAdminResult);
                    setMasterAdminLoading(false);
                }
                catch (err) {
                    console.log(err);
                }
            };
            fetchQueryData();
        }

    }, [email])
    return [isMasterAdmin, masterAdminLoading]
};

export default useMasterAdmin;