import React from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { HiOutlineArrowCircleRight } from 'react-icons/hi';
import { MdModeEditOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const ClientLocationTableRow = ({ i, locationD, setALocation, clientId }) => {
    const { Location_ID, Name, Address, Pincode, UseCase, id } = locationD;
    const navigate = useNavigate();

    //navigate to zone
    const handleNavigateToZone = () => {
        navigate(`/dashboard/masteradminclient/${clientId}/${id}`, {
            state: { clientId, Location_ID }
        });
    };

    return (
        <tr key={i} className='border-b'>
            <td className='p-3 break-words max-w-[80px]'>{i + 1}</td>
            <td className='p-3 break-words max-w-[100px]'>{Location_ID}</td>
            <td className='p-3 break-words min-w-[200px]'>{Name}</td>
            <td className='p-3 break-words max-w-[200px]'>{Address}</td>
            <td className='p-3 break-words max-w-[200px]'>{Pincode}</td>
            {/* <td className='p-3 break-words max-w-[200px]'>{UseCase.join(', ')}</td> */}
            <td className='p-3 break-words max-w-[200px]'>{UseCase}</td>
            <td className='p-3 break-words max-w-[200px]'>
                <label onClick={() => setALocation(locationD)}
                    htmlFor="addDetection-modal"
                    className='mx-auto p-1 font-bold text-[#375075] border border-white hover:border-[#375075]'
                >
                    Detections
                </label>
            </td>

            <td className='p-3 break-words max-w-[200px]'>
                <button
                    onClick={handleNavigateToZone}
                    className='mx-auto p-1 font-bold text-[#375075] flex items-center border border-white hover:border-[#375075]'
                >
                    Zones
                    <HiOutlineArrowCircleRight className='ml-1 text-lg' />
                </button>
            </td>

            <td className='p-3 max-w-[50px]'>
                <label onClick={() => setALocation(locationD)}
                    htmlFor="updateClientLocation-modal" >
                    <MdModeEditOutline className='text-2xl' />
                </label>
            </td>
            <td className='p-3 max-w-[50px]'>
                <label onClick={() => setALocation(locationD)}
                    htmlFor="confirmation-modal" >
                    <FiTrash2 className='text-2xl' />
                </label>
            </td>
        </tr>
    );
};

export default ClientLocationTableRow;