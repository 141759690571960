import React, { useCallback, useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { BsSliders } from "react-icons/bs";
import { DataContext } from "../../context/DataProvider/DataProvider";
import { collection, collectionGroup, doc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase.config";
import { uniqueArr } from "../../Utility/RemoveDuplicate";
import Loading from "../../components/Loading/Loading";
import { AuthContext } from "../../context/AuthProvider/AuthProvider";
import useMasterAdmin from "../../hooks/useMasterAdmin";
import FilterClientName from "./FilterClientName";
import Select, { components as RSComponents } from "react-select";

/* Styling for react-select element */
const selectStyles = {
	container: (provided) => ({
		...provided,
		width: 400,
	}),
};

/* Fetching functions */
async function fetchZones(selectedClient, selectedLocations) {
	const selectedLocationIDs = selectedLocations.map((ele) => ele.value);
	const locations = query(
		collectionGroup(db, "Zones"),
		where("Client_ID", "==", selectedClient),
		where("Location_ID", "in", [...selectedLocationIDs])
	);
	const querySnapshot = await getDocs(locations);
	const zones = [];
	querySnapshot.forEach((doc) => {
		const data = doc.data();
		zones.push({ value: `${data["Client_ID"]}-${data["Location_ID"]}-${data["Zone_ID"]}`, label: data["ZoneName"] });
	});
	return zones;
}

async function fetchDetections(selectedClient, selectedLocations) {
	const selectedLocationIDs = selectedLocations.map((ele) => ele.value);
	const q = query(collectionGroup(db, "Detections"), where("Client_ID", "==", selectedClient), where("Location_ID", "in", [...selectedLocationIDs]));
	const querySnapshot = await getDocs(q);
	const detections = [];
	querySnapshot.forEach((doc) => {
		const data = doc.data();
		data["Vehicles"].forEach((detection) => detections.push({ value: detection, label: detection }));
	});
	return [...new Map(detections.map((item) => [item["value"], item])).values()];
}

async function fetchDirections(selectedClient, selectedLocations) {
	const selectedLocationIDs = selectedLocations.map((ele) => ele.value);
	const q = query(collectionGroup(db, "Directions"), where("Client_ID", "==", selectedClient), where("Location_ID", "in", [...selectedLocationIDs]));
	const querySnapshot = await getDocs(q);
	const directions = [];
	querySnapshot.forEach((doc) => {
		const data = doc.data();
		data["direction-list"].forEach((direction) => directions.push({ value: direction, label: direction }));
	});
	return [...new Map(directions.map((item) => [item["value"], item])).values()];
}

const EventFilter = ({ handleFilter, setIsLoading, isLoading, resetHandler, selectedFilterOptions, setSelectedFilterOptions }) => {
	const { clientsData, clientIds, userClientAndLocation } = useContext(DataContext);
	const { user, authLoading } = useContext(AuthContext);
	const [isMasterAdmin, masterAdminLoading] = useMasterAdmin(user?.email);
	const [clientLocations, setClientLocations] = useState([]);

	const [locationIds, setLocationIds] = useState([]);
	const [locationOptions, setLocationOptions] = useState([]);

	const [locationZones, setLocationZones] = useState([]); // for react select so it will be {value:...,label:...}

	const [detections, setDetections] = useState([]);

	const [directions, setDirections] = useState([]);

	// method to handle mounting dropdown component
	const [showMenu, setShowMenu] = useState(false);
	const dropdownRef = useRef(null);
	useEffect(() => {
		function handleClickOutside(event) {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				// console.log("%cClicked outside", "color:red;");
				setShowMenu(false);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [dropdownRef]);

	//-------------------------Client & Location dropdown------------------------
	// initial select the first client if the selectedClient is null
	useEffect(() => {
		if (!selectedFilterOptions.selectedClient) {
			setSelectedFilterOptions((prev) => ({
				...prev,
				selectedClient: clientIds[0],
			}));
		}
	}, [selectedFilterOptions.selectedClient, clientIds]);

	//fetch those locations according to selected client
	useEffect(() => {
		// console.log('inside:', selectedClient);
		if (selectedFilterOptions.selectedClient && !authLoading && !masterAdminLoading) {
			const unsub = onSnapshot(
				query(collection(db, `clients-data-collection/${selectedFilterOptions.selectedClient}/Location`)),
				(snapShot) => {
					let list = [];
					snapShot.docs.forEach((doc) => {
						list.push({ id: doc.id, ...doc.data() });
					});
					// console.log('location list:', list);
					setClientLocations(list);

					if (isMasterAdmin) {
						const locationList = list?.map((l) => l?.Location_ID);
						const uniqueList = uniqueArr(locationList);
						setLocationIds(uniqueList); // change it ****
						const options = uniqueList.map((id) => {
							const locationName = list?.find((l) => l?.Location_ID === id).Name;
							return { value: id, label: locationName };
						});
						setLocationOptions(options);
						setSelectedFilterOptions((prev) => ({
							...prev,
							selectedLocations: options,
						}));
					} else if (!isMasterAdmin) {
						const location = userClientAndLocation?.userLocation;
						setLocationIds(location);
						// console.log("location", location);
						const options = location.map((id) => {
							const locationName = list?.find((l) => l?.Location_ID === id).Name;
							return { value: id, label: locationName };
						});
						setLocationOptions(options);
						setSelectedFilterOptions((prev) => ({
							...prev,
							selectedLocations: options,
						}));
					}
					// ** setSelectedLocation('');
					// ** setSelectedLocation([]);
					setIsLoading(false);
				},
				(error) => {
					console.log(error);
				}
			);

			return () => {
				unsub();
			};
		}
	}, [clientsData, selectedFilterOptions.selectedClient, isMasterAdmin, masterAdminLoading, userClientAndLocation?.userLocation]);

	//fetch those Zone according to selected Location
	useEffect(() => {
		if (selectedFilterOptions.selectedClient && selectedFilterOptions.selectedLocations.length) {
			async function setZones() {
				const availableZones = await fetchZones(selectedFilterOptions.selectedClient, selectedFilterOptions.selectedLocations);

				// remove currently selected zones if they are not part of this newly fetched zones
				if (selectedFilterOptions.selectedZones.length) {
					const availableZonesSet = new Set(availableZones.map((zone) => zone.value));
					const currentlySelectedZoneOptions = [...selectedFilterOptions.selectedZones];
					const filteredSelectedZoneOptions = currentlySelectedZoneOptions.filter((zone) => availableZonesSet.has(zone.value));
					setSelectedFilterOptions((prev) => ({
						...prev,
						selectedZones: filteredSelectedZoneOptions,
					}));
				}

				setIsLoading(false);
				setLocationZones(availableZones);
				setSelectedFilterOptions((prev) => ({ ...prev, selectedZones: availableZones }));
			}
			setZones();
		}
	}, [selectedFilterOptions.selectedClient, selectedFilterOptions.selectedLocations]);

	//fetch Detections according to selected Location
	useEffect(() => {
		if (selectedFilterOptions.selectedClient && selectedFilterOptions.selectedLocations.length) {
			async function setDetection() {
				const availableDetections = await fetchDetections(selectedFilterOptions.selectedClient, selectedFilterOptions.selectedLocations);
				if (!availableDetections.length) {
					console.log("Error getting detection");
					return;
				}
				setDetections(availableDetections);
				// remove currently selected detections if they are not part of this newly fetched detections
				if (selectedFilterOptions.selectedDetections.length) {
					const availableDetectionsSet = new Set(availableDetections.map((detection) => detection.value));
					const currentlySelectedDetectionOptions = [...selectedFilterOptions.selectedDetections];
					const filteredSelectedDetectionOptions = currentlySelectedDetectionOptions.filter((detection) =>
						availableDetectionsSet.has(detection.value)
					);
					setSelectedFilterOptions((prev) => ({
						...prev,
						selectedDetections: filteredSelectedDetectionOptions,
					}));
				} else {
					setSelectedFilterOptions((prev) => ({
						...prev,
						selectedDetections: availableDetections.filter((detection) => detection.value !== "Motorcycle"),
					}));
				}

				setIsLoading(false);
			}
			setDetection();
		}
	}, [selectedFilterOptions.selectedClient, selectedFilterOptions.selectedLocations]);

	//fetch Direction according to selected Location
	useEffect(() => {
		if (selectedFilterOptions.selectedClient && selectedFilterOptions.selectedLocations.length) {
			async function setDirection() {
				const availableDirections = await fetchDirections(selectedFilterOptions.selectedClient, selectedFilterOptions.selectedLocations);

				// remove currently selected directions if they are not part of this newly fetched directions
				if (selectedFilterOptions.selectedDirections.length) {
					const availableDirectionsSet = new Set(availableDirections.map((direction) => direction.value));
					const currentlySelectedDirectionOptions = [...selectedFilterOptions.selectedDirections];
					const filteredSelectedDirectionOptions = currentlySelectedDirectionOptions.filter((direction) =>
						availableDirectionsSet.has(direction.value)
					);
					setSelectedFilterOptions((prev) => ({
						...prev,
						selectedDirections: filteredSelectedDirectionOptions,
					}));
				}

				setIsLoading(false);
				if (!availableDirections.length) console.log("Error getting detection");
				else {
					setDirections(availableDirections);
					// selecting all directions on load
					setSelectedFilterOptions((prev) => ({
						...prev,
						selectedDirections: availableDirections,
					}));
				}
			}
			setDirection();
		}
	}, [selectedFilterOptions.selectedClient, selectedFilterOptions.selectedLocations]);

	//--------------------------dropdown selection ------------------------
	const handleClientSelect = (e) => {
		const dropDownClient = e.target?.value;
		setSelectedFilterOptions((prev) => ({
			...prev,
			selectedClient: dropDownClient,
		}));
	};

	const handleLocationSelect = (selectedOptions) => {
		setSelectedFilterOptions((prev) => ({
			...prev,
			selectedLocations: selectedOptions,
		}));
	};

	const handleZoneSelect = (selectedOptions) => {
		setSelectedFilterOptions((prev) => ({
			...prev,
			selectedZones: selectedOptions,
		}));
	};

	const handleDetectionSelect = (selectedOptions) => {
		setSelectedFilterOptions((prev) => ({
			...prev,
			selectedDetections: selectedOptions,
		}));
	};

	const handleDirectionSelect = (selectedOptions) => {
		setSelectedFilterOptions((prev) => ({
			...prev,
			selectedDirections: selectedOptions,
		}));
	};

	if (isLoading) return <Loading />;

	return (
		<>
			<div className="dropdown dropdown-end">
				<label tabIndex={0} className="btn !bg-transparent !border-0 !rounded-none" onClick={() => setShowMenu(true)}>
					<BsSliders className="text-3xl text-black"></BsSliders>
				</label>
				{showMenu && (
					<form
						onSubmit={handleFilter}
						tabIndex={0}
						ref={dropdownRef}
						className="dropdown-content z-[1] menu p-[10px] shadow bg-base-100 w-[33rem] text-black"
					>
						{/* Client */}
						<div className="flex flex-row item-center justify-start form-control mt-2">
							<label className="label inline w-24">
								<span className="label-text">Client :</span>
							</label>
							<select
								className="border border-[#D9D9D9] w-[400px] px-1 h-[38px] bg-white"
								name="client"
								value={selectedFilterOptions.selectedClient}
								onChange={handleClientSelect}
								required
							>
								{clientIds ? (
									<>
										{clientIds?.map((client, i) => (
											<FilterClientName key={i} i={i} client={client} clientsData={clientsData}></FilterClientName>
										))}
									</>
								) : (
									<option disabled>No client available</option>
								)}
							</select>
						</div>

						{/* location */}
						<div className="flex flex-row item-center justify-start form-control mt-2">
							<label className="label inline w-24">
								<span className="label-text">Location :</span>
							</label>

							<Select
								className="text-black"
								options={locationOptions}
								placeholder={"Select locations"}
								value={selectedFilterOptions.selectedLocations}
								onChange={handleLocationSelect}
								styles={selectStyles}
								isMulti
							/>
						</div>

						{/* Zone Name */}
						{/* <div className="flex flex-row item-center justify-start form-control mt-2">
							<label className="label inline w-24">
								<span className="label-text">Zone :</span>
							</label>

							<Select
								className="text-black"
								options={locationZones}
								placeholder={"Select Zones"}
								value={selectedFilterOptions.selectedZones}
								onChange={handleZoneSelect}
								styles={selectStyles}
								isMulti
							/>
						</div> */}

						{/* Detections */}
						<div className="flex flex-row item-center justify-start form-control mt-2">
							<label className="label w-24 flex flex-col justify-center items-start">
								<span className="label-text ">Detections:</span>
							</label>

							<Select
								className="text-black"
								options={detections}
								placeholder={"Select Detections"}
								value={selectedFilterOptions.selectedDetections}
								onChange={handleDetectionSelect}
								styles={selectStyles}
								isMulti
							/>
						</div>

						{/* Direction */}
						{/* <div className="flex flex-row item-center justify-start form-control mt-2">
							<label className="label inline w-24">
								<span className="label-text">Direction:</span>
							</label>

							<Select
								className="text-black"
								options={directions}
								placeholder={"Select Directions"}
								value={selectedFilterOptions.selectedDirections}
								onChange={handleDirectionSelect}
								styles={selectStyles}
								isMulti
							/>
						</div> */}

						{/* Date */}
						<div className="flex flex-row item-center justify-start form-control mt-2">
							<label className="label inline w-24">
								<span className="label-text">Date:</span>
							</label>
							<input
								className="border border-[#D9D9D9] w-[400px] px-1 h-[38px] bg-white"
								type="date"
								name="date"
								defaultValue={new Date().toISOString().slice(0, 10)}
							/>
						</div>
						{/* </div> */}

						<div className="flex justify-evenly mt-5">
							<button
								type="reset"
								onClick={resetHandler}
								className="py-1 px-7 mx-auto block bg-[#375075] text-white hover:bg-[#A6A6A6] border-0 rounded-none"
							>
								Reset
							</button>
							<button type="submit" className="py-1 px-7 mx-auto block bg-[#375075] text-white hover:bg-[#A6A6A6] border-0 rounded-none">
								Submit
							</button>
						</div>
					</form>
				)}
			</div>
		</>
	);
};

export default EventFilter;
