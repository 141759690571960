import { collection, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { db } from '../../../../../firebase/firebase.config';
import { isDiffGreaterThan5Minutes } from '../../../../../Utility/CheckPingDiff';
import { isMoreThan24Hours } from '../../../../../Utility/CheckPingHoursDiff';
import { fixDeviceDate } from '../../../../../Utility/fixDeviceDate';
import { fixDate } from '../../../../../Utility/FixTodaysDate';

const Outage = ({ data, latestDevice, setIsActive }) => {
    const [lastSevenOutage, setLastSevenOutage] = useState([]);
    const [todaysD] = fixDate();
    //console.log(latestDevice);

    // if 3 pings missed in a row, change the status according to missing pings
    useEffect(() => {

        if (data?.length > 1) {
            //filter all the data according to the device after that sort the data in descending order
            const filteredPings = data?.filter((ping) => ping?.['Device-ID'] === latestDevice?.["Device-ID"]
            );
            const sortedPings = filteredPings?.sort((a, b) => {
                if (a.Timestamp < b.Timestamp) return 1;
                if (b.Timestamp < a.Timestamp) return -1;
                return 0;
            });

            //formate the date using the fixDateFormat() funtion                     
            const timeTwo = fixDeviceDate(sortedPings[0]?.Timestamp);
            const isMissed = isDiffGreaterThan5Minutes(todaysD, timeTwo);
            const isMissedFor24Hours = isMoreThan24Hours(todaysD, timeTwo);

            if (isMissedFor24Hours) {
                setIsActive('Inactive');
            }
            else if (isMissed) {
                setIsActive('Down');
            }
            else {
                setIsActive('Active');
            }
        }
    }, [data?.length]);

    //---------- Last 7 days calculation ----------

    //get 7 days older date from today
    const today = new Date();
    const sevenDaysAgo = today.getTime() - (7 * 24 * 60 * 60 * 1000);
    //console.log(sevenDaysAgo);

    // Fetch last 7 days outage data from db
    useEffect(() => {
        const collectionRef = collection(db, 'ping-outage-collection');
        const q = query(collectionRef, where("DeviceID", "==", latestDevice?.['Device-ID']));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let list = [];
            querySnapshot?.docs?.forEach((doc) => {
                list.push({ id: doc.id, ...doc.data() });
            });
            const lastSevenOutages = list?.filter((ping) => ping?.Timestamp > sevenDaysAgo);
            //console.log('outage list:',lastSevenOutages);
            setLastSevenOutage(lastSevenOutages);
        },
            (error) => {
                console.error("Outage Error:", error);
            }
        );
        return () => {
            unsubscribe();
        };

    }, [latestDevice?.['Device-ID']]);

    return (
        <>
            <span
                className='w-full p-2 cursor-pointer'
            >
                {
                    lastSevenOutage?.length
                }
            </span>
        </>
    );
};

export default Outage;