import React, { useState } from "react";
import Loading from "../../../../../components/Loading/Loading";
import { db } from "../../../../../firebase/firebase.config";
import { doc, getDoc } from "firebase/firestore";
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import HourlyEntryExitChart from "../Charts/HourlyEntryExitChart";
import Select from "react-select";

const formateDate = (d) => {
	let day = d.getDate().toString().padStart(2, "0");
	let month = (d.getMonth() + 1).toString().padStart(2, "0");
	let year = d.getFullYear();
	return `${year}-${month}-${day}`;
};

function combineDate(date) {
	const d = new Date(date);
	return d.getFullYear().toString() + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0");
}

// custom styling for react-select
const selectStyles = {
	// menuList: (styles) => {
	// 	// console.log("menuList:", styles);
	// 	return {
	// 		...styles,
	// 		maxHeight: 136,
	// 	};
	// },
	container: (provided) => ({
		...provided,
		minWidth: 300,
		maxWidth: 646,
	}),
};

const HourlyTraffic = ({ selectedClient, selectedLocation }) => {
	const [hourlyReport, setHourlyReport] = useState([]);

	const [detectionsOptions, setDetectionsOptions] = useState([]);
	const [selectedDetectionsOptions, setSelectedDetectionsOptions] = useState([]);

	const [date, setDate] = useState(formateDate(new Date()));

	const [charts, setCharts] = useState(false);

	const [loading, setLoading] = useState(true);

	async function fetchDetections() {
		const docRef = doc(db, `clients-data-collection/${selectedClient.id}/Location/${selectedLocation.id}/Detections`, "detection-list");
		const docSnap = await getDoc(docRef);
		let detectionList = docSnap.data()?.Vehicles;
		detectionList = detectionList.filter((detection) => detection !== "Numberplate").sort();
		let options = detectionList.map((detection) => ({ value: detection, label: detection }));
		setDetectionsOptions(options);
		setSelectedDetectionsOptions(options);
	}

	async function fetchHourlyReport() {
		let reportData = [];
		const docRef = doc(db, "HourlyReport", `${selectedClient.id}-${selectedLocation.id}-${combineDate(date)}`);
		const docSnap = await getDoc(docRef);
		const data = docSnap.data();

		for (let i = 0; i <= 23; i++) {
			const formattedHour = i.toString().padStart(2, "0");
			let startTime = formattedHour;
			let endTime = (parseInt(formattedHour) + 1).toString().padStart(2, "0");
			let hourSumForSelectedDetections = { Entry: 0, Exit: 0 };
			selectedDetectionsOptions.forEach((detection) => {
				// console.log(data?.[formattedHour]?.[detection]?.["Entry"]);
				hourSumForSelectedDetections["Entry"] +=
					data?.[formattedHour]?.[detection.value]?.["Entry"] >= 0 ? data[formattedHour][detection.value]["Entry"] : 0;
				hourSumForSelectedDetections["Exit"] +=
					data?.[formattedHour]?.[detection.value]?.["Exit"] >= 0 ? data[formattedHour][detection.value]["Exit"] : 0;
			});
			reportData.push({
				Time: `${startTime}-${endTime}`,
				Entry: hourSumForSelectedDetections["Entry"],
				Exit: hourSumForSelectedDetections["Exit"],
			});
		}
		setHourlyReport(reportData);
		setLoading(false);
	}

	useEffect(() => {
		if (selectedClient.id && selectedLocation.id) {
			fetchDetections();
		}
	}, [selectedClient.id, selectedLocation.id]);

	useEffect(() => {
		if (selectedClient.id && selectedLocation.id && date && selectedDetectionsOptions.length) {
			setLoading(true);
			fetchHourlyReport();
		}
	}, [selectedClient.id, selectedLocation.id, date, selectedDetectionsOptions, detectionsOptions]);

	const handleDetectionsSelect = (selectedOptions) => {
		setSelectedDetectionsOptions(selectedOptions);
	};

	return (
		<div className="mx-6">
			<h1 className="text-sky-900 text-2xl font-bold mr-3">Hourly Traffic</h1>
			<div className="flex flex-col gap-3 my-2">
				<div className="flex flex-row gap-2 xl:justify-between items-center">
					{/* Detection and Date */}
					<div className="flex flex-row gap-7">
						{/* Detection */}
						<div className="flex flex-row gap-5 items-center">
							<h1 className="text-black xl:text-lg text-base">Detection:</h1>
							<Select
								className="text-black"
								options={detectionsOptions}
								placeholder={"Select Detections"}
								value={selectedDetectionsOptions}
								onChange={handleDetectionsSelect}
								styles={selectStyles}
								isMulti
							/>
						</div>
						{/* Date */}
						<div className="flex flex-row gap-5 items-center">
							<h1 className="text-black xl:text-lg text-base">Date:</h1>
							<input
								className="bg-gray-100 rounded text-black w-32 h-fit"
								id="datepicker"
								type="date"
								placeholder="Pick Date"
								value={date}
								onChange={(e) => setDate(e.target.value)}
								max={formateDate(new Date())}
							/>
						</div>
					</div>

					{/* Show charts and Download button */}
					<div className="flex flex-row gap-3">
						{!loading && hourlyReport.length && (
							<div className="flex flex-row mr-3 gap-3 items-center bg-slate-200 p-1 rounded">
								<span className="text-black">Data</span>
								<input type="checkbox" className="toggle toggle-accent" onChange={(e) => setCharts(e.target.checked)} checked={charts} />
								<span className="text-black">Chart</span>
							</div>
						)}

						{!loading && hourlyReport.length && selectedDetectionsOptions.length && (
							<CSVLink
								data={hourlyReport}
								filename={`${new Date(date).toLocaleDateString("en-us", {
									year: "numeric",
									month: "short",
									day: "numeric",
								})} Hourly Traffic Report.csv`}
								className="bg-green-500 hover:bg-green-900 hover:border-green-300 px-2 py-1 active:border-2 active:border-green-900 w-36 text-center text-white font-semibold h-[2.5rem]   border-2 border-green-800"
							>
								Download
							</CSVLink>
						)}
					</div>
				</div>

				{selectedDetectionsOptions.length === 0 ? (
					<div className="flex flex-row justify-around my-6">
						<div className="alert alert-info px-20 w-fit">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
								></path>
							</svg>
							<span>Select Detections !</span>
						</div>
					</div>
				) : charts ? (
					<>{loading ? <Loading /> : <HourlyEntryExitChart hourlyReport={hourlyReport}></HourlyEntryExitChart>}</>
				) : (
					<>
						{loading ? (
							<Loading />
						) : hourlyReport ? (
							<div className="flex flex-col gap-7 overflow-x-auto overflow-y-auto max-h-[70vh]">
								<table className="text-center self-center w-full border-t-[3px] border-t-black border-b-2 border-b-black">
									<thead className="sticky top-0 bg-gray-100">
										<tr className="border-b text-black">
											<th className="p-3 max-w-[200] xl:text-base text-sm">Time (in Hrs)</th>
											<th className="p-3 max-w-[200] xl:text-base text-sm">Entry</th>
											<th className="p-3 max-w-[200] xl:text-base text-sm">Exit</th>
										</tr>
									</thead>
									<tbody className="bg-sky-100">
										{hourlyReport.map((ele) => (
											<tr key={ele["Time"]} className="border-b text-black">
												<td className="p-3 max-w-[80] xl:text-base text-sm">{ele["Time"]}</td>
												<td className="p-3 max-w-[80] xl:text-base text-sm">{ele["Entry"]}</td>
												<td className="p-3 max-w-[80] xl:text-base text-sm">{ele["Exit"]}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						) : (
							<div className="flex flex-row justify-around my-6">
								<div className="alert alert-info px-20 w-fit">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6">
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
										></path>
									</svg>
									<span>No Data for {formateDate(date)} is available yet !</span>
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default HourlyTraffic;
