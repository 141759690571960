import React from "react";
import { useState } from "react";
import TimeDisplayDesktop from "../../../../../../../../components/TimeDisplayDesktop/TimeDisplayDesktop";
import { MdMoreTime } from "react-icons/md";
import TimePickerDesktop from "../../../../../../../../components/TimePicker/TimePickerDesktop";

const TimePickerSectionMain = ({ startingTime, setStartingTime, endingTime, setEndingTime }) => {
	// for handling modal mounting for starting ending time modals
	const [startTimeModalStatus, setStartTimeModalStatus] = useState(false);
	const [endTimeModalStatus, setEndTimeModalStatus] = useState(false);

	return (
		<>
			<div className="w-[72.5rem] flex flex-row justify-between my-9">
				{/* StartTime */}
				<div className="flex flex-row gap-[1.3rem] items-center">
					<h1 className="text-black text-2xl font-normal">Start time</h1>
					<div className="flex flex-row items-center justify-between rounded-[8px] bg-[#F2F5F7] w-[200px] h-[55px] py-[16px] px-[12px] border border-[#2E3192]">
						<TimeDisplayDesktop time={startingTime} />
						<div className="tooltip tooltip-right" data-tip="Edit Time">
							<label
								htmlFor="time_picker"
								className="btn btn-ghost !p-0 !w-[45px] !h-[24px] !rounded-none"
								onClick={() => setStartTimeModalStatus(true)}
							>
								<MdMoreTime className="text-3xl" />
							</label>
						</div>
					</div>
				</div>

				{/* EndTime */}
				<div className="flex flex-row gap-[4rem] items-center">
					<h1 className="text-black text-2xl font-normal">End time</h1>
					<div className="flex flex-row items-center justify-between rounded-[8px] bg-[#F2F5F7] w-[200px] h-[55px] py-[16px] px-[12px] border border-[#2E3192]">
						<TimeDisplayDesktop time={endingTime} />
						<div className="tooltip tooltip-right" data-tip="Edit Time">
							<label
								htmlFor="time_picker"
								className="btn btn-ghost !p-0 !w-[45px] !h-[24px] !rounded-none"
								onClick={() => setEndTimeModalStatus(true)}
							>
								<MdMoreTime className="text-3xl" />
							</label>
						</div>
					</div>
				</div>
			</div>

			{/* Time Picker Modals */}
			{startTimeModalStatus && (
				<TimePickerDesktop time={startingTime} setTime={setStartingTime} setisTimePickerOpen={setStartTimeModalStatus} typeText={"Starting"} />
			)}
			{endTimeModalStatus && (
				<TimePickerDesktop time={endingTime} setTime={setEndingTime} setisTimePickerOpen={setEndTimeModalStatus} typeText={"Ending"} />
			)}
		</>
	);
};

export default TimePickerSectionMain;
