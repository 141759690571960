import React from "react";
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const DailyEntryExitByVehicle = ({ dailyTrafficReport }) => {
	// console.log(dailyTrafficReport);

	const renderCustomizedLabel = (props) => {
		const { x, y, width, height, value } = props;
		// console.log(value);
		const radius = 10;

		return (
			<g>
				{/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
				<text x={x + width / 2} y={y - radius} fill="green" textAnchor="middle" dominantBaseline="middle">
					{value}
				</text>
			</g>
		);
	};

	return (
		<div className="mt-7 mb-10 flex justify-center">
			<ResponsiveContainer width="70%" height={400}>
				<BarChart
					width={500}
					height={300}
					data={dailyTrafficReport}
					margin={{
						top: 5,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="Detection" />
					<YAxis />
					<Tooltip />
					<Legend />
					<Bar dataKey="Entry" fill="blue">
						<LabelList dataKey="Entry" content={renderCustomizedLabel} />
					</Bar>
					<Bar dataKey="Exit" fill="red">
						<LabelList dataKey="Exit" content={renderCustomizedLabel} />
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};

export default DailyEntryExitByVehicle;
