import React, { useState } from 'react';
import { fixDeviceDate } from '../../../../../Utility/fixDeviceDate';
import ElapsedTime from './ElapsedTime';
import Outage from './Outage';

const DeviceTableRow = ({ data, latestDevice }) => {
    const [isActive, setIsActive] = useState('Active');//to handle the status

    const timeStamp = latestDevice?.Timestamp;
    const CpuTemp = latestDevice?.['Temperature-CPU'];
    const CpuUse1 = latestDevice?.['CPU1-Usage'];
    const CpuUse2 = latestDevice?.['CPU2-Usage'];
    const CpuUse3 = latestDevice?.['CPU3-Usage'];
    const CpuUse4 = latestDevice?.['CPU4-Usage'];
    const RAMuse = latestDevice?.['RAM-Usage']?.['use_%'];
    //console.log(latestDevice);
    const formattedTimeStamp = fixDeviceDate(timeStamp);

    return (
        <tr className='border-b'>
            <td className='border-r p-3 max-w-[170px]'>
                {isActive}
            </td>
            <td className={`border-r p-3 max-w-[120px] ${isActive === 'Inactive' ? 'bg-red-500' : ''}`}>{latestDevice?.["Device-ID"]}</td>
            <td className='border-r p-3 max-w-[200px]'>
                {latestDevice?.Location}
                {
                    isActive === 'Down' ? <span className='text-red-500 font-extrabold'>*</span> : ''
                }
            </td>
            <td className='border-r p-3 max-w-[200px]'>{formattedTimeStamp}</td>
            <td className='border-r p-3 max-w-[200px]'><ElapsedTime
                timeStamp={timeStamp}
            ></ElapsedTime></td>
            <td className='border-r p-3 max-w-[100px]'><Outage
                data={data}
                latestDevice={latestDevice}
                isActive={isActive}
                setIsActive={setIsActive}
            ></Outage></td>
            <td className='border-r p-3 max-w-[100px]'>{CpuTemp}&#8451;</td>
            <td className='border-r p-2 max-w-[70px]'>{CpuUse1}&#37;</td>
            <td className='border-r p-2 max-w-[70px]'>{CpuUse2}&#37;</td>
            <td className='border-r p-2 max-w-[70px]'>{CpuUse3}&#37;</td>
            <td className='border-r p-2 max-w-[70px]'>{CpuUse4}&#37;</td>
            <td className='border-r p-3 max-w-[100px]'>{RAMuse}&#37;</td>
        </tr>
    );
};

export default DeviceTableRow;