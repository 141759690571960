import React from "react";
import { fixDateFormate } from "../../Utility/fixDateFormat";
import { useEffect } from "react";
import { useState } from "react";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/firebase.config";
import Loading from "../../components/Loading/Loading";

const ListCard = ({ vehicle, selectedVehicle, handleSelectedVehicle, setIsLoading, isLoading }) => {
  const { Location, id, Direction, Class_label, Timestamp, Date, Exception } = vehicle || {};
  // const status = vehicle?.Status;
  const isDetails = true;
  const deviceID = vehicle?.["Device-ID"];
  const cameraID = vehicle?.["Camera-ID"];
  const clientID = vehicle?.["Client-ID"];
  const [cameraName, setCameraName] = useState(null);
  const [priorityAlertInfo, setPriorityAlertInfo] = useState(null);
  // console.log(cameraName, Direction);

  //receive formated date from fixDateFormat() funtion
  const [formatedDate, formatedTime] = fixDateFormate(Timestamp);

  //fetch All the Alerts Info from priority collection
  useEffect(() => {
    if (id && clientID && Date) {
      const unsub = onSnapshot(
        doc(collection(db, `${clientID}-priority-${Date}`), id),
        (snapShot) => {
          if (snapShot.exists()) {
            const data = snapShot.data();
            // console.log(data);
            setPriorityAlertInfo(data);
          } else {
            // console.log("Document not found.");
            setPriorityAlertInfo(null);
          }
        },
        (error) => {
          console.log(error);
        }
      );
      return () => {
        unsub();
      };
    }
  }, [id, clientID, Date]);

  //fetch Camera name from device-data-collection
  useEffect(() => {
    if (cameraID && deviceID) {
      const unsub = onSnapshot(
        doc(collection(db, `device-data-collection/${deviceID}/Cameras`), cameraID),
        (snapShot) => {
          if (snapShot.exists()) {
            const data = snapShot.data();
            // console.log(data);
            const camera = data?.CameraName;
            setCameraName(camera);
          } else {
            console.log("Document not found.");
            setCameraName(null);
          }
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
        }
      );
      return () => {
        unsub();
      };
    }
  }, [id, deviceID, cameraID]);

  const textContent = (
    <>
      <div className="">
        <div className="flex justify-between items-center">
          <p className="font-medium text-start">{Location}</p>

          <div className="flex font-medium text-xs text-end">
            <p className="ml-2">{formatedTime}</p>
          </div>
        </div>

        <p className="text-start">{Direction && cameraName ? `${Direction} detected at ${cameraName}` : "No detection found"}</p>

        <div className="flex justify-between">
          <p className="text-start">{Class_label ? `Detection: ${Class_label}` : "Detection: None"}</p>
          {(priorityAlertInfo?.Priority === "Priority" || priorityAlertInfo?.Priority === "Critical") && (
            <>
              <p className="text-end font-bold">{priorityAlertInfo?.Status}</p>
            </>
          )}
        </div>
      </div>
    </>
  );

  if (isLoading) {
    return <Loading></Loading>;
  }

  console.log(Exception, "Exception");

  return (
    <div onClick={() => handleSelectedVehicle(vehicle)} className="text-black bg-red-500">
      {["Exception", "Handled"]?.includes(Exception) ? (
        <>
          <div className={selectedVehicle?.id === id ? `py-1 px-5 ${Exception === "Exception" ? "bg-red-400" : "bg-green-400"} border-2 border-gray-800 hover:border-yellow-400` : `py-1 px-5 ${Exception === "Exception" ? "bg-red-400" : "bg-green-400"} hover:border-yellow-400`}>{textContent}</div>
        </>
      ) : priorityAlertInfo && priorityAlertInfo?.Priority === "Critical" && priorityAlertInfo?.Status === "Pending" ? (
        <>
          <div className={selectedVehicle?.id === id ? "py-1 px-5 bg-orange-500 text-white border-4 border-yellow-400" : "py-1 px-5 bg-orange-400 border-4 hover:bg-orange-500 hover:border-yellow-400 border-orange-400"}>{textContent}</div>
        </>
      ) : priorityAlertInfo && priorityAlertInfo?.Priority === "Critical" && priorityAlertInfo?.Status === "Approved" ? (
        <>
          <div className={selectedVehicle?.id === id ? "py-1 px-5 bg-green-600 text-white border-4 border-yellow-400" : "py-1 px-5 bg-green-500 hover:bg-green-600 hover:text-white hover:border-yellow-400 border-4 border-green-500"}>{textContent}</div>
        </>
      ) : priorityAlertInfo && priorityAlertInfo?.Priority === "Critical" && priorityAlertInfo?.Status === "Update" ? (
        <>
          <div className={selectedVehicle?.id === id ? "py-1 px-5 bg-red-700 text-white border-4 border-yellow-400" : "py-1 px-5 bg-red-600 hover:bg-red-700 hover:text-white border-4 hover:border-yellow-400 border-red-600"}>{textContent}</div>
        </>
      ) : priorityAlertInfo && priorityAlertInfo?.Priority === "Critical" && priorityAlertInfo?.Status === "Rejected" ? (
        <>
          <div className={selectedVehicle?.id === id ? "py-1 px-5 bg-black text-white border-4 border-yellow-400" : "py-1 px-5 bg-black text-white border-4 hover:border-yellow-400 border-black"}>{textContent}</div>
        </>
      ) : priorityAlertInfo && priorityAlertInfo?.Priority === "Priority" ? (
        <>
          <div className={selectedVehicle?.id === id ? "py-1 px-5 bg-purple-400 text-white border-4 border-yellow-400" : "py-1 px-5 bg-purple-400 hover:bg-purple-500 text-white border-4 hover:border-yellow-400 border-purple-400"}>{textContent}</div>
        </>
      ) : (
        <>
          <div className={selectedVehicle?.id === id ? "py-1 px-5 bg-[#A6A6A6] text-white border border-[#A6A6A6]" : "py-1 px-5 bg-[#D9D9D9] hover:bg-[#A6A6A6] hover:text-white border border-[#A6A6A6]"}>{textContent}</div>
        </>
      )}
    </div>
  );
};

export default ListCard;
