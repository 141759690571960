import React from 'react';
import { fixDeviceDate } from '../../../../../Utility/fixDeviceDate';
import { fixDate } from '../../../../../Utility/FixTodaysDate';
import { subtractDate } from '../../../../../Utility/SubtractDate';

const ElapsedTime = ({ timeStamp }) => {

    //device date and time
    const formattedDeviceDate = fixDeviceDate(timeStamp);

    //Current date and time
    const [todaysD] = fixDate();
    // console.log('device :', formattedDeviceDate, 'Today:', todaysD);
    const [formattedDiff] = subtractDate(todaysD, formattedDeviceDate)
    //console.log('diff :', formattedDiff);

    return (
        <>
            <span
                className='w-full p-2 cursor-pointer'
            >
                {formattedDiff}
            </span>
        </>
    );
};

export default ElapsedTime;