import { v4 as uuidv4 } from "uuid";

const convertImageSourceToFile = async (imageSourceLink) => {
	try {
		// Fetch image data
		const response = await fetch(imageSourceLink);
		const blob = await response.blob();

		// Generate a unique filename using uuid
		const fileName = `${uuidv4()}.jpg`;

		// Create File object
		const file = new File([blob], fileName, { type: blob.type });
		return file;
	} catch (error) {
		console.error("Error converting image source to File:", error);
		return null;
	}
};

export default convertImageSourceToFile;
