// Modal.js
import React from 'react';

const CustomModal = ({ children }) => {

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg p-6 w-1/3">
                <div className="mb-4">{children}</div>
            </div>
        </div>
    );
};

export default CustomModal;