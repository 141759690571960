import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { db } from "../../../../../firebase/firebase.config";
import { doc, getDoc } from "firebase/firestore";
import Loading from "../../../../../components/Loading/Loading";
import { CSVLink } from "react-csv";
import DailyEntryExitByVehicle from "../Charts/DailyEntryExitByVehicle";
import { DataContext } from "../../../../../context/DataProvider/DataProvider";

const formateDate = (d) => {
	let day = d.getDate().toString().padStart(2, "0");
	let month = (d.getMonth() + 1).toString().padStart(2, "0");
	let year = d.getFullYear();
	return `${year}-${month}-${day}`;
};

const combineFormattedDate = (d) => d.split("-").join("");

function combineDate(date) {
	const d = new Date(date);
	return d.getFullYear().toString() + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0");
}

const DailyTraffic = ({ selectedClient, selectedLocation }) => {
	const { devices, dataLoading } = useContext(DataContext);

	const [dailyTrafficReport, setDailyTrafficReport] = useState([]);

	const [date, setDate] = useState(formateDate(new Date()));

	const [charts, setCharts] = useState(false);

	const [loading, setLoading] = useState(true);

	async function DailyTrafficReport() {
		const docRef = doc(db, "actualCountCollection", `${selectedClient.id}-${combineDate(new Date(date))}`);
		const docSnap = await getDoc(docRef);

		let data = docSnap.data();
		if (!data) {
			setDailyTrafficReport([]);
			setLoading(false);
			return;
		}

		const zoneDataArray = devices.filter((ele) => ele.Location_ID === selectedLocation.id && ele.ClientID === selectedClient.id);
		const uniqueZoneDataArray = [...new Map(zoneDataArray.map((item) => [item["ZoneID"], item])).values()];

		let detectionWiseCount = {};
		uniqueZoneDataArray.forEach((ele) => {
			const entryKey = `${ele.Location_ID}_${ele.ZoneID}_Entry`;
			if (Object.hasOwn(data, entryKey)) {
				for (const [key, value] of Object.entries(data[entryKey])) {
					if (key !== "zoneID" && key !== "classLabel" && key !== "deviceID" && key !== "direction" && key !== "timestamp" && key !== "Numberplate") {
						if (Object.hasOwn(detectionWiseCount, key)) detectionWiseCount[key]["Entry"] += isNaN(value) ? 0 : value;
						else detectionWiseCount[key] = { Entry: isNaN(value) ? 0 : value, Exit: 0 };
					}
				}
			}

			const exitKey = `${ele.Location_ID}_${ele.ZoneID}_Exit`;
			if (Object.hasOwn(data, exitKey)) {
				for (const [key, value] of Object.entries(data[exitKey])) {
					if (key !== "zoneID" && key !== "classLabel" && key !== "deviceID" && key !== "direction" && key !== "timestamp" && key !== "Numberplate")
						if (Object.hasOwn(detectionWiseCount, key)) detectionWiseCount[key]["Exit"] += isNaN(value) ? 0 : value;
						else detectionWiseCount[key] = { Entry: 0, Exit: isNaN(value) ? 0 : value };
				}
			}
		});

		let report = [];
		for (const [key, value] of Object.entries(detectionWiseCount)) {
			report.push({ Detection: key, Entry: value["Entry"], Exit: value["Exit"] });
		}
		const sortedReport = report.sort((a, b) => a.Detection > b.Detection);
		setDailyTrafficReport(sortedReport);
		setLoading(false);
	}

	// for daily traffic
	useEffect(() => {
		if (selectedClient?.id && selectedLocation?.id && date && !dataLoading && devices.length) {
			setLoading(true);
			DailyTrafficReport();
		}
	}, [selectedClient?.id, selectedLocation?.id, dataLoading, devices, date]);

	// if (loading) return <Loading />;

	return (
		<div className="ml-6">
			<h1 className="text-sky-900 text-2xl font-bold mr-3">Daily traffic</h1>
			<div className="flex flex-col gap-3 my-1">
				<div className="flex flex-row justify-between items-start">
					{/* Date */}
					<div className="flex flex-row gap-8">
						<h1 className="text-black text-lg">Date:</h1>
						<input
							className="bg-gray-100 rounded text-black w-32"
							id="datepicker"
							type="date"
							placeholder="Pick Date"
							value={date}
							onChange={(e) => setDate(e.target.value)}
							max={formateDate(new Date())}
						/>
					</div>
					<div className="flex flex-row gap-3">
						{/* {!loading && dailyTrafficReport.length && (
							<div className="relative">
								<input
									type="checkbox"
									id="toggle"
									onChange={(e) => setCharts(e.target.checked)}
									checked={charts}
									className="peer appearance-none bg-slate-200 hover:bg-slate-400 hover:cursor-pointer active:bg-blue-500 checked:bg-blue-500 h-[2.5rem] w-36"
								/>
								<label htmlFor="toggle" className="peer-checked:text-white absolute z-[1] top-2 left-7 text-gray-700">
									Show {charts ? "Data" : "Charts"}
								</label>
							</div>
						)} */}
						{!loading && dailyTrafficReport.length && (
							<div className="flex flex-row mr-3 gap-3 items-center bg-slate-200 p-1 rounded">
								<span className="text-black">Data</span>
								<input type="checkbox" className="toggle toggle-accent" onChange={(e) => setCharts(e.target.checked)} checked={charts} />
								<span className="text-black">Chart</span>
							</div>
						)}
						{!loading && dailyTrafficReport.length && (
							<CSVLink
								data={dailyTrafficReport}
								filename={`${new Date(date).toLocaleDateString("en-us", {
									year: "numeric",
									month: "short",
									day: "numeric",
								})} Daily Traffic Report.csv`}
								className="mr-6 bg-green-500 hover:bg-green-900 hover:border-green-300 px-2 py-1 active:border-2 active:border-green-900 w-36 text-center text-white font-semibold h-[2.5rem]   border-2 border-green-800"
							>
								Download
							</CSVLink>
						)}
					</div>
				</div>

				{charts ? (
					<>{loading ? <Loading /> : <DailyEntryExitByVehicle dailyTrafficReport={dailyTrafficReport}></DailyEntryExitByVehicle>}</>
				) : (
					<>
						{loading ? (
							<Loading />
						) : dailyTrafficReport.length ? (
							<div className="flex flex-col gap-7 mr-6 overflow-x-auto overflow-y-auto max-h-[70vh]">
								<table className="text-center self-center w-full border-t-[3px] border-t-black border-b-2 border-b-black">
									<thead className="sticky top-0 bg-gray-100">
										<tr className="border-b text-black">
											<th className="p-3  max-w-[200] xl:text-base text-sm">Detection</th>
											<th className="p-3  max-w-[200] xl:text-base text-sm">Entry</th>
											<th className="p-3  max-w-[200] xl:text-base text-sm">Exit</th>
										</tr>
									</thead>
									<tbody className="bg-sky-100">
										{dailyTrafficReport.map((ele) => (
											<tr key={ele.Detection} className="border-b text-black">
												<td className="p-3 max-w-[80] xl:text-base text-sm">{ele.Detection}</td>
												<td className="p-3 max-w-[80] xl:text-base text-sm">{ele.Entry}</td>
												<td className="p-3 max-w-[80] xl:text-base text-sm">{ele.Exit}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						) : (
							<div className="flex flex-row justify-around my-6">
								<div className="alert alert-info px-20 w-fit">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6">
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
										></path>
									</svg>
									<span>No Data for {date} is available yet !</span>
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default DailyTraffic;
