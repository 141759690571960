import { deleteField, doc, updateDoc } from "firebase/firestore";
import React from "react";
import { toast } from "react-hot-toast";
import { db } from "../../../../../../../firebase/firebase.config";

const DeleteEntryModal = ({
	alertProfileID,
	dataToDelete,
	setAuthorizedEntryCount,
	authorizedExitCount,
	setAuthorizedExitCount,
	setDeleteEntryModal,
}) => {
	const deleteFromDB = async () => {
		// Entry
		const thresholdEntryRef = doc(db, `AuthorizedCountCollection/${alertProfileID}/Threshold`, "EntryCount");
		let vehicle = dataToDelete[0];
		let obj = {};
		obj[vehicle] = deleteField();
		await updateDoc(thresholdEntryRef, {
			...obj,
		});
		setAuthorizedEntryCount((prev) => {
			const copy = { ...prev };

			delete copy[vehicle];

			return copy;
		});

		// Exit
		const thresholdExitRef = doc(db, `AuthorizedCountCollection/${alertProfileID}/Threshold`, "ExitCount");
		obj = {};
		obj[vehicle] = deleteField();
		await updateDoc(thresholdExitRef, {
			...obj,
		});
		setAuthorizedExitCount((prev) => {
			const copy = { ...prev };

			delete copy[vehicle];

			return copy;
		});

		setDeleteEntryModal(false);
		toast.success("Deleted successfully");
	};

	const handleDelete = () => {
		deleteFromDB();
	};

	return (
		<div>
			<input type="checkbox" id="deleteEntry-modal" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box relative rounded-none bg-white w-[30rem]">
					<label
						onClick={() => setDeleteEntryModal(false)}
						htmlFor="deleteEntry-modal"
						className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]"
					>
						✕
					</label>
					<h3 className="text-2xl text-center font-bold mb-7 text-black">Delete {dataToDelete[0]}</h3>

					<div className="alert alert-warning my-3">
						<svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
							/>
						</svg>
						<span>Warning: Once deleted data cannot be retrieved!</span>
					</div>

					<div className="form-control gap-3 mb-4 ml-14 text-black w-80">
						<div className="flex flex-row justify-between">
							<label className="w-32" htmlFor="entryCount">
								Entry Count
							</label>
							<input
								className="bg-gray-100 pl-3"
								id="entryCount"
								name="entryCount"
								type="number"
								placeholder="Enter Number"
								defaultValue={dataToDelete[1]}
								readOnly
								required
							/>
						</div>

						<div className="flex flex-row justify-between">
							<label className="w-32" htmlFor="exitCount">
								Exit Count
							</label>
							<input
								className="bg-gray-100 pl-3"
								id="exitCount"
								name="exitCount"
								type="number"
								placeholder="Enter Number"
								defaultValue={authorizedExitCount[dataToDelete[0]]}
								readOnly
								required
							/>
						</div>
					</div>

					<button className="btn px-9 mx-auto block bg-[#375075] text-white hover:bg-[#A6A6A6] border-0 rounded-none" onClick={handleDelete}>
						Confirm
					</button>
				</div>
			</div>
		</div>
	);
};

export default DeleteEntryModal;
