export const uniqueArr = (arr) => {
    let uniqueChars = [];
    arr?.forEach((c) => {
        if (c) {
            if (!uniqueChars.includes(c)) {
                uniqueChars.push(c);
            }
        }
    });
    return uniqueChars.sort();
}