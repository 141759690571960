import React, { useEffect, useState } from 'react';
import Loading from '../../../../components/Loading/Loading';
import { toast } from 'react-hot-toast';
import { db } from '../../../../firebase/firebase.config';
import { collection, deleteDoc, doc, onSnapshot, query } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowDropleftCircle } from 'react-icons/io';
import AlMonkLogo from '../../../../components/AlMonkLogo';
import DeviceCameraTableRow from './DeviceCameraTableRow';
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';
import AddDeviceCamera from './AddDeviceCamera';
import EditDeviceCamera from './EditDeviceCamera';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import useFirestoreDocument from '../../../../hooks/useFirestoreDocument';

const DeviceCamera = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const clientId = location.state?.clientId;
    const Location_ID = location.state?.Location_ID;
    const Zone_ID = location.state?.Zone_ID;
    const deviceId = location.state?.deviceId;
    const ZoneName = location.state?.ZoneName;
    const [closeModal, setCloseModal] = useState(false);
    const [aCamera, setACamera] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [deviceCameraData, setDeviceCameraData] = useState([]);
    const [useCase, setUseCase] = useState([]);
    const [clientDoc, clientLoading] = useFirestoreDocument('clients-data-collection', clientId);
    const [breadcrumbsValues, setBreadcrumbsValues] = useState({
        clientId: '',
        locationId: '',
        zoneId: '',
        deviceId: '',
        values: false,
    });

    //set the client, location & zone value for breadcrumbs
    useEffect(() => {
        setBreadcrumbsValues({
            clientId: clientId,
            locationId: Location_ID,
            zoneId: Zone_ID,
            deviceId: deviceId,
            values: true,
        });
    }, [clientId, Location_ID, Zone_ID, deviceId]);

    // set client Usecase 
    useEffect(() => {
        if (clientDoc && !clientLoading) {
            setUseCase([clientDoc?.UseCase]);
        }
    }, [clientId, clientDoc, clientLoading]);


    // fetch all cameraData from firebase
    useEffect(() => {

        if (deviceId) {
            const unsub = onSnapshot(
                // query(collection(db, `clients-data-collection/${deviceId}/Location`), orderBy('CreatedAt', 'desc'))

                query(collection(db, `device-data-collection/${deviceId}/Cameras`)),
                (snapShot) => {
                    let list = [];
                    snapShot.docs.forEach((doc) => {
                        list.push({ id: doc.id, ...doc.data() });
                    });
                    // console.log('list',list);
                    setDeviceCameraData(list);
                    setIsLoading(false);
                },
                (error) => {
                    console.log(error);
                }
            );

            return () => {
                unsub();
            };
        }

    }, [deviceId]);


    //delete Camera
    const handleCameraDelete = async (camera) => {
        //console.log(camera);
        await deleteDoc(doc(db, `device-data-collection/${deviceId}/Cameras`, camera?.id));
        setDeviceCameraData(deviceCameraData.filter((item) => item.id !== camera?.id));
        toast.success(`camera deleted successfully`);
    }

    //modal functions
    const closeDeleteModal = () => {
        setACamera(null);
    }

    //navigate to Devices
    const handleNavigateToDevice = () => {
        navigate(`/dashboard/masteradminclient/masteradmindevice`, {
            state: { clientId, Location_ID, Zone_ID, ZoneName }
        });
    };

    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <div>

            {/* header div */}
            <div className='flex items-center bg-white justify-between pb-2 sticky top-0 z-[2]'>
                <div className='ml-6 mt-1'>
                    {/* breadcrumbs */}
                    <div className='pl-1 mt-3 text-sm text-black'>
                        {
                            breadcrumbsValues?.values && <div className='flex items-center'>
                                <p className='flex items-center font-semibold'>Client <MdOutlineKeyboardArrowRight className='pt-1 text-[19px]' /><span className='font-normal'>{breadcrumbsValues?.clientId}</span></p>
                                <p className='flex items-center font-semibold ml-1'>Location <MdOutlineKeyboardArrowRight className='pt-1 text-[19px]' /><span className='font-normal'>{breadcrumbsValues?.locationId}</span></p>
                                <p className='flex items-center font-semibold ml-1'>Zone <MdOutlineKeyboardArrowRight className='pt-1 text-[19px]' /><span className='font-normal'>{breadcrumbsValues?.zoneId}</span></p>
                                <p className='flex items-center font-semibold ml-1'>Device <MdOutlineKeyboardArrowRight className='pt-1 text-[19px]' /><span className='font-normal'>{breadcrumbsValues?.deviceId}</span></p>
                            </div>
                        }
                    </div>
                    <div className="flex items-center mt-3">
                        {/* <Link to={`/dashboard/masteradminclient`}>
                            <IoIosArrowDropleftCircle className='text-3xl mr-2 text-[#375075]'></IoIosArrowDropleftCircle>
                        </Link> */}
                        <button
                            onClick={handleNavigateToDevice}
                            className='text-3xl mr-2 text-[#375075]'
                        >
                            <IoIosArrowDropleftCircle className='text-[#375075]' />
                        </button>

                        <h1 className='font-bold text-3xl text-start'>Camera Management</h1>
                    </div>
                </div>

                <div className='flex items-center justify-end'>

                    <div className='ml-5'>
                        <AlMonkLogo />
                    </div>
                </div>
            </div>

            <div className='w-full pr-10 flex items-center justify-end'>

                <label htmlFor="addClientLocation-modal" onClick={() => setCloseModal(true)} className='btn w-[150px] mt-4 bg-[#375075] text-white hover:bg-[#A6A6A6] border-0 rounded-none'>Add Camera</label>
            </div>

            {/* Table */}
            {deviceCameraData?.length > 0 ?
                <div className="overflow-x-auto mx-auto custom-table-border mt-5 max-h-[87vh] overflow-y-auto max-w-[65%]">
                    <table className="w-full text-center">
                        <thead className='sticky top-0 border-b'>
                            <tr className='border-b'>
                                <th className='p-3 bg-white max-w-[80px]'>Sl No</th>
                                <th className='p-3 bg-white max-w-[150px]'>CameraID</th>
                                <th className='p-3 bg-white max-w-[150px]'>Camera Name</th>
                                <th className='p-3 bg-white max-w-[150px]'>Camera IP</th>
                                <th className='p-3 bg-white max-w-[150px]'>Usecase</th>
                                <th className='p-3 bg-white max-w-[50px]'></th>
                                <th className='p-3 bg-white max-w-[50px]'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                deviceCameraData?.map((cameraD, i) => <DeviceCameraTableRow
                                    key={cameraD?.id}
                                    i={i}
                                    cameraD={cameraD}
                                    setACamera={setACamera}
                                ></DeviceCameraTableRow>)
                            }

                        </tbody>
                    </table>
                </div>
                : <div className="flex justify-center items-center mx-auto text-black">
                    <h2 className="text-xl mt-5 font-semibold">No camera added yet</h2>
                </div>
            }

            {
                aCamera && <ConfirmModal
                    title={`Delete this Camera?`}
                    message={`If you delete ${aCamera?.CameraName}. It cannot be restore`}
                    successButtonName="Delete"
                    successAction={handleCameraDelete}
                    modalData={aCamera}
                    closeDeleteModal={closeDeleteModal}
                >
                </ConfirmModal>
            }

            {
                aCamera && <EditDeviceCamera
                    aCamera={aCamera}
                    setACamera={setACamera}
                    deviceId={deviceId}
                    useCase={useCase}
                ></EditDeviceCamera>
            }

            {
                closeModal &&
                <AddDeviceCamera
                    setCloseModal={setCloseModal}
                    deviceId={deviceId}
                    useCase={useCase}
                ></AddDeviceCamera>
            }

        </div>
    );
};

export default DeviceCamera;