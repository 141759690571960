import React from 'react';
import BlueBtn from '../../../../components/Buttons/BlueBtn';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase/firebase.config';
import { toast } from 'react-hot-toast';

const EditLocationZone = ({ aZone, setAZone, clientId, Location_ID, useCase }) => {
    const { id, ZoneName } = aZone;

    const handleUpdateZone = (e) => {
        e.preventDefault();
        const form = e.target;
        const zoneName = form.zoneName.value;
        const usecase = form.usecase.value;
        // console.log(zoneName, usecase);

        if ((zoneName.length < 1)) {
            return;
        }
        else {
            UpdateZoneData(zoneName, usecase);
            form.reset();
        }
    }

    const UpdateZoneData = async (zoneName, usecase) => {
        // Update document in collection 
        try {
            const docRef = doc(db, `clients-data-collection/${clientId}/Location/${Location_ID}/Zones`, id);
            const res = await updateDoc(docRef, {
                ZoneName: zoneName,
                UseCase: usecase
            });
            setAZone(null);
            toast.success('Zone data updated');
        } catch (err) {
            console.error(err);
        }
    }


    return (
        <div>
            <input type="checkbox" id="updateZone-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative rounded-none">
                    <label
                        htmlFor="updateZone-modal"
                        className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]">
                        ✕
                    </label>
                    <h3
                        className="text-3xl text-center font-bold mb-7">
                        Update Zone
                    </h3>

                    <form onSubmit={handleUpdateZone}>

                        {/* Zone Name */}
                        <div className="form-control flex mt-3">
                            <input
                                type="text"
                                name='zoneName'
                                placeholder="Zone Name"
                                defaultValue={ZoneName}
                                key={ZoneName}
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* use case */}
                        <div className="form-control mt-3">
                            <select
                                name='usecase'
                                defaultValue={useCase}
                                key={useCase}
                                className="select w-full bg-white border-white border-b-[#D9D9D9] rounded-none" required >
                                {
                                    useCase?.length > 0 ? <>
                                        {
                                            useCase?.map((u, i) => <option
                                                value={u}
                                                key={i}
                                            >{u}</option>)
                                        }
                                    </>
                                        : <option disabled>No usecase available</option>
                                }

                            </select>
                        </div>

                        <div className="form-control mt-3">
                            <BlueBtn>Update</BlueBtn>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    );
};

export default EditLocationZone;