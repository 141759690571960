import React from "react";
import { useState } from "react";

const TimePickerDesktop = ({ time, setTime, setisTimePickerOpen, typeText }) => {
	const [timePickerField, setTimePickerField] = useState(time);

	const handleTimeChange = (field, value) => {
		setTimePickerField((prevTime) => ({
			...prevTime,
			[field]: parseInt(value),
		}));
	};
	const handleOk = () => {
		setTime(timePickerField);
		setisTimePickerOpen(false);
	};

	return (
		<>
			<input type="checkbox" id="time_picker" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box rounded-none w-72">
					<label
						htmlFor="time_picker"
						className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]"
						onClick={() => setisTimePickerOpen(false)}
					>
						✕
					</label>
					<h3 className="font-bold text-lg text-center">Select {typeText} Time</h3>
					<div className="my-6 flex items-center justify-center">
						<div className="mr-4">
							<div className="font-semibold">Hours</div>
							<input
								type="number"
								min="0"
								max="23"
								value={timePickerField.hours}
								onChange={(e) => handleTimeChange("hours", e.target.value)}
								className="w-12 h-10 rounded border border-gray-300 text-center"
							/>
						</div>
						<div className="mr-4">
							<div className="font-semibold">Minutes</div>
							<input
								type="number"
								min="0"
								max="59"
								value={timePickerField.minutes}
								onChange={(e) => handleTimeChange("minutes", e.target.value)}
								className="w-12 h-10 rounded border border-gray-300 text-center"
							/>
						</div>
						<div>
							<div className="font-semibold">Seconds</div>
							<input
								type="number"
								min="0"
								max="59"
								value={timePickerField.seconds}
								onChange={(e) => handleTimeChange("seconds", e.target.value)}
								className="w-12 h-10 rounded border border-gray-300 text-center"
							/>
						</div>
					</div>
					<div className="modal-action">
						<label className="btn btn-success !rounded-none !normal-case" id="time_picker" onClick={handleOk}>
							Ok
						</label>
					</div>
				</div>
			</div>
		</>
	);
};

export default TimePickerDesktop;
