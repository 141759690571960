import { doc, updateDoc } from 'firebase/firestore';
import React from 'react';
import { toast } from 'react-hot-toast';
import BlueBtn from '../../../../components/Buttons/BlueBtn';
import { db } from '../../../../firebase/firebase.config';

const EditDeviceModal = ({
    aDevice,
    setADevice,
    clientId,
    useCase
}) => {
    const { PinCode, DeviceIp, Status, UseCase, id } = aDevice;
    //console.log(DeviceID, Client, Location, PinCode, CameraName, CameraIp, DeviceIp, UseCase, id);

    const handleUpdateDevice = async (e) => {
        e.preventDefault();
        const form = e.target;
        const pinCode = form.pinCode.value;
        const deviceIp = form.deviceIp.value;
        const status = form.status.value;
        const usecase = form.usecase.value;

        //console.log(deviceID, pinCode, deviceIp, usecase);
        updateDeviceData(pinCode, deviceIp, status, usecase);
        form.reset();
    }

    const updateDeviceData = async (pinCode, deviceIp, status, usecase) => {
        // Update document in collection "device-data-collection"
        try {
            const docRef = doc(db, "device-data-collection", id);
            const res = await updateDoc(docRef, {
                PinCode: pinCode,
                DeviceIp: deviceIp,
                Status: status,
                UseCase: usecase
            });
            setADevice(null);
            toast.success('User data updated');
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <div>
            <input type="checkbox" id="updateDevice-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative rounded-none">
                    <label
                        htmlFor="updateDevice-modal"
                        className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]">
                        ✕
                    </label>
                    <h3
                        className="text-3xl text-center font-bold mb-7">
                        Update Device Data
                    </h3>

                    <form onSubmit={handleUpdateDevice}>

                        {/* Pin Code */}
                        <div className="form-control mt-3">
                            <input
                                type="text"
                                name='pinCode'
                                placeholder="Pin Code"
                                defaultValue={PinCode}
                                key={PinCode}
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* Device IP */}
                        <div className="form-control flex mt-3">
                            {/* <label className="label inline"> <span className="label-text">Email</span></label> */}
                            <input
                                type="text"
                                name='deviceIp'
                                placeholder="Device IP"
                                defaultValue={DeviceIp}
                                key={DeviceIp}
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* status */}
                        <div className="form-control mt-3">
                            <select
                                name='status'
                                defaultValue={Status}
                                key={Status}
                                className="select w-full bg-white border-white border-b-[#D9D9D9] rounded-none" required >
                                <option>Up</option>
                                <option>Down</option>
                            </select>
                        </div>

                        {/* use case */}
                        <div className="form-control mt-3">
                            <select
                                name='usecase'
                                defaultValue={UseCase}
                                key={UseCase}
                                className="select w-full bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required >
                                <option>{useCase}</option>
                            </select>
                        </div>

                        <div className="form-control mt-3">
                            <BlueBtn>Confirm</BlueBtn>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    );
};

export default EditDeviceModal;