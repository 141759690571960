function isTimestampDifferenceWithinThreshold(timestamp1, timestamp2) {
	const lastEventTime = fixPingsDateFormat(timestamp2);
	const secondLastEventTime = fixPingsDateFormat(timestamp1);
	const diffResult = isDiffGreaterThan10Seconds(lastEventTime, secondLastEventTime);
	return diffResult;
}

function fixPingsDateFormat(deviceDate) {
	let formattedDeviceDate;
	if (deviceDate) {
		const year = deviceDate.slice(0, 4);
		const month = deviceDate.slice(4, 6);
		const day = deviceDate.slice(6, 8);
		const hours = deviceDate.slice(9, 11);
		const minutes = deviceDate.slice(11, 13);
		const seconds = deviceDate.slice(13, 15);
		const ampm = hours >= 12 ? "pm" : "am";
		formattedDeviceDate = `${year}/${month}/${day} ${hours % 12}:${minutes}:${seconds} ${ampm}`;
	}
	//console.log(formattedDeviceDate);
	return formattedDeviceDate;
}

//If the time difference is less then 6 seconds then the function will return true else return false
function isDiffGreaterThan10Seconds(datetime1, datetime2) {
	const diffInMillis = new Date(datetime1) - new Date(datetime2);
	const diffInSeconds = Math.abs(diffInMillis) / 1000;
	// console.log("diffInSeconds:",diffInSeconds);
	return diffInSeconds > 11 ? true : false;
}

export default isTimestampDifferenceWithinThreshold;
