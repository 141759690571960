import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import { AuthContext } from '../../context/AuthProvider/AuthProvider';
import useGetUserType from '../../hooks/useGetUserType';

const AlertsPageRoute = ({ children }) => {
    const { user, authLoading } = useContext(AuthContext);
    const { userType, userTypeLoading } = useGetUserType(user?.uid);
    const location = useLocation();

    if (userTypeLoading || authLoading) {
        return <Loading></Loading>
    }

    //check user
    if (user &&
        userType?.Role !== "Central Ops User" &&
        userType?.Role !== "Local Ops User" &&
        userType?.Role !== "Security Guard" &&
        userType?.Role !== "Regional User") {
        return children;
    }

    return <Navigate to="/login" state={{ from: location }} replace></Navigate>;

};

export default AlertsPageRoute;