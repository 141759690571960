import React from 'react';
import BlueBtn from '../../../../components/Buttons/BlueBtn';
import { collection, doc, getDocs, limit, orderBy, query, setDoc } from 'firebase/firestore';
import { db } from '../../../../firebase/firebase.config';
import { toast } from 'react-hot-toast';
import { newClientLocationIdGen } from '../../../../Utility/IdGenerator';

const AddClientLocation = ({ setCloseModal, clientId, useCase }) => {

    const handleAddClientLocation = e => {
        e.preventDefault();
        const form = e.target;
        const locationName = form.locationName.value;
        const address = form.address.value;
        const pinCode = form.pinCode.value;
        const usecase = form.usecase.value;

        //console.log(clocationName, address, pinCode, usecase);

        if ((locationName.length < 1) || (address.length < 1) || (pinCode.length < 1)) {
            return;
        }
        else {
            addClientLocationDataToDB(locationName, address, pinCode, usecase);
            form.reset();
        }
    }

    const addClientLocationDataToDB = async (locationName, address, pinCode, usecase) => {
        // Add a new document in collection "clients-data-collection"
        try {
            const collectionRef = collection(db, `clients-data-collection/${clientId}/Location`);
            const q = query(collectionRef, orderBy("CreatedAt", "desc"), limit(1));
            const querySnapshot = await getDocs(q);
            let lastLocation = '';
            querySnapshot.forEach((doc) => {
                // console.log(doc.id, " => ", doc.data());
                lastLocation = doc.data();
            });
            //console.log(lastLocation);
            if (!lastLocation) {
                const docID = 'LOC0001';
                const res = await setDoc(doc(db, `clients-data-collection/${clientId}/Location`, docID), {
                    Location_ID: docID,
                    Name: locationName,
                    Address: address,
                    Pincode: pinCode,
                    UseCase: usecase,
                    CreatedAt: Date.now()
                });
                //console.log('location id: ', res.id);
                setCloseModal(false);
                toast.success('Location added successfully');

            }
            else {

                // console.log('res', newClientLocationIdGen(lastLocation.Location_ID));
                const docID = newClientLocationIdGen(lastLocation?.Location_ID);

                const res = await setDoc(doc(db, `clients-data-collection/${clientId}/Location`, docID), {
                    Location_ID: docID,
                    Name: locationName,
                    Address: address,
                    Pincode: pinCode,
                    UseCase: usecase,
                    CreatedAt: Date.now()
                });
                // console.log('location id: ', res.id);
                setCloseModal(false);
                toast.success('Location added successfully');
            }

        } catch (err) {
            console.error('error', err);
        }
    }

    return (
        <div>
            <input type="checkbox" id="addClientLocation-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative rounded-none">
                    <label
                        htmlFor="addClientLocation-modal"
                        className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]">
                        ✕
                    </label>
                    <h3
                        className="text-3xl text-center font-bold mb-7">
                        Add Location
                    </h3>

                    <form onSubmit={handleAddClientLocation}>

                        {/* Location Name */}
                        <div className="form-control flex mt-3">
                            <input
                                type="text"
                                name='locationName'
                                placeholder="Location"
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* Address */}
                        <div className="form-control flex mt-3">
                            <input
                                type="text"
                                name='address'
                                placeholder="Address"
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* Pin Code */}
                        <div className="form-control mt-3">
                            <input
                                type="text"
                                name='pinCode'
                                placeholder="Pin Code"
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* use case */}
                        <div className="form-control mt-3">
                            <select
                                name='usecase'
                                className="select w-full bg-white border-white border-b-[#D9D9D9] rounded-none" required >
                                {
                                    useCase?.length > 0 ? <>
                                        {
                                            useCase?.map((u, i) => <option
                                                value={u}
                                                key={i}
                                            >{u}</option>)
                                        }
                                    </>
                                        : <option disabled>No usecase available</option>
                                }

                            </select>
                        </div>

                        <div className="form-control mt-3">
                            <BlueBtn>Confirm</BlueBtn>
                        </div>
                    </form>


                </div>
            </div>
        </div>
    );
};

export default AddClientLocation;