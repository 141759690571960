import React, { useContext, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaUserCog, FaCarSide, FaUserCircle, FaPlus } from "react-icons/fa";
import { MdAddAlert, MdBusAlert, MdLogout, MdPerson } from "react-icons/md";
import { HiBellAlert } from "react-icons/hi2";
import { BiMenuAltLeft } from "react-icons/bi";
// import { BsCarFrontFill } from "react-icons/bs";
import { TbLayoutGridAdd, TbReport } from "react-icons/tb";
import { AuthContext } from "../context/AuthProvider/AuthProvider";
import "../Pages/Dashboard/Dashboard.css";
import useAdmin from "../hooks/useAdmin";
import useMasterAdmin from "../hooks/useMasterAdmin";
import { toast } from "react-hot-toast";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { DataContext } from "../context/DataProvider/DataProvider";
import useGetUserType from "../hooks/useGetUserType";
import logo from "../images/gkicononly.png";

const DashboardLayout = () => {
  const { user, logOut } = useContext(AuthContext);
  const { setUserClientAndLocation } = useContext(DataContext);
  const { isAdmin } = useAdmin(user?.email);
  const [isExpand, setIsExpand] = useState(false);
  const [isMasterAdmin] = useMasterAdmin(user?.email);
  const { userType, userTypeLoading } = useGetUserType(user?.uid);
  const firstLetterOfEmail = user.email.toUpperCase().charAt(0);

  //Logout
  const handleLogOut = () => {
    //reset the user client and location info
    setUserClientAndLocation({
      userClientID: "",
      userClientName: "",
      userLocation: "",
    });

    logOut()
      .then(() => {
        toast.success("logout successful");
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="bg-white">
      {/* navbar toggle */}
      <div className="drawer drawer-mobile">
        <input id="dashboard-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
          <Outlet></Outlet>
          <label htmlFor="dashboard-drawer" className="text-3xl drawer-button mr-2 p-2 my-3 fixed top-0 left-0 z-[10] bg-white lg:hidden">
            <BiMenuAltLeft />
          </label>
        </div>

        {/* side navbar */}
        <div className="drawer-side">
          <label htmlFor="dashboard-drawer" className="drawer-overlay"></label>
          <ul className={`${isExpand ? "custom-menu-ul" : ""} w-[80px] menu bg-[#375075] flex flex-col justify-between lg:min-h-[100vh] py-3 px-1`}>
            {/* side navbar options */}

            <div>
              <li className="p-0 my-0">
                <img src={logo} className="h-18 w-20" onClick={() => setIsExpand(!isExpand)} alt="gklo" />
              </li>

              {/* Others user options */}
              {!isMasterAdmin && !isAdmin && (
                <>
                  <li>
                    <Link to="/dashboard" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                      <FaCarSide className="text-[28px] w-[30px] text-[#D9D9D9] hover:text-[#ff847c]" />
                      <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Events</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/dashboard/entryDashboard" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                      <TbLayoutGridAdd className="text-[28px] w-[30px] pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                      <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Records</span>
                    </Link>
                  </li>
                  {userType.Role !== "Central Ops User" && userType.Role !== "Local Ops User" && userType.Role !== "Security Guard" && userType.Role !== "Regional User" && (
                    <li>
                      <Link to="/dashboard/alerts" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                        <HiBellAlert className="text-[27px] w-[30px] lg:pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                        <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Alerts</span>
                      </Link>
                    </li>
                  )}
                  {userType.Role !== "Security Guard" && (
                    <>
                      <li>
                        <Link to="/dashboard/alertconfig" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                          <MdAddAlert className="text-[28px] w-[30px] lg:pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                          <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Configuration</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/dashboard/reports" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                          <TbReport className="text-[28px] w-[30px] lg:pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                          <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Reports</span>
                        </Link>
                      </li>
                    </>
                  )}
                </>
              )}

              {/* Admin options */}
              {isAdmin && (
                <div>
                  <li>
                    <Link to="/dashboard" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                      <FaCarSide className="text-[28px] w-[30px] lg:pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                      <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Events</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/entryDashboard" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                      <TbLayoutGridAdd className="text-[28px] w-[30px] pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                      <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Records</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/admincontrol" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                      <FaUserCog className="text-[28px] w-[30px] lg:pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                      <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Users</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/alerts" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                      <HiBellAlert className="text-[27px] w-[30px] lg:pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                      <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Alerts</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/alertconfig" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                      <MdAddAlert className="text-[28px] w-[30px] lg:pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                      <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Configuration</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/dashboard/reports" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                      <TbReport className="text-[28px] w-[30px] lg:pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                      <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Reports</span>
                    </Link>
                  </li>
                </div>
              )}

              {/* Master Admin options */}
              {isMasterAdmin && (
                <div>
                  <li>
                    <Link to="/dashboard" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                      <FaCarSide className="text-[28px] w-[30px] pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                      <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Events</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/entryDashboard" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                      <TbLayoutGridAdd className="text-[28px] w-[30px] pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                      <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Records</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/admincontrol" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                      <FaUserCog className="text-[28px] w-[30px] lg:pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                      <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Users</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/masteradminclient" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                      <FaUserCircle className="text-[28px] w-[30px] lg:pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                      <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Clients</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/masteradmindevicemonitor" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                      <MdBusAlert className="text-[28px] w-[30px] lg:pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                      <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Monitoring</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/alerts" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                      <HiBellAlert className="text-[27px] w-[30px] lg:pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                      <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Alerts</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/alertconfig" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                      <MdAddAlert className="text-[28px] w-[30px] lg:pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                      <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Configuration</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/reports" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                      <TbReport className="text-[28px] w-[30px] lg:pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                      <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Reports</span>
                    </Link>
                  </li>
                </div>
              )}

              {/* Others user options */}

              {userType.Role === "Security Guard" && (
                <>
                  <li>
                    <Link to="/dashboard/createEntry" className={`flex items-center ${isExpand ? "" : "justify-center"}`}>
                      <FaPlus className="text-[28px] w-[30px] lg:pt-0 text-[#D9D9D9] hover:text-[#ff847c]" />
                      <span className={`${isExpand ? "custom-hover-span" : ""} hidden text-[14px] w-full text-[#D9D9D9] hover:text-[#ff847c] font-semibold`}>Create Entry</span>
                    </Link>
                  </li>
                </>
              )}
            </div>

			
            <li>
              <div className="flex flex-col items-start">
                <div className="flex flex-col">
                  <MdPerson className="h-10 w-10 bg-black rounded-xl" />
				  <div className="">
			  		<Tippy content={user?.displayName}>
					  <p className={`text-lg break-words text-white font-lg ${!isExpand && "hidden"}`}>{String(user?.displayName)?.slice(0, 12)+".."} </p>
					</Tippy>
				  </div>
                </div>
                <MdLogout onClick={handleLogOut} className={`bg-red-600 ${!isExpand ? "" : "w-full"} rounded-lg` + `${isExpand ? "" : "mx-auto"} text-4xl lg:pb-1 text-[#D9D9D9] hover:text-[#ff847c]`} />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
