import React, { useState } from "react";

const EditModal = ({ purposeEntryIndexClicked, purposeList, setPurposeList, setEditModalStatus }) => {
	const [editedPurpose, setEditedPurpose] = useState(purposeList[purposeEntryIndexClicked]);
	const handleEdit = () => {
		let updatedPurposeList = [...purposeList];
		updatedPurposeList[purposeEntryIndexClicked] = editedPurpose;
		setPurposeList(updatedPurposeList);
	};

	return (
		<>
			<input type="checkbox" id="edit_purpose_modal" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box !rounded-none h-fit overflow-visible bg-white">
					<h3 className="font-bold text-lg text-black">Edit Date</h3>
					<textarea className="mt-2 w-full" value={editedPurpose} onChange={(e) => setEditedPurpose(e.target.value)} />

					<div className="modal-action">
						<label
							className="btn btn-success !rounded-none"
							htmlFor="edit_purpose_modal"
							onClick={() => {
								handleEdit();
								setEditModalStatus(false);
							}}
						>
							Confirm
						</label>
						<label htmlFor="edit_purpose_modal" className="btn btn-active btn-neutral !rounded-none" onClick={() => setEditModalStatus(false)}>
							Close
						</label>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditModal;
