import React from 'react';
import Loading from '../../components/Loading/Loading';
import { useState } from 'react';
import { fixDateFormate } from '../../Utility/fixDateFormat';
import { useEffect } from 'react';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase/firebase.config';

const EntryListCard = ({ entryEvent, selectedEntryVehicle, handleSelectedEntryVehicle, setIsLoading, isLoading }) => {
    const { Location, Detection, id, Action, Status, Timestamp, Exception } = entryEvent || {};

    //receive formated date from fixDateFormat() funtion
    const [formatedDate, formatedTime] = fixDateFormate(Timestamp);

    const textContent = <>

        <div className="">

            <div className="flex justify-between items-center">
                <p className='font-medium text-start'>{Location}</p>

                <div className="flex font-medium text-xs text-end">
                    <p className='ml-2'>{formatedTime}</p>
                </div>
            </div>

            <p className='text-start'>
                {
                    (Action)
                        ? `${Action} detected`
                        : "No detection found"
                }
            </p>

            <div className="flex justify-between">
                <p className='text-start'>
                    {
                        Detection ?
                            `Detection: ${Detection}`
                            : 'Detection: None'
                    }
                </p>

                <p className='text-end font-bold'>
                    {Status}
                </p>
            </div>
        </div>
    </>

    if (isLoading) {
        return <Loading></Loading>
    }

    return (

        <div onClick={() => handleSelectedEntryVehicle(entryEvent)} className='text-black'>


            {
               ["Exception", "Handled"]?.includes(Exception) ? (
                <>
                  <div className={entryEvent?.id === id ? `py-1 px-5 ${Exception === "Exception" ? "bg-red-400" : "bg-green-400"} border-2 border-gray-800 hover:border-yellow-400` : `py-1 px-5 ${Exception === "Exception" ? "bg-red-400" : "bg-green-400"} hover:border-yellow-400`}>{textContent}</div>
                </>
                )
                :
               ( (Status === 'Pending') ?
                    (

                        <>
                            <div className={selectedEntryVehicle?.id === id
                                ? 'py-1 px-5 bg-orange-500 text-white border-4 border-yellow-400'
                                : 'py-1 px-5 bg-orange-400 border-4 hover:bg-orange-500 hover:border-yellow-400 border-orange-400'}>
                                {textContent}
                            </div>
                        </>
                    )
                    : (
                        (Status === 'Approved') ?
                            (
                                <>
                                    <div className={selectedEntryVehicle?.id === id
                                        ? 'py-1 px-5 bg-green-600 text-white border-4 border-yellow-400'
                                        : 'py-1 px-5 bg-green-500 hover:bg-green-600 hover:text-white hover:border-yellow-400 border-4 border-green-500'}>
                                        {textContent}
                                    </div>
                                </>
                            )
                            : (
                                (Status === 'Update') ?
                                    (
                                        <>
                                            <div className={selectedEntryVehicle?.id === id
                                                ? 'py-1 px-5 bg-red-700 text-white border-4 border-yellow-400'
                                                : 'py-1 px-5 bg-red-600 hover:bg-red-700 hover:text-white border-4 hover:border-yellow-400 border-red-600'}>
                                                {textContent}
                                            </div>
                                        </>
                                    )
                                    : (
                                        (Status === 'Rejected') ?
                                            (
                                                <>
                                                    <div className={selectedEntryVehicle?.id === id
                                                        ? 'py-1 px-5 bg-black text-white border-4 border-yellow-400'
                                                        : 'py-1 px-5 bg-black text-white border-4 hover:border-yellow-400 border-black'}>
                                                        {textContent}
                                                    </div>
                                                </>
                                            )
                                            : (
                                                <>
                                                    <div className={selectedEntryVehicle?.id === id
                                                        ? 'py-1 px-5 bg-[#A6A6A6] text-white border border-[#A6A6A6]'
                                                        : 'py-1 px-5 bg-[#D9D9D9] hover:bg-[#A6A6A6] hover:text-white border border-[#A6A6A6]'}>
                                                        {textContent}
                                                    </div>
                                                </>
                                            )
                                    )
                            )
                    ))
            }

        </div>
    );
};

export default EntryListCard;