import React from 'react';
import logo from '../images/gkicononly.png';

const AlMonkLogo = () => {
    return (
        <div>
            <div className='flex w-[200px] items-center'>
                <a href="https://www.aimlmonks.com/" target="_blank" rel="noreferrer">
                    <img src={logo} alt="logo" className='h-16'/>
                </a>
                <p className='text-black text-sm uppercase font-bold p-0 m-0' style={{whiteSpace:'nowrap'}}>Gate Keeper</p>
            </div>
        </div>
    );
};

export default AlMonkLogo;