import React from "react";
import { useState } from "react";

const AddModal = ({ setPurposeList, setAddModalStatus }) => {
	const [newPurpose, setNewPurpose] = useState("");
	const handleAdd = () => {
		setPurposeList((prev) => [...prev, newPurpose]);
	};
	return (
		<>
			<input type="checkbox" id="add_purpose_modal" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box !rounded-none h-fit overflow-visible bg-white">
					<h3 className="font-bold text-lg text-black">Add New Purpose</h3>
					{/* <input type="text" className="mt-2 w-full" value={newPurpose} onChange={(e) => setNewPurpose(e.target.value)} /> */}
					<textarea className="mt-2 w-full" value={newPurpose} onChange={(e) => setNewPurpose(e.target.value)} />
					<div className="modal-action">
						<button className="btn btn-accent !rounded-none" onClick={handleAdd}>
							Add
						</button>
						<label htmlFor="add_purpose_modal" className="btn btn-active btn-neutral !rounded-none" onClick={() => setAddModalStatus(false)}>
							Close
						</label>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddModal;
