import React from "react";
import Loading from "./Loading/Loading";
import TagEventModalDisplayCard from "./TagEventModalDisplayCard";

const TagEventModal = ({ loading, setTagEventModal, selectedEventsID, setSelectedEventsID, eventsData }) => {
	if (loading) return <Loading />;
	return (
		<>
			<input type="checkbox" id="tag-event-modal" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box bg-white relative !pt-0 text-black w-[400px] overflow-y-scroll">
					<div className="sticky py-1 z-20 top-0 bg-inherit">
						<label
							htmlFor="tag-event-modal"
							onClick={() => setTagEventModal(false)}
							className="absolute right-2 top-2 btn btn-sm btn-outline btn-error btn-circle"
						>
							✕
						</label>

						<h3 className="text-3xl text-center font-medium mb-2">Tag Events</h3>
					</div>
					<div className="flex flex-col gap-1">
						{eventsData.map((event) => (
							<TagEventModalDisplayCard key={event.id} event={event} selectedEventsID={selectedEventsID} setSelectedEventsID={setSelectedEventsID} />
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default TagEventModal;
