import { doc, updateDoc } from 'firebase/firestore';
import React, { useContext } from 'react';
import { useState } from 'react';
import { db } from '../../firebase/firebase.config';
import toast from 'react-hot-toast';
import { AuthContext } from '../../context/AuthProvider/AuthProvider';
import { getCurrentDate, getCurrentTime } from '../../Utility/FixTodaysDate';

const EventApproveModal = ({ setIsApprove, selectedVehicle, gate, priorityAlertInfo }) => {
    const { Location, id, Date, Direction, Class_label } = selectedVehicle || {};
    const clientId = selectedVehicle?.["Client-ID"];
    const [isApproved, setIsApproved] = useState(false);
    const [isRejected, setIsRejected] = useState(false);
    const { user } = useContext(AuthContext);

    async function handleSubmit(e) {
        e.preventDefault();
        const form = e.target;
        const remarks = form.remarks.value;

        if (remarks?.length > 0 && isApproved) {
            const eventStatus = 'Approved';
            // console.log("Approved:", eventStatus);

            updateEventData(eventStatus, remarks);
            if (priorityAlertInfo?.EntryID) {
                updateEntryData(eventStatus, remarks);
            }
            setIsApproved(false);

        } else if (remarks?.length > 0 && isRejected) {
            const eventStatus = 'Rejected';
            // console.log("Rejected:", eventStatus);

            updateEventData(eventStatus, remarks);
            if (priorityAlertInfo?.EntryID) {
                updateEntryData(eventStatus, remarks);
            }
            setIsRejected(false);

        } else {
            toast.error("Please provide a remarks");
            return;
        }
        form.reset();
    }

    async function updateEventData(eventStatus, remarks) {

        // console.log("updateEventData:", eventStatus, remarks, `${clientId}-priority-${Date}`, id);
        if (eventStatus && remarks) {
            try {
                // CD0001-priority-20231207
                const docRef = doc(db, `${clientId}-priority-${Date}`, id);
                const res = await updateDoc(docRef, {
                    Status: eventStatus,
                    ApprovalRemarks: remarks,
                    ApproverUserID: user?.uid,
                    ApproverUserName: user?.displayName,
                    ApproveDate: getCurrentDate(),
                    ApproveTime: getCurrentTime(),
                });
                // console.log(res);
                // toast.success('priority data updated');
                setIsApprove(false);
            } catch (err) {
                console.error(err);
            }
        }
    }

    async function updateEntryData(eventStatus, remarks) {

        // console.log("updateEntryData:", eventStatus, remarks, `${clientId}-entries-${Date}`, priorityAlertInfo?.EntryID);
        if (eventStatus && remarks) {
            try {
                const docRef = doc(db, `${clientId}-entries-${Date}`, priorityAlertInfo?.EntryID);

                const res = await updateDoc(docRef, {
                    Status: eventStatus,
                    ApprovalRemarks: remarks,
                    ApproverUserID: user?.uid,
                    ApproverUserName: user?.displayName,
                    ApproveDate: getCurrentDate(),
                    ApproveTime: getCurrentTime(),
                });
                toast.success('entries Data updated');
                setIsApprove(false);
            } catch (err) {
                console.error(err);
            }
        }
    }


    return (
        <>
            <input type="checkbox" id="eventApprove-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative rounded-none bg-white">
                    <label
                        htmlFor="eventApprove-modal"
                        className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]">
                        ✕
                    </label>
                    <h3
                        className="text-3xl text-black text-center font-bold mb-7">
                        Approve Event
                    </h3>
                    <p className='text-black text-lg'>Location: {Location}</p>
                    <p className='text-black text-lg'>Gate: {gate}</p>
                    <p className='text-black text-lg'>Direction: {Direction}</p>
                    <p className='text-black text-lg'>Detection: {Class_label}</p>

                    <form onSubmit={handleSubmit}>
                        <textarea
                            className='mx-auto w-full bg-white px-2.5 py-4 border-2 border-gray-300 rounded mt-5 block'
                            name="remarks" required></textarea>
                        <div className='flex justify-center items-center px-5'>
                            <input type="submit" onClick={() => setIsApproved(true)} className='btn w-[170px] bg-green-500 text-white hover:bg-[#A6A6A6] border-0 rounded-none mt-5' value="Approve" />
                            <input type="submit" onClick={() => setIsRejected(true)} className='btn w-[170px] bg-red-500 text-white hover:bg-[#A6A6A6] border-0 rounded-none mt-5 ml-5' value="Reject" />
                        </div>
                    </form>

                </div>
            </div>
        </>
    );
};

export default EventApproveModal;