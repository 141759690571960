import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

const ImagePreviewModal = ({ setFiles, setImagePreviewModal, imageURLForPreview }) => {
	const handleAccept = async () => {
		const response = await fetch(imageURLForPreview);
		const blob = await response.blob();
		const imageFile = new File([blob], blob.name, { type: response.headers.get("content-type") });
		imageFile.url = imageURLForPreview;
		setFiles((prev) => [...prev, imageFile]);
		setImagePreviewModal(false);
	};
	const handleRemove = () => {
		setImagePreviewModal(false);
	};
	return (
		<div className="modal modal-open" role="dialog">
			<div className="modal-box w-fit">
				<h3 className="font-bold text-lg text-center">Preview</h3>
				<img src={imageURLForPreview} alt="selected image" className="my-4 w-[25rem] mx-auto" />
				<div className="flex flex-row justify-between items-center">
					<button className="btn btn-ghost btn-circle group" onClick={handleRemove}>
						<MdCancel className="text-3xl group-hover:text-red-500 group-active:text-red-700" />
					</button>
					<button className="btn btn-ghost group btn-circle" onClick={handleAccept}>
						<FaCheckCircle className="text-2xl group-hover:text-green-500 group-active:text-green-700" />
					</button>
				</div>
				{/* <div className="modal-action">
					<label htmlFor="my_modal_6" className="btn">
						Close!
					</label>
				</div> */}
			</div>
		</div>
	);
};

export default ImagePreviewModal;
