import React from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { MdModeEditOutline } from 'react-icons/md';

const DeviceCameraTableRow = ({ i, cameraD, setACamera }) => {
    const { CameraName, CameraIP, id, Camera_ID, UseCase } = cameraD;
    // console.log(cameraD);
    return (
        <tr key={i} className='border-b'>
            <td className='p-3 break-words max-w-[80px]'>{i + 1}</td>
            <td className='p-3 break-words min-w-[150px]'>{Camera_ID}</td>
            <td className='p-3 break-words min-w-[150px]'>{CameraName}</td>
            <td className='p-3 break-words max-w-[150px]'>{CameraIP}</td>
            <td className='p-3 break-words max-w-[150px]'>{UseCase}</td>

            <td className='p-3 max-w-[50px]'><label onClick={() => {
                setACamera(cameraD);
            }}
                htmlFor="updateCamera-modal" ><MdModeEditOutline className='text-2xl' />
            </label></td>
            <td className='p-3 max-w-[50px]'><label onClick={() => setACamera(cameraD)} htmlFor="confirmation-modal" >
                <FiTrash2 className='text-2xl' /> </label> </td>
        </tr>
    );
};

export default DeviceCameraTableRow;