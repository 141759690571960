import React from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const HourlyEntryExitChart = ({ hourlyReport }) => {
	// console.log(hourlyReport);

	return (
		<div className="mt-7 mb-10 flex justify-center">
			<ResponsiveContainer width="90%" height={400}>
				<LineChart
					width={500}
					height={300}
					data={hourlyReport}
					margin={{
						top: 5,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="Time" />
					<YAxis />
					<Tooltip />
					<Legend />
					<Line type="monotone" dataKey="Entry" stroke="blue" strokeWidth={2} activeDot={{ r: 8 }}></Line>
					<Line type="monotone" dataKey="Exit" stroke="red" strokeWidth={2} activeDot={{ r: 8 }}></Line>
				</LineChart>
			</ResponsiveContainer>
		</div>
	);
};

export default HourlyEntryExitChart;
