import React from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { MdModeEditOutline } from 'react-icons/md';
import { HiOutlineArrowCircleRight } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';

const LocationZoneTableRow = ({ i, zoneD, setAZone, clientId, Location_ID }) => {
    const { id, ZoneName, Zone_ID, UseCase } = zoneD;
    const navigate = useNavigate();

    //navigate to Devices
    const handleNavigateToDevice = () => {
        navigate(`/dashboard/masteradminclient/masteradmindevice`, {
            state: { clientId, Location_ID, Zone_ID, ZoneName }
        });
    };

    return (
        <tr key={i} className='border-b'>
            <td className='p-3 break-words max-w-[80px]'>{i + 1}</td>
            <td className='p-3 break-words min-w-[200px]'>{Zone_ID}</td>
            <td className='p-3 break-words max-w-[200px]'>{ZoneName}</td>
            <td className='p-3 break-words max-w-[200px]'>{UseCase}</td>
            <td className='p-3 break-words max-w-[200px]'>
                <button
                    onClick={handleNavigateToDevice}
                    className='mx-auto p-1 font-bold text-[#375075] flex items-center border border-white hover:border-[#375075]'
                >
                    Devices
                    <HiOutlineArrowCircleRight className='ml-1 text-lg'/>
                </button>
            </td>

            <td className='p-3 max-w-[50px]'><label onClick={() => {
                setAZone(zoneD);
            }}
                htmlFor="updateZone-modal" ><MdModeEditOutline className='text-2xl' />
            </label></td>
            <td className='p-3 max-w-[50px]'><label onClick={() => setAZone(zoneD)} htmlFor="confirmation-modal" >
                <FiTrash2 className='text-2xl' /> </label> </td>
        </tr>
    );
};

export default LocationZoneTableRow;