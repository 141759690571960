import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { DataContext } from "../../../../../../context/DataProvider/DataProvider";
import { useEffect } from "react";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../../../../../../firebase/firebase.config";
import Loading from "../../../../../../components/Loading/Loading";
import AddEntryModal from "./Modals/AddEntryModal";
import EditEntryModal from "./Modals/EditEntryModal";
import DeleteEntryModal from "./Modals/DeleteEntryModal";

const UnauthorizedEntryMain = ({ selectedClient, selectedLocation }) => {
	const { devices, dataLoading } = useContext(DataContext);

	const [zones, setZones] = useState([]);
	const [selectedZone, setSelectedZone] = useState({ Zone: "", ZoneID: "", ClientID: "", Location_ID: "" });

	const [whitelistVehicleData, setWhitelistVehicleData] = useState([]);

	const [detectionIDs, setDetectionIDs] = useState(new Set());

	const [alertProfile, setAlertProfile] = useState("");

	// const [isAlertProfileAdded, setIsAlertProfileAdded] = useState(false);

	// data operations
	const [dataToEdit, setDataToEdit] = useState(null);
	const [dataToDelete, setDataToDelete] = useState(null);

	// modal operations
	const [addEntryModal, setAddEntryModal] = useState(false);
	const [editEntryModal, setEditEntryModal] = useState(false);
	const [deleteEntryModal, setDeleteEntryModal] = useState(false);

	const [isLoading, setIsLoading] = useState(true);

	// console.log("whitelistVehicleData", whitelistVehicleData);

	useEffect(() => {
		if (!dataLoading) {
			let zoneDataArray = devices.filter((ele) => ele.Client === selectedClient && ele.Location === selectedLocation);
			let uniqueZoneDataArray = [...new Map(zoneDataArray.map((item) => [item["Zone"], item])).values()];
			setZones(uniqueZoneDataArray);
			setSelectedZone(uniqueZoneDataArray[0]);
		}
	}, [selectedClient, selectedLocation, devices, dataLoading]);

	useEffect(() => {
		const fetchZoneData = async () => {
			setIsLoading(true);

			const alertProfileString = `${selectedZone.ClientID}-${selectedZone.Location_ID}-UE-${selectedZone.ZoneID}`;
			setAlertProfile(alertProfileString);

			const q = collection(db, `UnauthorizedEntryCollection/${alertProfileString}/Whitelist`);

			let vehicleList = [];
			let ids = new Set();
			const querySnapshot = await getDocs(q);
			querySnapshot.forEach((doc) => {
				// console.log(doc.id, " => ", doc.data());
				vehicleList.push({ id: doc.id, ...doc.data() });
				ids.add(doc.id);
			});
			// console.log("vehicleList", vehicleList);
			setWhitelistVehicleData(vehicleList);
			setDetectionIDs(ids);
			setIsLoading(false);
		};
		if (selectedZone?.Zone) fetchZoneData();
	}, [selectedClient, selectedLocation, selectedZone]);

	if (isLoading) return <Loading />;

	if (!isLoading && !zones.length)
		return (
			<div className="flex flex-row justify-around">
				<div className="alert alert-error w-1/3">
					<svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
						/>
					</svg>
					<span>Unauthorized Entry Alert Configuration is not available for this Location</span>
				</div>
			</div>
		);

	return (
		<div>
			{/* Title */}
			<h1 className="text-sky-900 text-[32px] font-semibold ml-6">Unauthorized Entry</h1>

			<div className="flex flex-row justify-between ml-6 my-5 mr-16">
				<div className="flex flex-row gap-6 items-center">
					<h1 className="text-black text-2xl font-normal">Zone</h1>
					<select
						className="bg-[#F2F5F7] w-[350px] h-[55px] border border-[#2E3192] rounded-[8px] px-3 py-4 text-black text-base font-normal"
						value={selectedZone?.Zone}
						onChange={(e) => setSelectedZone(e.target.value)}
					>
						<option defaultValue disabled>
							Select Zone
						</option>
						{zones.map((zone) => (
							<option key={zone.ZoneID} value={zone.Zone}>
								{zone.Zone}
							</option>
						))}
					</select>
				</div>
				<label
					htmlFor="addEntry-modal"
					onClick={() => setAddEntryModal(true)}
					className="btn bg-[#2E3192] font-bold text-base text-white ml-36 !md:text-sm"
				>
					Add Vehicle
				</label>
			</div>

			<h1 className="ml-6 my-3 text-black text-2xl font-normal">Authorized Vehicles -</h1>

			{/* Table */}
			{whitelistVehicleData.length ? (
				<div className="overflow-x-auto mx-2 mt-5 mb-8 max-h-[70vh] overflow-y-auto flex flex-row justify-around">
					<table className="w-3/4 text-center text-base font-medium border-separate border-spacing-x-0 border-spacing-y-2">
						<thead className="sticky top-0">
							<tr className="text-black">
								<th className="p-3  max-w-[80] lg:text-base text-sm">Sl No</th>
								<th className="p-3  max-w-[200]">Detections</th>
								<th className="p-3  max-w-[200]">Vehicle Number</th>
								<th className="p-3  max-w-[50]"></th>
								<th className="p-3  max-w-[50]"></th>
							</tr>
						</thead>
						<tbody>
							{whitelistVehicleData.map((vehicle, i) => (
								<tr key={vehicle.id} className="h-[65px]">
									<td className="p-3 max-w-[80] text-black border-black border-l border-t border-b rounded-tl-[20px] rounded-bl-[20px] bg-[#FAFAFA]">
										{i + 1}
									</td>
									<td className="p-3 max-w-[200] border-black border-t border-b bg-[#FAFAFA]">{vehicle.VehicleType}</td>
									<td className="p-3 max-w-[200] border-black border-t border-b bg-[#FAFAFA]">{vehicle.VehicleNumber}</td>
									<td className="p-3 max-w-[50] border-black border-t border-b bg-[#FAFAFA]">
										<label
											onClick={() => {
												setDataToEdit(vehicle);
												setEditEntryModal(true);
											}}
											htmlFor="updateEntry-modal"
										>
											<div className="flex flex-row justify-around text-[#0D6EFD]">EDIT</div>
										</label>
									</td>
									<td className="p-3 max-w-[50] border-black border-r border-t border-b rounded-tr-[20px] rounded-br-[20px] bg-[#FAFAFA]">
										<label
											onClick={() => {
												setDataToDelete(vehicle);
												setDeleteEntryModal(true);
											}}
											htmlFor="deleteEntry-modal"
										>
											<div className="flex flex-row justify-around text-[#DC3545]">DELETE</div>
										</label>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : (
				<div className="flex justify-center items-center mx-auto">
					<h2 className="text-xl mt-5 font-semibold">No data added yet</h2>
				</div>
			)}

			{addEntryModal && (
				<AddEntryModal
					alertProfile={alertProfile}
					// isAlertProfileAdded={isAlertProfileAdded}
					selectedClient={selectedClient}
					selectedLocation={selectedLocation}
					selectedZone={selectedZone.Zone}
					detectionIDs={detectionIDs}
					// setIsAlertProfileAdded={setIsAlertProfileAdded}
					setAddEntryModal={setAddEntryModal}
					setWhitelistVehicleData={setWhitelistVehicleData}
				/>
			)}

			{editEntryModal && (
				<EditEntryModal
					alertProfile={alertProfile}
					detectionIDs={detectionIDs}
					setEditEntryModal={setEditEntryModal}
					setWhitelistVehicleData={setWhitelistVehicleData}
					dataToEdit={dataToEdit}
				/>
			)}

			{deleteEntryModal && (
				<DeleteEntryModal
					alertProfile={alertProfile}
					dataToDelete={dataToDelete}
					setWhitelistVehicleData={setWhitelistVehicleData}
					setDeleteEntryModal={setDeleteEntryModal}
				/>
			)}
		</div>
	);
};

export default UnauthorizedEntryMain;
