import { collection, doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase/firebase.config";

const useGetUserType = (userId) => {
	const [userType, setUserType] = useState({ Role: "", Client: "", ClientID: "" });
	const [userTypeLoading, setUserTypeLoading] = useState(true);

	useEffect(() => {
		if (userId) {
			const docRef = doc(collection(db, "userDataCollection"), userId);
			const fetchDocumentData = async () => {
				try {
					const docSnap = await getDoc(docRef);
					if (docSnap.exists()) {
						const data = { id: docSnap.id, ...docSnap.data() };
						const res = { Role: data?.Role, Client: data?.Client, ClientID: data?.ClientID };
						setUserType(res);
					} else {
						setUserType({ Role: "", Client: "", ClientID: "" });
					}
					setUserTypeLoading(false);
				} catch (err) {
					console.log(err);
				}
			};
			fetchDocumentData();
		}

	}, [userId]);
	
	return { userType, userTypeLoading };
};

export default useGetUserType;