import React, { createContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, updateProfile, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import app from '../../firebase/firebase.config';

export const AuthContext = createContext();
const auth = getAuth(app);

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [authLoading, setAuthLoading] = useState(true);

    // update profile info(get userName)
    // const updateUserProfile = (profile) => {
    //     return updateProfile(auth.currentUser, profile);
    // }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, currentUser => {
            //console.log(currentUser);
            setUser(currentUser);
            setAuthLoading(false);
        });

        return () => {
            return unsubscribe();
        }
    }, []);

    //Login
    const logIn = (email, password) => {
        setAuthLoading(true);
        return signInWithEmailAndPassword(auth, email, password);
    }

    //Logout
    const logOut = () => {
        setAuthLoading(true);
        return signOut(auth);
    }

    //Send verification email
    const verifyEmail = () => {
        return sendEmailVerification(auth.currentUser)
    }

    //Set new password(forgot password)
    const resetPassword = (email) => {
        return sendPasswordResetEmail(auth, email);
    }

    const authInfo = {
        user,
        authLoading,
        setAuthLoading,
        logIn,
        resetPassword,
        verifyEmail,
        logOut
    }
    return (
        <div>
            <AuthContext.Provider value={authInfo}>
                {children}
            </AuthContext.Provider>
        </div>
    );
};

export default AuthProvider;