const CreateEntryForm = ({
	selectedEventType,
	setSelectedEventType,
	availableLocations,
	availableZones,
	availableDetections,
	availablePurposes,
	availableRegisteredOrgs,
	selectedLocation,
	setSelectedLocation,
	selectedZone,
	setSelectedZone,
	selectedDetection,
	setSelectedDetection,
	selectedPurpose,
	setSelectedPurpose,
	selectedRegisteredOrg,
	setSelectedRegisteredOrg,
	dateDisplay,
	handleSubmit,
}) => {
	const handleLocationChange = (e) => {
		const selectedLocationId = e.target.options[e.target.selectedIndex].id;
		setSelectedLocation({ id: selectedLocationId, name: e.target.value });
	};
	const handleZoneChange = (e) => {
		const selectedZoneId = e.target.options[e.target.selectedIndex].id;
		setSelectedZone({ id: selectedZoneId, name: e.target.value });
	};
	return (
		<div className="bg-white rounded-lg h-[921px] p-4 overflow-hidden">
			<h1 className="font-semibold text-base">Create Entry</h1>
			<form className="my-1 flex flex-col gap-1 w-full h-fit" id="create-entry-form" onSubmit={handleSubmit}>
				<div className="form-control">
					<div className="label">
						<span className="label-text text-base font-semibold">Event</span>
					</div>
					<select
						name="eventType"
						className="select input-bordered"
						value={selectedEventType}
						onChange={(e) => setSelectedEventType(e.target.value)}
						required
					>
						<option>Entry</option>
						<option>Exit</option>
					</select>
				</div>
				<div className="form-control">
					<div className="label">
						<span className="label-text text-base font-semibold">Location</span>
					</div>
					<select name="location" className="select input-bordered" value={selectedLocation.name} onChange={handleLocationChange} required>
						{availableLocations.map((loc) => (
							<option key={loc.id} id={loc.id} value={loc.name}>
								{loc.name}
							</option>
						))}
					</select>
				</div>
				<div className="form-control">
					<div className="label">
						<span className="label-text  text-base font-semibold">Zone</span>
					</div>
					<select name="zone" className="select input-bordered" value={selectedZone.name} onChange={handleZoneChange} required>
						{availableZones.map((loc) => (
							<option key={loc.id} id={loc.id}>
								{loc.name}
							</option>
						))}
					</select>
				</div>
				<div className="form-control">
					<label className="label">
						<span className="label-text  text-base font-semibold">Time</span>
					</label>
					<input
						className="border input-bordered input disabled"
						type="text"
						name="time"
						placeholder="Date Time"
						defaultValue={dateDisplay}
						readOnly
						disabled={true}
					/>
				</div>
				<div className="form-control">
					<label className="label">
						<span className="label-text  text-base font-semibold">Vehicle</span>
					</label>
					<select
						name="detection"
						className="select input-bordered"
						value={selectedDetection}
						onChange={(e) => setSelectedDetection(e.target.value)}
						required
					>
						{availableDetections.map((detection) => (
							<option key={detection}>{detection}</option>
						))}
					</select>
				</div>
				<div className="form-control">
					<label className="label">
						<span className="label-text  text-base font-semibold">Number</span>
					</label>
					<input className="border input-bordered input" type="text" name="number" placeholder="Enter Vehicle Number" required />
				</div>
				{/* Purpose */}
				<div className="form-control">
					<label className="label">
						<span className="label-text  text-base font-semibold">Purpose</span>
					</label>
					<select
						name="purpose"
						className="select input-bordered"
						value={selectedPurpose}
						onChange={(e) => setSelectedPurpose(e.target.value)}
						required
					>
						{availablePurposes.map((purpose) => (
							<option key={purpose}>{purpose}</option>
						))}
					</select>
				</div>

				{/* Sender */}
				<div className="form-control">
					<label className="label">
						<span className="label-text  text-base font-semibold">Sender</span>
					</label>
					<select
						name="sender"
						className="select input-bordered"
						value={selectedRegisteredOrg}
						onChange={(e) => setSelectedRegisteredOrg(e.target.value)}
						required
					>
						{availableRegisteredOrgs.map((orgs) => (
							<option key={orgs}>{orgs}</option>
						))}
					</select>
				</div>

				{/* Remarks */}
				<div className="form-control">
					<label className="label">
						<span className="label-text  text-base font-semibold">Remarks</span>
					</label>
					<input className="border input-bordered input disabled" type="text" name="remarks" placeholder="Enter Remarks" required />
				</div>
			</form>
		</div>
	);
};

export default CreateEntryForm;
