import React, { useEffect, useState } from "react";
import ListCard from "./ListCard";
import { ImCircleLeft, ImCircleRight } from "react-icons/im";

const EventLists = ({ eventData, selectedVehicle, handleSelectedVehicle, totalPages, setIsLoading, isLoading }) => {
	//Pagination Code
	//after filter currentPage will be reset
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		setCurrentPage(1);
	}, [eventData]);

	// current pages function
	const handleNextClick = () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1);
		}
	};
	const handlePrevClick = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};
	//to desable the arrow buttons
	const preDisabled = currentPage === 1;
	const nextDisabled = currentPage === totalPages;

	//to slice the data according to page
	const itemsPerPage = 20;
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const itemsToDisplay = eventData?.slice(indexOfFirstItem, indexOfLastItem);

	return (
		<div className="lg:w-[36%] pt-3">
			<div className="h-[78vh]">
				<div className="grid grid-cols-1 px-3 max-h-full overflow-auto">
					{itemsToDisplay && itemsToDisplay?.length > 0
						? itemsToDisplay?.map((vehicle) => (
								<ListCard
									key={vehicle?.id}
									vehicle={vehicle}
									selectedVehicle={selectedVehicle}
									handleSelectedVehicle={handleSelectedVehicle}
									setIsLoading={setIsLoading}
									isLoading={isLoading}
								></ListCard>
						  ))
						: ""}
				</div>
			</div>

			{/* Pagination */}
			<div className="text-center px-3 mt-5 mx-auto flex justify-center items-center">
				<button onClick={handlePrevClick} disabled={preDisabled} className="text-[22px] text-[#375075] mx-2">
					<ImCircleLeft />
				</button>

				<span className="text-black">
					{indexOfFirstItem + 1}-{Math.min(indexOfLastItem, eventData?.length)} of {eventData?.length}
				</span>

				<button onClick={handleNextClick} disabled={nextDisabled} className="text-[22px] text-[#375075] mx-2">
					<ImCircleRight />
				</button>
			</div>
		</div>
	);
};

export default EventLists;
