import React from "react";

const DeleteModal = ({ setDeleteModalStatus, purposeEntryIndexClicked, purposeList, setPurposeList }) => {
	const handleDelete = () => {
		const list = [...purposeList];
		const updatedPurposeList = list.filter((ele) => ele !== purposeList[purposeEntryIndexClicked]);
		setPurposeList(updatedPurposeList);
	};
	return (
		<>
			<input type="checkbox" id="delete_purpose_modal" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box h-fit !rounded-none overflow-visible bg-white">
					<h3 className="font-bold text-lg text-black">Delete Entry ?</h3>
					<p className="text-black">Are you sure you want to delete this Entry ?</p>
					<textarea className="mt-2 w-full" defaultValue={purposeList[purposeEntryIndexClicked]} readOnly />
					<div className="modal-action">
						<label
							className="btn btn-success !rounded-none"
							htmlFor="delete_modal"
							onClick={() => {
								handleDelete();
								setDeleteModalStatus(false);
							}}
						>
							Confirm
						</label>
						<label htmlFor="delete_purpose_modal" className="btn btn-active btn-neutral !rounded-none" onClick={() => setDeleteModalStatus(false)}>
							Close
						</label>
					</div>
				</div>
			</div>
		</>
	);
};

export default DeleteModal;
