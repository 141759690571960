import { useState } from "react";
import { db } from "../../../firebase/firebase.config";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import TaggedEventDisplayCard from "../../../components/TaggedEventDisplayCard";
import TagEventModal from "../../../components/TagEventModal";
import { useEffect } from "react";

const TaggedEventsDisplay = ({ queryData, selectedEventsID, setSelectedEventsID, taggedEventVideoURLs, setTaggedEventVideoURLs }) => {
	const { Direction, Date, clientID, locationID, zoneID } = queryData;
	const [tagEventModal, setTagEventModal] = useState(false);
	const [eventsData, setEventsData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [taggedEventsData, setTaggedEventsData] = useState([]);

	useEffect(() => {
		const fetchEvents = () => {
			const collectionRef = collection(db, `${clientID}-detection-events-${Date}`);
			const queryConstraints = [];
			queryConstraints.push(where("Location-ID", "==", locationID));
			queryConstraints.push(where("Zone-ID", "==", zoneID));
			queryConstraints.push(where("Direction", "==", Direction));
			const q = query(collectionRef, ...queryConstraints);
			const unsubscribe = onSnapshot(
				q,
				(querySnapshot) => {
					let list = [];
					querySnapshot.docs.forEach((doc) => {
						list.push({ id: doc.id, ...doc.data() });
					});

					list.sort((a, b) => {
						if (a?.Timestamp < b?.Timestamp) return 1;
						if (b?.Timestamp < a?.Timestamp) return -1;
						return 0;
					});

					setEventsData([...list]);
				},
				(error) => {
					console.log(error);
				}
			);
			setLoading(false);
			return () => {
				unsubscribe();
			};
		};
		if (clientID && locationID && zoneID && Date && Direction) fetchEvents();
	}, [clientID, locationID, zoneID, Date, Direction]);

	useEffect(() => {
		if (eventsData.length && selectedEventsID.length) {
			const taggedData = eventsData.filter((event) => selectedEventsID.includes(event.id));
			setTaggedEventsData(taggedData);
		}
	}, [eventsData, selectedEventsID]);

	return (
		<div className="bg-white rounded-lg h-[921px] p-4 overflow-y-scroll">
			<div className="flex flex-row justify-between items-center mb-5">
				<h1 className="font-semibold text-base">Mapped Events</h1>
				<label className="btn btn-primary" htmlFor="tag-event-modal" onClick={() => setTagEventModal(true)}>
					Map Events
				</label>
			</div>

			{selectedEventsID.length ? (
				<div className="flex flex-col gap-2">
					{taggedEventsData.map((event) => (
						<TaggedEventDisplayCard key={event.id} event={event} />
					))}
				</div>
			) : (
				<div className="bg-[#F2F5F7] h-72 border border-[#2E3192] p-1 flex flex-col rounded-md">
					<h2 className="text-2xl text-black my-auto mx-auto font-semibold">Tag an Event</h2>
				</div>
			)}

			{tagEventModal ? (
				<TagEventModal
					loading={loading}
					setTagEventModal={setTagEventModal}
					selectedEventsID={selectedEventsID}
					setSelectedEventsID={setSelectedEventsID}
					eventsData={eventsData}
					taggedEventVideoURLs={taggedEventVideoURLs}
					setTaggedEventVideoURLs={setTaggedEventVideoURLs}
				/>
			) : (
				<></>
			)}
		</div>
	);
};

export default TaggedEventsDisplay;
