export function newClientIdGen(clientId) {
    const val = clientId.split('D');
    const numberPortion = (Number(val[1]) + 1);
    const numberOfZeros = 4 - numberPortion.toString().length;
    let zeroes = "";
    for (let i = 0; i < numberOfZeros; i++) zeroes += "0";
    return "CD" + zeroes + numberPortion;
}

export function newClientLocationIdGen(clientLocationId) {
    const val = clientLocationId.split('C');
    const numberPortion = (Number(val[1]) + 1);
    const numberOfZeros = 4 - numberPortion.toString().length;
    let zeroes = "";
    for (let i = 0; i < numberOfZeros; i++) zeroes += "0";
    return "LOC" + zeroes + numberPortion;
}

export function newCameraIdGen(cameraId) {
    const val = cameraId.split('M');
    const numberPortion = (Number(val[1]) + 1);
    const numberOfZeros = 4 - numberPortion.toString().length;
    let zeroes = "";
    for (let i = 0; i < numberOfZeros; i++) zeroes += "0";
    return "CAM" + zeroes + numberPortion;
}

export function newZoneIdGen(zoneId) {
    const val = zoneId.split('E');
    const numberPortion = (Number(val[1]) + 1);
    const numberOfZeros = 4 - numberPortion.toString().length;
    let zeroes = "";
    for (let i = 0; i < numberOfZeros; i++) zeroes += "0";
    return "ZONE" + zeroes + numberPortion;
}
