import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { db } from "../../../../../firebase/firebase.config";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../../../../../components/Loading/Loading";
import DailyEntryExitByDay from "../Charts/DailyEntryExitByDay";
import Select from "react-select";
import { DataContext } from "../../../../../context/DataProvider/DataProvider";
import { useContext } from "react";

const formateDate = (d) => {
	let day = d.getDate().toString().padStart(2, "0");
	let month = (d.getMonth() + 1).toString().padStart(2, "0");
	let year = d.getFullYear();
	return `${year}-${month}-${day}`;
};

const formateDateYYYYMMDD = (dateString) => {
	const year = dateString.slice(0, 4);
	const month = dateString.slice(4, 6);
	const day = dateString.slice(6, 8);
	return `${year}-${month}-${day}`;
};

const dateRangeGenerator = function* (start, end, step = 1) {
	let d = start;
	while (d <= end) {
		yield combineDate(d);
		d.setDate(d.getDate() + step);
	}
};

function combineDate(date) {
	const d = new Date(date);
	return d.getFullYear().toString() + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0");
}

// custom styling for react-select
const selectStyles = {
	// menuList: (styles) => {
	// 	// console.log("menuList:", styles);
	// 	return {
	// 		...styles,
	// 		maxHeight: 136,
	// 	};
	// },
	container: (provided) => ({
		...provided,
		minWidth: 300,
		maxWidth: 646,
	}),
};

const DayWiseTraffic = ({ selectedClient, selectedLocation }) => {
	const { devices, dataLoading } = useContext(DataContext);
	const [daywiseReport, setDaywiseReport] = useState([]);

	const [dateRange, setDateRange] = useState([new Date().getTime() - 7 * 24 * 60 * 60 * 1000, new Date()]);
	const [startDate, endDate] = dateRange;

	const [detectionsOptions, setDetectionsOptions] = useState([]);
	const [selectedDetectionsOptions, setSelectedDetectionsOptions] = useState([]);

	const [charts, setCharts] = useState(false);

	const [loading, setLoading] = useState(true);

	async function fetchDayWiseReport() {
		let dayWiseCount = [];

		const start = new Date(startDate);
		const startingDateString = combineDate(start);
		const startingDocID = `${selectedClient.id}-${startingDateString}`;

		const end = new Date(endDate);
		const endingDateString = combineDate(end);
		const endingDocID = `${selectedClient.id}-${endingDateString}`;

		const dateRange = [...dateRangeGenerator(start, end)];
		const datesInDb = new Set();

		const zoneDataArray = devices.filter((ele) => ele.Location_ID === selectedLocation.id && ele.ClientID === selectedClient.id);
		const uniqueZoneDataArray = [...new Map(zoneDataArray.map((item) => [item["ZoneID"], item])).values()];

		const q = query(collection(db, "actualCountCollection"), where("Doc_ID", ">=", startingDocID), where("Doc_ID", "<=", endingDocID));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			const data = doc.data();
			const dateString = data["Doc_ID"].split("-")[1];
			const date = formateDateYYYYMMDD(dateString);
			datesInDb.add(dateString);
			let entryExitCntObj = { Date: date, Entry: 0, Exit: 0 };

			uniqueZoneDataArray.forEach((ele) => {
				const entryKey = `${ele.Location_ID}_${ele.ZoneID}_Entry`;
				if (Object.hasOwn(data, entryKey))
					selectedDetectionsOptions?.forEach((detection) => {
						entryExitCntObj["Entry"] += data[entryKey]?.[detection.value] ? data[entryKey][detection.value] : 0;
					});

				const exitKey = `${ele.Location_ID}_${ele.ZoneID}_Exit`;
				if (Object.hasOwn(data, exitKey))
					selectedDetectionsOptions?.forEach((detection) => {
						entryExitCntObj["Exit"] += data[exitKey]?.[detection.value] ? data[exitKey][detection.value] : 0;
					});
			});
			// if (selectedDetectionsOptions.length)
			// 	selectedDetectionsOptions.forEach((detection) => {
			// 		entryExitCntObj["Entry"] += value?.[detection.value]?.["Entry"] ? value[detection.value]["Entry"] : 0;
			// 		entryExitCntObj["Exit"] += value?.[detection.value]?.["Exit"] ? value[detection.value]["Exit"] : 0;
			// 	});
			// else
			// 	detectionsOptions.forEach((detection) => {
			// 		entryExitCntObj["Entry"] += value?.[detection.value]?.["Entry"] ? value[detection.value]["Entry"] : 0;
			// 		entryExitCntObj["Exit"] += value?.[detection.value]?.["Exit"] ? value[detection.value]["Exit"] : 0;
			// 	});

			dayWiseCount.push(entryExitCntObj);
		});

		// adding dates that were not in db
		dateRange.forEach((ele) => {
			if (!datesInDb.has(ele)) {
				dayWiseCount.push({
					Date: formateDateYYYYMMDD(ele),
					Entry: 0,
					Exit: 0,
				});
			}
		});
		dayWiseCount.sort((a, b) => a["Date"] < b["Date"]);

		setDaywiseReport(dayWiseCount);
		setLoading(false);
	}

	// console.log(detectionsOptions);

	async function fetchDetections() {
		const docRef = doc(db, `clients-data-collection/${selectedClient.id}/Location/${selectedLocation.id}/Detections`, "detection-list");
		const docSnap = await getDoc(docRef);
		let detectionList = docSnap.data()?.Vehicles;
		detectionList = detectionList.filter((detection) => detection !== "Numberplate").sort();
		let options = detectionList.map((detection) => ({ value: detection, label: detection }));
		setDetectionsOptions(options);
		setSelectedDetectionsOptions(options);
	}

	useEffect(() => {
		if (selectedClient.id && selectedLocation.id) {
			fetchDetections();
		}
	}, [selectedClient.id, selectedLocation.id]);

	useEffect(() => {
		if (selectedClient.id && selectedLocation.id && selectedDetectionsOptions.length && startDate && endDate && !dataLoading && devices.length) {
			setLoading(true);
			fetchDayWiseReport();
		}
	}, [selectedClient.id, selectedLocation.id, dataLoading, devices, selectedDetectionsOptions, detectionsOptions, startDate, endDate]);

	const handleDetectionsSelect = (selectedOptions) => {
		setSelectedDetectionsOptions(selectedOptions);
	};

	return (
		<div className="mx-6">
			<h1 className="text-sky-900 text-2xl font-bold mr-3">Daywise traffic</h1>
			<div className="flex flex-col gap-3 my-2">
				<div className="flex flex-row gap-2 xl:justify-between items-center">
					{/* Detection and Date Range */}
					<div className="flex flex-row gap-4">
						{/* Detections */}
						<div className="flex flex-row gap-4 items-center">
							<h1 className="text-black text-base xl:text-lg">Detection:</h1>
							<Select
								className="text-black"
								options={detectionsOptions}
								placeholder={"Select Detections"}
								value={selectedDetectionsOptions}
								onChange={handleDetectionsSelect}
								styles={selectStyles}
								isMulti
							/>
						</div>
						{/* Date Range */}
						<div className="flex flex-row gap-1 items-center">
							<h1 className="text-black text-base xl:text-lg">Date range:</h1>
							<DatePicker
								className="bg-gray-100 text-black w-fit px-5"
								selectsRange={true}
								startDate={startDate}
								endDate={endDate}
								onChange={(update) => {
									setDateRange(update);
								}}
								isClearable={true}
								showIcon
								placeholderText="Select Date range"
								renderInput
							/>
						</div>
					</div>
					{/* Toggle and  Download*/}
					<div className="flex flex-row gap-3">
						{!loading && daywiseReport.length && (
							<div className="flex flex-row mr-3 gap-3 items-center bg-slate-200 p-1 rounded">
								<span className="text-black">Data</span>
								<input type="checkbox" className="toggle toggle-accent" onChange={(e) => setCharts(e.target.checked)} checked={charts} />
								<span className="text-black">Chart</span>
							</div>
						)}
						{/* {!loading && daywiseReport.length && selectedDetectionsOptions.length && (
							<div className="relative">
								<input
									type="checkbox"
									id="toggle"
									onChange={(e) => setCharts(e.target.checked)}
									checked={charts}
									className="peer appearance-none bg-slate-200 hover:bg-slate-400 hover:cursor-pointer active:bg-blue-500 checked:bg-blue-500 h-[2.5rem] w-36"
								/>
								<label htmlFor="toggle" className="peer-checked:text-white absolute z-[1] top-2 left-7 text-gray-700">
									Show {charts ? "Data" : "Charts"}
								</label>
							</div>
						)} */}
						{!loading && daywiseReport.length && selectedDetectionsOptions.length && (
							<CSVLink
								data={daywiseReport}
								filename={"Daywise Report.csv"}
								className="mr-6 bg-green-500 hover:bg-green-900 hover:border-green-300 px-2 py-1 active:border-2 active:border-green-900 w-36 text-center text-white font-semibold h-[2.5rem]   border-2 border-green-800"
							>
								Download
							</CSVLink>
						)}
					</div>
				</div>

				{selectedDetectionsOptions.length === 0 ? (
					<div className="flex flex-row justify-around my-6">
						<div className="alert alert-info px-20 w-fit">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
								></path>
							</svg>
							<span>Select Detections !</span>
						</div>
					</div>
				) : charts ? (
					<>{loading ? <Loading /> : <DailyEntryExitByDay daywiseReport={daywiseReport}></DailyEntryExitByDay>}</>
				) : (
					<>
						{startDate && endDate ? (
							loading ? (
								<Loading />
							) : (
								<div className="flex flex-col gap-7 mr-6 overflow-x-auto overflow-y-auto max-h-[70vh]">
									<table className="text-center self-center w-full border-t-[3px] border-t-black border-b-2 border-b-black">
										<thead className="sticky top-0 bg-gray-100">
											<tr className="border-b text-black">
												<th className="p-3 max-w-[200] xl:text-base text-sm">Date</th>
												<th className="p-3 max-w-[200] xl:text-base text-sm">Entry</th>
												<th className="p-3 max-w-[200] xl:text-base text-sm">Exit</th>
											</tr>
										</thead>
										<tbody className="bg-sky-100">
											{daywiseReport.map((ele) => (
												<tr key={ele["Date"]} className="border-b text-black">
													<td className="p-3 max-w-[80] xl:text-base text-sm">{ele["Date"]}</td>
													<td className="p-3 max-w-[80] xl:text-base text-sm">{ele["Entry"]}</td>
													<td className="p-3 max-w-[80] xl:text-base text-sm">{ele["Exit"]}</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							)
						) : (
							<div className="flex flex-row justify-around my-6">
								<div className="alert alert-info px-20 w-fit">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6">
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
										></path>
									</svg>
									<span>
										Select {!startDate && "Starting date and"} {!endDate && "Ending date"}
									</span>
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default DayWiseTraffic;
