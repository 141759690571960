import { doc, updateDoc } from "firebase/firestore";
import React from "react";
import { db } from "../../../../../../../firebase/firebase.config";
import { toast } from "react-hot-toast";
import BlueBtn from "../../../../../../../components/Buttons/BlueBtn";

const EditEntryModal = ({ alertProfileID, dataToEdit, setAuthorizedEntryCount, authorizedExitCount, setAuthorizedExitCount, setEditEntryModal }) => {
	const updateDB = async (entryCount, exitCount) => {
		// Entry
		const thresholdEntryRef = doc(db, `AuthorizedCountCollection/${alertProfileID}/Threshold`, "EntryCount");
		let vehicle = dataToEdit[0];
		let obj = {};
		obj[vehicle] = entryCount;
		await updateDoc(thresholdEntryRef, {
			...obj,
		});
		setAuthorizedEntryCount((prev) => {
			let newObj = { ...prev };
			newObj[vehicle] = entryCount;
			return newObj;
		});

		// Exit
		const thresholdExitRef = doc(db, `AuthorizedCountCollection/${alertProfileID}/Threshold`, "ExitCount");
		vehicle = dataToEdit[0];
		obj = {};
		obj[vehicle] = exitCount;
		await updateDoc(thresholdExitRef, {
			...obj,
		});
		setAuthorizedExitCount((prev) => {
			let newObj = { ...prev };
			newObj[vehicle] = exitCount;
			return newObj;
		});

		setEditEntryModal(false);
		toast.success("Changes saved");
	};

	const handleUpdate = (e) => {
		e.preventDefault();
		const form = e.target;
		const entryCount = parseInt(form.entryCount.value);
		const exitCount = parseInt(form.exitCount.value);
		updateDB(entryCount, exitCount);
	};

	return (
		<div>
			<input type="checkbox" id="updateEntry-modal" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box relative rounded-none bg-white w-[30rem]">
					<label
						htmlFor="updateEntry-modal"
						onClick={() => setEditEntryModal(false)}
						className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]"
					>
						✕
					</label>
					<h3 className="text-2xl text-center font-bold mb-7 text-black">Edit {dataToEdit[0]}</h3>

					<form className="ml-14" onSubmit={handleUpdate}>
						<div className="form-control gap-3 text-black w-80">
							<div className="flex flex-row justify-between">
								<label htmlFor="entryCount" className="w-32">
									Entry Count
								</label>
								<input
									className="bg-gray-100 pl-3"
									id="entryCount"
									name="entryCount"
									type="number"
									placeholder="Enter Number"
									defaultValue={dataToEdit[1]}
									required
								/>
							</div>

							<div className="flex flex-row justify-between">
								<label htmlFor="exitCount" className="w-32">
									Exit Count
								</label>
								<input
									className="bg-gray-100 pl-3"
									id="exitCount"
									name="exitCount"
									type="number"
									placeholder="Enter Number"
									defaultValue={authorizedExitCount[dataToEdit[0]]}
									required
								/>
							</div>
						</div>
						<div className="form-control mt-5">
							<BlueBtn>Update</BlueBtn>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default EditEntryModal;
