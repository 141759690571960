import React from "react";

const Week = ({ selectedDays, setSelectedDays }) => {
	const week = [
		{
			id: "Sunday",
			text: "S",
		},
		{
			id: "Monday",
			text: "M",
		},
		{
			id: "Tuesday",
			text: "T",
		},
		{
			id: "Wednesday",
			text: "W",
		},
		{
			id: "Thursday",
			text: "T",
		},
		{
			id: "Friday",
			text: "F",
		},
		{
			id: "Saturday",
			text: "S",
		},
	];

	const handleWeekSelect = async (e) => {
		const day = e.target.name;

		if (e.target.checked) {
			setSelectedDays((prevDays) => [...prevDays, day]);
		} else {
			setSelectedDays((prevDays) => {
				const afterExclusion = prevDays.filter((d) => d !== day);
				return afterExclusion;
			});
		}
	};

	return (
		<div className="flex flex-row justify-around w-[374px]">
			<div>
				{week.map((w) => (
					<div className="relative inline-block h-[50px] w-[50px]" key={w.id}>
						<input
							type="checkbox"
							id={w.id}
							name={w.id}
							onChange={handleWeekSelect}
							className="peer appearance-none bg-slate-200 hover:bg-slate-400 hover:cursor-pointer checked:bg-blue-500 h-12 w-12"
							checked={selectedDays?.includes(w.id)}
						/>
						<label
							htmlFor={w.id}
							className="peer-checked:text-white md:text-sm xl:text-base hover:cursor-pointer absolute z-[1] xl:top-1/4 md:top-[27%] xl:left-[37%] md:left-[40%]  text-gray-700"
						>
							{w.text}
						</label>
					</div>
				))}
			</div>
		</div>
	);
};

export default Week;
