import React, { useEffect, useState } from "react";
import BlueBtn from "../../../../../../../components/Buttons/BlueBtn";
import { arrayUnion, collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../../../../../../../firebase/firebase.config";
import { toast } from "react-hot-toast";

const AddEntryModal = ({
	alertProfile,
	detectionIDs,
	// isAlertProfileAdded,
	selectedClient,
	selectedLocation,
	selectedZone,
	setAddEntryModal,
	setWhitelistVehicleData,
	// setIsAlertProfileAdded,
}) => {
	const [detections, setDetections] = useState([]);

	// fetching detections when modal mounts
	useEffect(() => {
		if (selectedClient && selectedLocation) fetchDetections();
	}, [selectedClient, selectedLocation]);

	//fetch detection list for selected client location
	const fetchDetections = async () => {
		const alertStringArr = alertProfile.split("-");
		const selectedClientID = alertStringArr[0];
		const selectedLocationID = alertStringArr[1];
		const detectionCollectionRef = doc(db, `clients-data-collection/${selectedClientID}/Location/${selectedLocationID}/Detections`, "detection-list");
		const docSnap = await getDoc(detectionCollectionRef);
		let detections = docSnap.data()?.Vehicles;
		detections = detections.filter((detection) => detection !== "Numberplate").sort();
		setDetections([...detections.map((ele) => ({ value: ele, id: ele }))]);
	};

	const addAlertProfileToRecipients = async () => {
		const alertString = alertProfile.split("-").slice(0, 3).join("-");
		const docRef = doc(db, "AlertTypesCollection", alertString);

		const docSnap = await getDoc(docRef);
		let recipients = [];

		if (docSnap.exists()) recipients = [...docSnap.data().Recipients];

		// add profiles to recipients emails in userDataCollection
		recipients.forEach(async (email) => {
			// querying for each email
			const colRef = collection(db, "userDataCollection");
			const q = query(colRef, where("Email", "==", email));
			const querySnapshot = await getDocs(q);

			//since Email is unique so their will be only one document
			let userID;
			if (querySnapshot.empty) return; // no matching emails found
			querySnapshot.forEach((doc) => {
				// doc.data() is never undefined for query doc snapshots
				userID = doc.id;
			});

			// updating AlertProfile for queryied email
			// if checkbox is enabled then add that alertprofile from the user's AlertProfile else remove
			const userDocRef = doc(db, "userDataCollection", userID);

			await updateDoc(userDocRef, {
				AlertProfile: arrayUnion(alertString),
			});

			// setIsAlertProfileAdded(true);
		});
	};

	const addEntryToDB = async (vehicleType, vehicleNumber) => {
		const docRef = doc(db, "UnauthorizedEntryCollection", alertProfile);

		const docSnap = await getDoc(docRef);

		if (!docSnap.exists()) {
			console.log("exists", docSnap.exists());
			await setDoc(docRef, {
				AlertProfile: alertProfile,
				AlertName: "Unauthorized Entry",
				Client: selectedClient,
				Location: selectedLocation,
				Zone: selectedZone,
			});
		}
		await setDoc(doc(db, `UnauthorizedEntryCollection/${alertProfile}/Whitelist`, vehicleNumber), {
			VehicleType: vehicleType,
			VehicleNumber: vehicleNumber,
		});

		setWhitelistVehicleData((prev) => [...prev, { id: vehicleNumber, VehicleType: vehicleType, VehicleNumber: vehicleNumber }]);
		/*if (!isAlertProfileAdded) */
		await addAlertProfileToRecipients();

		toast.success(`${vehicleType} ${vehicleNumber} added`);
		setAddEntryModal(false);
	};

	const handleAddEntry = (e) => {
		e.preventDefault();
		const form = e.target;
		const vehicleType = form.vehicleType.value;
		const vehicleNumber = form.vehicleNumber.value;
		// console.log(vehicleType, vehicleNumber);
		if (detectionIDs.has(vehicleNumber)) {
			toast.error("Detection with this ID already exist , please enter a unique ID");
			return;
		}
		addEntryToDB(vehicleType, vehicleNumber)
			.then(console.log("Added"))
			.catch((err) => console.log("error", err));

		form.reset();
	};

	return (
		<div>
			<input type="checkbox" id="addEntry-modal" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box relative rounded-none bg-white w-[30rem]">
					<label
						onClick={() => setAddEntryModal(false)}
						htmlFor="addEntry-modal"
						className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]"
					>
						✕
					</label>
					<h3 className="text-2xl text-center font-bold mb-7 text-black">Add Vehicle</h3>

					<form className="ml-14" onSubmit={handleAddEntry}>
						<div className="form-control gap-3 text-black w-80">
							<div className="flex flex-row justify-between">
								<label htmlFor="vehicleType">Detection</label>
								<select id="vehicleType" name="vehicleType" className="bg-gray-100 w-48 h-[1.5rem] px-2">
									{detections.map((detection) => (
										<option key={detection.id} value={detection.value}>
											{detection.value}
										</option>
									))}
								</select>
							</div>
							<div className="flex flex-row justify-between">
								<label htmlFor="vehicleNumber">Vehicle Number</label>
								<input id="vehicleNumber" name="vehicleNumber" className="bg-gray-100 w-48 h-[1.5rem] p-2" type="text" />
							</div>
						</div>
						<div className="form-control mt-5">
							<BlueBtn>Update</BlueBtn>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddEntryModal;
