import React from 'react';

const FilterClientName = ({ i, client, clientsData }) => {
    const clientInfo = clientsData?.find(c => c?.ClientID === client);
    const clientName = clientInfo?.Client;
    // console.log(client,clientName);

    return (
        <option
            value={client}
            key={i}
        >{clientName}</option>
    );
};

export default FilterClientName;