import React from "react";
import BlueBtn from "../../../../../../../components/Buttons/BlueBtn";
import { useEffect } from "react";
import { useState } from "react";
import { db } from "../../../../../../../firebase/firebase.config";
import { arrayUnion, collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { toast } from "react-hot-toast";
import Loading from "../../../../../../../components/Loading/Loading";

// custom styling for react-select
const selectStyles = {
	menuList: (styles) => {
		// console.log("menuList:", styles);
		return {
			...styles,
			maxHeight: 136,
		};
	},
	container: (provided) => ({
		...provided,
		width: 235,
		height: 50,
	}),
};

const AddEntryModal = ({
	alertProfile,
	alertProfileID,
	authorizedEntryCount,
	selectedClient,
	selectedLocation,
	selectedZone,
	date,
	// isAlertProfileAdded,
	// setIsAlertProfileAdded,
	setAuthorizedEntryCount,
	setAuthorizedExitCount,
	setAddEntryModal,
}) => {
	const [detectionOptions, setDetectionOptions] = useState([]);

	const [loading, setLoading] = useState(true);

	// console.log("detectionOptions", detectionOptions);

	//fetch detection list for selected client location
	const fetchDetections = async () => {
		const [selectedClientID, selectedLocationID] = alertProfile.split("-").slice(0, 2);
		const detectionCollectionRef = doc(db, `clients-data-collection/${selectedClientID}/Location/${selectedLocationID}/Detections`, "detection-list");
		const docSnap = await getDoc(detectionCollectionRef);
		const detections = docSnap.data().Vehicles;
		let detectionList = [];
		detections.forEach((detection) => {
			if (!Object.hasOwn(authorizedEntryCount, detection) && detection !== "Numberplate") detectionList.push({ id: detection, value: detection });
		});
		detectionList = detectionList.sort((a, b) => a.value > b.value);
		setDetectionOptions(detectionList);
		setLoading(false);
	};

	useEffect(() => {
		// let options = [];
		// vehicles.forEach((detection) => {
		// 	if (!Object.hasOwn(authorizedEntryCount, detection)) options.push({ id: detection, value: detection });
		// });
		// setVehicleOptions(options);
		if (selectedClient && selectedLocation) {
			setLoading(true);
			fetchDetections();
		}
	}, [selectedClient, selectedLocation, authorizedEntryCount]);

	const addAlertProfileToRecipients = async () => {
		// const alertProfile = alertProfile.split("-").slice(0, 3).join("-");
		const docRef = doc(db, "AlertTypesCollection", alertProfile);

		const docSnap = await getDoc(docRef);
		let recipients = [];

		if (docSnap.exists()) recipients = [...docSnap.data().Recipients];

		// add profiles to recipients emails in userDataCollection
		recipients.forEach(async (email) => {
			// querying for each email
			const colRef = collection(db, "userDataCollection");
			const q = query(colRef, where("Email", "==", email));
			const querySnapshot = await getDocs(q);

			//since Email is unique so their will be only one document
			let userID;
			if (querySnapshot.empty) return; // no matching emails found
			querySnapshot.forEach((doc) => {
				// doc.data() is never undefined for query doc snapshots
				userID = doc.id;
			});

			// updating AlertProfile for queryied email
			// if checkbox is enabled then add that alertprofile from the user's AlertProfile else remove
			const userDocRef = doc(db, "userDataCollection", userID);

			await updateDoc(userDocRef, {
				AlertProfile: arrayUnion(alertProfile),
			});

			// setIsAlertProfileAdded(true);
		});
	};

	const addEntryDataToDB = async (detection, entryCount, exitCount) => {
		const docRef = doc(db, `AuthorizedCountCollection`, alertProfileID);
		const docSnap = await getDoc(docRef);
		if (!docSnap.exists()) {
			await setDoc(docRef, {
				AlertName: "Authorized Count Exceeded",
				Client: selectedClient,
				Location: selectedLocation,
				Zone: selectedZone.Zone,
				Date: date,
			});
		}

		// Entry
		const thresholdEntryRef = doc(db, `AuthorizedCountCollection/${alertProfileID}/Threshold`, "EntryCount");
		const thresholdEntrySnap = await getDoc(thresholdEntryRef);
		let data = {};
		// selectedVehicles.forEach((detection) => (data[detection] = entryCount));
		data[detection] = entryCount;
		if (thresholdEntrySnap.exists()) {
			await updateDoc(thresholdEntryRef, {
				...data,
			});
		} else {
			await setDoc(thresholdEntryRef, {
				...data,
				DocID: "EntryCount",
			});
		}

		setAuthorizedEntryCount((prev) => {
			return { ...prev, ...data };
		});

		// Exit
		const thresholdExitRef = doc(db, `AuthorizedCountCollection/${alertProfileID}/Threshold`, "ExitCount");
		const thresholdExitSnap = await getDoc(thresholdExitRef);
		data = {};
		// selectedVehicles.forEach((detection) => (data[detection] = exitCount));
		data[detection] = exitCount;
		if (thresholdExitSnap.exists()) {
			await updateDoc(thresholdExitRef, {
				...data,
			});
		} else {
			await setDoc(thresholdExitRef, {
				...data,
				DocID: "ExitCount",
			});
		}
		setAuthorizedExitCount((prev) => {
			return { ...prev, ...data };
		});

		// await addAlertProfileToRecipients();

		setAddEntryModal(false);
		toast.success("Entry Added");
	};

	// const handleVehicleSelect = (selectedOptions) => {
	// 	setSelectedVehicles(selectedOptions.map((ele) => ele.value));
	// };

	const handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;
		const detection = form.selectedDetection.value;
		const entryCount = parseInt(form.entryCount.value);
		const exitCount = parseInt(form.exitCount.value);
		addEntryDataToDB(detection, entryCount, exitCount);
		form.reset();
	};

	return (
		<div>
			<input type="checkbox" id="addEntry-modal" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box relative rounded-none bg-white w-[30rem]">
					<label
						onClick={() => setAddEntryModal(false)}
						htmlFor="addEntry-modal"
						className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]"
					>
						✕
					</label>
					<h3 className="text-2xl text-center font-bold mb-7 text-black">Add Entry</h3>
					{loading ? (
						<Loading />
					) : detectionOptions.length ? (
						<form className="ml-14" onSubmit={handleSubmit}>
							<div className="form-control gap-3 text-black w-80">
								<div className="flex flex-row  justify-between">
									<label className="w-32" htmlFor="detectionSelect">
										Detections
									</label>
									<select className="bg-gray-100 text-black px-2 w-[12.5rem]" id="detectionSelect" name="selectedDetection">
										{detectionOptions.map((detection) => (
											<option key={detection.id} value={detection.value}>
												{detection.value}
											</option>
										))}
									</select>
								</div>
								<div className="flex flex-row items-center gap-3">
									<label className="w-32" htmlFor="entry">
										Entry Count
									</label>
									<input
										id="entry"
										className="pl-3 bg-gray-100 text-black"
										name="entryCount"
										type="number"
										placeholder="Enter Number"
										min={0}
										required
									/>
								</div>
								<div className="flex flex-row items-center gap-3">
									<label className="w-32" htmlFor="exit">
										Exit Count
									</label>
									<input
										id="exit"
										className="pl-3 bg-gray-100 text-black"
										name="exitCount"
										type="number"
										placeholder="Enter Number"
										min={0}
										required
									/>
								</div>
							</div>
							<div className="form-control mt-5">
								<BlueBtn>Add</BlueBtn>
							</div>
						</form>
					) : (
						<div className="alert alert-info">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
								></path>
							</svg>
							<span>
								All available detections for {selectedClient} and {selectedLocation} have been added !
							</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default AddEntryModal;
