import { collection, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../../../../../firebase/firebase.config';


const Clients = ({ client, clientSelect, setClientSelect }) => {
    const [clientName, setClientName] = useState('');

    //fetch client name from clientcollection using the client id
    useEffect(() => {
        try {
            const collectionRef = collection(db, 'clients-data-collection');
            const q = query(collectionRef, where("ClientID", "==", client));

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                let list = [];
                querySnapshot.docs.forEach((doc) => {
                    list.push({ id: doc.id, ...doc.data() });
                });
                //console.log(list[0]?.Client);
                setClientName(list[0]?.Client);
            },
                (error) => {
                    console.log(error);
                }
            );

            return () => {
                unsubscribe();
            };

        } catch (err) {
            console.log(err);
        }
    }, [client]);

    return (
        <>
            <span
                className={`w-full hover:bg-green-500 p-2 cursor-pointer border-b ${clientSelect === client ? 'bg-green-500' : ''
                    }`}
                onClick={() => setClientSelect(client)}
            >
                {clientName}
            </span>
        </>
    );
};

export default Clients;