import React, { useEffect, useState } from "react";
import AlMonkLogo from "../../../../components/AlMonkLogo";
import { useContext } from "react";
import { DataContext } from "../../../../context/DataProvider/DataProvider";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase/firebase.config";
import Modal from "./Modals/Modal";
import { AuthContext } from "../../../../context/AuthProvider/AuthProvider";
import useGetUserType from "../../../../hooks/useGetUserType";
import useMasterAdmin from "../../../../hooks/useMasterAdmin";

const AlertMain = () => {
	const { user } = useContext(AuthContext);

	const { userType, userTypeLoading } = useGetUserType(user?.uid);
	const currentUserRole = userType?.Role;
	const [isMasterAdmin, masterAdminLoading] = useMasterAdmin(user?.email);

	const { clientsData, devices, userClientAndLocation, dataLoading } = useContext(DataContext);
	const [clientsList, setClientsList] = useState([]);
	const [selectedClient, setSelectedClient] = useState(""); // client which is actively selected
	const [selectedClientID, setSelectedClientID] = useState("");

	const [location, setLocation] = useState([]); // device locations associated with selected client
	const [selectedLocation, setSelectedLocation] = useState(""); // currently selected location from dropdown
	const [selectedLocationID, setSelectedLocationID] = useState("");

	// for mounting and unMounting modals
	const [modalsActive, setModalsActive] = useState([]);
	// const [nightMovementModal, setNightMovementModal] = useState(false);

	const [alertsConfig, setAlertsConfig] = useState([]); // { AlertName:... , AlertProfile: ... , Status: Enabled/Disabled  }

	const [tableLoading, setTableLoading] = useState(true);

	const [users, setUsers] = useState([]);

	//setting default client
	useEffect(() => {
		if (!userTypeLoading && !masterAdminLoading && !dataLoading) {
			let list = [];

			if (isMasterAdmin) {
				clientsData.forEach((client) => list.push(client));
			} else {
				list.push({ id: userType.ClientID, Client: userType.Client });
			}
			setClientsList(list);
			setSelectedClient(list[0].Client);
			setSelectedClientID(list[0].id);
		}
	}, [userType, userTypeLoading, isMasterAdmin, masterAdminLoading, clientsData, dataLoading]);

	const fetchUsers = async () => {
		const q = query(collection(db, "userDataCollection"), where("Client", "==", selectedClient));
		const querySnapshot = await getDocs(q);
		let userEmailList = [];
		querySnapshot.forEach((doc) => {
			userEmailList.push(doc.data().Email);
		});
		setUsers(userEmailList);
	};
	// to fetch users when client is selected
	useEffect(() => {
		if (selectedClient) fetchUsers();
	}, [selectedClient]);

	// fetching location associated to that client
	useEffect(() => {
		if (selectedClient && !dataLoading && !userTypeLoading) {
			let filteredList = devices.filter((ele) => ele.Client === selectedClient);
			if (currentUserRole) {
				if (
					currentUserRole === "Local Admin" ||
					currentUserRole === "Regional Admin" ||
					currentUserRole === "Local User" ||
					currentUserRole === "Local Ops User" ||
					currentUserRole === "Regional User" ||
					currentUserRole === "Regional Admin"
				) {
					const userLocations = new Set(userClientAndLocation.userLocation);
					filteredList = [...filteredList.filter((ele) => userLocations.has(ele.Location_ID))];
					const locationList = [];
					filteredList.forEach((ele) => locationList.push({ Location: ele.Location, id: ele.Location_ID }));
					const uniqueLocationList = [...new Map(locationList.map((item) => [item["id"], item])).values()];
					setLocation(uniqueLocationList);
				} else {
					const locationList = [];
					filteredList.forEach((ele) => locationList.push({ Location: ele.Location, id: ele.Location_ID }));
					const uniqueLocationList = [...new Map(locationList.map((item) => [item["id"], item])).values()];
					setLocation(uniqueLocationList);
				}
			} else {
				const locationList = [];
				filteredList.forEach((ele) => locationList.push({ Location: ele.Location, id: ele.Location_ID }));
				const uniqueLocationList = [...new Map(locationList.map((item) => [item["id"], item])).values()];
				setLocation(uniqueLocationList);
			}
		}
	}, [devices, selectedClient, dataLoading, userTypeLoading, currentUserRole, userClientAndLocation]);

	//setting default location
	useEffect(() => {
		const setDefault = async () => {
			const q = query(collection(db, "AlertTypesCollection"), where("Client", "==", selectedClient), where("Location", "==", location[0].Location));

			const querySnapshot = await getDocs(q);
			let alertList = [];
			querySnapshot.forEach((doc) => {
				alertList.push({
					id: doc.id,
					AlertName: doc.data().AlertName,
					AlertProfile: doc.data().AlertProfile, // if profile string not present maybe add that ?
					Recipients: doc.data().Recipients,
					Status: doc.data().Status,
				});
			});

			setAlertsConfig(alertList);
		};
		if (location.length) {
			setSelectedLocationID(location[0].id);
			setSelectedLocation(location[0].Location);
			setDefault();
		}
	}, [location]);

	useEffect(() => {
		if (alertsConfig.length) setTableLoading(false);
	}, [alertsConfig]);

	const handleClientSelect = (e) => {
		const client = e.target.value;
		const index = clientsData.findIndex((ele) => ele.Client === client);
		setSelectedClient(clientsData[index].Client);
		setSelectedClientID(clientsData[index].id);
	};

	const handleLocationSelect = async (e) => {
		const selectedLocationDropdown = e.target.value;
		setSelectedLocation(selectedLocationDropdown);

		let selectedLocationIndex = location.findIndex((loc) => loc.Location === selectedLocationDropdown);
		setSelectedLocationID(location[selectedLocationIndex].id);

		const q = query(collection(db, "AlertTypesCollection"), where("Client", "==", selectedClient), where("Location", "==", selectedLocationDropdown));

		const querySnapshot = await getDocs(q);
		let alertList = [];
		querySnapshot.forEach((doc) => {
			alertList.push({
				id: doc.id,
				AlertName: doc.data().AlertName,
				AlertProfile: doc.data().AlertProfile, // if profile string not present maybe add that ?
				Recipients: doc.data().Recipients,
				Status: doc.data().Status,
			});
		});
		// console.log("alertList", alertList);
		setAlertsConfig(alertList);
	};

	return (
		// wrapper div for whole page because for some reason page has a default bg color maybe due to daisy ui theme
		<div className="bg-white h-full">
			{/* header div */}
			<div className="flex items-center bg-white justify-between py-2 sticky top-0 z-[2]">
				<h1 className="font-bold ml-6 text-3xl text-start text-black">Alerts</h1>
				<div className="flex items-center justify-end">
					<input type="text" placeholder="Search" className="input input-bordered rounded mr-3 bg-slate-200 placeholder:text-zinc-800" />
					<div className="ml-5">
						<AlMonkLogo />
					</div>
				</div>
			</div>

			<div className="sticky top-20 z-[2] bg-white mx-6 mt-5 mb-9 xl:flex xl:flex-row xl:gap-14 xl:items-center md:flex md:flex-col md:gap-4">
				{/* Client */}
				<div className="xl:flex xl:flex-row xl:justify-between xl:items-center md:grid md:grid-cols-2 md:w-[28rem] xl:w-[19rem]">
					<h1 className="text-black text-lg mr-3">Client : </h1>
					<select
						className="bg-white rounded text-black text-lg xl:w-[14rem]"
						value={selectedClient ? selectedClient : "Select Client"}
						onChange={handleClientSelect}
					>
						<option defaultValue disabled>
							Select Client
						</option>
						{clientsList.map((client) => (
							<option key={client.id} value={client.Client}>
								{client.Client}
							</option>
						))}
					</select>
				</div>
				{/* Location */}
				<div className="xl:flex xl:flex-row xl:justify-between xl:items-center md:grid md:grid-cols-2 md:w-[28rem] xl:w-[19rem]">
					{selectedClient ? (
						<>
							<h1 className="text-black text-lg self-start mr-3">Location : </h1>
							<select
								className="bg-white rounded text-black text-lg xl:w-[12rem]"
								value={selectedLocation ? selectedLocation : "Select Location"}
								onChange={handleLocationSelect}
							>
								<option defaultValue disabled>
									Select Device Location
								</option>
								{location.map((loc) => (
									<option key={loc.id} value={loc.Location}>
										{loc.Location}
									</option>
								))}
							</select>
						</>
					) : (
						<h1 className="bg-red-100 rounded border-2 border-red-600 text-red-600 text-xl mt-2  mr-3 px-2">
							Select Client to see available locations
						</h1>
					)}
				</div>
			</div>

			{/* Table div */}
			{selectedClient && selectedLocation ? (
				tableLoading ? (
					<div
						className="inline-block mx-[50rem] relative top-28 h-12 w-12 animate-spin rounded-full border-8 border-sky-900 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
						role="status"
					>
						<span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
					</div>
				) : (
					<div className="overflow-x-auto w-3/4 my-20 mx-auto overflow-y-auto relative">
						<table className="w-full text-center text-black border border-gray-900 h-auto">
							{/* head */}
							<thead className="border border-black text-xl h-16 bg-gray-100">
								<tr className="w-[85rem]">
									<th className="border border-black w-[65rem]">Alerts</th>
									<th className="w-[19rem]">Status</th>
								</tr>
							</thead>

							{/* Body */}
							<tbody className="h-64 border border-black text-lg bg-sky-100">
								{alertsConfig.map((al) => (
									<tr key={al.AlertProfile} className="w-[85rem]">
										<td className="border border-black hover:bg-slate-300 hover:font-bold w-[65rem]">
											<label
												htmlFor={`${al.AlertProfile}modal`}
												className="bg-transparent h-[3.5rem] flex flex-row items-center justify-around hover:cursor-pointer"
												onClick={() => setModalsActive((prev) => [...prev, al.AlertProfile])}
											>
												{al.AlertName}
											</label>
										</td>
										<td className={`border border-black w-[19rem] ${al.Status === "Enabled" ? `bg-green-200` : `bg-red-200`}`}>
											{al.Status === "Enabled" ? "Enabled" : "Disabled"}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)
			) : (
				<div className="h-[35rem] w-[110rem] flex flex-col justify-around items-center">
					<h1 className="px-2 bg-blue-100 rounded border-2 border-blue-700 text-2xl text-center text-blue-700">
						Select {!selectedClient && `Client and`} {!selectedLocation && `Location`} to see related alert configurations
					</h1>
				</div>
			)}

			{/* modals */}
			{alertsConfig.map(
				(al, alertIndex) =>
					modalsActive.includes(al.AlertProfile) && (
						<Modal
							key={al.AlertProfile}
							alertName={al.AlertName}
							alertProfile={al.AlertProfile}
							alertIndex={alertIndex}
							alertsConfig={alertsConfig}
							setAlertsConfig={setAlertsConfig}
							setModalsActive={setModalsActive}
							users={users}
						/>
					)
			)}
		</div>
	);
};

export default AlertMain;
