import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import React, { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import { db } from "../../../../../../firebase/firebase.config";
import Loading from "../../../../../../components/Loading/Loading";
import toast from "react-hot-toast";
import { MdMoreTime } from "react-icons/md";
import TimePickerDesktop from "../../../../../../components/TimePicker/TimePickerDesktop";
import TimeDisplayDesktop from "../../../../../../components/TimeDisplayDesktop/TimeDisplayDesktop";

// custom styling for react-select
const selectStyles = {
	container: (provided, state) => ({
		...provided,
		width: "350px",
		minHeight: "55px",
		// maxHeight: `${55 + (state.selectProps.value ? state.selectProps.value.length * 40 : 0)}px`,
		borderWidth: 1,
		borderStyle: "solid",
		borderColor: "#2E3192",
		borderRadius: "8px",
	}),
	control: (provided, state) => ({
		...provided,
		backgroundColor: "#F2F5F7",
		minHeight: "55px",
		// maxHeight: `${55 + (state.selectProps.value ? state.selectProps.value.length * 40 : 0)}px`,
		borderRadius: "8px",
	}),
	menu: (provided) => ({
		...provided,
		backgroundColor: "#F2F5F7",
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: "block",
		backgroundColor: "#2E3192",
	}),
};

const NightMovementMain = ({ selectedClient, selectedClientID, selectedLocation, selectedLocationID, devices }) => {
	const [zones, setZones] = useState([]); // availabe options <select> component
	const [selectedZone, setSelectedZone] = useState({ Zone: "", ZoneID: "" });

	const [detectionOptions, setDetectionOptions] = useState([]); // options for react-select
	const [selectedDetectionOptions, setSelectedDetectionOptions] = useState([]); // to control react-select

	// for handling time
	const [startingTime, setStartingTime] = useState({
		hours: 0,
		minutes: 0,
		seconds: 0,
	});
	const [endingTime, setEndingTime] = useState({
		hours: 0,
		minutes: 0,
		seconds: 0,
	});

	// for handling modal mounting for starting ending time modals
	const [startTimeModalStatus, setStartTimeModalStatus] = useState(false);
	const [endTimeModalStatus, setEndTimeModalStatus] = useState(false);

	const [alertProfile, setAlertProfile] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	//fetch detection list for selected client location
	const fetchDetections = async () => {
		const detectionCollectionRef = doc(db, `clients-data-collection/${selectedClientID}/Location/${selectedLocationID}/Detections`, "detection-list");
		const docSnap = await getDoc(detectionCollectionRef);
		let detections = docSnap.data().Vehicles;
		detections = detections.filter((detection) => detection !== "Numberplate").sort();
		setDetectionOptions([...detections.map((ele) => ({ value: ele, label: ele }))]);
	};

	async function updateDB() {
		const alertCollectionRef = doc(db, "NightMovementCollection", alertProfile);
		const detections = selectedDetectionOptions.map((detection) => detection.value);
		await updateDoc(alertCollectionRef, {
			Detection: detections,
			StartTime:
				startingTime.hours.toString().padStart(2, "0") +
				startingTime.minutes.toString().padStart(2, "0") +
				startingTime.seconds.toString().padStart(2, "0"),
			EndTime:
				endingTime.hours.toString().padStart(2, "0") +
				endingTime.minutes.toString().padStart(2, "0") +
				endingTime.seconds.toString().padStart(2, "0"),
		});
	}

	// fetching zones for selected client and location
	useEffect(() => {
		const fetchZones = async () => {
			let zoneDataArray = devices.filter((ele) => ele.Client === selectedClient && ele.Location === selectedLocation);
			let uniqueZoneDataArray = [...new Map(zoneDataArray.map((item) => [item["Zone"], item])).values()];
			setZones(uniqueZoneDataArray);

			//setting default zone and id
			setSelectedZone({ Zone: uniqueZoneDataArray[0].Zone, ZoneID: uniqueZoneDataArray[0].ZoneID });
		};

		if (selectedClientID && selectedLocationID) fetchZones().then(fetchDetections());
	}, [selectedClientID, selectedLocationID]);

	// fetching and setting data for selected client => location => zone
	useEffect(() => {
		const setZoneData = async () => {
			setIsLoading(true);
			const alertProfileString = `${selectedClientID}-${selectedLocationID}-NM-${selectedZone.ZoneID}`;
			setAlertProfile(alertProfileString);
			const alertCollectionRef = doc(db, "NightMovementCollection", alertProfileString);
			const docSnap = await getDoc(alertCollectionRef);

			if (docSnap.exists()) {
				const detections = docSnap.data()?.Detection.map((detection) => ({ value: detection, label: detection }));
				setSelectedDetectionOptions(detections);
				const [startHr, startMin, startSec] = [
					parseInt(docSnap.data().StartTime.slice(0, 2)),
					parseInt(docSnap.data().StartTime.slice(2, 4)),
					parseInt(docSnap.data().StartTime.slice(4, 6)),
				];
				const [endHr, endMin, endSec] = [
					parseInt(docSnap.data().EndTime.slice(0, 2)),
					parseInt(docSnap.data().EndTime.slice(2, 4)),
					parseInt(docSnap.data().EndTime.slice(4, 6)),
				];
				setStartingTime({
					hours: startHr,
					minutes: startMin,
					seconds: startSec,
				});
				setEndingTime({ hours: endHr, minutes: endMin, seconds: endSec });
			} else {
				await setDoc(alertCollectionRef, {
					AlertProfile: alertProfileString,
					AlertName: "Night Movement",
					Client: selectedClient,
					Location: selectedLocation,
					Zone: selectedZone.Zone,
					ZoneID: selectedZone.ZoneID,
					Detection: [],
					StartTime: "000000",
					EndTime: "000000",
				});
			}
			setIsLoading(false);
		};
		if (selectedZone.ZoneID) setZoneData();
	}, [selectedZone.ZoneID, selectedLocationID]);

	const handleSave = () => {
		if (!selectedDetectionOptions.length) {
			toast.error("Select Detections !");
			return;
		}
		updateDB()
			.then(() => {
				toast.success("Saved !");
			})
			.catch((err) => {
				toast.error("Could not save , ran into problems");
				console.log("err", err);
			});
	};

	if (isLoading) return <Loading />;

	// if no zone is associated with the location
	if (!isLoading && !zones.length)
		return (
			<div className="flex flex-row justify-around">
				<div className="alert alert-error w-1/3">
					<svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
						/>
					</svg>
					<span>Night Movement Alert Configuration is not available for this Location</span>
				</div>
			</div>
		);

	return (
		<div className="mx-6">
			<h1 className="text-sky-900 text-[32px] font-semibold">Night Movement</h1>
			<div className="grid grid-cols-2 gap-4">
				{/* Column 1*/}
				<div className="m-4 col-span-1 grid grid-rows-4 gap-8 justify-end items-center">
					<h1 className="text-black text-2xl font-normal p-1">Zone</h1>
					<h1 className="text-black text-2xl font-normal p-1">Detections</h1>
					<h1 className="text-black text-2xl font-normal p-1">Start time</h1>
					<h1 className="text-black text-2xl font-normal p-1">End time</h1>
				</div>

				{/* Column 2 */}
				<div className="m-4 col-span-1 grid grid-rows-4 gap-8 justify-start items-center">
					{selectedLocation ? (
						<div className="flex flex-row justify-between items-end w-[22rem]">
							<select
								className="bg-[#F2F5F7] w-[350px] h-[55px] border border-[#2E3192] rounded-[8px] px-3 py-4 text-black text-base font-normal"
								value={selectedZone ? selectedZone : "Select Zone"}
								onChange={(e) => setSelectedZone({ Zone: e.target.value, ZoneID: e.target.id })}
							>
								<option defaultValue disabled>
									Select Zone
								</option>
								{zones.map((zone) => (
									<option key={zone.ZoneID} value={zone.zone} id={zone.ZoneID}>
										{zone.Zone}
									</option>
								))}
							</select>
						</div>
					) : (
						<h1 className="bg-red-100 rounded border-2 border-red-600 text-red-600 text-xl mt-16 mr-3 px-2">
							Select Location to see available zones
						</h1>
					)}
					<div className="flex flex-row items-end gap-5 mb-3">
						<Select
							className="text-black"
							options={detectionOptions}
							placeholder={"Select detection options"}
							value={selectedDetectionOptions}
							onChange={(selectedOptions) => setSelectedDetectionOptions(selectedOptions)}
							styles={selectStyles}
							isMulti
						/>
					</div>
					<div className="flex flex-row items-center justify-between rounded-[8px] bg-[#F2F5F7] w-[200px] h-[55px] py-[16px] px-[12px] border border-[#2E3192]">
						<TimeDisplayDesktop time={startingTime} />
						<div className="tooltip tooltip-right" data-tip="Edit Time">
							<label
								htmlFor="time_picker"
								className="btn btn-ghost !p-0 !w-[45px] !h-[24px] !rounded-none"
								onClick={() => setStartTimeModalStatus(true)}
							>
								<MdMoreTime className="text-3xl" />
							</label>
						</div>
					</div>
					<div className="flex flex-row items-center justify-between rounded-[8px] bg-[#F2F5F7] w-[200px] h-[55px] py-[16px] px-[12px] border border-[#2E3192]">
						<TimeDisplayDesktop time={endingTime} />
						<div className="tooltip tooltip-right" data-tip="Edit Time">
							<label
								htmlFor="time_picker"
								className="btn btn-ghost !p-0 !w-[45px] !h-[24px] !rounded-none"
								onClick={() => setEndTimeModalStatus(true)}
							>
								<MdMoreTime className="text-3xl" />
							</label>
						</div>
					</div>
				</div>
			</div>

			{/* Save Button */}
			<div className="my-2 flex justify-center items-center">
				<button className="btn btn-accent hover:btn-success font-bold text-base" onClick={handleSave}>
					Save
				</button>
			</div>
			{startTimeModalStatus && (
				<TimePickerDesktop time={startingTime} setTime={setStartingTime} setisTimePickerOpen={setStartTimeModalStatus} typeText={"Starting"} />
			)}
			{endTimeModalStatus && (
				<TimePickerDesktop time={endingTime} setTime={setEndingTime} setisTimePickerOpen={setEndTimeModalStatus} typeText={"Ending"} />
			)}
		</div>
	);
};

export default NightMovementMain;
