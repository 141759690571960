import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import AlMonkLogo from '../../../../components/AlMonkLogo';
import { db } from '../../../../firebase/firebase.config';
import { uniqueArr } from '../../../../Utility/RemoveDuplicate';
import LeftTable from './LeftTable/LeftTable';
import RightTable from './RightTable/RightTable';
import Loading from '../../../../components/Loading/Loading';

const DeviceMonitor = () => {
    const [data, setData] = useState([]);
    const [clients, setClients] = useState([]);
    const [locations, setLocations] = useState([]);
    const [devices, setDevices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [clientSelect, setClientSelect] = useState('');
    const [locationSelect, setLocationSelect] = useState('');


    // Fetch all device monitoring data from db   
    useEffect(() => {

        const d = new Date();
        const todaysDate = d.getFullYear() + '-' + ((d.getMonth() + 1).toString().padStart(2, '0')) + '-' + (d.getDate().toString().padStart(2, '0'));
        // console.log('date:',todaysDate);
        // const todaysDate = '2023-08-15';

        const collectionRef = collection(db, `ping-events-${todaysDate}`);
        const q = query(collectionRef, orderBy("Timestamp", "desc"));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let list = [];
            querySnapshot.docs.forEach((doc) => {
                list.push({ id: doc.id, ...doc.data() });
            });
            //console.log('device monitor:',list);
            setData(list);
            setIsLoading(false);
            const clientList = list.map(l => l?.['Client-ID']);
            setClients(uniqueArr(clientList));
            // console.log('clients:', clients);
        },
            (error) => {
                console.log(error);
            }
        );

        return () => {
            unsubscribe();
        };

    }, []);

    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <div>
            {/* header div */}
            <div className='flex items-center bg-white justify-between py-2 sticky top-0 z-[2]'>
                <h1 className='font-bold ml-6 text-3xl text-start'>Device Monitoring</h1>
                <div className='ml-5'>
                    <AlMonkLogo />
                </div>
            </div>

            {/* Table */}

            {
                data?.length !== 0
                    ? <>
                        <div className="flex lg:flex-row md:flex-row flex-col justify-between">
                            {/* Left Table */}
                            <div className='lg:w-[15%]'>
                                <LeftTable
                                    data={data}
                                    clients={clients}
                                    isLoading={isLoading}
                                    clientSelect={clientSelect}
                                    setClientSelect={setClientSelect}
                                    locations={locations}
                                    setLocations={setLocations}
                                    locationSelect={locationSelect}
                                    setLocationSelect={setLocationSelect}
                                    devices={devices}
                                    setDevices={setDevices}
                                ></LeftTable>
                            </div>

                            {/* Right Table */}
                            <div className="lg:w-[83%] mr-2">
                                <RightTable
                                    data={data}
                                    devices={devices}
                                    isLoading={isLoading}
                                    clientSelect={clientSelect}
                                    locationSelect={locationSelect}
                                ></RightTable>
                            </div>
                        </div>
                    </>
                    : <div className="flex justify-center items-center mx-auto">
                        <h2 className="text-xl mt-5 font-semibold">No data found</h2>
                    </div>
            }

        </div >
    );
};

export default DeviceMonitor;