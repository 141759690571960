import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AlMonkLogo from "../../../components/AlMonkLogo";
import EventImage from "./EventImage";
import Loading from "../../../components/Loading/Loading";
import { useState } from "react";
import TaggedEventsDisplay from "./TaggedEventsDisplay";
import EventDetailsForm from "./EventDetailsForm";
import { collection, doc, onSnapshot, query, runTransaction, setDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../../firebase/firebase.config";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import AddEntryImageModal from "../../../components/AddEntryImageModal";
import ImageCaptureModal from "../../../components/ImageCaptureModal";
import ImagePreviewModal from "../../../components/ImagePreviewModal";
import toast from "react-hot-toast";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthProvider/AuthProvider";
import useMasterAdmin from "../../../hooks/useMasterAdmin";

async function fetchDetections(clientID, locationID, setAvailableDetections) {
	const unsub = onSnapshot(
		query(collection(db, `clients-data-collection/${clientID}/Location/${locationID}/Detections`)),
		(snapShot) => {
			let list = [];
			snapShot.docs.forEach((doc) => {
				list = doc.data()?.Vehicles;
			});
			setAvailableDetections(list);
		},
		(error) => {
			console.log("error fetching detections", error.message);
		}
	);

	return () => {
		unsub();
	};
}

async function fetchOrgsAndPurpose(clientID, locationID, zoneID, setAvailableRegisteredOrgs, setAvailablePurposes) {
	const unsub = onSnapshot(
		doc(db, "SecurityAgentInitiationAlert", `${clientID}-${locationID}-SAIA-${zoneID}`),
		(doc) => {
			const orgs = doc.data().RegisteredOrganizationList;
			const purposes = doc.data().PurposeList;
			setAvailableRegisteredOrgs(orgs);
			setAvailablePurposes(purposes);
		},
		(error) => console.log("Error fetching purpose and orgs", error.message)
	);
	return () => unsub();
}

const EventDetailsMain = () => {
	const { user, authLoading } = useContext(AuthContext);
	const [isMasterAdmin, masterAdminLoading] = useMasterAdmin(user?.email);
	const eventInfo = useLocation();
	const eventDoc = eventInfo.state?.eventDoc;
	const {
		Direction,
		Timestamp,
		Class_label,
		Date,
		Location,
		"Client-ID": clientID,
		"Location-ID": locationID,
		Zone,
		"Zone-ID": zoneID,
		"Video-URL": videoURL,
	} = eventDoc;
	const eventID = eventDoc?.id;
	const [availableDetections, setAvailableDetections] = useState([]);
	const [availablePurposes, setAvailablePurposes] = useState([]);
	const [availableRegisteredOrgs, setAvailableRegisteredOrgs] = useState([]);
	const [taggedEventVideoURLs, setTaggedEventVideoURLs] = useState([videoURL]);

	const [loading, setLoading] = useState(true);

	const [photoUploadModal, setPhotoUploadModal] = useState(false);

	const [cameraModal, setCameraModal] = useState(false);
	const [files, setFiles] = useState([]);

	const [selectedEventsID, setSelectedEventsID] = useState([eventID]);

	const [imageURLForPreview, setImageURLForPreview] = useState(null);
	const [imagePreviewModal, setImagePreviewModal] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			if (clientID && locationID) {
				await fetchDetections(clientID, locationID, setAvailableDetections);
				await fetchOrgsAndPurpose(clientID, locationID, zoneID, setAvailableRegisteredOrgs, setAvailablePurposes);
				setLoading(false);
			}
		};
		fetchData();
	}, [eventID, clientID, locationID, zoneID]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const form = e.target;
		const eventType = form.event.value;
		const location = form.location.value;
		const zone = form.zone.value;
		const time = form.time.value;
		const vehicle = form.detection.value;
		const number = form.number.value;
		const purpose = form.purpose.value;
		const sender = form.sender.value;
		const remarks = form.remarks.value;

		// console.log(eventType, location, locationID, zone, zoneID, time, vehicle, number, purpose, sender, remarks);
		if (!files.length) {
			toast.error("Upload images to proceed");
			return;
		}
		if (eventType && location && locationID && zone && zoneID && time && vehicle && number && purpose && sender && remarks) {
			const toastId = toast.loading("Saving...");
			try {
				const downloadURLs = await handleUpload(files);
				toast.loading("Uploading Images...", {
					id: toastId,
				});
				if (downloadURLs?.length > 0) {
					await addEntryData(eventType, location, locationID, zone, zoneID, time, vehicle, number, purpose, sender, remarks, downloadURLs);
				}
				await updateDetectionEvents(locationID);
				setSelectedEventsID([]);
				setFiles([]);
				toast.success("Saved", {
					id: toastId,
				});
				form.reset();
			} catch (err) {
				toast.error("Error Saving", {
					id: toastId,
				});
				console.log("Error Saving", err);
			}
		}
		else {
			toast.error("Enter valid data");
			return;
		}
	};

	async function addEntryData(eventType, location, locID, zone, zoneID, time, detection, number, purpose, sender, remarks, downloadURLs) {
		try {
			const docID = `${clientID}-${locID}-${Timestamp}`;
			await setDoc(doc(db, `${clientID}-entries-${Date}`, docID), {
				docId: docID,
				Action: eventType,
				ClientID: clientID,
				Detection: detection,
				Entryby: user?.displayName,
				EventId: selectedEventsID,
				Location: location ? location : null,
				LocationID: locID ? locID : null,
				"Video-URL": taggedEventVideoURLs,
				"Photo-URL": downloadURLs.length ? downloadURLs : [],
				Purpose: purpose ? purpose : null,
				Sender: sender,
				Remarks: remarks ? remarks : null,
				Status: "Pending",
				Timestamp: time,
				VehicleNo: number ? number : null,
				Zone: zone ? zone : null,
				ZoneID: zoneID ? zoneID : null,
			});
		} catch (err) {
			console.error("error", err);
		}
	}

	async function updateDetectionEvents() {
		const collectionName = `${clientID}-priority-${Date}`;
		const entryID = `${clientID}-${locationID}-${Timestamp}`;

		const updatePromises = selectedEventsID.map((eventID) => {
			const eventDocRef = doc(db, `${clientID}-detection-events-${Date}`, eventID);
			return runTransaction(db, async (transaction) => {
				const eventDoc = await transaction.get(eventDocRef);
				if (eventDoc.exists()) {
					const entryDocRef = doc(db, collectionName, eventID);
					transaction.set(entryDocRef, { ...eventDoc.data(), id: eventID, Status: "Pending", Priority: "Critical", EntryID: entryID });
				}
			})
				.then(() => console.log(`${eventID} updated`))
				.catch((err) => {
					console.log(`Could not update ${eventID}`, err);
					throw new Error(`Could not update ${eventID}`);
				});
		});

		try {
			await Promise.all(updatePromises);
		} catch (err) {
			console.log("Could not update eventids", err);
		}
	}

	const handleUpload = async (files) => {
		// Set the folder name
		const folderName = `entry-${Date}`;

		// array to store download URLs
		const downloadURLs = [];

		// array of upload promises
		const uploadPromises = files.map((file) => {
			// Create a storage reference with the specified folder and file name
			const storageRef = ref(storage, `${folderName}/${file?.name}`);

			// Create and return a promise for each upload task
			return new Promise((resolve, reject) => {
				const uploadTask = uploadBytesResumable(storageRef, file);

				uploadTask.on(
					"state_changed",
					(snapshot) => {
						const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						console.log(`Upload ${file?.name} is ${progress}% done`);
					},
					(error) => {
						reject(`Error uploading file ${file?.name}: ${error.message}`);
					},
					() => {
						getDownloadURL(uploadTask.snapshot.ref)
							.then((downloadURL) => {
								downloadURLs.push(downloadURL); // Store the download URL
								resolve(`Upload ${file?.name} complete. Download URL: ${downloadURL}`);
							})
							.catch((downloadError) => {
								reject(`Error getting download URL for file ${file?.name}: ${downloadError.message}`);
							});
					}
				);
			});
		});

		try {
			await Promise.all(uploadPromises);
			return downloadURLs; // Return the array of download URLs
		} catch (error) {
			console.log("Error during uploads:", error);
			throw error; // Rethrow the error to handle it in the calling code
		}
	};

	if (loading) return <Loading />;

	return (
		<div className="bg-[#E9F1FE] h-full overflow-x-hidden">
			{/* header div */}
			<div className="flex bg-inherit items-center justify-between py-2 sticky top-0 z-[3]">
				<h1 className="font-semibold lg:ml-6 md:ml-12 lg:text-[32px] md:text-2xl text-start  text-black">Add Details</h1>
				<div className="flex items-center justify-end">
					<input type="text" placeholder="Search" className="input input-bordered rounded mr-3 bg-white placeholder:text-zinc-800" />
					<div className="ml-5">
						<AlMonkLogo />
					</div>
				</div>
			</div>

			{/* Body Div */}
			<div className="mt-4 ml-6 mr-4">
				{/* Submit Button Div */}
				<div className="flex flex-row justify-end">
					<button className="btn btn-success text-white px-16 pb-3 pt-2 font-bold text-xl" type="submit" form="entry-form" disabled={isMasterAdmin}>
						Submit
					</button>
				</div>

				<div className="my-3 grid grid-cols-3 gap-4">
					<EventDetailsForm
						eventDoc={eventDoc}
						availableDetections={availableDetections}
						availablePurposes={availablePurposes}
						availableRegisteredOrgs={availableRegisteredOrgs}
						handleSubmit={handleSubmit}
					/>
					<TaggedEventsDisplay
						eventDoc={eventDoc}
						selectedEventsID={selectedEventsID}
						setSelectedEventsID={setSelectedEventsID}
						taggedEventVideoURLs={taggedEventVideoURLs}
						setTaggedEventVideoURLs={setTaggedEventVideoURLs}
					/>
					<EventImage files={files} setFiles={setFiles} setPhotoUploadModal={setPhotoUploadModal} />
				</div>
			</div>
			{photoUploadModal && (
				<AddEntryImageModal
					setPhotoUploadModal={setPhotoUploadModal}
					setCameraModal={setCameraModal}
					setImageURLForPreview={setImageURLForPreview}
					setImagePreviewModal={setImagePreviewModal}
				/>
			)}
			{cameraModal ? <ImageCaptureModal setCameraModal={setCameraModal} setFiles={setFiles} /> : <></>}
			{imagePreviewModal ? (
				<ImagePreviewModal imageURLForPreview={imageURLForPreview} setImagePreviewModal={setImagePreviewModal} files={files} setFiles={setFiles} />
			) : (
				<></>
			)}
		</div>
	);
};

export default EventDetailsMain;
