import React from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Month from "./Month";
import Week from "./Week";
import { useEffect } from "react";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { toast } from "react-hot-toast";
import { FaEdit } from "react-icons/fa";
import Select from "react-select";
import { db } from "../../../../../../firebase/firebase.config";
import Loading from "../../../../../../components/Loading/Loading";
import { FiTrash2 } from "react-icons/fi";

// formates date as 12-Aug-2023
const formatDate = (date) => {
	const formatter = new Intl.DateTimeFormat("default", { month: "short" });
	const month = formatter.format(date);
	return `${date.getDate().toString().padStart(2, "0")}-${month}-${date.getFullYear()}`;
};

const NoMovement = ({ selectedClient, selectedClientID, selectedLocation, selectedLocationID, devices }) => {
	const [zones, setZones] = useState([]);
	const [zoneOptions, setZoneOptions] = useState([]);

	const [detectionOptions, setDetectionOptions] = useState([]); // options for react-select
	const [selectedDetectionOptions, setSelectedDetectionOptions] = useState([]); // to control react-select

	const [selectedZone, setSelectedZone] = useState("");
	const [selectedZoneOption, setSelectedZoneOption] = useState("");
	const [selectedZoneID, setSelectedZoneID] = useState("");

	const [alertProfile, setAlertProfile] = useState("");

	const [selectedDays, setSelectedDays] = useState([]); // selectedDays will contain week number starting from 0-sunday
	const [selectedDatesInMonth, setSelectedDatesInMonth] = useState([]); // selectedMonths will contain month number starting from 0-january

	const [date, setDate] = useState(null); // to control datepicker state
	const [dateClicked, setDateClicked] = useState(null); // date that is clicked on to be edited
	const [selectedDates, setSelectedDates] = useState([]); // final array of selected dates

	const [isLoading, setIsLoading] = useState(true);

	// custom styling for react-select
	const selectStyles = {
		container: (provided, state) => ({
			...provided,
			width: "350px",
			minHeight: "55px",
			// maxHeight: `${55 + (state.selectProps.value ? state.selectProps.value.length * 40 : 0)}px`,
			borderWidth: 1,
			borderStyle: "solid",
			borderColor: "#2E3192",
			borderRadius: "8px",
		}),
		control: (provided, state) => ({
			...provided,
			backgroundColor: "#F2F5F7",
			minHeight: "55px",
			// maxHeight: `${55 + (state.selectProps.value ? state.selectProps.value.length * 40 : 0)}px`,
			borderRadius: "8px",
		}),
		menu: (provided) => ({
			...provided,
			backgroundColor: "#F2F5F7",
		}),
		indicatorSeparator: (provided) => ({
			...provided,
			display: "block",
			backgroundColor: "#2E3192",
		}),
	};

	//fetch detection list for selected client location
	const fetchDetections = async () => {
		const detectionCollectionRef = doc(db, `clients-data-collection/${selectedClientID}/Location/${selectedLocationID}/Detections`, "detection-list");
		const docSnap = await getDoc(detectionCollectionRef);
		let detections = docSnap.data()?.Vehicles;
		detections = detections.filter((detection) => detection !== "Numberplate").sort();
		setDetectionOptions([...detections.map((ele) => ({ value: ele, label: ele }))]);
	};

	useEffect(() => {
		if (selectedClient && selectedLocation) {
			let zoneDataArray = devices.filter((ele) => ele.Client === selectedClient && ele.Location === selectedLocation);

			let uniqueZoneDataArray = [...new Map(zoneDataArray.map((item) => [item["Zone"], item])).values()];
			setZones(uniqueZoneDataArray);
			const zoneOptionsList = [];
			uniqueZoneDataArray.forEach((ele) =>
				zoneOptionsList.push({
					value: ele.Zone,
					label: ele.Zone,
				})
			);
			setZoneOptions(zoneOptionsList);

			// setting default zone
			setSelectedZone(uniqueZoneDataArray?.[0]?.Zone);
			setSelectedZoneOption({ value: uniqueZoneDataArray?.[0]?.Zone, label: uniqueZoneDataArray?.[0]?.Zone });
			setSelectedZoneID(uniqueZoneDataArray?.[0]?.ZoneID);
		}

		//fetching detections
		if (selectedClient && selectedLocation) fetchDetections().catch((err) => console.log("Err Fetching Detections", err));
	}, [selectedClient, selectedClientID, selectedLocation, selectedLocationID, devices]);

	useEffect(() => {
		const setZoneData = async (zone) => {
			setIsLoading(true);
			const alertProfileString = `${selectedClientID}-${selectedLocationID}-NMD-${selectedZoneID}`;
			// setAlertProfiles((prev) => [alertProfileString, ...prev]);
			setAlertProfile(alertProfileString);
			const alertCollectionRef = doc(db, "NoMovementCollection", alertProfileString);
			const docSnap = await getDoc(alertCollectionRef);
			if (docSnap.exists()) {
				setSelectedDetectionOptions([...docSnap.data().Detection.map((vehicle) => ({ label: vehicle, value: vehicle }))]);
				setSelectedDays(docSnap.data().WeeklyRepetitiveDays);
				setSelectedDatesInMonth(docSnap.data().MonthlyRepetitiveDays);

				// only load past 30 days in UI
				let dates = [...docSnap.data().NonRepetitiveDates];
				dates.sort((a, b) => new Date(b) - new Date(a));

				//filter to show only past 30 days from current date
				let curDate = new Date();
				let past30Dates = dates.filter((date) => new Date(date) < curDate).slice(-30);
				let futureDates = dates.filter((date) => new Date(date) > curDate);
				setSelectedDates([...futureDates, ...past30Dates]);
			} else {
				await setDoc(alertCollectionRef, {
					AlertProfile: alertProfileString,
					AlertName: "No Movement Day",
					Client: selectedClient,
					Location: selectedLocation,
					Zone: zone,
					Detection: [],
					WeeklyRepetitiveDays: [],
					MonthlyRepetitiveDays: [],
					NonRepetitiveDates: [],
				});
			}
			setIsLoading(false);
		};
		if (selectedZone) setZoneData(selectedZone);
	}, [selectedZone]);

	const handleZoneChange = (selectedOption) => {
		let zone = selectedOption.value;
		setSelectedZone(zone);
		setSelectedZoneOption(selectedOption);
		let index = zones.findIndex((ele) => ele.Zone === zone);
		setSelectedZoneID(zones[index].ZoneID);
	};

	const handleVehicleChange = (selectedOptions) => {
		setSelectedDetectionOptions([...selectedOptions]);
	};

	// adding dates
	const handleAdd = async () => {
		let formattedDate = formatDate(date);
		if (selectedDates.includes(formattedDate)) {
			toast.error(`${formattedDate} is already added`);
			return;
		}

		let dates = [...selectedDates];
		dates.push(formattedDate);
		dates.sort((a, b) => new Date(b) - new Date(a));

		setSelectedDates(dates);

		toast.success(`${formattedDate} added`);
	};

	const handleEdit = async () => {
		let formattedEditDate = formatDate(date);

		if (!selectedDates.includes(formattedEditDate)) {
			let dates = selectedDates;
			let index = dates.findIndex((ele) => ele === dateClicked);
			dates[index] = formattedEditDate;
			dates.sort((a, b) => new Date(b) - new Date(a));

			setSelectedDates(dates);
			setDateClicked(formattedEditDate);
		} else {
			toast.error(`${formattedEditDate} is already present , please select another date`);
		}
	};

	const handleDelete = async () => {
		setSelectedDates((prev) => prev.filter((ele) => ele !== dateClicked));

		toast.success(`${dateClicked} is succesfully deleted`);
	};

	async function updateDB() {
		const alertCollectionRef = doc(db, "NoMovementCollection", alertProfile);
		const detections = selectedDetectionOptions.map((detection) => detection.value);
		await updateDoc(alertCollectionRef, {
			Detection: detections,
			WeeklyRepetitiveDays: selectedDays,
			MonthlyRepetitiveDays: selectedDatesInMonth,
			NonRepetitiveDates: selectedDates,
		});
	}

	const handleSave = () => {
		updateDB()
			.then(() => {
				toast.success("Saved !");
			})
			.catch((err) => {
				toast.error("Could not save , ran into problems");
				console.log("err", err);
			});
	};

	if (isLoading) return <Loading />;

	if (!isLoading && !zones.length)
		return (
			<div className="flex flex-row justify-around">
				<div className="alert alert-error w-1/3">
					<svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
						/>
					</svg>
					<span>No Movement Alert Configuration is not available for this Location</span>
				</div>
			</div>
		);

	return (
		<div className="mx-6">
			{/* Title */}
			<h1 className="text-sky-900 text-[32px] font-semibold">No Movement</h1>
			<div className="pr-10">
				<div className="flex flex-row items-end justify-between my-8 w-[100rem] relative z-[2]">
					<div className="flex flex-row justify-between items-center w-[31rem]">
						<h1 className="text-black text-2xl font-normal">Zone</h1>
						<Select
							className="text-black"
							options={zoneOptions}
							value={selectedZoneOption}
							placeholder={"Select Zone"}
							onChange={handleZoneChange}
							styles={selectStyles}
						/>
					</div>
					<button className="fixed right-32 z-20 btn btn-accent hover:btn-success font-bold text-base" onClick={handleSave}>
						Save
					</button>
				</div>

				<div className="my-8 relative z-[2] flex flex-row justify-between items-center w-[31rem]">
					<h1 className="text-black text-2xl font-normal">Detections</h1>
					<Select
						className="text-black"
						options={detectionOptions}
						placeholder={"Select detection options"}
						value={selectedDetectionOptions}
						onChange={handleVehicleChange}
						styles={selectStyles}
						isMulti
					/>
				</div>

				{selectedClient && selectedLocation && selectedZone ? (
					<>
						{/* Repeat component */}
						<h2 className="my-3 text-black font-semibold lg:text-[32px] md:text-lg lg:text-left">Repetitive</h2>
						<div className="my-9 lg:grid grid-cols-2 items-start justify-around lg:mx-20">
							<div className="lg:flex lg:flex-col md:flex md:flex-row lg:gap-7 items-center md:gap-[7rem]">
								<p className="font-normal text-2xl text-slate-700 inline-block w-[68px]">Weekly</p>
								<Week selectedDays={selectedDays} setSelectedDays={setSelectedDays} />
							</div>

							<div className="lg:flex lg:flex-col md:flex md:flex-row lg:gap-7 items-center md:gap-[7rem]">
								<p className="font-normal text-2xl text-slate-700 inline-block w-[68px]">Monthly</p>
								<Month selectedDatesInMonth={selectedDatesInMonth} setSelectedDatesInMonth={setSelectedDatesInMonth} />
							</div>
						</div>

						{/* Non Repetitive component */}
						<div className="mt-16 mb-3">
							<div className="mb-11 flex flex-row justify-between">
								<h1 className="text-black font-semibold lg:text-[32px] md:text-base inline-block mr-10">Non-Repetitive</h1>

								{/* The button to open modal */}
								<label htmlFor="date_modal" className="btn bg-[#2E3192] font-bold text-base text-white ml-36 !md:text-sm">
									Add Date
								</label>
							</div>

							<div className="overflow-x-auto mx-2 mt-5 mb-8 max-h-[70vh] overflow-y-auto flex flex-row justify-around">
								<table className="w-1/2 text-center text-base font-medium border-separate border-spacing-x-0 border-spacing-y-2">
									<thead className="sticky top-0 border">
										<tr className="text-black">
											<th className="p-3 max-w-[200]">Dates</th>
											<th className="p-3 max-w-[50]">Edit</th>
											<th className="p-3 max-w-[50]">Delete</th>
										</tr>
									</thead>
									<tbody>
										{selectedDates.length ? (
											selectedDates.map((date, i) => {
												let dateArr = date.split("-");
												let numDate = dateArr[0];
												let month = dateArr[1];
												let year = dateArr[2];
												const curDate = new Date().setHours(0, 0, 0, 0);
												const d = new Date(date).setHours(0, 0, 0, 0);
												const bgColor = d < curDate ? `bg-slate-200` : d === curDate ? `bg-cyan-200` : `bg-[#FAFAFA]`;
												return (
													<tr className={`h-[65px]`} key={date}>
														<td className={`text-black border-black border-l border-t border-b rounded-tl-[20px] rounded-bl-[20px] ${bgColor}`}>
															{date}
															{/* {numDate}-{month}-{year} */}
														</td>
														<td className={`border-black border-t border-b ${bgColor}`}>
															<label
																htmlFor="Adddate_modal"
																id={`${numDate}-${month}-${year}`}
																onClick={(e) => {
																	// setDateToEdit(new Date(e.currentTarget.id));
																	setDate(new Date(e.currentTarget.id));
																	setDateClicked(e.currentTarget.id);
																}}
															>
																<div className="flex flex-row justify-around">
																	{d >= curDate && <FaEdit className="fill-blue-500 hover:fill-blue-800 active:border-violet-600 active:border-2" />}
																</div>
															</label>
														</td>
														<td className={`border-black border-r border-t border-b rounded-tr-[20px] rounded-br-[20px] ${bgColor}`}>
															<label
																htmlFor="delete_modal"
																id={`${numDate}-${month}-${year}`}
																onClick={(e) => {
																	// setDateToEdit(new Date(e.currentTarget.id));
																	setDate(new Date(e.currentTarget.id));
																	setDateClicked(e.currentTarget.id);
																}}
															>
																<div className="flex flex-row justify-around">
																	{d >= curDate && <FiTrash2 className="fill-red-500 hover:fill-red-800 active:border-pink-700 active:border-2" />}
																</div>
															</label>
														</td>
													</tr>
												);
											})
										) : (
											<p className="text-black text-base mb-3">No dates added yet</p>
										)}
									</tbody>
								</table>
							</div>

							{/* Add Modal */}
							<input type="checkbox" id="date_modal" className="modal-toggle" />
							<div className="modal">
								<div className="modal-box h-fit overflow-visible bg-white">
									<h3 className="font-bold text-lg text-black">Pick New Date Entry</h3>
									<DatePicker
										showIcon
										placeholderText="Click here to pick date"
										selected={date}
										onChange={(date) => setDate(date)}
										minDate={new Date()}
										className="bg-slate-100 rounded-sm my-4 text-slate-600"
									/>

									<div className="modal-action">
										<button className="btn btn-accent" onClick={handleAdd}>
											Add
										</button>
										<label htmlFor="date_modal" className="btn btn-active btn-neutral">
											Close
										</label>
									</div>
								</div>
							</div>

							{/* Edit Modal */}
							<input type="checkbox" id="Adddate_modal" className="modal-toggle" />
							<div className="modal">
								<div className="modal-box h-fit overflow-visible bg-white">
									<h3 className="font-bold text-lg text-black">Edit Date</h3>
									<DatePicker
										showIcon
										placeholderText="Click here to pick date"
										selected={date}
										onChange={(date) => setDate(date)}
										minDate={new Date()}
										className="bg-slate-100 rounded-sm my-4 text-slate-600"
									/>

									<div className="modal-action">
										<label className="btn btn-success" htmlFor="Adddate_modal" onClick={handleEdit}>
											Confirm
										</label>
										<label htmlFor="Adddate_modal" className="btn btn-active btn-neutral">
											Close
										</label>
									</div>
								</div>
							</div>

							{/* Delete Modal */}
							<input type="checkbox" id="delete_modal" className="modal-toggle" />
							<div className="modal">
								<div className="modal-box h-fit overflow-visible bg-white">
									<h3 className="font-bold text-lg text-black">Delete Date ?</h3>
									<p className="text-black">Are you sure you want to delete {dateClicked} ?</p>

									<div className="modal-action">
										<label className="btn btn-success" htmlFor="delete_modal" onClick={handleDelete}>
											Confirm
										</label>
										<label htmlFor="delete_modal" className="btn btn-active btn-neutral">
											Close
										</label>
									</div>
								</div>
							</div>
						</div>
					</>
				) : (
					<div className="h-[40rem] w-[100rem] flex flex-col justify-around items-center">
						<h1 className="px-2 bg-blue-100 rounded border-2 border-blue-700 text-2xl text-center text-blue-700">
							Select {!selectedClient && `Client,`} {!selectedLocation && `Location,`} {!selectedZone && `Zone`} to see related no movement alert
							configurations
						</h1>
					</div>
				)}
			</div>
		</div>
	);
};

export default NoMovement;
