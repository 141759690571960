import React, { useEffect, useState } from "react";
import Select from "react-select";
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { db } from "../../../../../firebase/firebase.config";
import Loading from "../../../../../components/Loading/Loading";
import { formatDateString } from "../../../../../Utility/fixDateFormat";
import { uniqueArr } from "../../../../../Utility/RemoveDuplicate";
import SecurityEventsChart from "../Charts/SecurityEventsChart";

const formateDateYYYYMMDD = (dateString) => {
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);
  return `${year}-${month}-${day}`;
};

const dateRangeGenerator = function* (start, end, step = 1) {
  let d = start;
  while (d <= end) {
    yield combineDate(d);
    d.setDate(d.getDate() + step);
  }
};

function combineDate(date) {
  const d = new Date(date);
  return d.getFullYear().toString() + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0");
}

// custom styling for react-select
const selectStyles = {
  container: (provided) => ({
    ...provided,
    minWidth: 300,
    maxWidth: 646,
  }),
};

const SecurityEvents = ({ selectedClient, selectedLocation }) => {
  const [securityEvents, setSecurityEvents] = useState([]);

  const [apply, isapply] = useState(true)
  const [charts, setCharts] = useState(false);

  const [dateRange, setDateRange] = useState([new Date().getTime() - 7 * 24 * 60 * 60 * 1000, new Date()]);
  const [startDate, endDate] = dateRange;

  const [detectionsOptions, setDetectionsOptions] = useState([]);
  const [selectedDetectionsOptions, setSelectedDetectionsOptions] = useState([]);

  const [actionOptions, setActionOptions] = useState([]);
  const [selectedActionOptions, setSelectedActionOptions] = useState([]);

  const [zoneData, setZoneData] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [selectedZone, setSelectedZone] = useState({ id: "", Zone: "" });

  const [loading, setLoading] = useState(true);
  // const selectedAction = 'Exit';

  async function fetchSecurityEventsData() {
    let list = [];

    const start = new Date(startDate);
    const startingDateString = combineDate(start);

    const end = new Date(endDate);
    const endingDateString = combineDate(end);
    let finalDocs = [];

    const dateRange = [...dateRangeGenerator(start, end)];

    for (let d of dateRange) {
      let q = query(
        collection(db, `${selectedClient.id}-entries-${d}`),
        where("LocationID", "==", selectedLocation.id),
        where("ZoneID", "==", selectedZone?.id),
        // where(
        //   "Action",
        //   "in",
        //   selectedActionOptions?.map((f) => f?.value)
        // )
      );

      try {
        const querySnapshot = await getDocs(q);
        querySnapshot?.docs?.map((h) => finalDocs?.push({ date: d, status: h?.data()?.Status, Detection: h?.data()?.Detection }));
      } catch (error) {
        console.log(error);
      }
    }
    const groupedRecords = finalDocs
      .filter((f) => selectedDetectionsOptions?.map((d) => d?.value)?.includes(f?.Detection))
      .reduce((acc, item) => {
        // Initialize the date object if it doesn't exist
        if (!acc[item?.date]) {
          acc[item.date] = {
            Approved: 0,
            Rejected: 0,
            Pending: 0,
            Total: 0,
            Date: formateDateYYYYMMDD(item?.date),
          };
        }
        if (item.status === "Approved") {
          acc[item.date].Approved++;
        } else if (item.status === "Rejected") {
          acc[item.date].Rejected++;
        } else if (item.status === "Pending") {
          acc[item.date].Pending++;
        }

        acc[item.date].Total++;

        return acc;
      }, {});

    // const q = query(
    //   collection(db, "PriorityStatusReport"),
    //   where("DateStamp", ">=", startingDateString),
    //   where("DateStamp", "<=", endingDateString),
    //   where("ClientID", "==", selectedClient.id),
    //   where("LocationID", "==", selectedLocation.id),
    //   where("ZoneID", "==", selectedZone?.id)
    // );

    // const querySnapshot = await getDocs(q);
    // querySnapshot.forEach((doc) => {
    //   // list.push({ id: doc.id, ...doc.data() });

    //   const data = doc.data();
    //   const dateString = data?.DateStamp;
    //   const clientID = data["ClientID"];
    //   const locationID = data["LocationID"];
    //   const date = formateDateYYYYMMDD(dateString);
    //   datesInDb.add(dateString);
    //   let entryExitCntObj = {
    //     Date: date,
    //     clientID,
    //     locationID,
    //     Pending: 0,
    //     Approved: 0,
    //     Rejected: 0,
    //     TotalEvents: 0,
    //   };

    //   selectedActionOptions.forEach((ele) => {
    //     const pendingKey = `Pending_${ele.value}`;
    //     if (Object.hasOwn(data, pendingKey))
    //       selectedDetectionsOptions?.forEach((detection) => {
    //         entryExitCntObj["Pending"] += data[pendingKey]?.[detection.value]
    //           ? data[pendingKey][detection.value]
    //           : 0;
    //       });
    //   });

    //   selectedActionOptions.forEach((ele) => {
    //     const spprovedKey = `Approved_${ele.value}`;
    //     if (Object.hasOwn(data, spprovedKey))
    //       selectedDetectionsOptions?.forEach((detection) => {
    //         entryExitCntObj["Approved"] += data[spprovedKey]?.[detection.value]
    //           ? data[spprovedKey][detection.value]
    //           : 0;
    //       });
    //   });

    //   selectedActionOptions.forEach((ele) => {
    //     const rejectedKey = `Rejected_${ele.value}`;
    //     if (Object.hasOwn(data, rejectedKey))
    //       selectedDetectionsOptions?.forEach((detection) => {
    //         entryExitCntObj["Rejected"] += data[rejectedKey]?.[detection.value]
    //           ? data[rejectedKey][detection.value]
    //           : 0;
    //       });
    //   });

    //   entryExitCntObj.TotalEvents =
    //     entryExitCntObj.Pending +
    //     entryExitCntObj.Approved +
    //     entryExitCntObj.Rejected;

    //   list.push(entryExitCntObj);
    // });

    // // console.log(list);

    // // adding dates that were not in db
    // dateRange.forEach((ele) => {
    //   if (!datesInDb.has(ele)) {
    //     list.push({
    //       Date: formateDateYYYYMMDD(ele),
    //       clientID: null,
    //       locationID: null,
    //       Pending: 0,
    //       Approved: 0,
    //       Rejected: 0,
    //       TotalEvents: 0,
    //     });
    //   }
    // });
    // list.sort((a, b) => a["Date"] < b["Date"]);

    setSecurityEvents(Object.values(groupedRecords || {}).sort((a, b) => b.Date.localeCompare(a.Date)));
    setLoading(false);
    isapply(false)
  }

  async function fetchDetections() {
    const docRef = doc(db, `clients-data-collection/${selectedClient.id}/Location/${selectedLocation.id}/Detections`, "detection-list");
    const docSnap = await getDoc(docRef);
    let detectionList = docSnap.data()?.Vehicles;
    detectionList = detectionList.filter((detection) => detection !== "Numberplate").sort();
    let options = detectionList.map((detection) => ({
      value: detection,
      label: detection,
    }));
    setDetectionsOptions(options);
    setSelectedDetectionsOptions(options);
  }

  async function fetchActions() {
    const docRef = doc(db, `clients-data-collection/${selectedClient.id}/Location/${selectedLocation.id}/Directions`, "direction-list");
    const docSnap = await getDoc(docRef);
    let directionList = docSnap.data()?.["direction-list"];
    let options = directionList.map((direction) => ({
      value: direction,
      label: direction,
    }));
    setActionOptions(options);
    setSelectedActionOptions(options);
  }

  //fetch those Zone according to selected Location
  useEffect(() => {
    if (selectedClient && selectedLocation) {
      const unsub = onSnapshot(
        query(collection(db, `clients-data-collection/${selectedClient?.id}/Location/${selectedLocation?.id}/Zones`)),
        (snapShot) => {
          let list = [];
          snapShot.docs.forEach((doc) => {
            list.push({ id: doc.id, ...doc.data() });
          });
          // console.log('zoneList:', list);
          setZoneData(list);
          const zoneList = list?.map((l) => l?.ZoneName);
          setZoneOptions(uniqueArr(zoneList));
          // console.log('zone name:', zoneList);
        },
        (error) => {
          console.log(error);
        }
      );

      return () => {
        unsub();
      };
    }
  }, [selectedClient, selectedLocation]);

  //Initially select the first zone
  useEffect(() => {
    if (zoneData?.length > 0 && !selectedZone.id) {
      setSelectedZone({ id: zoneData[0]?.id, Zone: zoneData[0]?.ZoneName });
    }
  }, [zoneData, selectedZone.id]);

  //call the detection and direction data function
  useEffect(() => {
    if (selectedClient.id && selectedLocation.id) {
      fetchDetections();
      fetchActions();
    }
  }, [selectedClient.id, selectedLocation.id]);

  useEffect(() => {
    // if(!apply){
    //   return
    // }
    if (selectedClient.id && selectedLocation.id && selectedDetectionsOptions.length  && startDate && endDate) {
      setLoading(true);
      fetchSecurityEventsData();
    }
  }, [selectedClient.id, selectedLocation.id, selectedDetectionsOptions.length, startDate, endDate]);

  const handleDetectionsSelect = (selectedOptions) => {
    setSelectedDetectionsOptions(selectedOptions);
  };

  const handleActionSelect = (selectedActionOptions) => {
    setSelectedActionOptions(selectedActionOptions);
  };

  // console.log(detectionsOptions,selectedDetectionsOptions );
  // console.log(actionOptions,selectedActionOptions );

  return (
    <div className="mx-6">
      <div className="flex items-center justify-between">
      <h1 className="text-black text-2xl font-semibold mr-3">Records Report</h1>
      <div className="flex bg-gray-50 px-2 py-0">
        <div className="xl:flex xl:flex-row xl:justify-between xl:items-center md:flex md:flex-row md:justify-between md:items-center md:w-[23rem] xl:w-[17rem] my-5">
          {selectedClient && selectedLocation ? (
            <>
              <h1 className="text-black text-lg mr-3">Zone : </h1>

              <select
                className="bg-white rounded text-black text-lg w-[9rem]"
                value={selectedZone?.Zone ? selectedZone?.Zone : "Select Zone"}
                // onChange={(e) => setSelectedZone(e.target.value)}
                onChange={(e) =>
                  setSelectedZone({
                    Zone: e.target.value,
                    id: e.target.selectedOptions[0].id,
                  })
                }
              >
                <option defaultValue disabled>
                  Select Zone
                </option>

                {zoneData.map((loc) => (
                  <option key={loc.id} id={loc.id} value={loc.ZoneName}>
                    {loc.ZoneName}
                  </option>
                ))}
              </select>
            </>
          ) : (
            <h1 className="bg-red-100 rounded border-2 border-red-600 text-red-600 text-xl mt-2  mr-3 px-2">Select Client and locations to see available zone</h1>
          )}
        </div>

        {/* Action */}
        {/* <div className="flex flex-row gap-4 items-center ml-5">
          <h1 className="text-black text-base xl:text-lg">Action:</h1>
          <Select className="text-black" options={actionOptions} placeholder={"Select Action"} value={selectedActionOptions} onChange={handleActionSelect} styles={selectStyles} isMulti />
        </div> */}
      </div>
      {/* <button onClick={()=>isapply(true)} className="bg-yellow-400 text-black px-3 py-1 text-[14px] font-medium hover:bg-yellow-500 active:bg-yellow-600">Apply</button> */}
      </div>
     
      <div className="flex flex-col gap-3 my-5">
        <div className="flex flex-row gap-2 xl:justify-between items-center">
          {/* Detection and Date Range */}
          <div className="flex flex-row gap-4">
            {/* Detections */}
            <div className="flex flex-row gap-1 items-center">
              <h1 className="text-black text-base xl:text-base">Detection:</h1>
              <Select className="text-black" options={detectionsOptions} placeholder={"Select Detections"} value={selectedDetectionsOptions} onChange={handleDetectionsSelect} styles={selectStyles} isMulti />
            </div>
            {/* Date Range */}
            <div className="flex flex-row gap-1 items-center">
              <h1 className="text-black text-sm xl:text-base">Date range:</h1>
              <DatePicker
                className="bg-gray-100 text-black w-fit px-5"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                isClearable={true}
                showIcon
                placeholderText="Select Date range"
                renderInput
              />
            </div>
          </div>
          {/* Toggle and  Download*/}
          <div className="flex flex-row gap-3">
            {!loading && securityEvents.length && (
              <div className="flex flex-row mr-3 gap-3 items-center bg-slate-200 p-1 rounded">
                <span className="text-black">Data</span>
                <input type="checkbox" className="toggle toggle-accent" onChange={(e) => setCharts(e.target.checked)} checked={charts} />
                <span className="text-black">Chart</span>
              </div>
            )}

            {!loading && securityEvents.length && selectedDetectionsOptions.length && (
              <CSVLink data={securityEvents} filename={"Records Report.csv"} className="mr-6 bg-green-500 hover:bg-green-900 hover:border-green-300 px-2 py-1 active:border-2 active:border-green-900 w-36 text-center text-white font-semibold h-[2.5rem]   border-2 border-green-800">
                Download
              </CSVLink>
            )}
          </div>
        </div>

        {selectedDetectionsOptions.length === 0 ? (
          <div className="flex flex-row justify-around my-6">
            <div className="alert alert-info px-20 w-fit">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
              <span>Select Detections !</span>
            </div>
          </div>
        ) : charts ? (
          <>{loading ? <Loading /> : <SecurityEventsChart securityEvents={securityEvents}></SecurityEventsChart>}</>
        ) : (
          <>
            {startDate && endDate ? (
              loading ? (
                <Loading />
              ) : (
                <div className="flex flex-col gap-7 mr-6 overflow-x-auto overflow-y-auto max-h-[70vh]">
                  <table className="text-center self-center w-full border-t-[3px] border-t-black border-b-2 border-b-black">
                    <thead className="sticky top-0 bg-gray-100">
                      <tr className="border-b text-black">
                        <th className="p-3 max-w-[200] xl:text-base text-sm">Date</th>
                        <th className="p-3 max-w-[200] xl:text-base text-sm">Pending</th>
                        <th className="p-3 max-w-[200] xl:text-base text-sm">Approved</th>
                        <th className="p-3 max-w-[200] xl:text-base text-sm">Rejected</th>
                        <th className="p-3 max-w-[200] xl:text-base text-sm">Total</th>
                      </tr>
                    </thead>
                    <tbody className="bg-sky-100">
                      {securityEvents?.map((ele) => (
                        <tr key={ele["Date"]} className="border-b text-black">
                          <td className="p-3 max-w-[80] xl:text-base text-sm">{ele["Date"]}</td>
                          <td className="p-3 max-w-[80] xl:text-base text-sm">{ele["Pending"]}</td>
                          <td className="p-3 max-w-[80] xl:text-base text-sm">{ele["Approved"]}</td>
                          <td className="p-3 max-w-[80] xl:text-base text-sm">{ele["Rejected"]}</td>
                          <td className="p-3 max-w-[80] xl:text-base text-sm">{ele["Total"]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            ) : (
              <div className="flex flex-row justify-around my-6">
                <div className="alert alert-info px-20 w-fit">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  <span>
                    Select {!startDate && "Starting date and"} {!endDate && "Ending date"}
                  </span>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SecurityEvents;
