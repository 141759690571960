import React from 'react';
import { db } from '../../../../firebase/firebase.config';
import { doc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import BlueBtn from '../../../../components/Buttons/BlueBtn';

const EditDeviceCamera = ({ aCamera, setACamera, deviceId, useCase }) => {
    const { CameraName, CameraIP, id } = aCamera;

    const handleUpdateCamera = (e) => {
        e.preventDefault();
        const form = e.target;
        const cameraName = form.cameraName.value;
        const cameraIp = form.cameraIp.value;
        const usecase = form.usecase.value;
        // console.log(cameraName, cameraIp);

        if ((cameraName.length < 1) || (cameraIp.length < 1)) {
            return;
        }
        else {
            UpdateCameraData(cameraName, cameraIp, usecase);
            form.reset();
        }
    }

    const UpdateCameraData = async (cameraName, cameraIp, usecase) => {
        // Update document in collection "device-data-collection"
        try {
            const docRef = doc(db, `device-data-collection/${deviceId}/Cameras`, id);
            const res = await updateDoc(docRef, {
                CameraName: cameraName,
                CameraIP: cameraIp,
                UseCase: usecase,
            });
            setACamera(null);
            toast.success('Camera data updated');
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <div>
            <input type="checkbox" id="updateCamera-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative rounded-none">
                    <label
                        htmlFor="updateCamera-modal"
                        className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]">
                        ✕
                    </label>
                    <h3
                        className="text-3xl text-center font-bold mb-7">
                        Update Camera
                    </h3>

                    <form onSubmit={handleUpdateCamera}>

                        {/* Camera Name */}
                        <div className="form-control flex mt-3">
                            <input
                                type="text"
                                name='cameraName'
                                placeholder="Camera Name"
                                defaultValue={CameraName}
                                key={CameraName}
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* Camera IP */}
                        <div className="form-control flex mt-3">
                            <input
                                type="text"
                                name='cameraIp'
                                placeholder="Camera IP"
                                defaultValue={CameraIP}
                                key={CameraIP}
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* use case */}
                        <div className="form-control mt-3">
                            <select
                                name='usecase'
                                defaultValue={useCase}
                                key={useCase}
                                className="select w-full bg-white border-white border-b-[#D9D9D9] rounded-none" required >
                                {
                                    useCase?.length > 0 ? <>
                                        {
                                            useCase?.map((u, i) => <option
                                                value={u}
                                                key={i}
                                            >{u}</option>)
                                        }
                                    </>
                                        : <option disabled>No usecase available</option>
                                }

                            </select>
                        </div>

                        <div className="form-control mt-3">
                            <BlueBtn>Update</BlueBtn>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    );
};

export default EditDeviceCamera;