import React from "react";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { toast } from "react-hot-toast";
import Select from "react-select";
import { db } from "../../../../../../firebase/firebase.config";
import Loading from "../../../../../../components/Loading/Loading";


const ExceptionAlertMain = ({ selectedClient, selectedClientID, selectedLocation, selectedLocationID, devices }) => {
	const [zones, setZones] = useState([]);
	const [zoneOptions, setZoneOptions] = useState([]);

	const [detectionOptions, setDetectionOptions] = useState([]); // options for react-select
	const [selectedDetectionOptions, setSelectedDetectionOptions] = useState([]); // to control react-select

	const [selectedZone, setSelectedZone] = useState("");
	const [selectedZoneOption, setSelectedZoneOption] = useState("");
	const [selectedZoneID, setSelectedZoneID] = useState("");
    const [exception_event, set_exception_event] = useState({entrytype:'', entrydelta:'', exittype:'', exitdelta:''})
    const [exception_record, set_exception_record] = useState({entrytype:'', entrydelta:'', exittype:'', exitdelta:''})
	const [alertProfile, setAlertProfile] = useState("");
  


	const [isLoading, setIsLoading] = useState(true);

	// custom styling for react-select
	const selectStyles = {
		container: (provided, state) => ({
			...provided,
			width: "350px",
			minHeight: "55px",
			// maxHeight: `${55 + (state.selectProps.value ? state.selectProps.value.length * 40 : 0)}px`,
			borderWidth: 1,
			borderStyle: "solid",
			borderColor: "#2E3192",
			borderRadius: "8px",
		}),
		control: (provided, state) => ({
			...provided,
			backgroundColor: "#F2F5F7",
			minHeight: "55px",
			// maxHeight: `${55 + (state.selectProps.value ? state.selectProps.value.length * 40 : 0)}px`,
			borderRadius: "8px",
		}),
		menu: (provided) => ({
			...provided,
			backgroundColor: "#F2F5F7",
		}),
		indicatorSeparator: (provided) => ({
			...provided,
			display: "block",
			backgroundColor: "#2E3192",
		}),
	};

	//fetch detection list for selected client location
	const fetchDetections = async () => {
		const detectionCollectionRef = doc(db, `clients-data-collection/${selectedClientID}/Location/${selectedLocationID}/Detections`, "detection-list");
		const docSnap = await getDoc(detectionCollectionRef);
		let detections = docSnap.data()?.Vehicles;
		detections = detections.filter((detection) => detection !== "Numberplate").sort();
		setDetectionOptions([...detections.map((ele) => ({ value: ele, label: ele }))]);
	};

	useEffect(() => {
		if (selectedClient && selectedLocation) {
			let zoneDataArray = devices.filter((ele) => ele.Client === selectedClient && ele.Location === selectedLocation);

			let uniqueZoneDataArray = [...new Map(zoneDataArray.map((item) => [item["Zone"], item])).values()];
			setZones(uniqueZoneDataArray);
			const zoneOptionsList = [];
			uniqueZoneDataArray.forEach((ele) =>
				zoneOptionsList.push({
					value: ele.Zone,
					label: ele.Zone,
				})
			);
			setZoneOptions(zoneOptionsList);

			// setting default zone
			setSelectedZone(uniqueZoneDataArray?.[0]?.Zone);
			setSelectedZoneOption({ value: uniqueZoneDataArray?.[0]?.Zone, label: uniqueZoneDataArray?.[0]?.Zone });
			setSelectedZoneID(uniqueZoneDataArray?.[0]?.ZoneID);
		}

		//fetching detections
		if (selectedClient && selectedLocation) fetchDetections().catch((err) => console.log("Err Fetching Detections", err));
	}, [selectedClient, selectedClientID, selectedLocation, selectedLocationID, devices]);

	useEffect(() => {
		const setZoneData = async (zone) => {
			setIsLoading(true);
			const alertProfileString = `${selectedClientID}-${selectedLocationID}-EA-${selectedZoneID}`;
			setAlertProfile(alertProfileString);
			const alertCollectionRef = doc(db, "ExceptionAlertCollection", alertProfileString);
			const docSnap = await getDoc(alertCollectionRef);
			if (docSnap.exists()) {
                let {Exception_Event, Exception_Record} = docSnap?.data()||{}
                set_exception_event(Exception_Event)
                set_exception_record(Exception_Record)
				setSelectedDetectionOptions([...docSnap.data().Detection.map((vehicle) => ({ label: vehicle, value: vehicle }))]);
			} else {
				await setDoc(alertCollectionRef, {
					AlertProfile: alertProfileString,
					AlertName: "Exception Alert",
					Client: selectedClient,
					Location: selectedLocation,
					Zone: zone,
					Detection: []
				});
			}
			setIsLoading(false);
		};
		if (selectedZone) setZoneData(selectedZone);
	}, [selectedZone]);

	const handleZoneChange = (selectedOption) => {
		let zone = selectedOption.value;
		setSelectedZone(zone);
		setSelectedZoneOption(selectedOption);
		let index = zones.findIndex((ele) => ele.Zone === zone);
		setSelectedZoneID(zones[index].ZoneID);
	};

	const handleVehicleChange = (selectedOptions) => {
		setSelectedDetectionOptions([...selectedOptions]);
	};


	async function updateDB() {
		const alertCollectionRef = doc(db, "ExceptionAlertCollection", alertProfile);
		const detections = selectedDetectionOptions.map((detection) => detection.value);
		await updateDoc(alertCollectionRef, {
			Detection: detections,
            Exception_Event:exception_event,
            Exception_Record:exception_record,
		});
	}

	const handleSave = () => {
		updateDB()
			.then(() => {
				toast.success("Saved !");
			})
			.catch((err) => {
				toast.error("Could not save , ran into problems");
				console.log("err", err);
			});
	};

	if (isLoading) return <Loading />;

	if (!isLoading && !zones.length)
		return (
			<div className="flex flex-row justify-around">
				<div className="alert alert-error w-1/3">
					<svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
						/>
					</svg>
					<span>No Movement Alert Configuration is not available for this Location</span>
				</div>
			</div>
		);

	return (
		<div className="mx-6">
			<h1 className="text-sky-900 text-[32px] font-semibold">Exception Alert</h1>
            <div className="flex gap-10">
            
          </div>
			<div className="pr-10 flex h-full w-full items-start flex-wrap lg:flex-nowrap gap-2 lg:gap-6">
				<div className="flex flex-row items-end justify-between my-8 w-[100rem] relative z-[2]">
					<div className="flex flex-row justify-between items-start gap-20 lg:gap-5">
						<h1 className="text-black text-2xl font-normal">Zone</h1>
						<Select
							className="text-black"
							options={zoneOptions}
							value={selectedZoneOption}
							placeholder={"Select Zone"}
							onChange={handleZoneChange}
							styles={selectStyles}
						/>
					</div>
				</div>

				<div className="my-8 relative z-[2] flex flex-row justify-between items-start gap-3 w-[31rem]">
					<h1 className="text-black text-2xl font-normal">Detections</h1>
					<Select
						className="text-black"
						options={detectionOptions}
						placeholder={"Select detection options"}
						value={selectedDetectionOptions}
						onChange={handleVehicleChange}
						styles={selectStyles}
						isMulti
					/>
				</div>
			</div>
            <div className="exceptioneventrecordset flex justify-between flex-wrap gap-10">
                    <div className="event">
                        <div className="head mb-5">
                        <h5 className="text-[28px] text-black font-semibold">Exception Event</h5>
                        <p className="text-slate-500">(records not created by security agent)</p>
                        </div>
                        <div className="body flex gap-5 flex-col">
                            <div className="entry flex items-center gap-5">
                            <label className="text-black text-[20px] w-[100px]">For Entry</label>
                            <div className="entryset flex gap-5">
                                    <Select
                                    className="text-black h-full"
                                    options={[{value:'After', label:'After'}, {value:'Before', label:'Before'}]}
                                    placeholder={"Select Zone"}
                                    onChange={e=>set_exception_event(prev=>({...prev, entrytype:e.value}))}
                                    defaultInputValue={exception_event?.entrytype}
                                    styles={selectStyles}
                                />
                              <input value={exception_event?.entrydelta} onChange={e=>set_exception_event(prev=>({...prev, entrydelta:e.target.value}))} type="number" className="text-[20px] text-center text-black bg-blue-50 h-[60px] rounded-lg border border-blue-800 w-[100px]" name="" id="" />
                            </div>
                            
                            </div>
                            <div className="entry flex items-center gap-5">
                            <label className="text-black text-[20px] w-[100px]">For Exit</label>
                            <div className="entryset flex gap-5">
                                    <Select
                                    className="text-black h-full"
                                    options={[{value:'After', label:'After'}, {value:'Before', label:'Before'}]}
                                    placeholder={"Select Zone"}
                                    styles={selectStyles}
                                    onChange={e=>set_exception_event(prev=>({...prev, exittype:e.value}))}
                                    defaultInputValue={exception_event?.exittype}
                                />
                              <input value={exception_event?.exitdelta} onChange={e=>set_exception_event(prev=>({...prev, exitdelta:e.target.value}))} type="number" className="text-[20px] text-center text-black bg-blue-50 h-[60px] rounded-lg border border-blue-800 w-[100px]" name="" id="" />
                            </div>
                            
                            </div>
                        </div>

                    </div>
                    <div className="records">
                    <div className="head mb-5">
                        <h5 className="text-[28px] text-black font-semibold">Exception Record</h5>
                        <p className="text-slate-500">(Event not generated by AI system)</p>
                        </div>
                        <div className="body flex gap-5 flex-col">
                            <div className="entry flex items-center gap-5">
                            <label className="text-black text-[20px] w-[100px]">For Entry</label>
                            <div className="entryset flex gap-5">
                                    <Select
                                    className="text-black h-full"
                                    options={[{value:'After', label:'After'}, {value:'Before', label:'Before'}]}
                                    defaultInputValue={exception_record?.entrytype}
                                    placeholder={"Select Type"}
                                    onChange={e=>set_exception_record(prev=>({...prev, entrytype:e.value}))}
                                    styles={selectStyles}
                                />
                              <input value={exception_record?.entrydelta} onChange={e=>set_exception_record(prev=>({...prev, entrydelta:e.target.value}))} type="number" className="text-[20px] text-center text-black bg-blue-50 h-[60px] rounded-lg border border-blue-800 w-[100px]" name="" id="" />
                            </div>
                            
                            {console.log(exception_record?.exittype, 'exception_record?.exittype')}
                            </div>
                            <div className="entry flex items-center gap-5">
                            <label className="text-black text-[20px] w-[100px]">For Exit</label>
                            <div className="entryset flex gap-5">
                                    <Select
                                    className="text-black h-full"
                                    defaultInputValue={exception_record?.exittype}
                                    options={[{value:'After', label:'After'}, {value:'Before', label:'Before'}]}
                                    placeholder={"Select Type"}
                                    onChange={e=>set_exception_record(prev=>({...prev, exittype:e.value}))}
                                    styles={selectStyles}
                                />
                              <input value={exception_record?.exitdelta} onChange={e=>set_exception_record(prev=>({...prev, exitdelta:e.target.value}))} type="number" className="text-[20px] text-center text-black bg-blue-50 h-[60px] rounded-lg border border-blue-800 w-[100px]" name="" id="" />
                            </div>
                            
                            </div>
                        </div>
                    </div>
                </div>

                <button className="fixed mt-10 right-32 z-20 btn btn-accent hover:btn-success font-bold text-base" onClick={handleSave}>
						Save
					</button>
		</div>
	);
};

export default ExceptionAlertMain;
