import { useCallback } from "react";
import { useRef } from "react";
import Webcam from "react-webcam";
import toast from "react-hot-toast";
import convertImageSourceToFile from "../Utility/convertImageSourceToFile";

const videoConstraints = {
	width: 1280,
	height: 720,
	facingMode: "user",
};

const ImageCaptureModal = ({ setCameraModal, setFiles }) => {
	const webcamRef = useRef(null);

	const capture = useCallback(async () => {
		const imageSrc = webcamRef.current.getScreenshot();
		const imageFile = await convertImageSourceToFile(imageSrc);
		imageFile.url = imageSrc;
		setFiles((prev) => [...prev, imageFile]);
		toast.success("Captured");
	}, [webcamRef]);

	return (
		<>
			<input type="checkbox" id="camera_modal" className="modal-toggle" />
			<div className="modal" role="dialog">
				<div className="modal-box">
					<Webcam ref={webcamRef} audio={false} height={720} screenshotFormat="image/jpeg" width={1280} videoConstraints={videoConstraints} />
					<div className="modal-action">
						<button onClick={capture} className="btn btn-secondary">
							Capture
						</button>
						<label htmlFor="camera_modal" className="btn" onClick={() => setCameraModal(false)}>
							Close
						</label>
					</div>
				</div>
			</div>
		</>
	);
};

export default ImageCaptureModal;
