import React from "react";
import { useState } from "react";

const AddModal = ({ setRegisteredOrganizationList, setAddModalStatus }) => {
	const [newRegisteredOrganization, setNewRegisteredOrganization] = useState("");
	const handleAdd = () => {
		setRegisteredOrganizationList((prev) => [...prev, newRegisteredOrganization]);
	};
	return (
		<>
			<input type="checkbox" id="add_registeredOrganization_modal" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box !rounded-none h-fit overflow-visible bg-white">
					<h3 className="font-bold text-lg text-black">Add New Registered Organization</h3>
					{/* <input type="text" className="mt-2 w-full" value={newRegisteredOrganization} onChange={(e) => setNewRegisteredOrganization(e.target.value)} /> */}
					<textarea className="mt-2 w-full" value={newRegisteredOrganization} onChange={(e) => setNewRegisteredOrganization(e.target.value)} />
					<div className="modal-action">
						<button className="btn btn-accent !rounded-none" onClick={handleAdd}>
							Add
						</button>
						<label
							htmlFor="add_registeredOrganization_modal"
							className="btn btn-active btn-neutral !rounded-none"
							onClick={() => setAddModalStatus(false)}
						>
							Close
						</label>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddModal;
