import { useState } from "react";

const EventDetailsForm = ({ eventDoc, availableDetections, availablePurposes, availableRegisteredOrgs, handleSubmit }) => {
	const { id, Location: locationName, Zone: zoneName, Timestamp, Direction, Class_label: detection } = eventDoc;
	const [selectedDetection, setSelectedDetection] = useState(detection);
	return (
		<div className="bg-white rounded-lg h-[921px] p-4 overflow-hidden">
			<h1 className="font-semibold text-base">Create Entry</h1>
			<form className="my-1 flex flex-col gap-1 w-full h-fit" id="entry-form" onSubmit={handleSubmit}>
				<div className="form-control">
					<div className="label">
						<span className="label-text  text-base font-semibold">Event</span>
					</div>
					<input
						className="border input-bordered input disabled"
						type="text"
						name="event"
						placeholder="Event"
						defaultValue={Direction}
						readOnly
						disabled={true}
					/>
				</div>
				<div className="form-control">
					<div className="label">
						<span className="label-text  text-base font-semibold">Location</span>
					</div>
					<input
						className="border input-bordered input disabled"
						type="text"
						name="location"
						placeholder="Location"
						defaultValue={locationName}
						readOnly
						disabled={true}
					/>
				</div>
				<div className="form-control">
					<div className="label">
						<span className="label-text  text-base font-semibold">Zone</span>
					</div>
					<input
						className="border input-bordered input disabled"
						type="text"
						name="zone"
						placeholder="Zone"
						defaultValue={zoneName}
						readOnly
						disabled={true}
					/>
				</div>
				<div className="form-control">
					<label className="label">
						<span className="label-text  text-base font-semibold">Time</span>
					</label>
					<input
						className="border input-bordered input disabled"
						type="text"
						name="time"
						placeholder="Date Time"
						key={Timestamp}
						defaultValue={Timestamp}
						readOnly
						disabled={true}
					/>
				</div>
				<div className="form-control">
					<label className="label">
						<span className="label-text  text-base font-semibold">Vehicle</span>
					</label>
					<select
						name="detection"
						className="select input-bordered"
						value={selectedDetection}
						onChange={(e) => setSelectedDetection(e.target.value)}
						required
					>
						{availableDetections.map((detection) => (
							<option key={detection}>{detection}</option>
						))}
					</select>
				</div>
				<div className="form-control">
					<label className="label">
						<span className="label-text  text-base font-semibold">Number</span>
					</label>
					<input className="border input-bordered input disabled" type="text" name="number" placeholder="Enter Vehicle Number" required />
				</div>
				{/* Purpose */}
				<div className="form-control">
					<label className="label">
						<span className="label-text  text-base font-semibold">Purpose</span>
					</label>
					<select name="purpose" className="select input-bordered" required>
						{availablePurposes.map((purpose) => (
							<option key={purpose}>{purpose}</option>
						))}
					</select>
				</div>

				{/* Sender */}
				<div className="form-control">
					<label className="label">
						<span className="label-text  text-base font-semibold">Sender</span>
					</label>
					<select name="sender" className="select input-bordered" required>
						{availableRegisteredOrgs.map((orgs) => (
							<option key={orgs}>{orgs}</option>
						))}
					</select>
				</div>

				{/* Remarks */}
				<div className="form-control">
					<label className="label">
						<span className="label-text  text-base font-semibold">Remarks</span>
					</label>
					<input className="border input-bordered input disabled" type="text" name="remarks" placeholder="Enter Remarks" required />
				</div>
			</form>
		</div>
	);
};

export default EventDetailsForm;
