import React from "react";

const Month = ({ selectedDatesInMonth, setSelectedDatesInMonth }) => {
	const dateInMonthArray = Array.from({ length: 31 }, (_, index) => (index + 1).toString().padStart(2, "0"));
	const firstRow = dateInMonthArray.slice(0, 7);
	const secondRow = dateInMonthArray.slice(7, 14);
	const thirdRow = dateInMonthArray.slice(14, 21);
	const fourthRow = dateInMonthArray.slice(21, 28);
	const fifthRow = dateInMonthArray.slice(28, 31);

	const handleMonthSelect = async (e) => {
		const dateOfMonth = e.target.name;

		if (e.target.checked) {
			setSelectedDatesInMonth((prevMonths) => [...prevMonths, dateOfMonth]);
		} else {
			setSelectedDatesInMonth((prevMonths) => {
				const afterExclusion = prevMonths.filter((d) => d !== dateOfMonth);
				return afterExclusion;
			});
		}
	};

	return (
		<div className="flex flex-col gap-[1px]">
			<div className="flex flex-row w-[374px]">
				{firstRow.map((d) => (
					<div className="relative inline-block lg:text-lg md:text-sm h-[50px] w-[50px]" key={d}>
						<input
							type="checkbox"
							id={d}
							name={d}
							onChange={handleMonthSelect}
							checked={selectedDatesInMonth?.includes(d)}
							className="peer appearance-none bg-slate-200 hover:bg-slate-400 hover:cursor-pointer checked:bg-blue-500 h-12 w-12"
						/>
						<label
							htmlFor={d}
							className="peer-checked:text-white hover:cursor-pointer absolute z-[1] lg:top-[22%] lg:left-[30%] md:top-[27%] md:left-[33%] text-gray-700"
						>
							{d}
						</label>
					</div>
				))}
			</div>
			<div className="flex flex-row w-[374px]">
				{secondRow.map((d) => (
					<div className="relative inline-block lg:text-lg md:text-sm h-[50px] w-[50px]" key={d}>
						<input
							type="checkbox"
							id={d}
							name={d}
							onChange={handleMonthSelect}
							checked={selectedDatesInMonth?.includes(d)}
							className="peer appearance-none bg-slate-200 hover:bg-slate-400 hover:cursor-pointer checked:bg-blue-500 h-12 w-12"
						/>
						<label
							htmlFor={d}
							className="peer-checked:text-white absolute z-[1] hover:cursor-pointer lg:top-[22%] lg:left-[30%] md:top-[27%] md:left-[33%] text-gray-700"
						>
							{d}
						</label>
					</div>
				))}{" "}
			</div>
			<div className="flex flex-row w-[374px]">
				{thirdRow.map((d) => (
					<div className="relative inline-block lg:text-lg md:text-sm h-[50px] w-[50px]" key={d}>
						<input
							type="checkbox"
							id={d}
							name={d}
							onChange={handleMonthSelect}
							checked={selectedDatesInMonth?.includes(d)}
							className="peer appearance-none bg-slate-200 hover:bg-slate-400 hover:cursor-pointer checked:bg-blue-500 h-12 w-12"
						/>
						<label
							htmlFor={d}
							className="peer-checked:text-white absolute z-[1] hover:cursor-pointer lg:top-[22%] lg:left-[30%] md:top-[27%] md:left-[33%] text-gray-700"
						>
							{d}
						</label>
					</div>
				))}
			</div>
			<div className="flex flex-row w-[374px]">
				{fourthRow.map((d) => (
					<div className="relative inline-block lg:text-lg md:text-sm h-[50px] w-[50px]" key={d}>
						<input
							type="checkbox"
							id={d}
							name={d}
							onChange={handleMonthSelect}
							checked={selectedDatesInMonth?.includes(d)}
							className="peer appearance-none bg-slate-200 hover:bg-slate-400 hover:cursor-pointer checked:bg-blue-500 h-12 w-12"
						/>
						<label
							htmlFor={d}
							className="peer-checked:text-white absolute z-[1] hover:cursor-pointer lg:top-[22%] lg:left-[30%] md:top-[27%] md:left-[33%] text-gray-700"
						>
							{d}
						</label>
					</div>
				))}
			</div>
			<div className="flex flex-row w-[374px]">
				{fifthRow.map((d) => (
					<div className="relative inline-block lg:text-lg md:text-sm h-[50px] w-[50px]" key={d}>
						<input
							type="checkbox"
							id={d}
							name={d}
							onChange={handleMonthSelect}
							checked={selectedDatesInMonth?.includes(d)}
							className="peer appearance-none bg-slate-200 hover:bg-slate-400 hover:cursor-pointer checked:bg-blue-500 h-12 w-12"
						/>
						<label
							htmlFor={d}
							className="peer-checked:text-white absolute z-[1] hover:cursor-pointer lg:top-[22%] lg:left-[30%] md:top-[27%] md:left-[33%] text-gray-700"
						>
							{d}
						</label>
					</div>
				))}
			</div>
		</div>
	);
};

export default Month;
