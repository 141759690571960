import { MdDeleteForever } from "react-icons/md";

const ImageGalleryDisplay = ({ files, setFiles }) => {
	const handleDelete = (index) => {
		const updatedFiles = [...files];
		updatedFiles.splice(index, 1);
		setFiles(updatedFiles);
	};

	return (
		<div className="w-full h-full overflow-y-scroll flex flex-col gap-2">
			{files.map((file, index) => (
				<div key={file.name + index} className="bg-[#F2F5F7] h-72 border border-[#2E3192] relative rounded-md">
					<img className="h-full w-full rounded-md" alt={file.name} src={file.url} />
					<button
						className="absolute bottom-0 right-0 rounded-br-md bg-red-500 group hover:bg-red-100 hover:border-2 hover:border-red-600 text-white p-2 hover:transition-all hover:ease-in-out"
						onClick={() => handleDelete(index)}
					>
						<MdDeleteForever className="group-hover:text-red-500 text-2xl" />
					</button>
				</div>
			))}
		</div>
	);
};

export default ImageGalleryDisplay;
