import React from "react";
import AlMonkLogo from "../../../../components/AlMonkLogo";
import { useContext } from "react";
import { DataContext } from "../../../../context/DataProvider/DataProvider";
import { useState } from "react";
import { useEffect } from "react";
import Daily from "./Categories/Daily";
import DailyTraffic from "./Categories/DailyTraffic";
import DayWiseTraffic from "./Categories/DayWiseTraffic";
import Violations from "./Categories/Violations";
import { AuthContext } from "../../../../context/AuthProvider/AuthProvider";
import useMasterAdmin from "../../../../hooks/useMasterAdmin";
import useGetUserType from "../../../../hooks/useGetUserType";
import HourlyTraffic from "./Categories/HourlyTraffic";
import EventDetails from "./Categories/EventDetails";
import StatusReport from "./Categories/StatusReport";
import PriorityEventsDetails from "./Categories/PriorityEventsDetails/PriorityEventsDetails";
import SecurityEvents from "./Categories/SecurityEvents";
import SecurityValidation from "./Categories/SecurityValidation";

const ReportsMain = () => {
	const { user } = useContext(AuthContext);
	const { userType, userTypeLoading } = useGetUserType(user?.uid);
	const currentUserRole = userType?.Role;
	const [isMasterAdmin, masterAdminLoading] = useMasterAdmin(user?.email);

	const { devices, clientsData, userClientAndLocation, dataLoading } = useContext(DataContext);

	const [clientsList, setClientsList] = useState([]); 
	const [selectedClient, setSelectedClient] = useState({ id: "", Client: "" }); 

	const [location, setLocation] = useState([]); 
	const [selectedLocation, setSelectedLocation] = useState({ id: "", Location: "" }); 

	const [selectedReport, setSelectedReport] = useState("Violations");

	//setting default client
	useEffect(() => {
		if (!userTypeLoading && !masterAdminLoading && !dataLoading) {
			let list = [];
			if (isMasterAdmin) {
				clientsData.forEach((client) => list.push(client));
			} else {
				list.push({ id: userType.ClientID, Client: userType.Client });
			}
			setClientsList(list);
			setSelectedClient(list[0]);
		}
	}, [userType, userTypeLoading, clientsData, dataLoading, isMasterAdmin, masterAdminLoading]);

	// selecting default location
	useEffect(() => {
		if (selectedClient && !dataLoading && !userTypeLoading) {
			let filteredList = devices.filter((ele) => ele.ClientID === selectedClient.id);
			if (currentUserRole) {
				if (
					currentUserRole === "Local Admin" ||
					currentUserRole === "Regional Admin" ||
					currentUserRole === "Local User" ||
					currentUserRole === "Local Ops User" ||
					currentUserRole === "Regional User" ||
					currentUserRole === "Regional Admin"
				) {
					const userLocations = new Set(userClientAndLocation.userLocation);
					filteredList = [...filteredList.filter((ele) => userLocations.has(ele.Location_ID))];
					const locationList = [];
					filteredList.forEach((ele) => locationList.push({ Location: ele.Location, id: ele.Location_ID }));
					const uniqueLocationList = [...new Map(locationList.map((item) => [item["id"], item])).values()];
					setLocation(uniqueLocationList);
					setSelectedLocation(uniqueLocationList[0]);
				} else {
					const locationList = [];
					filteredList.forEach((ele) => locationList.push({ Location: ele.Location, id: ele.Location_ID }));
					const uniqueLocationList = [...new Map(locationList.map((item) => [item["id"], item])).values()];
					setLocation(uniqueLocationList);
					setSelectedLocation(uniqueLocationList[0]);
				}
			} else {
				const locationList = [];
				filteredList.forEach((ele) => locationList.push({ Location: ele.Location, id: ele.Location_ID }));
				const uniqueLocationList = [...new Map(locationList.map((item) => [item["id"], item])).values()];
				setLocation(uniqueLocationList);
				setSelectedLocation(uniqueLocationList[0]);
			}
		}
	}, [devices, selectedClient, dataLoading, userTypeLoading, currentUserRole, userClientAndLocation]);

	return (
		<div className="bg-white h-full overflow-x-hidden">
			{/* header div */}
			<div className="flex items-center bg-white justify-between py-2 sticky top-0 z-[2]">
				<div>
				<h1 className="font-bold xl:ml-6 md:ml-12 xl:text-3xl md:text-2xl text-start  text-black">Reports</h1>
				</div>
				<div className="sticky top-20 z-[2] bg-white mx-6 mt-5 mb-5 flex justify-around w-full bg-gray-50 py-2 gap-5 flex-wrap">
				{/* Client */}
				<div className="xl:flex xl:flex-row xl:justify-between xl:items-center md:flex md:flex-row md:justify-between md:items-center md:w-[23rem] xl:w-[19rem]">
					<h1 className="text-black text-lg mr-3">Client : </h1>
					<select
						className="bg-gray-200 rounded text-black text-lg w-[14rem]"
						value={selectedClient?.Client ? selectedClient.Client : "Select Client"}
						onChange={(e) => setSelectedClient({ Client: e.target.value, id: e.target.selectedOptions[0].id })}
					>
						<option defaultValue disabled>
							Select Client
						</option>
						{clientsList.map((client) => (
							<option key={client.id} id={client.id} value={client.Client}>
								{client.Client}
							</option>
						))}
					</select>
				</div>
				{/* Location */}
				<div className="xl:flex xl:flex-row xl:justify-between xl:items-center md:flex md:flex-row md:justify-between md:items-center md:w-[23rem] xl:w-[17rem]">
					{selectedClient ? (
						<>
							<h1 className="text-black text-lg mr-3">Location : </h1>
							<select
								className="bg-gray-200 rounded text-black text-lg w-[9rem]"
								value={selectedLocation?.Location ? selectedLocation.Location : "Select Location"}
								onChange={(e) => setSelectedLocation({ Location: e.target.value, id: e.target.selectedOptions[0].id })}
							>
								<option defaultValue disabled>
									Select Location
								</option>
								{location.map((loc) => (
									<option key={loc.id} id={loc.id} value={loc.Location}>
										{loc.Location}
									</option>
								))}
							</select>
						</>
					) : (
						<h1 className="bg-red-100 rounded border-2 border-red-600 text-red-600 text-xl mt-2  mr-3 px-2">
							Select Client to see available locations
						</h1>
					)}
				</div>
				<div className="xl:flex xl:flex-row xl:justify-between xl:items-center md:flex md:flex-row md:justify-between md:items-center md:w-[23rem] xl:w-[18rem]">
					{selectedLocation ? (
						<>
							<h1 className="text-black text-lg mr-3">Report : </h1>
							<select
								className="bg-gray-200 rounded text-black text-lg p-1 w-[13rem]"
								value={selectedReport}
								onChange={(e) => setSelectedReport(e.target.value)}
							>
								{/* <option value={"Daily"}>Daily</option>
								<option value={"Daily Traffic"}>Daily Traffic</option>
								<option value={"Daywise Traffic"}>Daywise Traffic</option> */}
								<option value={"Violations"}>Violations</option>
								{/* <option value={"Hourly Traffic"}>Hourly Traffic</option> */}
								<option value={"Event Details"}>Event Details</option>
								{/* <option value={"Status Report"}>Status Report</option> */}
								{/* <option value={"Priority Events Details"}>Priority Events Details</option> */}
								<option value={"Events and Records"}>Events and Records</option>
								<option value={"Records Report"}>Records Report</option>
							</select>
						</>
					) : (
						<h1 className="bg-red-100 rounded border-2 border-red-600 text-red-600 text-xl mt-2  mr-3 px-2">
							Select Location to see available Alert Configurations
						</h1>
					)}
				</div>
			</div>
				<div className="flex items-center justify-end">
					<div className="ml-5">
						<AlMonkLogo />
					</div>
				</div>
			</div>

			{selectedReport === "Daily" && <Daily selectedClient={selectedClient} selectedLocation={selectedLocation} />}
			{selectedReport === "Daily Traffic" && <DailyTraffic selectedClient={selectedClient} selectedLocation={selectedLocation} />}
			{selectedReport === "Daywise Traffic" && <DayWiseTraffic selectedClient={selectedClient} selectedLocation={selectedLocation} />}
			{selectedReport === "Violations" && <Violations selectedClient={selectedClient} selectedLocation={selectedLocation} />}
			{selectedReport === "Hourly Traffic" && <HourlyTraffic selectedClient={selectedClient} selectedLocation={selectedLocation} />}
			{selectedReport === "Event Details" && <EventDetails selectedClient={selectedClient} selectedLocation={selectedLocation} />}
			{selectedReport === "Status Report" && <StatusReport selectedClient={selectedClient} selectedLocation={selectedLocation} />}
			{selectedReport === "Events and Records" && <SecurityValidation selectedClient={selectedClient} selectedLocation={selectedLocation} />}
			{selectedReport === "Records Report" &&  <SecurityEvents selectedClient={selectedClient} selectedLocation={selectedLocation} />}
		</div>
	);
};

export default ReportsMain;
