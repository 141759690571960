import React, { useEffect, useState } from "react";
import { db } from "../../../../../../../firebase/firebase.config";
import { deleteDoc, doc, getDoc, setDoc } from "firebase/firestore";
import BlueBtn from "../../../../../../../components/Buttons/BlueBtn";
import { toast } from "react-hot-toast";

const EditEntryModal = ({ alertProfile, detectionIDs, setEditEntryModal, setWhitelistVehicleData, dataToEdit }) => {
	// const { vehicleTypes } = useContext(DataContext);
	// const { Vehicles: vehicles } = vehicleTypes;

	const [vehicles, setVehicles] = useState([]);

	useEffect(() => {
		fetchDetections();
	}, [alertProfile]);

	const fetchDetections = async () => {
		const alertStringArr = alertProfile.split("-");
		const selectedClientID = alertStringArr[0];
		const selectedLocationID = alertStringArr[1];
		const detectionCollectionRef = doc(db, `clients-data-collection/${selectedClientID}/Location/${selectedLocationID}/Detections`, "detection-list");
		const docSnap = await getDoc(detectionCollectionRef);
		let detections = docSnap.data()?.Vehicles;
		detections = detections.filter((detection) => detection !== "Numberplate").sort();
		setVehicles([...detections.map((ele) => ({ value: ele, id: ele }))]);
	};

	const updateDataInDB = async (id, vehicleType, vehicleNumber) => {
		await deleteDoc(doc(db, `UnauthorizedEntryCollection/${alertProfile}/Whitelist`, id));
		await setDoc(doc(db, `UnauthorizedEntryCollection/${alertProfile}/Whitelist`, vehicleNumber), {
			VehicleType: vehicleType,
			VehicleNumber: vehicleNumber,
		});

		setWhitelistVehicleData((prev) => {
			let index = prev.findIndex((vehicle) => vehicle.id === id);
			prev[index]["VehicleType"] = vehicleType;
			prev[index]["VehicleNumber"] = vehicleNumber;
			return prev;
		});

		toast.success("Entry Updated");
		setEditEntryModal(false);
	};

	const handleUpdateEntry = (e) => {
		e.preventDefault();
		const form = e.target;
		const vehicleType = form.vehicleType.value;
		const vehicleNumber = form.vehicleNumber.value;
		// console.log(vehicleType, vehicleNumber);

		if (detectionIDs.has(vehicleNumber)) {
			toast.error("Detection with this ID already exist , please enter a unique ID");
			return;
		}

		updateDataInDB(dataToEdit.id, vehicleType, vehicleNumber)
			.then(console.log("Edited"))
			.catch((err) => console.log("error", err));
	};

	return (
		<div>
			<input type="checkbox" id="updateEntry-modal" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box relative rounded-none bg-white w-[30rem]">
					<label
						onClick={() => setEditEntryModal(false)}
						htmlFor="updateEntry-modal"
						className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]"
					>
						✕
					</label>
					<h3 className="text-2xl text-center font-bold mb-7 text-black">Edit Vehicle</h3>

					<form className="ml-14" onSubmit={handleUpdateEntry}>
						<div className="form-control gap-3 text-black w-80">
							<div className="flex flex-row justify-between">
								<label htmlFor="vehicleType">Detection</label>
								<select id="vehicleType" name="vehicleType" className="bg-gray-100 w-48 h-[1.5rem] px-2" defaultValue={dataToEdit.VehicleType}>
									{vehicles.map((vehicle) => (
										<option key={vehicle.id} value={vehicle.value}>
											{vehicle.value}
										</option>
									))}
								</select>
							</div>
							<div className="flex flex-row justify-between">
								<label htmlFor="vehicleNumber">Vehicle Number</label>
								<input
									id="vehicleNumber"
									name="vehicleNumber"
									className="bg-gray-100 w-48 h-[1.5rem] p-2"
									type="text"
									defaultValue={dataToEdit.VehicleNumber}
								/>
							</div>
						</div>
						<div className="form-control mt-5">
							<BlueBtn>Update</BlueBtn>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default EditEntryModal;
