import React, { useContext, useEffect, useState } from "react";
import AlMonkLogo from "../../../../components/AlMonkLogo";
import { DataContext } from "../../../../context/DataProvider/DataProvider";
import NoMovement from "./ConfigPages/NoMovementPage/NoMovement";
import { AuthContext } from "../../../../context/AuthProvider/AuthProvider";
import UnauthorizedEntryMain from "./ConfigPages/UnauthorizedEntryPage/UnauthorizedEntryMain";
import useGetUserType from "../../../../hooks/useGetUserType";
import AuthorizedCountMain from "./ConfigPages/AuthorizedEntry/AuthorizedCountMain";
import useMasterAdmin from "../../../../hooks/useMasterAdmin";
import NightMovementMain from "./ConfigPages/NightMovement/NightMovementMain";
import SecurityAgentInitiationAlertMain from "./ConfigPages/SecurityAgentInitiationAlert/SecurityAgentInitiationAlertMain";
import ExceptionAlertMain from "./ConfigPages/ExceptionAlert/ExceptionAlertMain";

const AlertsConfigMain = () => {
	const { user } = useContext(AuthContext);

	const { userType, userTypeLoading } = useGetUserType(user?.uid);
	const currentUserRole = userType?.Role;
	const [isMasterAdmin, masterAdminLoading] = useMasterAdmin(user?.email);

	const { clientsData, devices, userClientAndLocation, dataLoading } = useContext(DataContext);
	const [clientsList, setClientsList] = useState([]);
	const [selectedClient, setSelectedClient] = useState(""); // client which is actively selected
	const [selectedClientID, setSelectedClientID] = useState("");

	const [location, setLocation] = useState([]); // device locations associated with selected client
	const [selectedLocation, setSelectedLocation] = useState(""); // currently selected location from dropdown
	const [selectedLocationID, setSelectedLocationID] = useState("");

	const [selectedAlertConfig, setSelectedAlertConfig] = useState("Authorised Count");

	//setting default client
	useEffect(() => {
		if (!userTypeLoading && !masterAdminLoading && !dataLoading) {
			let list = [];
			/*
			if (userType.Role === "Master Admin") {
				clientsData.forEach((client) => list.push(client));
			} */
			if (isMasterAdmin) {
				clientsData.forEach((client) => list.push(client));
			} else {
				list.push({ id: userType.ClientID, Client: userType.Client });
			}
			setClientsList(list);
			setSelectedClient(list[0].Client);
			setSelectedClientID(list[0].id);
		}
	}, [userType, userTypeLoading, clientsData, dataLoading, isMasterAdmin, masterAdminLoading]);

	// set locations from devices
	useEffect(() => {
		if (selectedClient && !dataLoading && !userTypeLoading) {
			let filteredList = devices.filter((ele) => ele.Client === selectedClient);
			if (currentUserRole) {
				if (
					currentUserRole === "Local Admin" ||
					currentUserRole === "Regional Admin" ||
					currentUserRole === "Local User" ||
					currentUserRole === "Local Ops User" ||
					currentUserRole === "Regional User" ||
					currentUserRole === "Regional Admin"
				) {
					const userLocations = new Set(userClientAndLocation.userLocation);
					filteredList = [...filteredList.filter((ele) => userLocations.has(ele.Location_ID))];
					const locationList = [];
					filteredList.forEach((ele) => locationList.push({ Location: ele.Location, id: ele.Location_ID }));
					const uniqueLocationList = [...new Map(locationList.map((item) => [item["id"], item])).values()];
					setLocation(uniqueLocationList);
				} else {
					const locationList = [];
					filteredList.forEach((ele) => locationList.push({ Location: ele.Location, id: ele.Location_ID }));
					const uniqueLocationList = [...new Map(locationList.map((item) => [item["id"], item])).values()];
					setLocation(uniqueLocationList);
				}
			} else {
				const locationList = [];
				filteredList.forEach((ele) => locationList.push({ Location: ele.Location, id: ele.Location_ID }));
				const uniqueLocationList = [...new Map(locationList.map((item) => [item["id"], item])).values()];
				setLocation(uniqueLocationList);
			}
		}
	}, [devices, selectedClient, dataLoading, userTypeLoading, currentUserRole, userClientAndLocation]);

	//setting default location
	useEffect(() => {
		if (selectedClient && location.length > 0) {
			setSelectedLocationID(location[0].id);
			setSelectedLocation(location[0].Location);
		}
	}, [location]);

	const handleClientSelect = (e) => {
		const client = e.target.value;
		const index = clientsData.findIndex((ele) => ele.Client === client);
		setSelectedClient(clientsData[index].Client);
		setSelectedClientID(clientsData[index].id);
	};

	const handleLocationSelect = async (e) => {
		const selectedLocationDropdown = e.target.value;
		setSelectedLocation(selectedLocationDropdown);
		let selectedLocationIndex = location.findIndex((loc) => loc.Location === selectedLocationDropdown);
		setSelectedLocationID(location[selectedLocationIndex].id);
	};

	return (
		<div className="bg-white h-full overflow-x-hidden">
			{/* header div */}
			<div className="flex items-center bg-white justify-between py-2 sticky top-0 z-[3]">
				<h1 className="font-semibold lg:ml-6 md:ml-12 lg:text-[32px] md:text-2xl text-start  text-black">Alerts Configuration</h1>
				<div className="flex items-center justify-end">
					<input type="text" placeholder="Search" className="input input-bordered rounded mr-3 bg-slate-200 placeholder:text-zinc-800" />
					<div className="ml-5">
						<AlMonkLogo />
					</div>
				</div>
			</div>

			{/* Select Client Location AlertConfig div */}
			<div className="sticky top-20 z-[3] bg-white mx-6 mt-5 mb-9 lg:flex lg:flex-row lg:justify-between md:flex md:flex-col md:gap-4">
				{/* Client */}
				<div className="lg:flex lg:flex-row lg:justify-between lg:items-center md:grid md:grid-cols-2 md:w-[28rem] lg:w-[297px]">
					<h1 className="text-black text-base font-semibold">Client</h1>
					<select
						className="bg-[#F2F5F7] border border-[#2E3192] rounded-lg px-3 py-4 text-black text-base  lg:w-[237px]"
						value={selectedClient ? selectedClient : "Select Client"}
						onChange={handleClientSelect}
					>
						<option defaultValue disabled>
							Select Client
						</option>
						{clientsList.map((client) => (
							<option key={client.id} value={client.Client}>
								{client.Client}
							</option>
						))}
					</select>
				</div>
				{/* Location */}
				<div className="lg:flex lg:flex-row lg:justify-between lg:items-center md:grid md:grid-cols-2 md:w-[28rem] lg:w-[319px]">
					{selectedClient ? (
						<>
							<h1 className="text-black text-base font-semibold">Location</h1>
							<select
								className="bg-[#F2F5F7] border border-[#2E3192] rounded-lg px-3 py-4 text-black text-base  lg:w-[237px]"
								value={selectedLocation ? selectedLocation : "Select Location"}
								onChange={handleLocationSelect}
							>
								<option defaultValue disabled>
									Select Location
								</option>
								{location.map((loc) => (
									<option key={loc.id} value={loc.Location}>
										{loc.Location}
									</option>
								))}
							</select>
						</>
					) : (
						<h1 className="bg-red-100 rounded border-2 border-red-600 text-red-600 text-xl mt-2  mr-3 px-2">
							Select Client to see available locations
						</h1>
					)}
				</div>
				<div className="lg:flex lg:flex-row lg:justify-between lg:items-center md:grid md:grid-cols-2 md:w-[28rem] lg:w-[440px]">
					{selectedLocation ? (
						<>
							<h1 className="text-black text-base font-semibold">Alert Configuration</h1>
							<select
								className="bg-[#F2F5F7] border border-[#2E3192] rounded-lg px-3 py-4 text-black text-base lg:w-[282px]"
								value={selectedAlertConfig}
								onChange={(e) => setSelectedAlertConfig(e.target.value)}
							>
								<option value={"Authorised Count"}>Authorised Count</option>
								<option value={"No Movement"}>No Movement</option>
								<option value={"Night Movement"}>Night Movement</option>
								<option value={"Unauthorized Entry"}>Unauthorized Entry</option>
								<option value={"Security Agent Initiation Alert"}>Security Agent Initiation Alert</option>
								<option value={"Exception Alert"}>Exception Alert</option>
							</select>
						</>
					) : (
						<h1 className="bg-red-100 rounded border-2 border-red-600 text-red-600 text-xl mt-2  mr-3 px-2">
							Select Location to see available Alert Configurations
						</h1>
					)}
				</div>
			</div>

			{/* <hr className=" bg-slate-600 rounded border-t-4 mt-6 mb-11 mx-3" /> */}

			{selectedAlertConfig === "No Movement" && (
				<NoMovement
					selectedClient={selectedClient}
					selectedClientID={selectedClientID}
					selectedLocation={selectedLocation}
					selectedLocationID={selectedLocationID}
					devices={devices}
				/>
			)}

			{selectedAlertConfig === "Authorised Count" && <AuthorizedCountMain selectedClient={selectedClient} selectedLocation={selectedLocation} />}

			{selectedAlertConfig === "Unauthorized Entry" && <UnauthorizedEntryMain selectedClient={selectedClient} selectedLocation={selectedLocation} />}

			{selectedAlertConfig === "Night Movement" && (
				<NightMovementMain
					selectedClient={selectedClient}
					selectedClientID={selectedClientID}
					selectedLocation={selectedLocation}
					selectedLocationID={selectedLocationID}
					devices={devices}
				/>
			)}

			{selectedAlertConfig === "Security Agent Initiation Alert" && (
				<SecurityAgentInitiationAlertMain selectedClientID={selectedClientID} selectedLocationID={selectedLocationID} devices={devices} />
			)}
			{selectedAlertConfig === "Exception Alert" && (
				<ExceptionAlertMain
				selectedClient={selectedClient}
				selectedClientID={selectedClientID}
				selectedLocation={selectedLocation}
				selectedLocationID={selectedLocationID}
				devices={devices}
			/>
			)}
		</div>
	);
};

export default AlertsConfigMain;
