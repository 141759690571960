import { useState } from "react";
import { fixDateFormate } from "../Utility/fixDateFormat";
import { useEffect } from "react";

const TaggedEventDisplayCard = ({ event }) => {
	const { id, Class_label, Direction, Timestamp, Date } = event || {};
	const [urlExist, setUrlExist] = useState(false);
	const vdoURL = event?.["Video-URL"];

	const [formatedDate, formatedTime] = fixDateFormate(Timestamp);
	const fileName = vdoURL?.substring(vdoURL?.lastIndexOf("/") + 1);
	useEffect(() => {
		fetch(`http://gk.secquraise.com/api/checkVideoURL?url=${fileName}&date=${Date}`)
			.then((response) => response.json())
			.then((data) => {
				if (data.exists) {
					// console.log('Video URL exists');
					setUrlExist(true);
					// setIsLoading(false);
				} else {
					// console.log('Video URL does not exist');
					// setUrlExist(false);
				}
			})
			.catch((error) => {
				console.log("Error checking video URL:", error);
			});
	}, [fileName]);
	const contents = (
		<>
			{urlExist ? (
				<div className="h-full w-full">
					<video className="object-cover h-full w-full rounded-b-md" key={id} controls>
						<source src={vdoURL} type="video/mp4" />
					</video>
				</div>
			) : (
				<>
					<div className="w-full h-full flex flex-col items-center justify-around rounded-b-md">
						<h2 className="text-2xl text-slate-800 font-semibold">Video upload pending</h2>
					</div>
				</>
			)}
		</>
	);
	return (
		<div className="bg-[#F2F5F7] h-72 border border-[#2E3192] flex flex-col rounded-md">
			<div className="flex flex-row justify-between items-center font-bold uppercase p-1">
				<div className="flex flex-row items-start gap-1">
					<p>{Class_label}</p>
					<p>{Direction}</p>
				</div>
				<div className="flex flex-row items-end gap-1">
					<p>{formatedDate}</p>
					<p>{formatedTime}</p>
				</div>
			</div>
			<div className="mt-2 w-full h-full rounded-b-md">{contents}</div>
		</div>
	);
};

export default TaggedEventDisplayCard;
