import React from 'react';
import BlueBtn from '../../../../components/Buttons/BlueBtn';
import { toast } from 'react-hot-toast';
import { db } from '../../../../firebase/firebase.config';
import { doc, updateDoc } from 'firebase/firestore';

const EditClientLocation = ({ aLocation, setALocation, clientId, useCase }) => {
    const { Name, Address, Pincode, id } = aLocation;

    const handleUpdateClientLocation = (e) => {
        e.preventDefault();
        const form = e.target;
        const locationName = form.locationName.value;
        const address = form.address.value;
        const pinCode = form.pinCode.value;
        const usecase = form.usecase.value;

        //console.log(clocationName, address, pinCode, usecase);


        if ((locationName.length < 1) || (address.length < 1) || (pinCode.length < 1)) {
            return;
        }
        else {
            UpdateClientLocationData(locationName, address, pinCode, usecase);
            form.reset();
        }
    }

    const UpdateClientLocationData = async (locationName, address, pinCode, usecase) => {
        // Update document in collection "clients-data-collection"
        try {
            const docRef = doc(db, `clients-data-collection/${clientId}/Location`, id);
            const res = await updateDoc(docRef, {
                Name: locationName,
                Address: address,
                Pincode: pinCode,
                UseCase: usecase,
            });
            setALocation(null);
            toast.success('Location data updated');
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            <input type="checkbox" id="updateClientLocation-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative rounded-none">
                    <label
                        htmlFor="updateClientLocation-modal"
                        className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]">
                        ✕
                    </label>
                    <h3
                        className="text-3xl text-center font-bold mb-7">
                        Update Location
                    </h3>

                    <form onSubmit={handleUpdateClientLocation}>

                        {/* Location Name */}
                        <div className="form-control flex mt-3">
                            <input
                                type="text"
                                name='locationName'
                                placeholder="locationName"
                                defaultValue={Name}
                                key={Name}
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* Address */}
                        <div className="form-control flex mt-3">
                            <input
                                type="text"
                                name='address'
                                placeholder="Address"
                                defaultValue={Address}
                                key={Address}
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* Pin Code */}
                        <div className="form-control mt-3">
                            <input
                                type="text"
                                name='pinCode'
                                placeholder="Pin Code"
                                defaultValue={Pincode}
                                key={Pincode}
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* use case */}
                        <div className="form-control mt-3">
                            <select
                                name='usecase'
                                defaultValue={useCase}
                                key={useCase}
                                className="select w-full bg-white border-white border-b-[#D9D9D9] rounded-none" required >
                                {
                                    useCase?.length > 0 ? <>
                                        {
                                            useCase?.map((u, i) => <option
                                                value={u}
                                                key={i}
                                            >{u}</option>)
                                        }
                                    </>
                                        : <option disabled>No usecase available</option>
                                }

                            </select>
                        </div>

                        <div className="form-control mt-3">
                            <BlueBtn>Update</BlueBtn>
                        </div>
                    </form>

                </div>
            </div>
        </>
    );
};

export default EditClientLocation;