import React from 'react';
import Loading from '../../components/Loading/Loading';
import { db } from '../../firebase/firebase.config';
import { useEffect } from 'react';
import { fixDateFormate } from '../../Utility/fixDateFormat';
import { useState } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthProvider/AuthProvider';
import EntryApprovalModal from './EntryApprovalModal';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import useGetUserType from '../../hooks/useGetUserType';

const EntryDetails = ({ isLoading, selectedEntryVehicle, setIsLoading }) => {
    const { Location, Detection, id, Action, Status, Timestamp, LocationID, ClientID, ApprovalRemarks, Remarks, ApproverUserName, EventId, Entryby, Purpose, Sender, VehicleNo, ApproveDate, ApproveTime } = selectedEntryVehicle || {};
    const photoURL = selectedEntryVehicle?.["Photo-URL"];
    const [isApprove, setIsApprove] = useState(false);
    const { user } = useContext(AuthContext);
    const [approverList, setApproverList] = useState([]);
    const [isEvent, setIsEvent] = useState(false);
    const [videoURLArr, setVideoURLArr] = useState([]);
    const { userType, userTypeLoading } = useGetUserType(user?.uid);

    //receive formated date from fixDateFormat() funtion 
    const [formatedDate, formatedTime] = fixDateFormate(Timestamp);

    //fetch all event videos of this selected entry
    useEffect(() => {
        setVideoURLArr([]);
        if (ClientID && Timestamp && id) {
            const datePart = Timestamp.slice(0, 8);
            const unsub = onSnapshot(
                query(collection(db, `${ClientID}-priority-${datePart}`), where("EntryID", "==", id)),
                (snapShot) => {
                    let list = [];
                    snapShot.docs.forEach((doc) => {
                        list.push({ id: doc.id, ...doc.data() });
                    });
                    // console.log('list count:', list);

                    const resultArray = list?.map((l) => l?.["Video-URL"]);
                    // console.log('resultArray:', resultArray);
                    setVideoURLArr([...resultArray]);
                },
                (error) => {
                    console.log(error);
                }
            );

            return () => {
                unsub();
            };
        }

    }, [id, ClientID]);


    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? photoURL.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        const isLastSlide = currentIndex === photoURL.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    };


    const contents = <>
        {
            photoURL?.length > 0 ?
                <>
                    <div className='w-[90%] h-[470px] m-auto relative group'>
                        <div
                            style={{ backgroundImage: `url(${photoURL[currentIndex]})` }}
                            className='w-full h-full bg-center bg-cover duration-500'
                        ></div>
                        {/* Left Arrow */}
                        <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                            <BsChevronCompactLeft onClick={prevSlide} size={30} />
                        </div>
                        {/* Right Arrow */}
                        <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                            <BsChevronCompactRight onClick={nextSlide} size={30} />
                        </div>
                        <div className="flex top-4 justify-center py-2">
                            {photoURL?.map((slide, slideIndex) => (
                                <div
                                    key={slideIndex}
                                    onClick={() => goToSlide(slideIndex)}
                                    className={`text-2xl cursor-pointer ${currentIndex === slideIndex ? 'text-blue-500' : ''}`}
                                >
                                    ●
                                </div>
                            ))}
                        </div>

                    </div>


                </>
                : <>
                    <div className="w-[90%] h-[470px] bg-black flex justify-center items-center mx-auto">
                        <h2 className="text-2xl text-white mt-5 font-semibold">Image not available</h2>
                    </div>
                </>
        }
    </>


    //-------------------event video carousel----------------

    //fetch approver list
    useEffect(() => {

        if (ClientID && LocationID) {

            // console.log(clientID, locationID);

            const docRef = doc(db, "AlertTypesCollection", `${ClientID}-${LocationID}-SAAA`);

            const unsubscribe = onSnapshot(docRef, (docSnap) => {
                if (docSnap.exists()) {
                    const approver = docSnap.data().Recipients;
                    setApproverList(approver);
                    // console.log(approver);

                } else {
                    console.log("No such document!");
                    setApproverList([]);
                }
            });

            return () => unsubscribe();
        }

    }, [id, ClientID, LocationID]);

    //event Video carousel
    const prevVideoSlide = () => {
        const isFirstSlide = currentVideoIndex === 0;
        const newIndex = isFirstSlide ? videoURLArr.length - 1 : currentVideoIndex - 1;
        setCurrentVideoIndex(newIndex);
    };

    const nextVideoSlide = () => {
        const isLastSlide = currentVideoIndex === videoURLArr.length - 1;
        const newIndex = isLastSlide ? 0 : currentVideoIndex + 1;
        setCurrentVideoIndex(newIndex);
    };

    const goToVideoSlide = (slideIndex) => {
        setCurrentVideoIndex(slideIndex);
    };


    useEffect(() => {
        // Auto-play next video when the index changes
        // You can add more logic for handling video playback
        // This example uses autoPlay, muted, and playsInline for compatibility
        const videoElement = document.getElementById('videoPlayer');

        if (videoElement) {
            videoElement.load();
            videoElement.play();
        }
    }, [currentVideoIndex]);

    const videoContents = <>
        {
            videoURLArr?.length > 0 ?
                <>
                    <div className="w-[90%] h-[470px] m-auto relative group">
                        <video
                            id="videoPlayer"
                            className="w-full h-full object-cover duration-500"
                            controls
                            muted
                            playsInline
                            loop
                        >
                            <source src={videoURLArr[currentVideoIndex]} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        {/* Left Arrow */}
                        <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
                            <BsChevronCompactLeft onClick={prevVideoSlide} size={30} />
                        </div>
                        {/* Right Arrow */}
                        <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
                            <BsChevronCompactRight onClick={nextVideoSlide} size={30} />
                        </div>
                        <div className="flex top-4 justify-center py-2">
                            {videoURLArr?.map((slide, slideIndex) => (
                                <div
                                    key={slideIndex}
                                    onClick={() => goToVideoSlide(slideIndex)}
                                    className={`text-2xl cursor-pointer ${currentVideoIndex === slideIndex ? 'text-blue-500' : ''}`}
                                >
                                    ●
                                </div>
                            ))}
                        </div>
                    </div>

                </>
                : <>
                    <div className="w-[90%] h-[470px] bg-black flex justify-center items-center mx-auto">
                        <h2 className="text-2xl text-white mt-5 font-semibold">Video not available</h2>
                    </div>
                </>
        }
    </>


    const handleImageToggle = () => {
        setIsEvent(!isEvent);
    };

    if (isLoading) {
        return <Loading></Loading>
    }

    // console.log("entry photo array:",photoURL);

    // console.log("videoURLArr:",videoURLArr, isEvent);

    return (
        <div className='lg:w-[63%] md:lg:w-[55%] pt-3'>
            {/* vehicle Image or video */}
            {/* <div className='flex justify-center h-[470px]'>
                {contents}
            </div> */}

            < div className="flex justify-center h-[470px]">

                {
                    (isEvent) ? <>
                        {videoContents}
                    </>
                        : <>
                            {contents}
                        </>
                }
            </div>

            {/* vehicle info */}
            <div className='flex flex-col px-12 my-4 text-black'>
                {/* <p className='text-[16px] font-semibold mt-2'>{Location}</p> */}
                <div className="flex justify-between items-start">
                    <div className='w-[50%]'>
                        <p className='text-[16px] font-semibold text-start'>{Location}</p>
                        <p className='text-start'>
                            {
                                (Detection)
                                    ? `${Detection} ${Action} detected`
                                    : `No detection found`
                            }
                        </p>

                        {
                            <>
                                <p className="text-start font-bold mt-4">Details added:</p>

                                <p>
                                    User: <span className="ml-2">{Entryby ? Entryby : "null"}</span>{" "}
                                </p>
                                <p>
                                    Remark: <span className="ml-2">{Remarks ? Remarks : "null"}</span>
                                </p>
                                <p>
                                    Purpose: <span className="ml-2">{Purpose ? Purpose : "null"}</span>
                                </p>
                                <p>
                                    Sender: <span className="ml-2">{Sender ? Sender : "null"}</span>
                                </p>
                                <p className="mb-4">
                                    VehicleNo: <span className="ml-2">{VehicleNo ? VehicleNo : 'null'}</span>
                                </p>
                            </>
                        }

                        {((Status !== "Approved" || Status !== "Rejected") && ApproverUserName && Remarks) && (
                            <>
                                <p className="text-start font-bold mt-4">Approval History:</p>
                                <p>
                                    User: <span className="ml-2">{ApproverUserName ? ApproverUserName : "null"}</span>{" "}
                                </p>
                                <p>
                                    Remark: <span className="ml-2">{ApprovalRemarks ? ApprovalRemarks : "null"}</span>
                                </p>
                                <p>
                                    Status: <span className="ml-2">{Status ? Status : "null"}</span>
                                </p>
                                <p>
                                    Approve Date: <span className="ml-2">{ApproveDate ? ApproveDate : "null"}</span>
                                </p>
                                <p>
                                    Approve Time: <span className="ml-2">{ApproveTime ? ApproveTime : "null"}</span>
                                </p>
                            </>
                        )}

                    </div>

                    <div className="flex flex-col font-medium text-[16px] text-end w-[30%]">
                        <p className='ml-2'>{formatedDate} {formatedTime}</p>

                        {/* Event toggle button */}
                        {EventId?.length > 0 && (
                            <label
                                onClick={handleImageToggle}
                                className={"btn w-[145px] self-end bg-[#375075] text-white hover:bg-[#A6A6A6] border-0 rounded-none mt-5"}
                            >
                                {isEvent ? 'Images' : "Videos"}
                            </label>
                        )}

                        {
                            (approverList.includes(user?.email) && userType?.Role !== "Security Guard") && (
                                <>
                                    <label htmlFor="entryApprove-modal"
                                        onClick={() => setIsApprove(true)}
                                        className={Status !== 'Approved'
                                            ? 'btn w-[145px] self-end bg-red-600 text-white hover:bg-[#A6A6A6] border-0 rounded-none mt-5'
                                            : 'btn w-[145px] self-end bg-[#375075] text-white hover:bg-[#A6A6A6] border-0 rounded-none mt-5'}
                                        disabled={Status === "Approved" || Status === "Rejected"}
                                    >
                                        {
                                            (Status === "Pending" || Status === "Update") ? "Approve" : Status === "Approved" ? "Approved" : "Rejected"
                                        }
                                    </label>
                                </>
                            )
                        }

                    </div>
                </div>

            </div>

            {
                isApprove &&
                <EntryApprovalModal
                    setIsApprove={setIsApprove}
                    selectedVehicle={selectedEntryVehicle}
                ></EntryApprovalModal>
            }
        </div>
    );
};

export default EntryDetails;