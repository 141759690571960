import React from 'react';

const BlueBtn = ({children}) => {
    return (
        <div>
             <button className="btn px-9 mx-auto block bg-[#375075] text-white hover:bg-[#A6A6A6] border-0 rounded-none">{children}</button>
        </div>
    );
};

export default BlueBtn;