//This function will convert "20230309-110814" to 
export function fixDateFormate(rawDate) {
    const splitTimestamp = rawDate?.split('-');
    //console.log(splitTimestamp);
    let formatedDate,
        formatedTime,
        anotherDateFormat;
    if (splitTimestamp?.length) {
        const date = splitTimestamp[0];
        const time = splitTimestamp[1];
        formatedDate = `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6)}`; //2023-03-09
        formatedTime = `${time.substr(0, 2)}:${time.substr(2, 2)}:${time.substr(4)}`;//16:46:55 
        // dateResult = `${formatedDate}T${formatedTime}Z`;

        anotherDateFormat = new Date(date.substr(0, 4), date.substr(4, 2), date.substr(6), time.substr(0, 2), time.substr(2, 2), time.substr(4));//Sun Apr 09 2023 16:46:55 GMT+0600 (Bangladesh Standard Time)
    }
    return [formatedDate, formatedTime, anotherDateFormat];
}