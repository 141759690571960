import { collection, doc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import React, { createContext, useEffect } from "react";
import { useState } from "react";
import { db } from "../../firebase/firebase.config";
import { uniqueArr } from "../../Utility/RemoveDuplicate";
import { useContext } from "react";
import { AuthContext } from "../AuthProvider/AuthProvider";
import useMasterAdmin from "../../hooks/useMasterAdmin";

export const DataContext = createContext();

const DataProvider = ({ children }) => {
	const { user } = useContext(AuthContext);
	const [isMasterAdmin, masterAdminLoading] = useMasterAdmin(user?.email);
	const [devices, setDevices] = useState([]);
	const [clientsData, setClientsData] = useState([]);
	const [clientNames, setClientNames] = useState([]);
	const [clientIds, setClientIds] = useState([]);
	const [deviceLocations, setDeviceLocations] = useState([]);
	const [vehicleTypes, setVehicleTypes] = useState([]);
	const [dataLoading, setDataLoading] = useState(true);
	const [firstClientLoc, setFirstClientLoc] = useState({
		locId: '',
		locname: '',
	});
	const [useCases, setUseCases] = useState({
		useCaseData: [],
		useCaseName: [],
	});
	const [userClientAndLocation, setUserClientAndLocation] = useState({
		userClientID: "",
		userClientName: "",
		userLocation: "",
	});

	//--------------------------fetch the client and location of current user-------------------
	useEffect(() => {
		const userID = user?.uid;
		// console.log(userID, isMasterAdmin, masterAdminLoading);
		if (userID && !isMasterAdmin && !masterAdminLoading) {
			try {
				const docRef = doc(db, "userDataCollection", userID);
				const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
					if (docSnapshot.exists()) {
						const data = { id: docSnapshot.id, ...docSnapshot.data() };
						// console.log(data);
						const userLoc = uniqueArr(data?.Location);
						//set user client and location info
						setUserClientAndLocation({
							userClientID: data?.ClientID,
							userClientName: data?.Client,
							userLocation: userLoc,
						});
					} else {
						console.log("user not found");
					}

					setDataLoading(false);
				});
				return () => unsubscribe();
			} catch (err) {
				console.log(err);
			}
		}
	}, [isMasterAdmin, user?.uid, masterAdminLoading]);

	// fetch all client Name from clientDataCollection
	useEffect(() => {
		const fetchData = async () => {
			let list = [];
			try {
				const querySnapshot = await getDocs(collection(db, "clients-data-collection"));
				querySnapshot.forEach((doc) => {
					list.push({ id: doc.id, ...doc.data() });
				});
				setClientsData(list);
				if (isMasterAdmin) {
					const clientIdList = list?.map((l) => l?.ClientID);
					setClientIds(uniqueArr(clientIdList));
					const clientNameList = list?.map((l) => l?.Client);
					setClientNames(uniqueArr(clientNameList));
				} else {
					if (userClientAndLocation?.userClientID && userClientAndLocation?.userClientName) {
						const clientIdList = userClientAndLocation?.userClientID;
						setClientIds([clientIdList]);
						const clientName = userClientAndLocation?.userClientName;
						setClientNames([clientName]);
					}
				}
				setDataLoading(false);
			} catch (err) {
				console.log(err);
			}
		};
		fetchData();
		//console.log("data:",data);
	}, [isMasterAdmin, userClientAndLocation?.userClientID]);

	// fetch all Vehicle types from vehicleTypesCollection
	useEffect(() => {
		const fetchData = async () => {
			let list = [];
			try {
				const querySnapshot = await getDocs(collection(db, "vehicleTypesCollection"));
				querySnapshot.forEach((doc) => {
					list.push({ id: doc.id, ...doc.data() });
				});
				// console.log('Vehicle:', list[0]);
				setVehicleTypes(list[0]);
				setDataLoading(false);
			} catch (err) {
				console.log(err);
			}
		};
		fetchData();
		//console.log("data:",data);
	}, []);

	// fetch all Locations Name from deviceDataCollection
	useEffect(() => {
		const fetchData = async () => {
			let list = [];
			try {
				const querySnapshot = await getDocs(collection(db, "device-data-collection"));
				querySnapshot.forEach((doc) => {
					list.push({ id: doc.id, ...doc.data() });
				});
				setDevices(list);
				const locationList = list?.map((l) => l?.Location);
				setDeviceLocations(uniqueArr(locationList));
				//console.log('locationList:',locations,'device id list:', deviceIDs);
				setDataLoading(false);
			} catch (err) {
				console.log(err);
			}
		};
		fetchData();
		//console.log("data:",data);
	}, []);

	// fetch all usecase Name from UseCases collection
	useEffect(() => {
		const fetchData = async () => {
			let list = [];
			try {
				const querySnapshot = await getDocs(collection(db, "Usecases"), where("Status", "==", "Enabled"));
				querySnapshot.forEach((doc) => {
					list.push({ id: doc.id, ...doc.data() });
				});
				const NameList = list?.map((l) => l?.UsecaseName);
				setUseCases({
					useCaseData: [...list],
					useCaseName: [...NameList],
				});
				// console.log('NameList:',NameList);
				setDataLoading(false);
			} catch (err) {
				console.log(err);
			}
		};
		fetchData();
		//console.log("data:",data);
	}, []);

	//fetch the first location of first client
	useEffect(() => {
		if (clientIds) {
			const clientid = clientIds[0];
			const unsub = onSnapshot(
				query(collection(db, `clients-data-collection/${clientid}/Location`)),
				(snapShot) => {
					let list = [];
					snapShot.docs.forEach((doc) => {
						list.push({ id: doc.id, ...doc.data() });
					});
					// console.log('location list:',list);

					const allLocationIds = list?.map((l) => l?.Location_ID);
					const locationIds = uniqueArr(allLocationIds);
					const firstId = locationIds[0];
					const locInfo = list?.find(l => l?.Location_ID === firstId);
					const firstName = locInfo?.Name;
					setFirstClientLoc({
						locId: firstId,
						locname: firstName
					});

					setDataLoading(false);

				},
				(error) => {
					console.log(error);
				}
			);

			return () => {
				unsub();
			};
		}
	}, [clientIds]);


	const dataInfo = {
		devices,
		deviceLocations,
		dataLoading,
		setDataLoading,
		clientsData,
		clientIds,
		clientNames,
		vehicleTypes,
		userClientAndLocation,
		setUserClientAndLocation,
		useCases,
		firstClientLoc,
	};
	return (
		<div>
			<DataContext.Provider value={dataInfo}>{children}</DataContext.Provider>
		</div>
	);
};

export default DataProvider;
