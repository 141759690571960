import React from 'react';
import Loading from '../../../../../components/Loading/Loading';
import DeviceTableRow from './DeviceTableRow';


const RightTable = ({ data, devices, isLoading, clientSelect, locationSelect }) => {
    // console.log(devices);

    //fetch all the data according to the device ID
    const latestDeviceData = data?.filter(ping => devices.includes(ping["Device-ID"]))
        ?.sort((a, b) => (a["Timestamp"] < b["Timestamp"]) ? 1 : -1)
        ?.reduce((acc, curr) => {
            if (!acc.find(el => el["Device-ID"] === curr["Device-ID"])) {
                acc.push(curr);
            }
            return acc;
        }, []);

    // console.log(latestDeviceData);

    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <div className="lg:w-full overflow-x-auto custom-table-border mt-5 max-h-[87vh] overflow-y-auto">
            <table className="w-full text-center">
                <thead className='sticky top-0 border-b'>
                    <tr className='text-center border-b'>
                        <th rowSpan="2" className='border-r py-3 px-2 bg-white max-w-[170px] font-semibold'>Status</th>
                        <th rowSpan="2" className='border-r py-3 px-2 bg-white max-w-[170px] font-semibold'>Device</th>
                        <th rowSpan="2" className='border-r py-3 px-2 bg-white max-w-[170px] font-semibold'>Location</th>
                        <th rowSpan="2" className='border-r py-3 px-2 bg-white max-w-[200px] font-semibold'>Last ping</th>
                        <th rowSpan="2" className='border-r py-3 px-2 bg-white max-w-[200px] font-semibold'>Time elapsed</th>
                        <th rowSpan="2" className='border-r py-3 px-2 bg-white max-w-[100px] font-semibold break-words'>Outage in last week</th>
                        <th rowSpan="2" className='border-r py-3 px-2 bg-white max-w-[100px] font-semibold break-words'>CPU temp</th>
                        <th colSpan="4" className='border-r py-3 px-2 bg-white max-w-[100px] font-semibold break-words'>CPU usage</th>
                        <th rowSpan="2" className='py-3 px-2 bg-white max-w-[100px] font-semibold break-words'>RAM usage</th>
                    </tr>
                    <tr className='text-center text-[13px] border-b'>
                        <th className='border-r py-2 px-2 bg-white max-w-[70px] font-semibold'>CPU-1</th>
                        <th className='border-r py-2 px-2 bg-white max-w-[70px] font-semibold'>CPU-2</th>
                        <th className='border-r py-2 px-2 bg-white max-w-[70px] font-semibold'>CPU-3</th>
                        <th className='border-r py-2 px-2 bg-white max-w-[70px] font-semibold'>CPU-4</th>
                    </tr>
                </thead>
                <tbody className=''>
                    {
                        latestDeviceData && latestDeviceData.map(latestDevice => <DeviceTableRow
                            key={latestDevice.id}
                            data={data}
                            latestDevice={latestDevice}
                        ></DeviceTableRow>)
                    }
                </tbody>
            </table>
        </div>
    );
};

export default RightTable;