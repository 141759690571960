import { FaCamera } from "react-icons/fa6";

const FileUpload = ({ setCameraModal, setImageURLForPreview, setImagePreviewModal }) => {
	const uploadHandler = (event) => {
		const file = event.target.files[0];
		if (!file) return;
		const imageURL = URL.createObjectURL(file);
		setImageURLForPreview(imageURL);
		setImagePreviewModal(true);
	};

	return (
		<>
			<div className="bg-[#EDF2F7] border border-[#2E3192] rounded-md border-dashed p-3">
				<div className="w-fit mx-auto flex flex-col gap-4 items-center">
					<label className="form-control w-full max-w-xs">
						<div className="label">
							<span className="label-text font-semibold">Supported files</span>
							<span className="label-text-alt font-semibold">JPG, PNG</span>
						</div>
						<input
							type="file"
							className="file-input file-input-bordered file-input-primary file-input-md w-full max-w-xs"
							id="file-uploader"
							onChange={uploadHandler}
							accept="image/jpeg, image/png"
						/>
					</label>
					<span>-- OR --</span>
					<label htmlFor="camera_modal" className="btn btn-primary w-full flex flex-row gap-4" onClick={() => setCameraModal(true)}>
						<FaCamera />
						<span>Capture</span>
					</label>
				</div>
			</div>
		</>
	);
};

export default FileUpload;
