import React, { useEffect, useState } from 'react';
import BlueBtn from '../../../components/Buttons/BlueBtn';
import { toast } from 'react-hot-toast';
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input'
import { AuthContext } from '../../../context/AuthProvider/AuthProvider';
import { useContext } from 'react';
import useMasterAdmin from '../../../hooks/useMasterAdmin';
import { db } from '../../../firebase/firebase.config';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { uniqueArr } from '../../../Utility/RemoveDuplicate';
import useGetUserType from '../../../hooks/useGetUserType';
import { DataContext } from '../../../context/DataProvider/DataProvider';
import MultiSelectDropdown from './MultiSelectDropdown';
import Select from "react-select";

const AddUserModal = ({
    setCloseModal,
    clientsData,
    clientNames,
    setIsLoading }) => {
    const { user } = useContext(AuthContext);
    const { userClientAndLocation, dataLoading } = useContext(DataContext);
    const [phoneValue, setPhoneValue] = useState(null);//phone number value
    let [email, setEmail] = useState('');//email format validation
    const [errorMessage, setErrorMessage] = useState('');
    const [userError, setUserError] = useState('');
    const [isMasterAdmin] = useMasterAdmin(user?.email);
    const [selectedClient, setSelectedClient] = useState('');
    const [locationData, setLocationData] = useState([]);
    const [locationNames, setLocationNames] = useState([]);
    // const [iLocationValues, setiLocationValues] = useState([]);
    const { userType, userTypeLoading } = useGetUserType(user?.uid);
    const currentUserRole = userType?.Role;
    const [locationsOptions, setLocationsOptions] = useState([]);
    const [selectedLocationsOptions, setSelectedLocationsOptions] = useState([]);

    // initially select the first client if the selectedClient is null
    useEffect(() => {
        // whenever selectedClient  changes , reset the selected locations
        setSelectedLocationsOptions([]);
        if (!selectedClient) {
            setSelectedClient(clientNames[0]);
        }
    }, [selectedClient, clientNames]);

    //fetch those locations according to selected client
    useEffect(() => {

        if (selectedClient) {
            const clientInfo = clientsData?.find(c => c?.Client.toLowerCase() === selectedClient.toLowerCase());
            const clientId = clientInfo?.ClientID;

            const unsub = onSnapshot(
                query(collection(db, `clients-data-collection/${clientId}/Location`)),
                (snapShot) => {
                    let list = [];
                    snapShot.docs.forEach((doc) => {
                        list.push({ id: doc.id, ...doc.data() });
                    });
                    // console.log('location list:', list);
                    setLocationData([...list]);
                    if (currentUserRole && !userTypeLoading && !dataLoading) {
                        if (currentUserRole === 'Local Admin' || currentUserRole === 'Regional Admin') {
                            const filteredList = list?.filter(item => userClientAndLocation?.userLocation.includes(item?.Location_ID));
                            const names = filteredList.map(item => item.Name);
                            setLocationNames(uniqueArr(names));
                            // console.log('filtered list:', names);
                        }
                    } else {
                        const locationNameList = list?.map((l) => l?.Name);
                        setLocationNames(uniqueArr(locationNameList));
                    }
                    setIsLoading(false);
                },
                (error) => {
                    console.log(error);
                }
            );

            return () => {
                unsub();
            };
        }
    }, [clientsData, selectedClient, currentUserRole, dataLoading, userClientAndLocation?.userLocation]);

    const handleAddUser = e => {
        e.preventDefault();
        const form = e.target;
        const client = form.client.value;
        const name = form.name.value;
        const password = form.password.value;
        const phone = form.phone.value;
        // const phone = value && formatPhoneNumberIntl(value);
        const role = form.role.value;

        //get client id
        const clientInfo = clientsData?.find(c => c?.Client.toLowerCase() === client.toLowerCase());
        const clientId = clientInfo?.ClientID;

        //get location id
        // let location;
        // if (iLocationValues?.length > 0) {
        //     const filteredLoc = locationData?.filter(item => iLocationValues?.includes(item?.Name));
        //     const ids = filteredLoc?.map(item => item?.Location_ID);
        //     location = [...ids];
        // }

        let location;
        if (selectedLocationsOptions?.length > 0) {
            // console.log(selectedLocationsOptions);
            const locValues = selectedLocationsOptions.map(option => option.value);
            const filteredLoc = locationData?.filter(item => locValues?.includes(item?.Name));
            const ids = filteredLoc?.map(item => item?.Location_ID);
            location = [...ids];
        }

        // console.log(client, clientId, name, email, phone, role, location);

        // return if user enter an invalid email
        if (!validate(email)) {
            setErrorMessage('Please enter valid email');
            return;
        }
        if (client?.length < 1 || name?.length < 1 || phone?.length <= 6 || password?.length < 6 || selectedLocationsOptions?.length < 1) {
            return;
        } else {
            createNewUser(client, clientId, name, email, password, phone, role, location);
            form.reset();
            setPhoneValue(null);
            setSelectedClient('');
        }
        setErrorMessage('');
    }

    // Add a new user with firbase Admin
    const createNewUser = (client, clientId, name, email, password, phone, role, location) => {
        const user = { client, clientId, name, email, password, phone, role, location };
        try {
            fetch('http://gk.secquraise.com/api/createUser', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(user)
            })
                .then(res => res.json())
                .then(data => {
                    //console.log('saved user details:', data);
                    setCloseModal(false);
                    setUserError('');
                    toast.success('User added successfully');
                })
        }
        catch (err) {
            console.error(err);
            setUserError(err.message);
            toast.error('Failed to create new user');
        };
    }

    // Email pattern validation
    const validate = (email) => {
        if (email.trim().match(/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
            return false;
        } else if (email.trim() === '') {
            return false;
        }

        return true;
    }

    const handleClientSelect = (e) => {
        const dropDownClient = e.target?.value;
        setSelectedClient(dropDownClient);
    };

    useEffect(() => {
        if (locationNames) {
            let allOptions = locationNames.map((location) => ({ value: location, label: location }));
            setLocationsOptions(allOptions);
        }
    }, [locationNames, locationData]);

    const handleCloseModal = () => {
        setSelectedClient('');
        setSelectedLocationsOptions([]);
    }

    const handleLocationsSelect = (selectedOptions) => {
        setSelectedLocationsOptions(selectedOptions);
    };

    // custom styling for react-select
    const selectStyles = {
        container: (provided) => ({
            ...provided,
            minWidth: 300,
            maxWidth: 646,
        }),
    };

    return (
        <div>
            <input type="checkbox" id="addUser-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative rounded-none bg-white text-black">
                    <label
                        htmlFor="addUser-modal"
                        onClick={handleCloseModal}
                        className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]"
                    >
                        ✕
                    </label>
                    <h3 className="text-3xl text-center font-bold mb-9">Add User</h3>

                    <form onSubmit={handleAddUser}>

                        {/* Client */}
                        <div className="form-control flex mt-4">
                            <select
                                name='client'
                                value={selectedClient}
                                onChange={handleClientSelect}
                                className="select bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required >
                                {
                                    clientNames?.length > 0 ?
                                        clientNames?.map((client, i) => <option
                                            value={client}
                                            key={i}
                                        >{client}</option>)
                                        : <option disabled>No client added yet</option>
                                }
                            </select>
                        </div>

                        {/* Name */}
                        <div className="form-control flex mt-4">
                            <input
                                type="text"
                                name='name'
                                placeholder="Name"
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                        </div>

                        {/* Email */}
                        <div className="form-control mt-4">
                            <input
                                type="text"
                                name='email'
                                placeholder="Email"
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                onChange={e => setEmail(e.target.value)}
                                required />
                            {errorMessage && <span className='text-red-600 mt-1 pl-3'>{errorMessage}</span>}
                        </div>

                        {/* Password */}
                        <div className="form-control mt-4">
                            <input
                                type="password"
                                name='password'
                                placeholder="Password"
                                className="input bg-white border-white border-b-[#D9D9D9] rounded-none"
                                required />
                            {userError && <span className='text-red-600 mt-1 pl-3'>{userError}</span>}
                        </div>

                        {/* Phone */}
                        <div className="form-control flex mt-4">
                            <PhoneInput
                                name='phone'
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="IN"
                                placeholder="Enter phone number"
                                className="input bg-white active:border-white active:border-b-[#D9D9D9] focus:border-b-[#D9D9D9] focus:border-white border-white border-b-[#D9D9D9] rounded-none"
                                value={phoneValue}
                                onChange={setPhoneValue}
                                required />
                        </div>

                        {/* User role */}
                        <div className="form-control mt-4">
                            {
                                isMasterAdmin ? <>
                                    <select
                                        name='role'
                                        className="select bg-white border-white border-b-[#D9D9D9] rounded-none"
                                        required >
                                        <option>Central Admin</option>
                                        <option>Central User</option>
                                        <option>Central Ops User</option>
                                        <option>Local Admin</option>
                                        <option>Local User</option>
                                        <option>Local Ops User</option>
                                        <option>Master Admin</option>
                                        <option>Regional Admin</option>
                                        <option>Regional User</option>
                                        <option>Security Guard</option>
                                    </select>
                                </>
                                    : <>
                                        <select
                                            name='role'
                                            className="select bg-white border-white border-b-[#D9D9D9] rounded-none"
                                            required >
                                            <option>Central Admin</option>
                                            <option>Central User</option>
                                            <option>Central Ops User</option>
                                            <option>Local Admin</option>
                                            <option>Local User</option>
                                            <option>Local Ops User</option>
                                            <option>Regional Admin</option>
                                            <option>Regional User</option>
                                            <option>Security Guard</option>
                                        </select>
                                    </>
                            }
                        </div>

                        {/* Location */}
                        <div className="form-control mt-4">
                            {/* <MultiSelectDropdown
                                optionList={locationNames}
                                ivalues={iLocationValues}
                                setivalues={setiLocationValues}
                            ></MultiSelectDropdown> */}

                            <Select
                                className="text-black"
                                options={locationsOptions}
                                placeholder={"Select Location"}
                                value={selectedLocationsOptions}
                                onChange={handleLocationsSelect}
                                styles={selectStyles}
                                isMulti
                            />

                        </div>

                        <div className="form-control mt-4">
                            <BlueBtn>Confirm</BlueBtn>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    );
};

export default AddUserModal;