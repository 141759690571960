import React, { useContext, useEffect, useState } from "react";
import EventDetails from "./EventDetails";
import EventLists from "./EventLists";
import { collection, getDocs, onSnapshot, query, where, or, and } from "firebase/firestore";
import { db } from "../../firebase/firebase.config";
import EventFilter from "./EventFilter";
import Loading from "../../components/Loading/Loading";
import AlMonkLogo from "../../components/AlMonkLogo";
import { DataContext } from "../../context/DataProvider/DataProvider";
import { eventTodaysDate } from "../../Utility/FixTodaysDate";
import { AuthContext } from "../../context/AuthProvider/AuthProvider";
import useMasterAdmin from "../../hooks/useMasterAdmin";
import toast from "react-hot-toast";
import FilterHeaderDisplay from "./FilterHeaderDisplay";
import { isSubstringInArray } from "../../Utility/isSubstringInArray";

// formats date to "1-Oct-2023" format
const formatDate = (date) => {
	const formatter = new Intl.DateTimeFormat("default", { month: "short" });
	const month = formatter.format(date);
	return `${date.getDate().toString().padStart(2, "0")}-${month}-${date.getFullYear()}`;
};

function getDateInYYYYMMDD(date) {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");

	return `${year}${month}${day}`;
}

const Events = () => {
	const { dataLoading, clientsData, clientIds, userClientAndLocation, firstClientLoc } = useContext(DataContext);
	const { user, authLoading } = useContext(AuthContext);
	const [isMasterAdmin] = useMasterAdmin(user?.email);
	const [isLoading, setIsLoading] = useState(true);
	const [eventData, setEventData] = useState([]);
	const [selectedVehicle, setSelectedVehicle] = useState({}); // this represents currently selected and higlighted event record
	const [totalPages, setTotalPages] = useState(0);

	// this executes the queryAndSet effect only when filterValues are changed by cancelling the filter
	// boxes (in that case it is set to true). This prevents function trigger due to filterValues change from submit button
	const [queryFromCancellingFilterBox, setQueryFromCancellingFilterBox] = useState(false);

	/* To control the state of dropdown filter values, this object holds currently selected options*/
	const [selectedFilterOptions, setSelectedFilterOptions] = useState({
		selectedClient: "",
		selectedLocations: [],
		selectedZones: [],
		selectedDetections: [],
		selectedDirections: [],
	});

	const [filteredEventData, setFilteredEventData] = useState({
		filterSatus: false,
		filteredData: [],
	});
	const [filterValues, setFilterValues] = useState({
		filterClient: "",
		filterLocations: [],
		filterZoneNames: [],
		filterDetectionTypes: [],
		filterDirectionTypes: [],
		filterDate: "",
		values: false,
	});

	// Fetch all event data from db
	useEffect(() => {
		const todaysDate = eventTodaysDate();
		// const todaysDate = '20231216';
		const breadcrumbsFormattedDate = `${todaysDate.substring(0, 4)}-${todaysDate.substring(4, 6)}-${todaysDate.substring(6)}`;

		//if the user masterAdmin ==> show all the data
		if (isMasterAdmin && !authLoading && !dataLoading && firstClientLoc?.locId && firstClientLoc?.locname) {
			const collectionRef = collection(db, `${clientIds[0]}-detection-events-${todaysDate}`);
			const queryConstraints = [];
			if (clientsData?.length > 0 && clientIds?.length > 0) {
				const clientID = clientIds[0];
				const client = clientsData?.find((item) => item?.ClientID === clientID);
				const clientName = client?.Client;

				queryConstraints.push(where("Client-ID", "==", clientID));
				queryConstraints.push(where("Location-ID", "==", firstClientLoc?.locId));
				// queryConstraints.push(where("Class_label", "!=", 'Numberplate'));
			}
			if (queryConstraints?.length > 0) {
				const q = query(collectionRef, ...queryConstraints);
				// console.log(queryConstraints);
				getEventsData(q);
			}
		}
		//if the user not masterAdmin ==> initially show data according to user's client and location
		else if (!isMasterAdmin && !authLoading && !dataLoading) {
			const queryConstraints = [];
			// console.log('inside hook clientID:', userClientAndLocation);
			const clientID = userClientAndLocation?.userClientID;
			const locationIDs = userClientAndLocation?.userLocation;
			const collectionRef = collection(db, `${clientID}-detection-events-${todaysDate}`);

			if (clientID) queryConstraints.push(where("Client-ID", "==", clientID));

			if (locationIDs.length) queryConstraints.push(where("Location-ID", "in", locationIDs));

			if (queryConstraints?.length > 0) {
				const q = query(collectionRef, ...queryConstraints);
				getEventsData(q);

				//get client & location name from id
				const client = clientsData?.find((item) => item?.ClientID === clientID);
				const clientName = client?.Client;
			}
		}
	}, [clientIds, isMasterAdmin, firstClientLoc?.locId, firstClientLoc?.locname]);

	const queryAndSet = async () => {
		const client = selectedFilterOptions.selectedClient;
		const locations = selectedFilterOptions.selectedLocations.map((location) => location.value);
		const zones = selectedFilterOptions.selectedZones.map((zone) => zone.value);
		// const detections = selectedFilterOptions.selectedDetections.map((zone) => zone.value);
		const detections = new Set(selectedFilterOptions.selectedDetections.map((zone) => zone.value));
		const directions = selectedFilterOptions.selectedDirections.map((direction) => direction.value);
		const date = getDateInYYYYMMDD(new Date(filterValues.filterDate));

		const queryConstraints = [];
		queryConstraints.push(where("Client-ID", "==", client));

		const zoneLocationConstraints = [];
		
		if (locations.length) {
			locations.forEach((location) => {
				if (!isSubstringInArray(location, zones)) zoneLocationConstraints.push(where("Location-ID", "==", location));
			});
			queryConstraints.push(or(...zoneLocationConstraints));
		}

		const collectionRef = collection(db, `${client}-detection-events-${date}`);
		const q = query(collectionRef, and(...queryConstraints));
		const querySnapshot = await getDocs(q);

		let list = [];
		querySnapshot.forEach((doc) => {
			list.push({ id: doc.id, ...doc.data() });
		});

		let filteredList = [...list];
		if (detections.size) filteredList = filteredList?.filter((item) => detections.has(item?.Class_label));
		console.log(list.length, filteredList.length);
		filteredList.sort((a, b) => {
			if (a?.Timestamp < b?.Timestamp) return 1;
			if (b?.Timestamp < a?.Timestamp) return -1;
			return 0;
		});

		setEventData(list);
		setFilteredEventData({
			filterSatus: true,
			filteredData: filteredList,
		});
		setTotalPages(Math.ceil(filteredList?.length / 20));
		setIsLoading(false);
	};

	useEffect(() => {
		if (filterValues.values && queryFromCancellingFilterBox) queryAndSet();
	}, [filterValues, queryFromCancellingFilterBox]);

	// filter data accoring to location, vehicle and date
	const handleFilter = async (event) => {
		event.preventDefault();

		const client = selectedFilterOptions.selectedClient;
		const locations = selectedFilterOptions.selectedLocations.map((location) => location.value);
		// const detections = selectedFilterOptions.selectedDetections.map((zone) => zone.value);
		const detections = new Set(selectedFilterOptions.selectedDetections.map((zone) => zone.value));
		const directions = selectedFilterOptions.selectedDirections.map((direction) => direction.value);
		const dateValue = event.target.date.value;
		var date = dateValue.replace(/-/g, "");

		const queryConstraints = [];
		if (client) queryConstraints.push(where("Client-ID", "==", client));

		if (locations.length) {
			const constraints = [];
			locations.forEach((location) => constraints.push(where("Location-ID", "==", location)));
			queryConstraints.push(or(...constraints));
		} else {
			toast.error("Locations Not Selected");
			return;
		}

		// temproray fix for firebase rate limited query problem
		if (!detections.size) {
			toast.error("Detections Not Selected");
			return;
		}

		const collectionRef = collection(db, `${client}-detection-events-${date}`);
		const q = query(collectionRef, and(...queryConstraints));
		const querySnapshot = await getDocs(q);

		let list = [];
		querySnapshot.forEach((doc) => {
			list.push({ id: doc.id, ...doc.data() });
		});

		let filteredList = [...list];
		if (detections.size) filteredList = filteredList?.filter((item) => detections.has(item?.Class_label));
		filteredList.sort((a, b) => {
			if (a?.Timestamp < b?.Timestamp) return 1;
			if (b?.Timestamp < a?.Timestamp) return -1;
			return 0;
		});

		setEventData(list);
		setFilterValues({
			filterClient: clientsData?.find((item) => item?.ClientID === client).Client,
			filterLocations: selectedFilterOptions.selectedLocations,
			filterZoneNames: selectedFilterOptions.selectedZones,
			filterDetectionTypes: selectedFilterOptions.selectedDetections,
			filterDirectionTypes: selectedFilterOptions.selectedDirections,
			filterDate: formatDate(new Date(dateValue)),
			values: true,
		});
		setFilteredEventData({
			filterSatus: true,
			filteredData: filteredList,
		});
		if (queryFromCancellingFilterBox) setQueryFromCancellingFilterBox(false);
		setTotalPages(Math.ceil(filteredList?.length / 20));
		setIsLoading(false);
	};

	// for reseting filters
	const resetHandler = async () => {
		setSelectedFilterOptions({
			selectedClient: "",
			selectedLocations: [],
			selectedZones: [],
			selectedDetections: [],
			selectedDirections: [],
		});
		if (queryFromCancellingFilterBox) setQueryFromCancellingFilterBox(false);
		setFilterValues((prev) => ({ ...prev, values: false }));
		setFilteredEventData((prev) => ({ ...prev, filterSatus: false }));

		const clientID = selectedFilterOptions.selectedClient;
		const todaysDate = eventTodaysDate();
		const collectionRef = collection(db, `${clientID}-detection-events-${todaysDate}`);
		const queryConstraints = [];
		if (!isMasterAdmin) {
			const locationIDs = userClientAndLocation?.userLocation;
			queryConstraints.push(where("Location-ID", "in", locationIDs));
		}
		const q = query(collectionRef, ...queryConstraints);
		getEventsData(q);

		toast.success("Filters reset to default");
	};

	//helper function to fetch eventsdata
	function getEventsData(q) {
		const unsubscribe = onSnapshot(
			q,
			(querySnapshot) => {
				let list = [];
				querySnapshot.docs.forEach((doc) => {
					list.push({ id: doc.id, ...doc.data() });
				});

				list = list.filter((item) => item?.Class_label !== "Motorcycle");
				list.sort((a, b) => {
					if (a?.Timestamp < b?.Timestamp) return 1;
					if (b?.Timestamp < a?.Timestamp) return -1;
					return 0;
				});

				// console.log(list?.length);
				setIsLoading(false);
				setEventData([...list]);
				setTotalPages(Math.ceil(list?.length / 20));
			},
			(error) => {
				console.log(error);
			}
		);
		return () => {
			unsubscribe();
		};
	}

	// initial select the first element from the data list
	useEffect(() => {
		if (eventData?.length > 0 && !selectedVehicle) {
			setSelectedVehicle(eventData[0]);
		}
		if (!eventData?.length) {
			setSelectedVehicle(null);
		}
	}, [eventData, selectedVehicle]);

	// get selected Vehicle
	const handleSelectedVehicle = (selectedVehicle) => {
		setSelectedVehicle(selectedVehicle);
	};

	// console.log(isLoading, dataLoading, authLoading);
	if (isLoading || dataLoading) {
		return <Loading></Loading>;
	}

	return (
		<div className="bg-white">
			<>
				{/* header div */}
				<div className="flex bg-white items-center justify-between py-2 sticky top-0 z-[2]">
					<div className="pl-3 text-sm text-black">
						<FilterHeaderDisplay
							filterValues={filterValues}
							setFilterValues={setFilterValues}
							setSelectedFilterOptions={setSelectedFilterOptions}
							queryFromCancellingFilterBox={queryFromCancellingFilterBox}
							setQueryFromCancellingFilterBox={setQueryFromCancellingFilterBox}
						/>
					</div>

					<div className="flex items-center justify-end">
						<input type="text" placeholder="Search" className="input input-bordered rounded mr-3 bg-white" />
						<EventFilter
							selectedFilterOptions={selectedFilterOptions}
							setSelectedFilterOptions={setSelectedFilterOptions}
							handleFilter={handleFilter}
							resetHandler={resetHandler}
							isLoading={isLoading}
							setIsLoading={setIsLoading}
						></EventFilter>
						<div className="ml-5">
							<AlMonkLogo />
						</div>
					</div>
				</div>

				{filteredEventData?.filterSatus ? (
					<>
						{filteredEventData?.filteredData?.length > 0 ? (
							<>
								{/* events data section */}
								<div className="flex lg:flex-row md:flex-row flex-col ">
									<EventLists
										eventData={filteredEventData?.filteredData}
										selectedVehicle={selectedVehicle}
										handleSelectedVehicle={handleSelectedVehicle}
										totalPages={totalPages}
										setIsLoading={setIsLoading}
										isLoading={isLoading}
									></EventLists>
									<EventDetails isLoading={isLoading} selectedVehicle={selectedVehicle} setIsLoading={setIsLoading}></EventDetails>
								</div>
							</>
						) : (
							<div className="flex justify-center items-center mx-auto text-black">
								<h2 className="text-xl mt-5 font-semibold">No data found</h2>
							</div>
						)}
					</>
				) : (
					<>
						{eventData && eventData?.length > 0 ? (
							<>
								{/* events data section */}
								<div className="flex lg:flex-row md:flex-row flex-col ">
									<EventLists
										eventData={eventData}
										selectedVehicle={selectedVehicle}
										handleSelectedVehicle={handleSelectedVehicle}
										totalPages={totalPages}
										setIsLoading={setIsLoading}
										isLoading={isLoading}
									></EventLists>
									<EventDetails isLoading={isLoading} selectedVehicle={selectedVehicle} setIsLoading={setIsLoading}></EventDetails>
								</div>
							</>
						) : (
							<div className="flex justify-center items-center mx-auto text-black">
								<h2 className="text-xl mt-5 font-semibold">No data found</h2>
							</div>
						)}
					</>
				)}
			</>
		</div>
	);
};

export default Events;
