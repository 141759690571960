import DashboardLayout from "../../Layout/DashboardLayout";
import Main from "../../Layout/Main";
import ManageUser from "../../Pages/Dashboard/UserManagement/ManageUser";
import AlertMain from "../../Pages/Dashboard/MasterAdminPages/Alerts/AlertMain";
import AlertsConfigMain from "../../Pages/Dashboard/MasterAdminPages/AlertsConfiguration/AlertsConfigMain";
import ClientLocation from "../../Pages/Dashboard/MasterAdminPages/ClientLocation/ClientLocation";
import MasterClient from "../../Pages/Dashboard/MasterAdminPages/ClientPages/MasterClient";
import DeviceCamera from "../../Pages/Dashboard/MasterAdminPages/DeviceCamera/DeviceCamera";
import DeviceMonitor from "../../Pages/Dashboard/MasterAdminPages/DeviceMonitorPages/DeviceMonitor";
import DeviceManagement from "../../Pages/Dashboard/MasterAdminPages/DevicePages/DeviceManagement";
import LocationZone from "../../Pages/Dashboard/MasterAdminPages/LocationZone/LocationZone";
import Events from "../../Pages/Events/Events";
import Login from "../../Pages/Login/Login";
import AdminRoute from "../AdminRoute/AdminRoute";
import MasterAdminRoute from "../MasterAdminRoute/MasterAdminRoute";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import ReportsMain from "../../Pages/Dashboard/MasterAdminPages/Reports/ReportsMain";
import GuardRestrictedRoute from "../AlertSpecificRoute/GuardRestrictedRoute";
import AlertsPageRoute from "../AlertSpecificRoute/AlertsPageRoute";
import EventDetailsMain from "../../Pages/Dashboard/EventDetails/EventDetailsMain";
import EntryDashboardMain from "../../Pages/EntryDashboard/EntryDashboardMain";
import CreateEntryMain from "../../Pages/Dashboard/CreateEntry/CreateEntryMain";

const { createBrowserRouter } = require("react-router-dom");

const router = createBrowserRouter([
	{
		path: "/",
		element: <Main></Main>,
		children: [
			{
				path: "/",
				element: <Login></Login>,
			},
			{
				path: "/login",
				element: <Login></Login>,
			},
		],
	},

	{
		path: "/dashboard",
		element: (
			<PrivateRoute>
				<DashboardLayout />
			</PrivateRoute>
		),
		children: [
			{
				path: "/dashboard",
				element: (
					<PrivateRoute>
						<Events />
					</PrivateRoute>
				),
			},
			{
				path: "/dashboard/entryDashboard",
				element: (
					<PrivateRoute>
						<EntryDashboardMain />
					</PrivateRoute>
				),
			},
			{
				path: "/dashboard/admincontrol",
				element: (
					<AdminRoute>
						<ManageUser />
					</AdminRoute>
				),
			},
			{
				path: "/dashboard/masteradminclient/masteradmindevice",
				element: (
					<MasterAdminRoute>
						<DeviceManagement />
					</MasterAdminRoute>
				),
			},
			{
				path: "/dashboard/masteradmindevice/:deviceId",
				element: (
					<MasterAdminRoute>
						<DeviceCamera />
					</MasterAdminRoute>
				),
			},
			{
				path: "/dashboard/masteradminclient",
				element: (
					<MasterAdminRoute>
						<MasterClient />
					</MasterAdminRoute>
				),
			},
			{
				path: "/dashboard/masteradminclient/:clientId",
				element: (
					<MasterAdminRoute>
						<ClientLocation />
					</MasterAdminRoute>
				),
			},
			{
				path: "/dashboard/masteradminclient/:clientId/:id",
				element: (
					<MasterAdminRoute>
						<LocationZone />
					</MasterAdminRoute>
				),
			},
			{
				path: "/dashboard/masteradmindevicemonitor",
				element: (
					<MasterAdminRoute>
						<DeviceMonitor />
					</MasterAdminRoute>
				),
			},

			{
				path: "/dashboard/alerts",
				element: (
					<AlertsPageRoute>
						<AlertMain />
					</AlertsPageRoute>
				),
			},
			{
				path: "/dashboard/alertconfig",
				element: (
					<GuardRestrictedRoute>
						<AlertsConfigMain />
					</GuardRestrictedRoute>
				),
			},
			{
				path: "/dashboard/reports",
				element: (
					<GuardRestrictedRoute>
						<ReportsMain />
					</GuardRestrictedRoute>
				),
			},
			{
				path: "/dashboard/eventDetails",
				element: (
					<PrivateRoute>
						<EventDetailsMain />
					</PrivateRoute>
				),
			},
			{
				path: "/dashboard/createEntry",
				element: (
					<PrivateRoute>
						<CreateEntryMain />
					</PrivateRoute>
				),
			},
		],
	},

	{
		path: "*",
		element: <div> 404 Not found</div>,
	},
]);

export default router;
