import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react"
import { db } from "../firebase/firebase.config";

const useAdmin = email => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminLoading, setAdminLoading] = useState(true);

    useEffect(() => {
        if (email) {
            const userData = collection(db, "userDataCollection");
            const q = query(userData, where("Email", "==", email));

            const fetchQueryData = async () => {
                let listData = [];
                try {
                    const queryDatas = await getDocs(q);
                    queryDatas.forEach((doc) => {
                        listData.push({ id: doc.id, ...doc.data() });
                    });
                    // console.log('list:',listData[0].Role);
                    const role = listData[0]?.Role;
                    let adminResult = false;
                    if (role === 'Central Admin') {
                        adminResult = true;
                    } else if (role === 'Local Admin') {
                        adminResult = true;
                    } else if (role === 'Regional Admin') {
                        adminResult = true;
                    }
                    setIsAdmin(adminResult);
                    setAdminLoading(false);
                }
                catch (err) {
                    console.log(err);
                }
            };
            fetchQueryData();
        }

    }, [email])
    return { isAdmin, adminLoading }
}

export default useAdmin;